<template>
    <web-layout>
        <div class="login-registration mx-auto xl:w-4/5 xxl:w-3/4">
            <div class="grid gap-8">
                <div class="grid gap-6 sm:gap-10">
                    <div>
                        <a :href="appointmentUrl"
                           class="!inline-flex btn-link btn-icon">
                            <Icon class="w-6" name="back"/>
                            Back to Report List</a>
                    </div>
                    <h1 class="heading-primary">
                        Book Appointment
                    </h1>
                </div>
                <div class="flex flex-col gap-6 md:gap-8">
                    <!--                    <sidebar-registration-->
                    <!--                        :current-page-title="componentDisplay"-->
                    <!--                        :payment-method-completed="paymentMethodCompleted"-->
                    <!--                        :test-booking-completed="testBookingCompleted"-->
                    <!--                        :disclaimer-completed="testBookingCompleted"-->
                    <!--                    />-->
                    <div class="card card--large lg:w-3/4">
                        <form @submit.prevent="goToNext">
                            <test-booking-new
                                :show-buttons="false"
                                v-model="form.test_information"
                                :errors="form.errors"
                                :show-cancel="false"
                                @next="goToNext"
                                @back="goToPrevious"
                                @submit="submitForm"/>
                            <div class="mt-16 border-b">
                                <payment-method
                                    :show-buttons="false"
                                    v-model="form.payment_method"
                                    :errors="form.errors"
                                    @next="goToNext"
                                    @back="goToPrevious"
                                    @submit="submitForm"/>
                            </div>
                            <div class="mt-16">
                                <disclaimer
                                    :show-buttons="false"
                                    v-model="form.disclaimer.isAccepted"
                                    :errors="form.errors"
                                    @next="goToNext"
                                    @back="goToPrevious"
                                    @submit="submitForm"/>
                            </div>
                            <form-footer class="!pt-8">
                                <cancel-button
                                    @click.prevent="cancel()">
                                    Cancel
                                </cancel-button>
                                <primary-button
                                    @click.prevent="submitForm">
                                    Submit
                                </primary-button>
                            </form-footer>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </web-layout>
</template>

<script>
import {useForm} from "@inertiajs/inertia-vue3"
import {useVuelidate} from "@vuelidate/core"
import {
    provide,
    ref,
    toRefs,
    reactive,
} from "vue"
import PrimaryButton from "../../../components/Front/Buttons/PrimaryButton"
import SidebarRegistration from "../../../components/Front/Layout/SidebarRegistration"
import PaymentMethod from "./Partials/PaymentMethod"
import TestBookingNew from "./Partials/TestBooking"
import Disclaimer from "./Partials/Disclaimer"
import WebLayout from "../../../components/Front/Layout/WebLayout"
import CancelButton from "../../../components/Front/Buttons/CancelButton"
import Icon from "../../../components/Front/General/Icon"
import FormFooter from "../../../components/Front/Layout/FormFooter";
import scrollToSmoothly from "../../../script";

const BASIC_INFORMATION = "basic-information"
const TEST_BOOKING = "test-booking"
const PAYMENT_METHOD = "payment-method"
const PAYMENT_SELF = "self"
const DISCLAIMER = "disclaimer"

export default {
    name: "AppointmentBookingSingle",
    components: {
        FormFooter,
        Icon,
        PrimaryButton,
        TestBookingNew,
        SidebarRegistration,
        PaymentMethod,
        Disclaimer,
        WebLayout,
        CancelButton
    },
    props: {
        raceList: {type: Object, required: true},
        genderList: {type: Object, required: true},
        ethnicityList: {type: Object, required: true},
        stateList: {type: Object, required: true},
        testList: {type: Object, required: true},
        pregnancyList: {type: Object, required: true},
        paymentMethodList: {type: Object, required: true},
        paymentStatusList: {type: Object, required: true},
    },
    provide() {
        return {userName: this.$attrs.user_name}
    },
    setup(props) {
        const cancelRedirect = ref(route("patient.appointment.index"))
        const form = useForm("AppointmentBooking", {
            test_information: {
                appointment_date: null,
                name: null,
                is_first_test: null,
                is_health_care_worker: null,
                has_symptom: null,
                symptom_date: null,
                is_hospitalized: null,
                is_in_icu: null,
                is_in_congregate_care: null,
                is_pregnant: null,
                doctor_information: {
                    ordering_facility: null,
                    doctor_name: null,
                    doctor_street_address_one: null,
                    doctor_street_address_two: null,
                    city: null,
                    state: null,
                    zip_code: null,
                    phone_number: null,
                },
            },
            payment_method: {
                payment_type: "self",
                driver_license: null,
                payment_status: null,
                insurance_name: null,
                insurance_holder_name: null,
                insurance_id: null,
                insurance_group: null,
            },
            disclaimer: {
                isAccepted: null,
            },
        })

        const appState = reactive({
            basicInformationCompleted: false,
            testBookingCompleted: false,
            paymentMethodCompleted: false,
            disclaimerMethodCompleted: false,
        })

        const genderOptions = new Map()
        const raceOptions = new Map()
        const ethnicityOptions = new Map()
        const stateOptions = new Map()
        const testOptions = new Map()
        const pregnancyOptions = new Map()
        const paymentMethodOptions = new Map()
        const paymentStatusOptions = new Map()
        const yesNoOptions = new Map()

        Object.keys(props.genderList).forEach(key => genderOptions.set(key, props.genderList[key]))
        Object.keys(props.raceList).forEach(key => raceOptions.set(key, props.raceList[key]))
        Object.keys(props.ethnicityList).forEach(key => ethnicityOptions.set(key, props.ethnicityList[key]))
        Object.keys(props.stateList).forEach(key => stateOptions.set(key, props.stateList[key]))
        Object.keys(props.pregnancyList).forEach(key => pregnancyOptions.set(key, props.pregnancyList[key]))
        Object.keys(props.testList).forEach(key => testOptions.set(key, props.testList[key]))
        Object.keys(props.paymentMethodList).forEach(key => paymentMethodOptions.set(key, props.paymentMethodList[key]))
        Object.keys(props.paymentStatusList).forEach(key => paymentStatusOptions.set(key, props.paymentStatusList[key]))

        yesNoOptions.set(true, "Yes")
        yesNoOptions.set(false, "No")

        provide("raceOptions", raceOptions)
        provide("genderOptions", genderOptions)
        provide("ethnicityOptions", ethnicityOptions)
        provide("stateOptions", stateOptions)
        provide("pregnancyOptions", pregnancyOptions)
        provide("testOptions", testOptions)
        provide("paymentMethodOptions", paymentMethodOptions)
        provide("paymentStatusOptions", paymentStatusOptions)
        provide("yesNoOptions", yesNoOptions)
        provide("form", form)

        function cancel() {
            Inertia.get("/")
        }

        const v = useVuelidate()
        const appointmentUrl = ref(route("patient.appointment.index"))
        return {v, form, ...toRefs(appState), cancelRedirect, appointmentUrl}
    },
    data() {
        return {
            componentDisplay: TEST_BOOKING,
        }
    },
    methods: {
        goToNext() {
            if (this.componentDisplay === BASIC_INFORMATION) {
                this.basicInformationCompleted = true
                this.componentDisplay = TEST_BOOKING
            } else if (this.componentDisplay === TEST_BOOKING) {
                this.testBookingCompleted = true
                this.componentDisplay = PAYMENT_METHOD
            } else if (this.componentDisplay === PAYMENT_METHOD) {
                this.paymentMethodCompleted = true
                this.componentDisplay = DISCLAIMER
            } else if (this.componentDisplay === DISCLAIMER) {
                this.disclaimerMethodCompleted = true
                if (this.form.disclaimer.isAccepted) {
                    this.submitForm()
                }
            }
        },
        goToPrevious() {
            if (this.componentDisplay === TEST_BOOKING) {
                this.$inertia.get(this.cancelRedirect)
            } else if (this.componentDisplay === PAYMENT_METHOD) {
                this.testBookingCompleted = false
                this.paymentMethodCompleted = false
                this.componentDisplay = TEST_BOOKING
            } else if (this.componentDisplay === DISCLAIMER) {
                this.paymentMethodCompleted = false
                this.disclaimerMethodCompleted = false
                this.componentDisplay = PAYMENT_METHOD
            }
        },
        submitForm() {
            const targetUrl = route("patient.appointment-registration-form.store")
            this.v.$touch()

            this.$nextTick(() => {
                const inputError = document.querySelectorAll('.input-error')[0]
                if (inputError) {
                    scrollToSmoothly(inputError.offsetTop - 84);
                }
            })

            if (this.v.$error) return
            this.form.post(targetUrl)
        },
    },
    computed: {
        showTestInformation: function () {
            return this.componentDisplay === TEST_BOOKING
        },
        showPaymentMethod: function () {
            return this.componentDisplay === PAYMENT_METHOD
        },
        showDisclaimerMethod: function () {
            return this.componentDisplay === DISCLAIMER
        },
    },
}
</script>

<style scoped>

</style>

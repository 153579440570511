<template>
    <admin-layout active-link="clients">
        <div class="card--large !px-0 !pt-0 !pb-8">
            <div class="table-head-container">
                <h1 class="font-bold mt-0 text-grey-1100 leading-none" style="font-size: 32px;" v-text="'Clients'"/>
                <primary-button
                    class="flex items-center gap-2 !py-2 !px-5"
                    @click="goToAddClient">
                    <Icon class="w-3.5 h-3.5" name="plus"/>
                    Add Clients
                </primary-button>
            </div>
            <div class="overflow-x-auto">
                <AntTable
                    :columns="clientListTableColumns"
                    :dataSource="clientListTableData"
                    :pagination="appState.pagination"
                    :loading="loading"
                    :scroll="{ x: 1500, y: 700 }"
                    @change="handleChange"
                >
                    <template #filterSearch="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
                        <div class="grid gap-2 p-2">
                            <a-input
                                class="placeholder-grey-400"
                                ref="searchInput"
                                :placeholder="`Enter ${column.dataIndex.split('_').join(' ')}`"
                                :value="selectedKeys[0]"
                                @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                                @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                            >

                            </a-input>
                            <div class="flex items-center justify-between gap-2">
                                <a-button
                                    class="btn-icon"
                                    type="primary"
                                    size="small"
                                    @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                                >
                                    <Icon class="w-3.5 h-3.5" name="search"/>
                                    Search
                                </a-button>
                                <a-button class="ant-reset" size="small" @click="handleReset(clearFilters)">
                                    Reset
                                </a-button>
                            </div>
                        </div>

                    </template>
                    <template #filterIcon="filtered">
                        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }"/>
                    </template>
                    <template #customRender="{ text, column }">
                            <span v-if="searchText && searchedColumn === column.dataIndex">
                                <template
                                    v-for="(fragment, i) in text
                                    .toString()
                                    .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
                                >
                                    <mark
                                        v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                                        class="highlight"
                                        :key="i"
                                    >
                                      {{ fragment }}
                                    </mark>
                                    <template v-else>{{ fragment }}</template>
                                </template>
                          </span>
                        <template v-else>
                            {{ text }}
                        </template>
                    </template>
                    <template #action="{ record }">
                        <div class="flex gap-2 items-center">
                            <action-button @click="goToEditClient(record.id)" action-type="edit"
                                           tooltip-text="Edit client"/>
                        </div>
                    </template>
                </AntTable>
            </div>
        </div>
    </admin-layout>
</template>

<script>
    import { SearchOutlined }     from "@ant-design/icons-vue"
    import { Inertia }            from "@inertiajs/inertia"
    import { default as AButton } from "ant-design-vue/lib/button"
    import { default as AInput }  from "ant-design-vue/lib/input"
    import AntTable               from "ant-design-vue/lib/table"
    import FilterDropdown         from "ant-design-vue/lib/table/filterDropdown"
    import Icon                   from "../../../components/Admin/General/Icon"
    import {
        computed,
        reactive,
        ref,
        watch,
        onMounted,
    }                             from "vue"
    import {get as getFromObject}  from "lodash"
    import AdminLayout            from "../../../components/Admin/Layout/AdminLayout"
    import PrimaryButton          from "../../../components/Admin/Buttons/PrimaryButton"
    import ActionButton           from "../../../components/Admin/Buttons/ActionButton"

    export default {
        name: "Index.vue",
        components: {
            ActionButton,
            PrimaryButton,
            AdminLayout,
            SearchOutlined,
            AButton,
            AInput,
            AntTable,
            FilterDropdown,
            Icon,
        },
        props: {
            clients: { type: Object, default: () => ({}) },
        },
        setup(props) {
            const appState = reactive({
                searchText: "",
                searchedColumn: "",
                pagination: {
                    current: 1,
                    total: 0,
                    pageSize: 20,
                    from: 1,
                },
                queries: {
                    order: '',
                    facility_name: '',
                    doctor_name: '',
                },
            })

            const clientListTableData = ref([])

            const loadClientListTableData = (dataArray) => {
                if(!dataArray || !Array.isArray(dataArray)) {
                    return
                }

                clientListTableData.value = []

                dataArray.forEach((item, index) => {
                    clientListTableData.value.push({
                        key: index + appState.pagination.from,
                        id: item.id,
                        facility_name: item.facility_name,
                        doctor_name: item.doctor_name,
                        street_address_one: item.street_address_one,
                        street_address_two: item.street_address_two,
                        city: item.city,
                        state: item.state,
                        zip_code: item.zip_code,
                        phone_number: item.phone_number,
                    })
                })

            }

            const clientListTableColumns = computed(() => {
                const filtered = filteredInfo.value || {}
                const sorted = sortedInfo.value || {}
                return [
                    {
                        title: "S.No.",
                        width: "7em",
                        dataIndex: "key",
                        key: "key",
                        fixed: "left",
                    },
                    {
                        title: "Action",
                        key: "action",
                        width: "7em",
                        slots: {
                            customRender: "action",
                        },
                        fixed: "left",
                    },
                    {
                        title: "Facility Name",
                        dataIndex: "facility_name",
                        key: "facility_name",
                        sorter: (a, b) => a.facility_name.localeCompare(b.facility_name),
                        sortDirections: ["descend", "ascend"],
                        slots: {
                            filterDropdown: "filterSearch",
                            filterIcon: "filterIcon",
                            customRender: "customRender",
                        },
                        onFilter: (value, record) =>
                            record.facility_name.toString().toLowerCase().includes(value.toLowerCase()),
                        onFilterDropdownVisibleChange: visible => {
                            if (visible) {
                                setTimeout(() => {
                                    searchInput.value.focus()
                                }, 0)
                            }
                        },
                    },
                    {
                        title: "Doctor Name",
                        dataIndex: "doctor_name",
                        key: "doctor_name",
                        sorter: (a, b) => a.doctor_name.localeCompare(b.doctor_name),
                        sortDirections: ["descend", "ascend"],
                        slots: {
                            filterDropdown: "filterSearch",
                            filterIcon: "filterIcon",
                            customRender: "customRender",
                        },
                        onFilter: (value, record) =>
                            record.doctor_name.toString().toLowerCase().includes(value.toLowerCase()),
                        onFilterDropdownVisibleChange: visible => {
                            if (visible) {
                                setTimeout(() => {
                                    searchInput.value.focus()
                                }, 0)
                            }
                        },
                    },
                    {
                        title: "Street Address One",
                        dataIndex: "street_address_one",
                        key: "street_address_one",
                        sorter: (a, b) => a.street_address_one.localeCompare(b.street_address_one),
                        sortDirections: ["descend", "ascend"],
                    },
                    {
                        title: "Street Address Two",
                        dataIndex: "street_address_two",
                        key: "street_address_two",
                        sorter: (a, b) => a.street_address_two.localeCompare(b.street_address_two),
                        sortDirections: ["descend", "ascend"],
                    },
                    {
                        title: "City",
                        dataIndex: "city",
                        key: "city",
                        width: "12em",
                        sorter: (a, b) => a.city.localeCompare(b.city),
                        sortDirections: ["descend", "ascend"],
                    },
                    {
                        title: "State",
                        dataIndex: "state",
                        key: "state",
                        width: "12em",
                        sorter: (a, b) => a.state.localeCompare(b.state),
                        sortDirections: ["descend", "ascend"],
                    },
                    {
                        title: "Zip Code",
                        dataIndex: "zip_code",
                        key: "zip_code",
                        width: "12em",
                        sorter: (a, b) => a.zip_code.localeCompare(b.zip_code),
                        sortDirections: ["descend", "ascend"],
                    },
                    {
                        title: "Phone Number",
                        dataIndex: "phone_number",
                        key: "phone_number",
                        width: "12em",
                        sorter: (a, b) => a.phone_number.localeCompare(b.phone_number),
                        sortDirections: ["descend", "ascend"],
                    },
                ]
            })

            const searchInput = ref()
            const filteredInfo = ref()
            const sortedInfo = ref()
            // const pagination = reactive({})
            const loading = ref(true)

            const handleSearch = (selectedKeys, confirm, dataIndex) => {
                confirm()
                appState.searchText = selectedKeys[0]
                appState.searchedColumn = dataIndex
            }

            const handleReset = clearFilters => {
                clearFilters()
                appState.searchText = ""
            }

            const loadOrder = (sorter)=> {
                if(!sorter) {
                    return ''
                }
                const order = getFromObject(sorter, 'order', "ascend")
                const column = getFromObject(sorter, 'field', '')
                return order === "ascend" ? `${column}` : `-${column}`
            }

            const handleChange = (pager, filters, sorter) => {
                appState.pagination = {...pager}
                appState.queries.order = loadOrder(sorter)
                appState.queries.facility_name = getFromObject(filters, 'facility_name.0', '')
                appState.queries.doctor_name = getFromObject(filters, 'doctor_name.0', '')
                console.log(appState.queries)
                loadData()
                filteredInfo.value = filters
                sortedInfo.value = sorter
            }

            const loadData = () => {
                loading.value = true
                axios.get("/api/clients", {
                    params: {
                        page: appState.pagination.current,
                        ...appState.queries
                    }
                }).then(({data}) => {
                    appState.pagination.current = (getFromObject(data, 'meta.current_page', 1))
                    appState.pagination.total = (getFromObject(data, 'meta.total', 0))
                    appState.pagination.pageSize = (getFromObject(data, 'meta.per_page', 20))
                    appState.pagination.from = (getFromObject(data, 'meta.from', 1))

                    loadClientListTableData(getFromObject(data, 'data', []))

                    loading.value = false
                }).catch((error) => {
                    loading.value = false
                    console.error(error)
                })
            }

            onMounted(() => {
                loadData()
            })

            // watch(() => props.clients, (newValue, oldValue) => {
            //     // let tmpDataArr = Array.from(props.clients.data)
            //     let tmpDataArr = []
            //
            //     clientListTableData.value = []
            //
            //     tmpDataArr.forEach((item, index) => {
            //         clientListTableData.value.push({
            //             key: index + 1,
            //             id: item.id,
            //             facility_name: item.facility_name,
            //             doctor_name: item.doctor_name,
            //             street_address_one: item.street_address_one,
            //             street_address_two: item.street_address_two,
            //             city: item.city,
            //             state: item.state,
            //             zip_code: item.zip_code,
            //             phone_number: item.phone_number,
            //         })
            //     })
            // }, {
            //     deep: true,
            //     immediate: true,
            // })

            const goToAddClient = () => {
                Inertia.get(route("admin.clients.create"))
            }
            const goToEditClient = (id) => {
                Inertia.get(route("admin.clients.edit", id))
            }

            return {
                handleSearch,
                handleReset,
                handleChange,
                searchText: "",
                searchInput,
                searchedColumn: "",
                clientListTableColumns,
                clientListTableData,
                goToAddClient,
                goToEditClient,
                appState,
                loading
            }
        },
    }
</script>

<style scoped>

</style>

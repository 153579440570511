<template>
    <AntTable
        :columns="requisitionListTableColumns"
        :dataSource="requisitionListTableData"
        :pagination="requisitionTableState.pagination"
        :loading="requisitionTableState.loading"
        :scroll="requisitionTableState.scroll"
        @change="handleChange">
        <template #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
            <div class="grid gap-2 p-2">
                <a-input
                    class="placeholder-grey-400"
                    ref="searchInput"
                    :placeholder="`Search ${column.dataIndex.split('_').join(' ')}`"
                    :value="selectedKeys[0]"
                    style="width: 188px; margin-bottom: 8px; display: block"
                    @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                    @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                />
                <div class="flex items-center justify-between gap-2">
                    <a-button
                        type="primary"
                        size="small"
                        class="btn-icon"
                        @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                    >
                        <Icon class="w-3.5 h-3.5" name="search"/>
                        Search
                    </a-button>
                    <a-button size="small" class="ant-reset" @click="handleReset(clearFilters)">
                        Reset
                    </a-button>
                </div>
            </div>
        </template>
        <template #filterIcon="filtered">
            <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }"/>
        </template>
        <template #customRender="{ text, column }">
                        <span v-if="searchText && searchedColumn === column.dataIndex">
                            <template
                                v-for="(fragment, i) in text
                                .toString()
                                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
                            >
                                <mark
                                    v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                                    class="highlight"
                                    :key="i"
                                >
                                    {{ fragment }}
                                </mark>
                                <template v-else>{{ fragment }}</template>
                            </template>
                        </span>
            <template v-else>
                {{ text }}
            </template>
        </template>
        <template #patientName="{record}">
            <PatientName :name="record.patient_name"/>
        </template>
        <template #sampleId="{ record }">
            <p
                v-for="sample in record.samples"
                v-text="sample.sample_id"
                :key="sample.sample_id"
            />
        </template>
        <template #sampleType="{ record }">
            <p
                v-for="sample in record.samples"
                v-text="sample.sample_type"
                :key="sample.sample_id"
            />
        </template>
        <template #sampleCollectedOn="{ record }">
            <p
                v-for="sample in record.samples"
                v-text="sample.sample_collected_on"
                :key="sample.sample_id"
            />
        </template>
        <template #testName="{ record }">
            <p
                v-for="(test, index) in getTests(record)"
                :key="index"
                v-text="test"
            />
        </template>
        <template #action="{ record }">
            <action-button-wrapper class="whitespace-wrap text-grey-500">
                <action-button
                    tooltip-text="View"
                    action-type="view"
                    @click="showRequisitionDetail(record.req_id)"/>
                <action-button action-type="delete" tooltip-text="Delete"
                               @click="openRequisitionDeleteModal(record.req_id)">
                </action-button>
            </action-button-wrapper>
            <!--                        <action-button-wrapper>-->
            <!--                            <action-button-->
            <!--                                tooltip-text="View"-->
            <!--                                action-type="view"-->
            <!--                                @click="showRequisitionDetail(record.req_id)"/>-->
            <!--                            <action-button-->
            <!--                                tooltip-text="Delete"-->
            <!--                                action-type="delete"-->
            <!--                                @click="handleShowRequisitionDeleteModal(record.req_id)"/>-->
            <!--                        </action-button-wrapper>-->
        </template>
        <template #email="{record}">
            <span class="!normal-case">{{ record.email }}</span>
        </template>
    </AntTable>
</template>

<script>
    import { default as AButton } from "ant-design-vue/lib/button"
    import { default as AInput }  from "ant-design-vue/lib/input"
    import AntTable               from "ant-design-vue/lib/table"
    import Icon                   from "../../../../components/Admin/General/Icon"
    import PatientName            from "../../../../components/Admin/General/PatientName"
    import { SearchOutlined }     from "@ant-design/icons-vue"
    import ActionButtonWrapper    from "../../../../components/Admin/Buttons/ActionButtonWrapper"
    import ActionButton           from "../../../../components/Admin/Buttons/ActionButton"
    import {inject}               from "vue"
    import DeleteRequisitionModal from "./DeleteRequisitionModal"
    export default {
        name: "RequisitionListTable",
        components: {
            DeleteRequisitionModal,
            AButton,
            ActionButton,
            ActionButtonWrapper,
            AInput,
            AntTable,
            PatientName,
            Icon,
            SearchOutlined,
        },
        setup() {
            const requisitionListTableColumns = inject('requisitionListTableColumns', [])
            const requisitionListTableData = inject('requisitionListTableData', [])
            const requisitionTableState = inject('requisitionTableState', { })
            const handleChange = inject('handleChange')
            const handleSearch = inject('handleSearch')
            const handleReset = inject('handleReset')
            const searchInput = inject('searchInput')
            const showRequisitionDetail = inject('showRequisitionDetail' )
            const openRequisitionDeleteModal = inject('openRequisitionDeleteModal')
            const filteredInfo = inject('filteredInfo')
            const sortedInfo = inject('sortedInfo')

            const getTests = (requisition) => {
                let tests = []
                requisition.samples.forEach((sample) => {
                    if (!Array.isArray(sample.tests)) {
                        return
                    }
                    sample.tests.forEach((test) => {
                        tests.push(test.test_name)
                    })
                })

                return tests
            }

            return {
                requisitionListTableData,
                requisitionListTableColumns,
                requisitionTableState,
                handleChange,
                handleSearch,
                handleReset,
                getTests,
                searchInput,
                showRequisitionDetail,
                searchText: requisitionTableState.searchText,
                searchedColumn: requisitionTableState.searchedColumn,
                openRequisitionDeleteModal,
            }
        }
    }
</script>

<style scoped>

</style>

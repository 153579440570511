<template>
    <web-layout>
        <div class="container !p-0">
            <div class="card--large !p-0">
                <div class="table-head-container flex flex-wrap gap-6 justify-between items-center">
                    <h1 class="font-bold mt-0 mb-8 text-grey-1100 leading-none !mb-0" style="font-size: 32px;" v-text="'Report List'"/>
                    <div class="flex gap-2">
                        <PrimaryButton :href="addAppointmentUrl" class="btn-icon !text-sm !px-4.5 !py-2.5">
                            <Icon class="!w-3 h-3" name="plus"/>
                            Book Appointment
                        </PrimaryButton>
                    </div>
                </div>
                <div class="tab mb-8">
                    <div class="tab__list px-8 gap-2" aria-label="Tabs">
                        <button
                            @click="showTab(TAB_PATIENT)"
                            :class="currentTab === TAB_PATIENT  ? 'tab__item is--active !rounded-b-none bg-white !text-sm':'tab__item bg-white !rounded-b-none !text-sm'"
                        >
                            <span> Patient</span>
                        </button>
                        <button
                            :disabled="!familyAppointmentDetail.data.length"
                            @click="showTab(TAB_FAMILY)"
                            :class="currentTab === TAB_FAMILY  ?
                         'tab__item is--active !rounded-b-none bg-white !text-sm':'tab__item bg-white !rounded-b-none !text-sm'"
                        >
                            <span> Family Member</span>
                        </button>
                    </div>
                </div>
                <div v-if="currentTab === TAB_PATIENT" class="overflow-x-auto">
                    <ant-table v-if="itemsWithSno" :columns="columns" :data-source="itemsWithSno" rowKey="sno" :pagination="false"
                               @change="onChange">
                        <template #resultDate="{record}">
                            <span v-if="record.sample_result_date" v-text="record.sample_result_date"/>
                            <span v-else class="text-red-500 italic" v-text="'Not Uploaded'"/>
                        </template>

                        <template #operation="{ record }">

                            <a :href="'/result/pdf/'+record.requisitions_id+'/'+record.sample_id"
                               class="btn-action btn-action--pdf"
                               v-if="record.sample_status === 'result_approved'">
                                <Icon class="w-3 h-3.5" name="pdf"/>
                            </a>

                            <p v-else>No report available yet</p>
                        </template>
                    </ant-table>
                    <report-empty v-else :add-appointment-url="addAppointmentUrl"/>
                </div>
                <div v-else class="mt-8 overflow-x-auto">
                    <ant-table v-if="familyItemsWithSno" :columns="familyColumns" :data-source="familyItemsWithSno" rowKey="sno" :pagination="false"
                               @change="onChange">
                        <template #resultDate="{record}">
                            <span v-if="record.sample_result_date" v-text="record.sample_result_date"/>
                            <span v-else class="text-red-500 italic" v-text="'Not Uploaded'"/>
                        </template>

                        <template #operation="{ record }">

                            <a :href="'/result/pdf/'+record.requisitions_id+'/'+record.sample_id"
                               class="btn-action btn-action--pdf"
                               v-if="record.sample_status === 'result_approved'">
                                <Icon class="w-3 h-3.5" name="pdf"/>
                            </a>

                            <p v-else>No report available yet</p>
                        </template>
                    </ant-table>
                    <report-empty v-else :add-appointment-url="addAppointmentUrl"/>
                </div>
            </div>
        </div>
    </web-layout>
</template>

<script>
import {
    computed,
    reactive,
    ref, toRefs,
} from "vue"
    import PrimaryButton            from "../../../../components/Front/Buttons/PrimaryButton"
    import WebLayout                from "../../../../components/Front/Layout/WebLayout"
    import { default as AButton }   from "ant-design-vue/lib/button"
    import "ant-design-vue/lib/button/style/index.css"
    import "ant-design-vue/lib/dropdown/style/index.css"
    import { default as AInput }    from "ant-design-vue/lib/input"
    import "ant-design-vue/lib/input/style/index.css"
    import AntTable                 from "ant-design-vue/lib/table"
    import "ant-design-vue/lib/table/style/index.css"
    import { get as getFromObject } from "lodash"
    import Icon                     from "../../../../components/Front/General/Icon"
    import ReportEmpty              from "./ReportEmpty"
    const TAB_PATIENT = "patient_appointment"
    const TAB_FAMILY = "family_appointment"
    export default {
        name: "Index",
        props: ["patientAppointmentInfo", "statusOptions", "testOptions", "patientFullName","familyAppointmentDetail"],
        provide() {
            return { userName: this.patientFullName }
        },
        components: {
            PrimaryButton,
            WebLayout,
            AButton,
            AInput,
            AntTable,
            Icon,
            ReportEmpty,
        },
        setup(props) {
            const addAppointmentUrl = ref(route("patient.appointment.create"))
            const state = reactive({
                searchText: "",
                searchedColumn: "",
            })
            const sortedInfo = ref()
            const searchInput = ref()
            const filteredInfo = ref()

            const appState = reactive({
                currentTab: TAB_PATIENT,
            })

            const itemsWithSno = computed(() => {
                return props.patientAppointmentInfo.data.map((d, index) => ({ ...d, sno: index + 1 }))
            })
            const familyItemsWithSno=computed(()=>{
                return props.familyAppointmentDetail.data.map((d, index) => ({ ...d, sno: index + 1 }))
            })
            const columns = computed(() => {
                const sorted = sortedInfo.value || {}
                const filtered = filteredInfo.value || {}
                return [
                    {
                        title: "S.No.",
                        dataIndex: "sno",
                        key: "sno",
                    },
                    {
                        title: "Test Type",
                        dataIndex: "test_name",
                        key: "test_name",
                        filters: [
                            {
                                text: "SARS-CoV-2 RT-PCR",
                                value: props.testOptions["sars-cov-2-rt-pcr"],
                            },
                            {
                                text: "COVID Antibody Rapid Test",
                                value: props.testOptions["covid-antibody-rapid-test"],
                            },
                        ],
                        filteredValue: filtered.test_name || null,
                        onFilter: (value, record) => record.test_name.includes(value),
                        sorter: (a, b) => a.test_name.localeCompare(b.test_name),
                        sortDirections: ["descend", "ascend"],
                        ellipsis: true,

                    },
                    {
                        title: "Appointment Date",
                        dataIndex: "appointment_date",
                        key: "appointment_date",
                        sorter: (a, b) => a.appointment_date.localeCompare(b.appointment_date),
                        sortDirections: ["descend", "ascend"],
                    },
                    {
                        title: "Status",
                        dataIndex: "status",
                        key: "status",
                        filters: [
                            {
                                text: "Pending",
                                value: props.statusOptions['pending'].charAt(0).toUpperCase()+ props.statusOptions['pending'].slice(1),
                            },
                            {
                                text: "Confirmed",
                                value: props.statusOptions['confirmed'].charAt(0).toUpperCase() + props.statusOptions['confirmed'].slice(1),
                            },
                            {
                                text: "Rescheduled",
                                value: props.statusOptions['rescheduled'].charAt(0).toUpperCase() + props.statusOptions['rescheduled'].slice(1),
                            },
                        ],
                        filteredValue: filtered.status || null,
                        onFilter: (value, record) => record.status.includes(value),
                        sorter: (a, b) => a.status.localeCompare(b.status),
                        sortDirections: ["descend", "ascend"],
                    },
                    {
                        title: "Result Date",
                        dataIndex: "sample_result_date",
                        key: "sample_result_date",
                        sorter: (a, b) => a.sample_result_date.localeCompare(b.sample_result_date),
                        sortDirections: ["descend", "ascend"],
                        slots: {
                            customRender: "resultDate",
                        },
                    },
                    {
                        title: "Action",
                        key: "operation",
                        slots: {
                            customRender: "operation",
                        },
                    },
                ]
            })
 const familyColumns = computed(() => {
                const sorted = sortedInfo.value || {}
                const filtered = filteredInfo.value || {}
                return [
                    {
                        title: "S.No.",
                        dataIndex: "sno",
                        key: "sno",
                    },
                    {
                        title: "Patient Name",
                        dataIndex: "patient_name",
                        key: "patient_name",
                        sorter: (a, b) => a.patient_name.localeCompare(b.patient_name),
                        sortDirections: ["descend", "ascend"],
                    },
                    {
                        title: "Test Type",
                        dataIndex: "test_name",
                        key: "test_name",
                        filters: [
                            {
                                text: "SARS-CoV-2 RT-PCR",
                                value: props.testOptions["sars-cov-2-rt-pcr"],
                            },
                            {
                                text: "COVID Antibody Rapid Test",
                                value: props.testOptions["covid-antibody-rapid-test"],
                            },
                        ],
                        filteredValue: filtered.test_name || null,
                        onFilter: (value, record) => record.test_name.includes(value),
                        sorter: (a, b) => a.test_name.localeCompare(b.test_name),
                        sortDirections: ["descend", "ascend"],
                        ellipsis: true,

                    },
                     {
                        title: "Sample Collection Date",
                        dataIndex: "sample_collection_date",
                        key: "sample_collection_date",
                        sorter: (a, b) => a.appointment_date.localeCompare(b.appointment_date),
                        sortDirections: ["descend", "ascend"],
                    },

                    {
                        title: "Result Date",
                        dataIndex: "sample_result_date",
                        key: "sample_result_date",
                        sorter: (a, b) => a.sample_result_date.localeCompare(b.sample_result_date),
                        sortDirections: ["descend", "ascend"],
                        slots: {
                            customRender: "resultDate",
                        },
                    },
                    {
                        title: "Action",
                        key: "operation",
                        slots: {
                            customRender: "operation",
                        },
                    },
                ]
            })

            const onChange = (pagination, filters, sorter) => {
                console.log("params", pagination, filters, sorter)
                filteredInfo.value = filters
                sortedInfo.value = sorter
            }
            function showTab(tab) {
                appState.currentTab = tab
            }
            return {
                ...toRefs(appState),
                TAB_PATIENT,
                TAB_FAMILY,
                showTab,
                addAppointmentUrl,
                getFromObject,
                columns,
                familyColumns,
                itemsWithSno,
                familyItemsWithSno,
                searchInput,
                onChange,
            }
        },
    }
</script>

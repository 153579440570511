<template>
    <button v-if="linkComponent === 'button'"
            :class="classes"
            :type="type"
            v-bind="$attrs">
        <slot/>
    </button>
    <component
        v-else
        :is="linkComponent"
        :as="as"
        :class="classes"
        :data="dataProps"
        :href="href"
        :method="method"
        v-bind="$attrs">
        <slot/>
    </component>
</template>

<script>
import {Link} from '@inertiajs/inertia-vue3'

export default {
    name: "ButtonComponent",
    inheritAttrs: false,
    components: {Link,},
    props: {
        inertiaLink: {type: Boolean, required: false, default: true},
        type: {type: String, required: false, default: "button"},
        as: {type: String, required: false, default: "a"},
        method: {type: String, required: false, default: "get"},
        href: {type: String, required: false, default: null},
        dataProps: {type: [Object], required: false, default: () => ({})},
    },

    computed: {
        classes: function () {
            return `inline-block py-3.5 px-6 rounded-xl  text-center transition`
        },

        linkComponent: function () {
            return this.href ? (this.inertiaLink ? "Link" : "a") : "button"
        },
    },
}
</script>

<template>
    <div class="flex justify-between items-end pb-4 border-b border-pinkShade-200 !mb-6">
        <H2 :title="title"/>
    </div>
</template>

<script>
    import H2 from "../typography/H2"

    export default {
        name: "CardHeader",
        components: { H2 },
        props: {
            title: { type: String, required: false },
        },
    }
</script>

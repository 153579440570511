<template>
    <Multiselect
        v-model="optionValue"
        :options="optionsArray"
        label="name"
        @input="handleOnInput"
        :placeholder="placeHolder"
        @select="handleOnSelect"
        :searchable="searchable"
        trackBy="name"
        :can-deselect="allowEmpty"
    />
</template>

<script>
import Multiselect from "@vueform/multiselect"

export default {
    name: "SelectInput",
    components: {Multiselect},
    props: {
        modelValue: {type: [Number, String], required: false, default: null},
        placeHolder: {type: String, required: false, default: "Select an option"},
        options: {type: [Object, Map], required: true},
        searchable: {type: Boolean, required: false},
        allowEmpty: { type: Boolean, required: false, default: true }
    },

    data() {
        return {
            optionValue: 0,
            optionsArray: [],
        }

    },

    // mounted() {
    //     this.optionValue = this.modelValue;
    //     const arr = Object.fromEntries(this.options)
    //     for (const key in arr) {
    //         if (arr.hasOwnProperty(key)) {
    //             this.optionsArray.push(
    //                 {
    //                     name: arr[key],
    //                     value: key,
    //                 },
    //             )
    //         }
    //     }
    // },

    watch: {
        modelValue: {
            handler(value) {
                this.optionValue = value
            },
            deep: true,
            immediate: true
        },
        options: {
            handler(value) {
                this.optionsArray = []
                const arr = Object.fromEntries(value)
                for (const key in arr) {
                    if (arr.hasOwnProperty(key)) {
                        this.optionsArray.push(
                            {
                                name: arr[key],
                                value: key,
                            },
                        )
                    }
                }
            },
            deep: true,
            immediate: true
        },
    },

    methods: {
        handleOnInput() {
            this.$emit("update:modelValue", this.optionValue)
        },

        handleOnSelect() {
            this.$emit("update:modelValue", this.optionValue)
        },
    },
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<template>
    <div class="dropdown__menu" @blur="open = false" @close-dropdown="toggle">
        <button @click.stop="toggle" tabindex="0">
            <slot name="trigger"/>
        </button>
        <ul :class="listClass" @click.stop>
            <slot name="content"/>
        </ul>
    </div>
</template>


<script>
    export default {
        data() {
            return {
                showMenu: false,
            }
        },
        computed: {
            listClass: function() {
                if (this.showMenu) {
                    return "dropdown dropdown--show"
                }
                return "dropdown"
            },
        },
        methods: {
            toggle: function() {
                this.showMenu = !this.showMenu
                this.showMenu && this.$nextTick(() => {
                    document.addEventListener("click", this.hide)
                })
            },
            hide: function() {
                this.showMenu = false
                document.removeEventListener("click", this.hide)
            },
        },
    }
</script>

<style lang="scss">

</style>

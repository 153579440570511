<template>
  <div class="btn-icon cursor-pointer p-4 w-full block rounded bg-red-100 text-danger-100 hover:bg-red-200  transition">
    <Icon name="delete" class="w-3 h-3"/>
    <slot/>
  </div>
</template>

<script>
import Icon from "../General/Icon"

export default {
  name: "AddButton",
  components: {Icon},
}
</script>


<style lang="scss">
.delete-btn {
  background: #FEF7F8;
}
</style>

<template>
    <admin-layout active-link="billing">
        <div class="card--large !p-0 min-h-32">
            <div class="table-head-container !pb-0">
                <h1 class="heading-primary !text-grey-1100" v-text="'Billing'"/>
                <dropdown>
                    <template v-slot:trigger>

                        <primary-button class="btn-icon">
                            <Icon name="download"/>
                            Download report
                        </primary-button>
                    </template>

                    <template v-slot:content
                    >
                        <li>
                            <a class="!flex w-full whitespace-nowrap gap-2 items-center btn-icon hover:text-tertiary-200"
                               :href="downloadCovidReport(reportDateRange)">
                                <Icon name="download"/>
                                Download DHHS Report
                            </a>
                        </li>
                        <li>
                            <a class="!flex w-full whitespace-nowrap gap-2 items-center btn-icon hover:text-tertiary-200"
                               :href="downloadInsuranceReport(reportDateRange)">
                                <Icon name="download"/>
                                Download Billing Report
                            </a>
                        </li>
                    </template>
                </dropdown>
            </div>
            <div class="p-10">
                <div class="grid gap-6 sm:grid-cols-2 lg:grid-cols-4">
                    <div>
                        <label-component :value="'Start Date'"/>
                        <AntDatePicker
                            v-model:value="startValue"
                            :disabled-date="disabledStartDate"
                            format="MM-DD-YYYY"
                            placeholder="Select start date"
                            class="form-control h-12 !px-4 !py-2.5"
                            @openChange="handleStartOpenChange"
                        />
                    </div>
                    <div>
                        <label-component :value="'End Date'"/>
                        <AntDatePicker
                            v-model:value="endValue"
                            :disabled-date="disabledEndDate"
                            format="MM-DD-YYYY"
                            placeholder="Select end date"
                            class="form-control h-12 !px-4 !py-2.5"
                            :open="endOpen"
                            @openChange="handleEndOpenChange"
                        />
                    </div>
                    <div>
                        <label-component :value="'Ordering Facility'"/>
                        <ant-autocomplete class="form-control--suggestion w-full"
                                          v-model:value="facility_name"
                                          :options="facilityOptions"
                                          @search="onSearchClient"
                                          @select="onSelectClient"
                        />
                    </div>
                    <div>
                        <label-component :value="'Type of Test'"/>
                        <ant-autocomplete class="form-control--suggestion w-full"
                                          v-model:value="test_name"
                                          :options="testOptions"
                                          @search="onSearchTest"
                                          @select="onSelectTest"
                        />
                    </div>
                </div>
            </div>
        </div>
    </admin-layout>
</template>

<script>
import {
    default as AntDatePicker,
    RangePicker,
} from "ant-design-vue/lib/date-picker"
import {DatePicker} from "v-calendar"
import {
    inject,
    reactive,
    ref,
    watch,
} from "vue"
import InputComponent from "../../../components/Admin/Forms/Input"
import Icon from "../../../components/Admin/General/Icon"
import Dropdown from "../../../components/Admin/General/Dropdown"
import PrimaryButton from "../../../components/Admin/Buttons/PrimaryButton"
import AdminLayout from "../../../components/Admin/Layout/AdminLayout"
import {default as AntAutocomplete} from "ant-design-vue/lib/auto-complete"
import LabelComponent from "../../../components/Front/Forms/Label";

export default {
    name: "CovidReport",
    components: {
        RangePicker,
        Icon,
        InputComponent,
        AdminLayout,
        DatePicker,
        AntDatePicker,
        AntAutocomplete,
        LabelComponent,
        Dropdown,
        PrimaryButton,
    },
    props: {
        tests: {type: Object, required: false, default: () => ({})},
        clients: {type: Object, required: false, default: () => ({})}
    },
    setup(props) {
        const reportDateRange = ref([])
        const facilityOptions = ref([])
        const testOptions = ref([])
        const queries = reactive({

        })


        const currentDate = new Date()

        const datePickerConfig = {
            type: "string",
            mask: "MM-DD-YYYY", // Uses 'iso' if missing
        }

        const startValue = ref()
        const endValue = ref()
        const endOpen = ref(false)

        const disabledStartDate = startValue => {
            if (!startValue || !endValue.value) {
                return false
            }

            return startValue.valueOf() > endValue.value.valueOf()
        }

        const disabledEndDate = endValue => {
            if (!endValue || !startValue.value) {
                return false
            }

            return startValue.value.valueOf() >= endValue.valueOf()
        }

        const handleStartOpenChange = open => {
            if (!open) {
                endOpen.value = true
            }
        }

        const handleEndOpenChange = open => {
            endOpen.value = open
        }

        watch(startValue, () => {
            // console.log("startValue", startValue.value)
        })
        watch(endValue, () => {
            // console.log("endValue", endValue.value)
        })

        const query = reactive({
            start: null,
            end: null,
            facility_name: null,
            type_of_test: null,
        })

        const onChange = (value, dateString) => {
            // console.log('Selected Time: ', value);
            // console.log("Formatted Selected Time: ", dateString)
        }

        const onOk = value => {
            // console.log("onOk: ", value)
        }

        const downloadCovidReport = (range) => {
            if (startValue.value) {
                query.start = (startValue.value).format("DD-MM-YYYY")
            }

            if (endValue.value) {
                query.end = (endValue.value).format("DD-MM-YYYY")
            }
            // console.log(query)
            return (route("admin.reports.download.dhs.csv", {
                _query: {
                    from: query.start,
                    to: query.end,
                    facility_name: query.facility_name,
                    type_of_test: query.type_of_test,
                },
            }))
        }

        const downloadInsuranceReport = (range) => {
            if (startValue.value) {
                query.start = (startValue.value).format("DD-MM-YYYY")
            }

            if (endValue.value) {
                query.end = (endValue.value).format("DD-MM-YYYY")
            }

            return (route("admin.reports.download.insurance.csv", {
                _query: {
                    from: query.start,
                    to: query.end,
                    facility_name: query.facility_name,
                    type_of_test: query.type_of_test,
                },
            }))
        }
        const clientData = ref([])

        if (props.clients.data && Array.isArray(props.clients.data)) {
            clientData.value = props.clients.data
            let tmpArray = []
            tmpArray.push({
                value: 'All',
                text: 'All'
            })
            props.clients.data.forEach(item => {
                tmpArray.push({
                    value: item.facility_name
                })
            })
            facilityOptions.value = tmpArray
        }

        const onSearchClient = searchText => {
            query.facility_name = null
            let tmpArray = []
            if(!searchText) {
                tmpArray.push({
                    value: 'All',
                    text: 'All'
                })
            }
            props.clients.data.forEach(item => {
                if (item.facility_name && item.facility_name.toLowerCase().includes(searchText.toLowerCase())) {
                    tmpArray.push({
                        key: item.id,
                        value: item.facility_name
                    })
                }
            })
            facilityOptions.value = tmpArray
        }

        const onSelectClient = value => {
            if(value === "All") {
                query.facility_name = "all"
                return
            }
            let selectedItem = clientData.value.find(item => item.facility_name === value)
            query.facility_name = selectedItem.facility_name ?? null
        }

        const testData = ref([])

        if (props.tests) {
            let tmpArray = []
            tmpArray.push({
                value: 'All',
                text: 'All'
            })
            Object.keys(props.tests).forEach(key => {
                tmpArray.push({
                    value: props.tests[key],
                    text: props.tests[key]
                })
            })
            testOptions.value = tmpArray
        }

        const onSearchTest = searchText => {
            query.type_of_test = null
            let tmpArray = []
            if(!searchText) {
                tmpArray.push({
                    value: 'All',
                    text: 'All'
                })
            }

            Object.keys(props.tests).forEach(key => {
                if(props.tests[key] && props.tests[key].toLowerCase().includes(searchText.toLowerCase())) {
                    tmpArray.push({
                        value: props.tests[key],
                        text: props.tests[key]
                    })
                }
            })
            testOptions.value = tmpArray
        }

        const onSelectTest = value => {
            if(value === "All") {
                query.type_of_test = "all"
                return
            }
            let selectedItem = Object.keys(props.tests).find(key => props.tests[key] === value)
            query.type_of_test = selectedItem  ?? null
        }

        return {
            query,
            endOpen,
            startValue,
            endValue,
            disabledStartDate,
            disabledEndDate,
            handleStartOpenChange,
            handleEndOpenChange,
            reportDateRange,
            currentDate,
            datePickerConfig,
            downloadCovidReport,
            downloadInsuranceReport,
            onChange,
            onOk,
            facility_name: ref(''),
            test_name: ref(''),
            facilityOptions,
            onSearchClient,
            onSelectClient,
            testOptions,
            onSearchTest,
            onSelectTest,

        }
    },
}
</script>

<style scoped>

</style>

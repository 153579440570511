import { reactive, ref, toRefs } from  "vue"
import { getFromObject }         from "../../helpers"
export default function useDatePicker() {
    const masks = reactive({
        input: "MM-DD-YYYY",
    })

    const mode = ref("date")

    const datePickerConfig = {
        type: "string",
        mask: "MM-DD-YYYY", // Uses 'iso' if missing
    }

    const dateRange = reactive({
        start: null,
        end: null,
    })

    const updateDateRange = (newRange) => {
        dateRange.start = getFromObject(newRange, 'start', null)
        dateRange.end = getFromObject(newRange, 'end', null)
    }

    const clearDateRange = () =>  {
        dateRange.start = null
        dateRange.end  = null
    }

    return {
        masks,
        mode,
        datePickerConfig,
        dateRange,
        updateDateRange,
        clearDateRange
    }
}

<template>
    <div class="card--large card--empty text-center flex flex-col items-center justify-center py-8">
        <svg class="w-80 h-40" viewBox="0 0 316 160" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
                <g opacity="0.2">
                    <path
                        d="M128.189 152.177H191.476C194.236 152.177 196.473 149.941 196.473 147.181V140.519C196.473 139.6 195.728 138.854 194.808 138.854C193.888 138.854 193.142 139.6 193.142 140.519V147.181C193.142 148.101 192.396 148.847 191.476 148.847H128.189C127.269 148.847 126.523 148.101 126.523 147.181V57.2456C126.523 56.3255 127.269 55.5799 128.189 55.5799C130.948 55.5799 133.185 57.8168 133.185 60.5769V65.5732C133.185 67.4125 134.676 68.9038 136.516 68.9038H193.142V78.8972C193.142 79.8165 193.888 80.5621 194.808 80.5621C195.728 80.5621 196.473 79.8165 196.473 78.8972V68.9038H203.135C204.975 68.9038 206.466 67.4125 206.466 65.5732V60.5769C206.461 55.9798 202.735 52.2547 198.138 52.2493H128.189C125.429 52.2493 123.192 54.4862 123.192 57.2456V147.181C123.192 149.941 125.429 152.177 128.189 152.177ZM198.138 55.5799C200.898 55.5799 203.135 57.8168 203.135 60.5769V65.5732H136.516V60.5769C136.51 58.7686 135.909 57.013 134.807 55.5799H198.138Z"
                        fill="#662446"/>
                    <path
                        d="M153.171 83.8935C154.091 83.8935 154.837 83.1479 154.837 82.2278V78.8972C154.837 76.1371 152.599 73.9001 149.84 73.9001H136.517C133.756 73.9001 131.52 76.1371 131.52 78.8972V92.2204C131.52 94.9798 133.756 97.2167 136.517 97.2167H144.843C145.764 97.2167 146.509 96.4711 146.509 95.5518C146.509 94.6317 145.764 93.8861 144.843 93.8861H136.517C135.597 93.8861 134.851 93.1404 134.851 92.2204V78.8972C134.851 77.9772 135.597 77.2315 136.517 77.2315H149.84C150.76 77.2315 151.505 77.9772 151.505 78.8972V82.2278C151.505 83.1479 152.251 83.8935 153.171 83.8935Z"
                        fill="#662446"/>
                    <path
                        d="M133.185 105.544H144.843C145.764 105.544 146.509 104.799 146.509 103.879C146.509 102.959 145.764 102.214 144.843 102.214H133.185C132.265 102.214 131.52 102.959 131.52 103.879C131.52 104.799 132.265 105.544 133.185 105.544Z"
                        fill="#662446"/>
                    <path
                        d="M133.185 112.206H144.843C145.764 112.206 146.509 111.461 146.509 110.541C146.509 109.621 145.764 108.876 144.843 108.876H133.185C132.265 108.876 131.52 109.621 131.52 110.541C131.52 111.461 132.265 112.206 133.185 112.206Z"
                        fill="#662446"/>
                    <path
                        d="M133.185 118.868H144.843C145.764 118.868 146.509 118.123 146.509 117.203C146.509 116.283 145.764 115.537 144.843 115.537H133.185C132.265 115.537 131.52 116.283 131.52 117.203C131.52 118.123 132.265 118.868 133.185 118.868Z"
                        fill="#662446"/>
                    <path
                        d="M133.185 125.53H149.84C150.76 125.53 151.505 124.785 151.505 123.865C151.505 122.945 150.76 122.199 149.84 122.199H133.185C132.265 122.199 131.52 122.945 131.52 123.865C131.52 124.785 132.265 125.53 133.185 125.53Z"
                        fill="#662446"/>
                    <path
                        d="M149.839 130.527C149.839 131.447 150.585 132.192 151.505 132.192H158.167C159.087 132.192 159.833 131.447 159.833 130.527C159.833 129.606 159.087 128.861 158.167 128.861H151.505C150.585 128.861 149.839 129.606 149.839 130.527Z"
                        fill="#662446"/>
                    <path
                        d="M133.185 132.192H144.843C145.764 132.192 146.509 131.447 146.509 130.527C146.509 129.606 145.764 128.861 144.843 128.861H133.185C132.265 128.861 131.52 129.606 131.52 130.527C131.52 131.447 132.265 132.192 133.185 132.192Z"
                        fill="#662446"/>
                    <path
                        d="M133.185 138.854H186.48C187.4 138.854 188.146 138.108 188.146 137.189C188.146 136.269 187.4 135.523 186.48 135.523H133.185C132.265 135.523 131.52 136.269 131.52 137.189C131.52 138.108 132.265 138.854 133.185 138.854Z"
                        fill="#662446"/>
                </g>
                <path
                    d="M206.098 129.046L218.419 141.364C219.765 142.71 219.765 144.893 218.419 146.24C217.073 147.586 214.89 147.586 213.543 146.239L201.226 133.919C195.735 138.32 188.907 140.714 181.87 140.704C164.751 140.704 150.857 126.81 150.857 109.691C150.857 92.5716 164.751 78.6777 181.87 78.6777C198.989 78.6777 212.883 92.5716 212.883 109.691C212.893 116.727 210.499 123.556 206.098 129.046ZM199.186 126.49C203.559 121.992 206.001 115.964 205.991 109.691C205.991 96.3621 195.195 85.5695 181.87 85.5695C168.541 85.5695 157.749 96.3621 157.749 109.691C157.749 123.016 168.541 133.812 181.87 133.812C188.143 133.822 194.171 131.38 198.669 127.006L199.186 126.49Z"
                    fill="#FF59AE"/>
                <path
                    d="M183.771 95.1363C183.429 94.8553 182.989 94.723 182.55 94.7666C182.11 94.8107 181.705 95.0273 181.426 95.3696L168.64 110.91C168.246 111.368 168.131 112.003 168.338 112.57C168.546 113.137 169.044 113.548 169.64 113.643C170.237 113.737 170.838 113.501 171.211 113.027L183.997 97.4861C184.279 97.1453 184.415 96.7058 184.372 96.2643C184.33 95.8229 184.113 95.4173 183.771 95.1363Z"
                    fill="#FFBDDF"/>
                <path
                    d="M177.383 93.4505C177.053 93.1573 176.619 93.0073 176.178 93.0345C175.737 93.0607 175.324 93.2615 175.031 93.593L167.619 101.952C167.009 102.641 167.073 103.694 167.761 104.304C168.45 104.914 169.503 104.85 170.113 104.162L177.525 95.8028C177.818 95.4721 177.968 95.038 177.942 94.5969C177.915 94.1555 177.714 93.743 177.383 93.4505Z"
                    fill="#FFBDDF"/>
            </g>
            <path opacity="0.1"
                  d="M272.759 35.3689C273.315 34.3344 273.436 33.1452 273.098 32.0199C272.899 31.3587 272.203 30.9838 271.541 31.1821C270.88 31.3806 270.505 32.0775 270.704 32.7387C271.005 33.7413 270.434 34.8017 269.431 35.1027C268.306 35.4405 267.38 36.1961 266.823 37.2307C266.266 38.2651 266.146 39.4544 266.484 40.5797C266.583 40.9103 266.807 41.1693 267.088 41.3209C267.37 41.4725 267.71 41.5167 268.04 41.4174C268.701 41.2189 269.076 40.522 268.878 39.8608C268.577 38.8583 269.148 37.7978 270.15 37.4968C271.276 37.1592 272.202 36.4034 272.759 35.3689Z"
                  fill="#662446"/>
            <path opacity="0.1"
                  d="M204.205 0C202.017 0 200.237 1.7797 200.237 3.96735C200.237 6.155 202.017 7.93483 204.205 7.93483C206.392 7.93483 208.172 6.155 208.172 3.96735C208.172 1.7797 206.392 0 204.205 0ZM204.205 5.43508C203.395 5.43508 202.737 4.77664 202.737 3.96735C202.737 3.15806 203.395 2.49975 204.205 2.49975C205.014 2.49975 205.672 3.15818 205.672 3.96735C205.672 4.77664 205.014 5.43508 204.205 5.43508Z"
                  fill="#662446"/>
            <path opacity="0.1"
                  d="M88.3004 19.9133C85.7959 19.9133 83.7582 21.951 83.7582 24.4555C83.7582 25.5818 82.842 26.4979 81.7157 26.4979C81.0255 26.4979 80.4658 27.0575 80.4658 27.7478C80.4658 28.4381 81.0255 28.9977 81.7157 28.9977C84.2203 28.9977 86.258 26.96 86.258 24.4555C86.258 23.3292 87.1741 22.4131 88.3004 22.4131C88.9906 22.4131 89.5503 21.8535 89.5503 21.1632C89.5503 20.4729 88.9907 19.9133 88.3004 19.9133Z"
                  fill="#662446"/>
            <g opacity="0.1">
                <path d="M152.874 16.4913C150.612 16.4913 150.608 20.0072 152.874 20.0072C155.136 20.0072 155.14 16.4913 152.874 16.4913Z"
                      fill="#662446"/>
                <path d="M158.344 13.2493C156.082 13.2493 156.078 16.7651 158.344 16.7651C160.606 16.7651 160.61 13.2493 158.344 13.2493Z"
                      fill="#662446"/>
                <path d="M158.505 19.7334C156.243 19.7334 156.24 23.2493 158.505 23.2493C160.768 23.2491 160.772 19.7334 158.505 19.7334Z"
                      fill="#662446"/>
            </g>
            <path opacity="0.1"
                  d="M311.751 104.885H305.288L303.307 98.7743C302.851 97.3668 301.601 96.458 300.121 96.458C300.12 96.458 300.118 96.458 300.117 96.458C298.636 96.4599 297.386 97.3722 296.933 98.782L294.97 104.885H288.532C287.051 104.885 285.801 105.795 285.345 107.204C284.89 108.613 285.371 110.083 286.571 110.95L291.795 114.721L289.798 120.85C289.339 122.257 289.817 123.728 291.014 124.598C291.612 125.032 292.297 125.249 292.982 125.249C293.669 125.249 294.356 125.031 294.954 124.595L300.166 120.797L305.352 124.566C306.55 125.437 308.097 125.436 309.293 124.564C310.49 123.692 310.965 122.22 310.503 120.813L308.506 114.724L313.717 110.946C314.914 110.077 315.393 108.607 314.937 107.201C314.481 105.794 313.23 104.885 311.751 104.885ZM312.25 108.922L306.3 113.236C305.861 113.554 305.677 114.121 305.846 114.637L308.128 121.592C308.302 122.123 307.972 122.434 307.821 122.544C307.671 122.653 307.273 122.872 306.822 122.544L300.9 118.241C300.462 117.922 299.868 117.922 299.429 118.241L293.482 122.574C293.032 122.903 292.633 122.684 292.483 122.575C292.333 122.466 292.002 122.155 292.174 121.625L294.454 114.628C294.623 114.112 294.438 113.546 293.997 113.228L288.035 108.923C287.582 108.596 287.666 108.15 287.724 107.973C287.781 107.796 287.974 107.384 288.532 107.384H295.881C296.424 107.384 296.905 107.034 297.071 106.517L299.312 99.5469C299.483 99.0156 299.934 98.9578 300.12 98.9575H300.121C300.308 98.9575 300.758 99.0151 300.929 99.5451L303.191 106.52C303.358 107.036 303.838 107.385 304.38 107.385H311.751C312.309 107.385 312.502 107.795 312.56 107.972C312.616 108.149 312.701 108.595 312.25 108.922Z"
                  fill="#662446"/>
            <path opacity="0.1"
                  d="M34.9282 149.752C37.5471 149.752 39.6777 147.622 39.6777 145.003C39.6777 142.384 37.5471 140.253 34.9282 140.253C32.3094 140.253 30.1787 142.384 30.1787 145.003C30.1787 147.622 32.3094 149.752 34.9282 149.752ZM34.9282 142.753C36.1687 142.753 37.178 143.762 37.178 145.003C37.178 146.243 36.1687 147.252 34.9282 147.252C33.6877 147.252 32.6785 146.243 32.6785 145.003C32.6785 143.762 33.6879 142.753 34.9282 142.753Z"
                  fill="#662446"/>
            <path opacity="0.1"
                  d="M78.0981 95.5099C75.9134 96.8311 73.3936 97.9092 70.7831 97.5637C70.1063 97.4741 69.4337 97.2717 68.8164 96.9677C69.1808 96.7425 69.5339 96.4954 69.8729 96.2257C72.1802 94.3902 74.6013 90.0344 71.2584 87.9126C68.3457 86.0639 64.6471 88.3596 63.7577 91.352C63.2812 92.9552 63.5602 94.5834 64.3139 95.9842C63.4594 96.0946 62.5894 96.0657 61.7514 95.8789C60.1696 95.5264 59.4941 97.9582 61.0807 98.3119C62.7614 98.6864 64.4737 98.6206 66.0842 98.1692C66.6662 98.6677 67.3184 99.0815 68.0128 99.3802C71.8351 101.024 75.9848 99.7366 79.3714 97.6885C80.7592 96.8492 79.4923 94.6665 78.0981 95.5099ZM68.3703 94.2099C67.8876 94.6202 67.3492 94.9654 66.7773 95.2442C66.4089 94.7019 66.1576 94.0756 66.0732 93.3736C65.8639 91.6317 67.3412 89.4934 69.2704 89.8076C71.8342 90.2254 69.3203 93.4025 68.3703 94.2099Z"
                  fill="#662446"/>
            <path opacity="0.1"
                  d="M261.237 153.073L256.783 148.619C256.546 148.382 256.225 148.249 255.89 148.249C255.556 148.249 255.235 148.382 254.998 148.619L250.545 153.073C250.053 153.566 250.053 154.365 250.545 154.858L254.999 159.311C255.245 159.557 255.568 159.68 255.891 159.68C256.213 159.68 256.536 159.557 256.782 159.311L261.237 154.858C261.474 154.621 261.607 154.3 261.607 153.966C261.607 153.631 261.474 153.31 261.237 153.073ZM255.891 156.635L253.221 153.965L255.891 151.295L258.561 153.965L255.891 156.635Z"
                  fill="#662446"/>
            <g opacity="0.1">
                <path
                    d="M239.425 74.2493C238.735 74.2493 238.175 74.8088 238.175 75.4991V77.1657C238.175 77.856 238.735 78.4156 239.425 78.4156C240.115 78.4156 240.675 77.856 240.675 77.1657V75.4991C240.675 74.8088 240.115 74.2493 239.425 74.2493Z"
                    fill="#662446"/>
                <path
                    d="M239.425 82.5818C238.735 82.5818 238.175 83.1414 238.175 83.8317V85.4981C238.175 86.1884 238.735 86.748 239.425 86.748C240.115 86.748 240.675 86.1884 240.675 85.4981V83.8317C240.675 83.1414 240.115 82.5818 239.425 82.5818Z"
                    fill="#662446"/>
                <path
                    d="M244.425 81.7485C245.115 81.7485 245.675 81.189 245.675 80.4987C245.675 79.8083 245.115 79.2488 244.425 79.2488H242.758C242.068 79.2488 241.508 79.8083 241.508 80.4987C241.508 81.189 242.068 81.7485 242.758 81.7485H244.425Z"
                    fill="#662446"/>
                <path
                    d="M233.176 80.4987C233.176 81.189 233.735 81.7485 234.426 81.7485H236.092C236.782 81.7485 237.342 81.189 237.342 80.4987C237.342 79.8083 236.782 79.2488 236.092 79.2488H234.426C233.735 79.2488 233.176 79.8083 233.176 80.4987Z"
                    fill="#662446"/>
            </g>
            <path opacity="0.1"
                  d="M7.21545 59.3289C7.38681 60.4116 8.19723 61.2209 9.28024 61.3909C9.41611 61.4123 9.55084 61.4227 9.68382 61.4227C10.6105 61.4227 11.4444 60.916 11.877 60.0608L13.8863 56.0896L18.2982 56.7928C19.3803 56.9654 20.3997 56.4462 20.8972 55.471C21.3948 54.4956 21.216 53.3651 20.4419 52.5908L17.2698 49.4189L19.2982 45.4443C19.7965 44.4676 19.6172 43.3361 18.8409 42.5618C18.0645 41.7874 16.9328 41.6108 15.9572 42.1114L11.9961 44.145L8.83479 40.9837C8.05974 40.2088 6.92848 40.0303 5.95246 40.5294C4.97656 41.0285 4.45898 42.0501 4.63359 43.1323L5.34652 47.5483L1.35755 49.5765C0.380899 50.0731 -0.139301 51.0932 0.032432 52.1754C0.20404 53.2574 1.01421 54.0664 2.09672 54.2363L6.51977 54.9308L7.21545 59.3289ZM7.29232 49.3634C7.77652 49.1172 8.04637 48.5863 7.95975 48.05L7.11234 42.7964L10.8726 46.5567C11.2565 46.9405 11.8442 47.0329 12.3273 46.7848L17.0428 44.3646L14.6303 49.0919C14.384 49.5745 14.4768 50.1607 14.8598 50.5439L18.6292 54.3141L13.3796 53.4776C12.8428 53.3922 12.3129 53.6629 12.0676 54.1476L9.6747 58.8754L8.84679 53.642C8.76204 53.1064 8.34184 52.6865 7.80614 52.6026L2.5473 51.7761L7.29232 49.3634Z"
                  fill="#662446"/>
            <defs>
                <clipPath id="clip0">
                    <rect width="100" height="100" fill="white" transform="translate(123 52.2493)"/>
                </clipPath>
            </defs>
        </svg>
        <H2 class="!text-2xl !pt-6 !pb-3 !text-grey-900" title="Nothing is searched yet"/>
        <p class="text-grey-500">
            Please try to search by the name of patient <br>
            to fill requisition form
        </p>
    </div>
</template>
<script>
    import H2 from "../../../../components/Admin/typography/H2"

    export default {
        components: { H2 },
        name: "EmptySearch",
    }
</script>

<template>
  <div>
    <div class="form-header flex items-center border-b border-blue-400 pb-4 mb-6">
      <Icon class="mr-3 !w-7 h-7" name="basic-information-1"/>
      <h3 class="heading-primary heading-primary-sm" v-text="'Basic Information'"/>
    </div>
    <div class="grid gap-6">
      <div class="grid md:grid-cols-2 gap-6">
        <div :class="(v$.email.$error || emailExists )? 'form-group input-error' : 'form-group'">
          <label-component :value="'Email Address'"/>
          <input-component
              type="email"
              name="email"
              v-model="email"
          />
          <input-error
              v-if="getFromObject(errors, 'basic_information.email')"
              :message="getFromObject(errors, 'basic_information.email')"/>
          <input-error
              v-if="v$.email.$error"
              :message="v$.email.$errors[0].$message"/>
        </div>
      </div>
      <div class="grid md:grid-cols-2 gap-6">
        <div :class="(v$.email_confirmation.$error)? 'form-group input-error' : 'form-group'">
          <label-component :value="'Confirm Email Address'"/>
            <input-component
              type="email_confirmation"
              name="email_confirmation"
              v-model="email_confirmation"
            />
            <input-error
              v-if="v$.email_confirmation.$error"
              :message="v$.email_confirmation.$errors[0].$message"/>
        </div>
      </div>
      <div class="grid md:grid-cols-2 gap-6">
        <div :class="v$.first_name.$error ? 'form-group input-error' : 'form-group'">
          <label-component :value="'First Name'"/>
          <input-component
              type="text"
              name="first_name"
              v-model="first_name"
              @focusout="v$.first_name.$touch"/>
          <input-error
              v-if="getFromObject(errors, 'basic_information.first_name')"
              :message="getFromObject(errors, 'basic_information.first_name')"/>
          <input-error
              v-if="v$.first_name.$error"
              :message="v$.first_name.$errors[0].$message"/>
        </div>
        <div :class="v$.last_name.$error ? 'form-group input-error' : 'form-group'">
          <label-component :value="'Last Name'"/>
          <input-component
              type="text"
              name="last_name"
              v-model="last_name"
              @focusout="v$.last_name.$touch"/>
          <input-error v-if="getFromObject(errors, 'basic_information.last_name')"
                       :message="getFromObject(errors, 'basic_information.last_name')"/>
          <input-error v-if="v$.last_name.$error" :message="v$.last_name.$errors[0].$message"/>
        </div>
      </div>
      <div class="grid md:grid-cols-2 gap-6">
        <div :class="v$.dob.$error ? 'form-group input-error' : 'form-group'">
          <label-component :value="'Date of Birth'"/>
          <select-date-picker
              v-model="dob"
              @focusout="v$.dob.$touch"/>
          <input-error
              v-if="getFromObject(errors, 'basic_information.dob')"
              :message="getFromObject(errors, 'basic_information.dob')"/>
          <input-error v-if="v$.dob.$error" :message="v$.dob.$errors[0].$message"/>
        </div>
        <div :class="v$.gender.$error ? 'form-group input-error' : 'form-group'">
          <label-component :value="'Gender'"/>
          <radio-group-input
              name="gender"
              :options="genderOptions"
              v-model="gender"
              @focusout="v$.gender.$touch"/>
          <input-error
              v-if="getFromObject(errors, 'basic_information.gender')"
              :message="getFromObject(errors, 'basic_information.gender')"/>
          <input-error v-if="v$.gender.$error" :message="v$.gender.$errors[0].$message"/>
        </div>
      </div>

      <div class="grid md:grid-cols-2 gap-6">
        <div :class="v$.race.$error ? 'form-group input-error' : 'form-group'">
          <label-component :value="'Race'"/>
          <select-input
              name="race"
              v-model="race"
              :options="raceOptions"
              @focusout="v$.race.$touch"
              place-holder="Select Race"/>
          <input-error
              v-if="getFromObject(errors, 'basic_information.race')"
              :message="getFromObject(errors, 'basic_information.race')"/>
          <input-error
              v-if="v$.race.$error"
              :message="v$.race.$errors[0].$message"/>
        </div>
        <div :class="v$.ethnicity.$error ? 'form-group input-error' : 'form-group'">
          <label-component
              :value="'Ethnicity'"/>
          <select-input
              name="ethnicity"
              v-model="ethnicity"
              :options="ethnicityOptions"
              @focusout="v$.ethnicity.$touch"
              place-holder="Select ethnicity"/>
          <input-error
              v-if="getFromObject(errors, 'basic_information.ethnicity')"
              :message="getFromObject(errors, 'basic_information.ethnicity')"/>
          <input-error
              v-if="v$.ethnicity.$error"
              :message="v$.ethnicity.$errors[0].$message"/>
        </div>
      </div>
      <div class="grid md:grid-cols-2 gap-6">
        <div :class="v$.phone_number.$error ? 'form-group input-error' : 'form-group'">
          <label-component :value="'Phone Number'"/>
          <input-component
              type="text"
              name="phone_number"
              v-model="phone_number"
              @focusout="v$.phone_number.$touch"/>
          <input-error v-if="getFromObject(errors, 'basic_information.phone_number')"
                       :message="getFromObject(errors, 'basic_information.phone_number')"/>
          <input-error v-if="v$.phone_number.$error" :message="v$.phone_number.$errors[0].$message"/>
        </div>
      </div>
      <div class="grid md:grid-cols-2 gap-6">
        <div :class="v$.street_address_one.$error ? 'form-group input-error' : 'form-group'">
          <label-component
              :value="'Street Address 1'"/>
          <input-component
              type="text"
              name="street_address_one"
              @focusout="v$.street_address_one.$touch"
              v-model="street_address_one"/>
          <input-error
              v-if="getFromObject(errors, 'basic_information.street_address_one')"
              :message="getFromObject(errors, 'basic_information.street_address_one')"/>
          <input-error
              v-if="v$.street_address_one.$error"
              :message="v$.street_address_one.$errors[0].$message"/>
        </div>
<!--        <div>-->
<!--          <label-component-->
<!--              :value="'Street Address 2 (Optional)'"/>-->
<!--          <input-component-->
<!--              type="text"-->
<!--              name="street_address_two"-->
<!--              v-model="street_address_two"-->
<!--              @focusout="v$.street_address_two.$touch"/>-->
<!--          <input-error-->
<!--              v-if="getFromObject(errors, 'basic_information.street_address_two')"-->
<!--              :message="getFromObject(errors, 'basic_information.street_address_two')"/>-->
<!--          <input-error-->
<!--              v-if="v$.street_address_two.$error"-->
<!--              :message="v$.street_address_two.$errors[0].$message"/>-->
<!--        </div>-->
      </div>
      <div class="grid md:grid-cols-3 gap-6">
        <div :class="v$.city.$error ? 'form-group input-error' : 'form-group'">
          <label-component
              :value="'City'"/>
          <input-component
              type="text"
              name="city"
              v-model="city"
              @focusout="v$.city.$touch"/>
          <input-error
              v-if="getFromObject(errors, 'basic_information.city')"
              :message="getFromObject(errors, 'basic_information.city')"/>
          <input-error
              v-if="v$.city.$error"
              :message="v$.city.$errors[0].$message"/>
        </div>
        <div :class="v$.state.$error ? 'form-group input-error' : 'form-group'">
          <label-component
              :value="'State'"/>
          <select-input
              name="state"
              v-model="state"
              :options="stateOptions"
              place-holder="Select state"
              :searchable="true"
              @focusout="v$.state.$touch"/>
          <input-error
              v-if="getFromObject(errors, 'basic_information.state')"
              :message="getFromObject(errors, 'basic_information.state')"/>
          <input-error
              v-if="v$.state.$error"
              :message="v$.state.$errors[0].$message"/>
        </div>
        <div :class="v$.zip_code.$error ? 'form-group input-error' : 'form-group'">
          <label-component
              :value="'ZipCode'"/>
          <input-component
              type="text"
              name="zip_code"
              v-model="zip_code"
              @focusout="v$.zip_code.$touch"/>
          <input-error
              v-if="getFromObject(errors, 'basic_information.zip_code')"
              :message="getFromObject(errors, 'basic_information.zip_code')"/>
          <input-error
              v-if="v$.zip_code.$error"
              :message="v$.zip_code.$errors[0].$message"/>
        </div>
      </div>
    </div>
    <form-footer v-if="showButtons" class="!pt-8">
      <cancel-button @click.prevent="cancel">Cancel</cancel-button>
      <primary-button @click.prevent="nextPage">Next</primary-button>
    </form-footer>
  </div>
</template>

<script>
import {Inertia} from "@inertiajs/inertia"
import {useVuelidate} from "@vuelidate/core"
import {
  alpha,
  email,
  required,
  sameAs
} from "@vuelidate/validators"
import {get as getFromObject} from "lodash"
import {
  computed,
  inject,
  reactive,
  ref,
  toRefs,
  watch,
} from "vue"
import Icon from "../../../../components/Admin/General/Icon"

import CancelButton from "../../../../components/Front/Buttons/CancelButton"
import PrimaryButton from "../../../../components/Front/Buttons/PrimaryButton"
import DropDownInput from "../../../../components/Front/Forms/DropDownInput"
import InputComponent from "../../../../components/Front/Forms/Input"
import InputError from "../../../../components/Front/Forms/InputError"
import LabelComponent from "../../../../components/Front/Forms/Label"
import RadioGroupInput from "../../../../components/Front/Forms/RadioGroupInput"
import SelectInput from "../../../../components/Front/Forms/SelectInput"
import SelectDatePicker from "../../../../components/Front/Forms/SelectDatePicker"
import CardHeader from "../../../../components/Front/General/CardHeader"
import Header from "../../../../components/Front/Layout/Header"
import SidebarRegistration from "../../../../components/Front/Layout/SidebarRegistration"
import FormFooter from "../../../../components/Front/Layout/FormFooter";
import scrollToSmoothly from "../../../../script";

export default {
  name: "BasicInformation",
  components: {
    FormFooter,
    Icon,
    CardHeader,
    PrimaryButton,
    CancelButton,
    InputError,
    SelectInput,
    SelectDatePicker,
    SidebarRegistration,
    Header,
    InputComponent,
    LabelComponent,
    DropDownInput,
    RadioGroupInput,
  },
  props: {
    modelValue: {type: Object, required: false, default: () => ({})},
    errors: {type: Object, required: false, default: () => ({})},
    showButtons: {type: Boolean, required: false, default: false},
  },
  inheritAttrs: false,
  emits: ["next", "update:modelValue"],
  setup(props, {emit}) {
    const raceOptions = inject("raceOptions")
    const genderOptions = inject("genderOptions")
    const ethnicityOptions = inject("ethnicityOptions")
    const stateOptions = inject("stateOptions")

    const dobPickerConfig = ref({
      type: "string",
      mask: "MM-DD-YYYY",
    })

    const appState = reactive({
      email: null,
      email_confirmation: null,
      first_name: null,
      last_name: null,
      dob: null,
      gender: null,
      phone_number: null,
      race: null,
      ethnicity: null,
      street_address_one: null,
      street_address_two: null,
      city: null,
      state: null,
      zip_code: null,
      emailExists: false,
    })

    const resultValue = computed(() => {
      return {
        email: appState.email,
        email_confirmation: appState.email_confirmation,
        first_name: appState.first_name,
        last_name: appState.last_name,
        dob: appState.dob,
        gender: appState.gender,
        phone_number: appState.phone_number,
        race: appState.race,
        ethnicity: appState.ethnicity,
        street_address_one: appState.street_address_one,
        street_address_two: appState.street_address_two,
        city: appState.city,
        state: appState.state,
        zip_code: appState.zip_code,
      }
    })

    watch(resultValue, () => {
      emit("update:modelValue", resultValue.value)
    })

    let {modelValue} = toRefs(props)

    watch(() => modelValue.value, (newValue, oldValue) => {
      appState.email = newValue.email
      appState.email_confirmation = newValue.email_confirmation
      appState.first_name = newValue.first_name
      appState.last_name = newValue.last_name
      appState.dob = newValue.dob
      appState.gender = newValue.gender
      appState.phone_number = newValue.phone_number
      appState.race = newValue.race
      appState.ethnicity = newValue.ethnicity
      appState.street_address_one = newValue.street_address_one
      appState.street_address_two = newValue.street_address_two
      appState.city = newValue.city
      appState.state = newValue.state
      appState.zip_code = newValue.zip_code

    }, {deep: true, immediate: true})

    // const basic_information = getFromObject(props, "form.basic_information")

    const rules = computed(() => ({
      email: {
        required, email,
      },
      email_confirmation: {
        required,
        email,
        sameAsEmail: sameAs(appState.email, "email"),
      },
      first_name: {
        required
      },
      last_name: {
        required
      },
      dob: {
        required,
      },
      gender: {
        required,
      },
      phone_number: {
        required,
      },
      race: {
        required,
      },
      ethnicity: {
        required,
      },
      street_address_one: {
        required,
      },
      street_address_two: {},
      city: {
        required,
      },
      state: {
        required,
      },
      zip_code: {
        required,
      },
    }))

    const v$ = useVuelidate(rules, appState)

    function cancel() {
      Inertia.get("/")
    }

    return {
      getFromObject, ...toRefs(appState),
      dobPickerConfig,
      cancel,
      raceOptions,
      genderOptions,
      ethnicityOptions,
      stateOptions,
      v$
    }
  },
  methods: {
    nextPage() {
      this.v$.$touch()

        this.$nextTick(() => {
            const inputError = document.querySelectorAll('.input-error')[0]
            if (inputError) {
                scrollToSmoothly(inputError.offsetTop - 84);
            }
        })

      if (this.v$.$error) return

      this.$emit("next")
    },
    checkEmail() {
      this.v$.email.$touch()

      const value = this.email

      if (value === "" || this.v$.email.$error) return true

      axios.post("/check-email", {
        email: value,
      }).then((response) => {
        this.emailExists = false
      }).catch(error => {
        this.emailExists = true
      })
    },
  },
}
</script>

<style scoped>

</style>

<template>
    <admin-layout :if-has-breadcrumb="true" active-link="patient">
        <template #breadcrumb>
            <bread-crumb>
                <bread-crumb-item :href="patientIndexUrl" label="Patient"/>
                <bread-crumb-item label="Edit Patient Information"/>
            </bread-crumb>
        </template>
        <div v-if="form" class="card--large">

            <form @submit.prevent="submitForm(form.id)">
                <div class="flex items-center justify-between pb-8">
                    <h1 class="heading-primary !text-grey-1100 !pb-0" v-text="'Edit Patient Information'"/>
                    <div>
                        <img v-if="imagePreview" alt="profile-pic" :src="imagePreview" style="max-height:100px">
                        <img v-else-if="form.thumbnail" alt="profile-pic" :src="form.thumbnail"
                             style="max-height:100px">
                        <img v-else alt="default-pic" :src="'/images/default_avatar.png'" style="max-height:100px">
                    </div>
                </div>

                <div class="grid lg:grid-cols-3 gap-y-6 gap-10">

                    <div>
                        <label-component v-if="form.thumbnail==='/images/default_avatar.png'"
                                         :value="'Upload patient photo'"/>
                        <label-component v-else :value="'Edit patient photo'"/>
                        <div @change="imageSelected" class="file__input-wrap file__input--secondary rounded">
                            <input type="file" class="file__input">
                            <span v-if="!imagePreview" class="pl-2 text-sm">No file Chosen</span>
<!--                            <span v-if="imagePreview" class="pl-2 text-sm w-3/5 truncate" v-text="form.imageName"/>-->
                            <span class="file__input-trigger ml-auto" v-text="'Choose File'"/>
                        </div>
                        <span>
                           <span class="text-pinkShade-100">*</span>
                            <span class="text-xs">
                                <span class="text-tertiary-100" v-text="'*'"/>
                                Image Support: Jpg, Jpeg, Png. Image size under 5Mb.
                            </span>
                       </span>
                        <input-error
                            v-if="getFromObject(form.errors, 'image')"
                            :message="getFromObject(form.errors, 'image')"/>
                        <input-error v-if="v$.image.$error" :message="v$.image.$errors[0].$message"/>
                    </div>
                    <div>
                        <label-component :value="'Patient’s First Name'"/>
                        <input-component
                            type="text"
                            name="first_name"
                            v-model="form.first_name"/>
                        <input-error
                            v-if="getFromObject(form.errors, 'first_name')"
                            :message="getFromObject(form.errors, 'first_name')"/>
                        <input-error v-if="v$.first_name.$error" :message="v$.first_name.$errors[0].$message"/>
                    </div>
                    <div>
                        <label-component :value="'Patient’s Last Name'"/>
                        <input-component
                            type="text"
                            name="last_name"
                            v-model="form.last_name"/>
                        <input-error
                            v-if="getFromObject(form.errors, 'last_name')"
                            :message="getFromObject(form.errors, 'last_name')"/>
                        <input-error v-if="v$.last_name.$error" :message="v$.last_name.$errors[0].$message"/>
                    </div>
                    <div>
                        <label-component :value="'Date of Birth'"/>
                        <select-date-picker
                            v-model="form.dob"
                            @focusout="v$.dob.$touch"/>
                        <input-error
                            v-if="getFromObject(form.errors, 'dob')"
                            :message="getFromObject(form.errors, 'dob')"/>
                        <input-error v-if="v$.dob.$error" :message="v$.dob.$errors[0].$message"/>
                    </div>
                    <div>
                        <label-component :value="'Gender'"/>
                        <radio-group-input
                            name="gender"
                            :options="genderOptions"
                            v-model="form.gender"
                            @focusout="v$.gender.$touch"/>
                        <input-error
                            v-if="getFromObject(form.errors, 'gender')"
                            :message="getFromObject(form.errors, 'gender')"/>
                        <input-error v-if="v$.gender.$error" :message="v$.gender.$errors[0].$message"/>
                    </div>
                    <div>
                        <label-component :value="'Street Address 1'"/>
                        <input-component
                            type="text"
                            name="street_address_one"
                            v-model="form.street_address_one"/>
                        <input-error
                            v-if="getFromObject(form.errors, 'street_address_one')"
                            :message="getFromObject(form.errors, 'street_address_one')"/>
                        <input-error v-if="v$.street_address_one.$error"
                                     :message="v$.street_address_one.$errors[0].$message"/>
                    </div>
                    <div>
                        <label-component :value="'Street Address 2'"/>
                        <input-component
                            type="text"
                            name="street_address_two"
                            v-model="form.street_address_two"/>
                        <input-error
                            v-if="getFromObject(form.errors, 'street_address_two')"
                            :message="getFromObject(form.errors, 'street_address_two')"/>
                        <input-error v-if="v$.street_address_two.$error"
                                     :message="v$.street_address_two.$errors[0].$message"/>
                    </div>

                    <div>
                        <label-component :value="'City'"/>
                        <input-component
                            type="text"
                            name="city"
                            v-model="form.city"/>
                        <input-error
                            v-if="getFromObject(form.errors, 'city')"
                            :message="getFromObject(form.errors, 'city')"/>
                        <input-error v-if="v$.city.$error" :message="v$.city.$errors[0].$message"/>
                    </div>
                    <div>
                        <label-component :value="'State'"/>
                        <select-input
                            :searchable="true"
                            place-holder="Select a state"
                            name="state"
                            :options="stateOptions"
                            v-model="form.state"/>
                        <input-error
                            v-if="getFromObject(form.errors, 'state')"
                            :message="getFromObject(form.errors, 'state')"/>
                        <input-error v-if="v$.state.$error" :message="v$.state.$errors[0].$message"/>
                    </div>

                    <div>
                        <label-component :value="'Zip Code'"/>
                        <input-component
                            type="text"
                            name="zip_code"
                            v-model="form.zip_code"/>
                        <input-error
                            v-if="getFromObject(form.errors, 'zip_code')"
                            :message="getFromObject(form.errors, 'zip_code')"/>
                        <input-error v-if="v$.zip_code.$error" :message="v$.zip_code.$errors[0].$message"/>
                    </div>
                    <div>
                        <label-component :value="'Phone Number'"/>
                        <input-component
                            type="text"
                            name="phone_number"
                            v-model="form.phone_number"/>
                        <input-error
                            v-if="getFromObject(form.errors, 'phone_number')"
                            :message="getFromObject(form.errors, 'phone_number')"/>
                        <input-error v-if="v$.phone_number.$error" :message="v$.phone_number.$errors[0].$message"/>
                    </div>
                    <div>
                        <label-component :value="'Race'"/>
                        <select-input
                            name="race"
                            v-model="form.race"
                            :options="raceOptions"
                            @focusout="v$.race.$touch"
                            place-holder="Select Race"/>
                        <input-error
                            v-if="getFromObject(form.errors, 'basic_information.race')"
                            :message="getFromObject(form.errors, 'basic_information.race')"/>
                        <input-error
                            v-if="v$.race.$error"
                            :message="v$.race.$errors[0].$message"/>
                    </div>
                    <div>
                        <label-component
                            :value="'Ethnicity'"/>
                        <select-input
                            name="ethnicity"
                            v-model="form.ethnicity"
                            :options="ethnicityOptions"
                            @focusout="v$.ethnicity.$touch"
                            place-holder="Select ethnicity"/>
                        <input-error
                            v-if="getFromObject(form.errors, 'basic_information.ethnicity')"
                            :message="getFromObject(form.errors, 'basic_information.ethnicity')"/>
                        <input-error
                            v-if="v$.ethnicity.$error"
                            :message="v$.ethnicity.$errors[0].$message"/>
                    </div>
                    <div class="lg:col-span-3">
                        <label-component :value="'Notes'"/>
                        <textarea
                            maxlength="100"
                            rows="4"
                            cols="30"
                            name="notes"
                            class="
                            form-control
                            p-3
                            placeholder-grey-400
                            text-grey-900
                            bg-white
                            text-sm
                            outline-none
                            border border-grey-300
                            focus:border-tertiary-100
                            focus:outline-none
                            transition
                            transition-all
                            duration-150
                        "
                            v-model="form.notes"/>
                    </div>
                </div>
                <div class="mt-6 pt-6 flex items-center justify-between border-t border-pinkShade-200">
                       <span>
                           <span class="text-pinkShade-100">*</span>
                           <span class="text-sm" v-text="'* All fields are required unless specified optional'"/>
                       </span>

                    <div class="flex items-center gap-8">
                        <cancel-button :inertia-link="true" :href="patientIndexUrl" class="btn">Cancel</cancel-button>
                        <primary-button @click="submitForm(form.id)" class="btn" :disabled="form.processing">
                            <span v-if="form.processing">Submitting</span>
                            <span v-else>Submit</span>
                        </primary-button>
                    </div>
                </div>
            </form>
        </div>
    </admin-layout>
</template>

<script>
import AdminLayout from "../../../components/Admin/Layout/AdminLayout";
import BreadCrumb from "../../../components/Admin/BreadCrumb/BreadCrumb";
import BreadCrumbItem from "../../../components/Admin/BreadCrumb/BreadCrumbItem";
import CancelButton from "../../../components/Admin/Buttons/CancelButton";
import PrimaryButton from "../../../components/Admin/Buttons/PrimaryButton";
import {useForm} from "@inertiajs/inertia-vue3"
import {useVuelidate} from "@vuelidate/core"
import {
    helpers,
    required,
} from "@vuelidate/validators"
import {get as getFromObject} from "lodash"
import {computed, ref, watch} from "vue"
import LabelComponent from "../../../components/Admin/Forms/Label";
import InputComponent from "../../../components/Admin/Forms/Input";
import SelectInput from "../../../components/Admin/Forms/SelectInput";
import DropDownInput from "../../../components/Admin/Forms/DropDownInput";
import InputError from "../../../components/Admin/Forms/InputError";
import RadioGroupInput from "../../../components/Admin/Forms/RadioGroupInput"
import SelectDatePicker from "../../../components/Admin/Forms/SelectDatePicker"
import Modal from "../../../components/Admin/Modal"
import SecondaryButton from "../../../components/Admin/Buttons/SecondaryButton";

export default {
    name: "Edit",
    components: {
        InputError,
        DropDownInput,
        SelectInput,
        InputComponent,
        LabelComponent,
        PrimaryButton,
        CancelButton,
        BreadCrumbItem,
        BreadCrumb,
        AdminLayout,
        RadioGroupInput
        ,
        SelectDatePicker,
        Modal,
        SecondaryButton
    },
    props: {
        raceList: {type: Object, required: true},
        genderList: {type: Object, required: true},
        ethnicityList: {type: Object, required: true},
        stateList: {type: Object, required: true},
        patient: {type: Object, required: true},
    },
    setup(props) {
        const patientIndexUrl = route('admin.patient.index')
        const showApproveResultModal = ref(false);
        const image = ref();
        const imagePreview = ref();
        const imageUploadOfPatient = ref();

        const stateOptions = new Map()
        Object.keys(props.stateList).forEach(key => stateOptions.set(key, props.stateList[key]))

        const raceOptions = new Map()
        Object.keys(props.raceList).forEach(key => raceOptions.set(key, props.raceList[key]))

        const genderOptions = new Map()
        Object.keys(props.genderList).forEach(key => genderOptions.set(key, props.genderList[key]))

        const ethnicityOptions = new Map()
        Object.keys(props.ethnicityList).forEach(key => ethnicityOptions.set(key, props.ethnicityList[key]))

        const form = useForm("NewPatientForm", {
            id: props.patient.data.id,
            first_name: props.patient.data.first_name,
            last_name: props.patient.data.last_name,
            dob: props.patient.data.dob_formatted,
            gender: props.patient.data.gender,
            phone_number: props.patient.data.phone_number,
            race: props.patient.data.race,
            ethnicity: props.patient.data.ethnicity,
            street_address_one: props.patient.data.street_address_one,
            street_address_two: props.patient.data.street_address_two,
            city: props.patient.data.city,
            state: props.patient.data.state,
            zip_code: props.patient.data.zip_code,
            image: null,
            thumbnail: props.patient.data.thumbnail,
            notes: props.patient.notes
        })

        watch(() => props.patient, (newValue, oldValue) => {
            form.id = newValue.data.id
            form.first_name = newValue.data.first_name
            form.last_name = newValue.data.last_name
            form.dob = newValue.data.dob_formatted
            form.gender = newValue.data.gender
            form.phone_number = newValue.data.phone_number
            form.race = newValue.data.race
            form.ethnicity = newValue.data.ethnicity
            form.street_address_one = newValue.data.street_address_one
            form.street_address_two = newValue.data.street_address_two
            form.city = newValue.data.city
            form.state = newValue.data.state
            form.zip_code = newValue.data.zip_code
            form.image = null
            form.thumbnail = newValue.data.thumbnail
            form.notes = newValue.data.notes

        }, {deep: true, immediate: true})

        const rules = computed(() => ({
            dob: {
                required,
            },
            gender: {
                required,
            },
            first_name: {
                required,
            },
            last_name: {
                required,
            },
            street_address_one: {
                required,
            },
            street_address_two: {},
            city: {
                required,
            },
            state: {
                required,
            },
            zip_code: {
                required,
            },
            race: {
                required,
            },
            ethnicity: {
                required,
            },
            phone_number: {
                required,
            },
            image: {},
        }))

        const v$ = useVuelidate(rules, form)

        function closeApproveResultModal() {
            showApproveResultModal.value = false;
        }

        function imageSelected(e) {
            form.imageName = e.target.files[0].name
            image.value = e.target.files[0];
            const imageType = e.target.files[0].type;
            if (imageType === 'application/pdf') {
                return form.errors.image = 'We only accept images:jpg,png. Please try again with correct image format'
            }
            form.errors.image = null
            let reader = new FileReader();
            reader.readAsDataURL(image.value);
            reader.onload = e => {
                imagePreview.value = e.target.result;
            }
        }

        const showModal = (id) => {
            imagePreview.value = null;
            imageUploadOfPatient.value = id;
            showApproveResultModal.value = true;
        };
        const handleCancel = () => {
            showApproveResultModal.value = false;
        };

        function submitForm(id) {
            if (form.processing) return

            this.v$.$touch()

            if (this.v$.$error) return
            form.image = image.value;
            form.post(route('admin.patient.update', id), {
                onSuccess: () => {
                    imagePreview.value = null
                }
            })
        }

        return {
            patientIndexUrl,
            v$,
            form,
            stateOptions,
            raceOptions,
            genderOptions,
            ethnicityOptions,
            getFromObject,
            showApproveResultModal,
            closeApproveResultModal,
            imageSelected,
            imagePreview,
            showModal,
            handleCancel,
            submitForm,
        }
    },
}
</script>

<style scoped>

</style>

<template>
    <div>
        <client-header :is-admin-user="true"/>
        <div class="content !p-10 xxl:!p-16 !mx-auto">
            <div style="padding-bottom: 40px;" v-if="ifHasBreadcrumb">
                <div class="breadcrumb flex flex-wrap gap-4 items-center justify-between text-grey-500">
                    <slot name="breadcrumb"/>
                </div>
                <div>
                    <slot name="actions"/>
                </div>
            </div>
            <slot/>
        </div>
    </div>
</template>

<script>
    import ClientHeader     from "./ClientHeader"
    import SidebarComponent from "./ClientSidebar"
    import "ant-design-vue/lib/auto-complete/style/index.css"
    import "ant-design-vue/lib/select/style/index.css"
    import "ant-design-vue/lib/date-picker/style/index.css"
    import "ant-design-vue/lib/button/style/index.css"
    import "ant-design-vue/lib/dropdown/style/index.css"
    import "ant-design-vue/lib/input/style/index.css"
    import "ant-design-vue/lib/table/style/index.css"
    import "ant-design-vue/lib/pagination/style/index.css"
    import "ant-design-vue/lib/spin/style/index.css"
    import "ant-design-vue/lib/message/style/index.css"

    export default {
        name: "ClientLayout",
        components: { ClientHeader, SidebarComponent },
        props: {
            ifHasBreadcrumb: { type: Boolean, required: false, default: false },
            activeLink: { type: String, required: false },
        },
    }
</script>


<template>
    <div
        class="btn-icon cursor-pointer p-4 w-full block rounded bg-grey-100 hover:bg-pinkShade-100 text-pinkShade-600 transition">
        <Icon name="plus" class="w-3 h-3"/>
        <slot/>
    </div>
</template>

<script>
import Icon from "../General/Icon"

export default {
    name: "AddButton",
    components: {Icon},
}
</script>

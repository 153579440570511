<template>
    <span :class="isCapitalize + 'text-grey-700'" v-text="value"/>
</template>

<script>
    export default {
        name: "LabelValue",
        props: {
            value: { type: String, required: true },
            isCapitalize: {type:String,required:false,default:'capitalize'},
        },
    }

</script>

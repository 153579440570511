<template>
    <ol class="flex list-reset breadcrumb flex-wrap text-grey-500 text-sm">
        <slot/>
    </ol>
</template>

<script type="text/ecmascript-6">
    import BreadCrumbItem from "./BreadCrumbItem"

    export default {
        name: "BreadCrumb",

        components: { BreadCrumbItem },
    }
</script>

<template>
    <div>
        <div class="form-header">
            <Icon name="covid-test-booking-2"/>
            <h3 class="heading-primary heading-primary-sm" v-text="'Covid Test'"/>
        </div>
        <div class="grid gap-6">
<!--            <div v-if="showAppointmentDate">-->
<!--                <H3 title="Appointment"/>-->
<!--                <div class="grid md:grid-cols-3 gap-10">-->
<!--            <div-->
<!--                :class="(v$.appointment_date.$error || getFromObject(errors, 'test_information.appointment_date')) ? 'form-group input-error' : 'form-group'">-->
<!--                        <label-component v-text="'Appointment Date'"/>-->
<!--                        <date-picker-->
<!--                            name="appointment-date"-->
<!--                            v-model="appointment_date"-->
<!--                            mode="dateTime"-->
<!--                            :is24hr="false"-->
<!--                            :model-config="dateTimePickerConfig"-->
<!--                            :popover="{ visibility: 'focus'  }"-->
<!--                            :masks="{ inputDateTime: 'MM-DD-YYYY h:mmA'}">-->
<!--                            <template v-slot="{ inputValue, inputEvents }">-->
<!--                                <input-component-->
<!--                                    :value="inputValue"-->
<!--                                    v-on="inputEvents"-->
<!--                                    placeholder="MM-DD-YYYY h:mmA"-->
<!--                                />-->
<!--                            </template>-->
<!--                        </date-picker>-->
<!--                        <input-error-->
<!--                            v-if="getFromObject(errors, 'test_information.appointment_date')"-->
<!--                            :message="getFromObject(errors, 'test_information.appointment_date')"/>-->
<!--                        <input-error v-if="v$.appointment_date.$error" :message="v$.appointment_date.$errors[0].$message"/>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
            <div>
                <H3 title="Test Information"/>
                <div class="grid md:grid-cols-3 gap-10">
                    <div
                        :class="(v$.name.$error || getFromObject(errors, 'test_information.name')) ? 'form-group input-error' : 'form-group'">
                        <label-component :value="'Test Name'"/>
                        <check-box-group-input class="!grid !gap-3" name="test-name" :options="testOptions" v-model="name"/>
                        <input-error
                            v-if="getFromObject(errors, 'test_information.name')"
                            :message="getFromObject(errors, 'test_information.name')"/>
                        <input-error v-if="v$.name.$error" :message="v$.name.$errors[0].$message"/>
                    </div>
                    <div
                        :class="(v$.is_first_test.$error || getFromObject(errors, 'test_information.is_first_test')) ? 'form-group input-error' : 'form-group'">
                        <label-component :value="'First Test'"/>
                        <radio-group-input class="!grid !gap-3" name="is-first-test"
                                           :options="yesNoOptions"
                                           v-model="is_first_test"/>
                        <input-error
                            v-if="getFromObject(errors, 'test_information.is_first_test')"
                            :message="getFromObject(errors, 'test_information.is_first_test')"/>
                        <input-error v-if="v$.is_first_test.$error" :message="v$.is_first_test.$errors[0].$message"/>
                    </div>
                    <div
                        :class="(v$.is_health_care_worker.$error || getFromObject(errors, 'test_information.is_health_care_worker')) ? 'form-group input-error' : 'form-group'">
                        <label-component :value="'Health Care Workers'"/>
                        <radio-group-input class="!grid !gap-3"
                                           name="health-care-workers"
                                           :options="yesNoOptions"
                                           v-model="is_health_care_worker"/>
                        <input-error
                            v-if="getFromObject(errors, 'test_information.is_health_care_worker')"
                            :message="getFromObject(errors, 'test_information.is_health_care_worker')"/>
                        <input-error
                            v-if="v$.is_health_care_worker.$error"
                            :message="v$.is_health_care_worker.$errors[0].$message"/>
                    </div>
                    <div
                        :class="(v$.has_symptom.$error || getFromObject(errors, 'test_information.has_symptom')) ? 'form-group input-error' : 'form-group'">
                        <label-component :value="'Symptoms'"/>
                        <radio-group-input
                            class="!grid !gap-3"
                            name="symptom"
                            :options="yesNoOptions"
                            v-model="has_symptom"
                            @change="resetSymptoms"/>
                        <input-error
                            v-if="getFromObject(errors, 'test_information.has_symptom')"
                            :message="getFromObject(errors, 'test_information.has_symptom')"/>
                        <input-error v-if="v$.has_symptom.$error" :message="v$.has_symptom.$errors[0].$message"/>
                    </div>
                    <div v-show="has_symptom"
                         :class="(v$.symptom_date.$error || getFromObject(errors, 'test_information.symptom_date')) ? 'form-group input-error' : 'form-group'">
                        <label-component :value="'Symptoms onset date'"/>
                        <date-picker name="symptom-date"
                                     class="md:w-4/5"
                                     v-model="symptom_date"
                                     mode="date"
                                     :max-date="currentDate"
                                     :model-config="datePickerConfig"
                                     :popover="{ visibility: 'focus'  }"
                                     :masks="{ input: 'MM-DD-YYYY'}">
                            <template v-slot="{ inputValue, inputEvents }">
                                <div class="form-icon">
                                    <input-component
                                        :value="inputValue"
                                        v-on="inputEvents"
                                        placeholder="Select Date"
                                    />
                                    <Icon name="date"/>
                                </div>
                            </template>
                        </date-picker>
                        <input-error
                            v-if="getFromObject(errors, 'test_information.symptom_date')"
                            :message="getFromObject(errors, 'test_information.symptom_date')"/>
                        <input-error v-if="v$.symptom_date.$error" :message="v$.symptom_date.$errors[0].$message"/>
                    </div>
                    <div
                        :class="(v$.is_hospitalized.$error || getFromObject(errors, 'test_information.is_hospitalized')) ? 'form-group input-error' : 'form-group'">
                        <label-component :value="'Hospitalized'"/>
                        <radio-group-input class="!grid !gap-3"
                                           name="hospitalized"
                                           :options="yesNoOptions"
                                           v-model="is_hospitalized"
                        />
                        <input-error
                            v-if="getFromObject(errors, 'test_information.is_hospitalized')"
                            :message="getFromObject(errors, 'test_information.is_hospitalized')"/>
                        <input-error
                            v-if="v$.is_hospitalized.$error"
                            :message="v$.is_hospitalized.$errors[0].$message"/>
                    </div>
                    <div>
                        <label-component :value="'                            ICU\n'"/>
                        <radio-group-input class="!grid !gap-3"
                                           name="icu"
                                           :options="yesNoOptions"
                                           v-model="is_in_icu"/>
                        <input-error
                            v-if="getFromObject(errors, 'test_information.is_in_icu')"
                            :message="getFromObject(errors, 'test_information.is_in_icu')"/>
                        <input-error v-if="v$.is_in_icu.$error" :message="v$.is_in_icu.$errors[0].$message"/>
                    </div>
                    <div
                        :class="(v$.is_in_congregate_care.$error || getFromObject(errors, 'test_information.is_in_congregate_care')) ? 'form-group input-error' : 'form-group'">
                        <label-component :value="'Are you living in congregate care setting?'"/>
                        <radio-group-input class="!grid !gap-3"
                                           name="congregate-care "
                                           :options="yesNoOptions"
                                           v-model="is_in_congregate_care"/>
                        <input-error
                            v-if="getFromObject(errors, 'test_information.is_in_congregate_care')"
                            :message="getFromObject(errors, 'test_information.is_in_congregate_care')"/>
                        <input-error v-if="v$.is_in_congregate_care.$error" :message="v$.is_in_congregate_care.$errors[0].$message"/>
                    </div>
                    <div
                        :class="(v$.is_pregnant.$error || getFromObject(errors, 'test_information.is_pregnant')) ? 'form-group input-error' : 'form-group'">
                        <label-component :value="'Are you Pregnant?'"/>
                        <radio-group-input class="!grid !gap-3"
                                           name="pregnant"
                                           :options="pregnancyOptions"
                                           v-model="is_pregnant"/>
                        <input-error
                            v-if="getFromObject(errors, 'test_information.is_pregnant')"
                            :message="getFromObject(errors, 'test_information.is_pregnant')"/>
                        <input-error v-if="v$.is_pregnant.$error" :message="v$.is_pregnant.$errors[0].$message"/>
                    </div>
                </div>
            </div>
            <hr class="separator-dashed">
        </div>
    </div>
    <div v-if="showButtons" class="pt-8 flex justify-between">
        <span class="text-sm" v-text="'* All fields are required unless specified optional'"/>
        <div v-if="showButtons" class="flex gap-8">
            <cancel-button @click="goBack">Back</cancel-button>
            <primary-button @click="nextPage" class="btn px-10">Next</primary-button>
        </div>
    </div>
</template>

<script>
    import { useVuelidate }         from "@vuelidate/core"
    import {
        helpers,
        required,
    }                               from "@vuelidate/validators"
    import { get as getFromObject } from "lodash"
    import { DatePicker }           from "v-calendar"
    import {
        computed,
        inject,
        reactive,
        toRefs,
        watch,
    }                               from "vue"
    import SelectDropdown           from "../../../../components/Admin/Forms/SelectDropdown"
    import Icon                     from "../../../../components/Admin/General/Icon.vue"
    import CancelButton             from "../../../../components/Admin/Buttons/CancelButton"
    import PrimaryButton            from "../../../../components/Admin/Buttons/PrimaryButton"
    import CheckBoxGroupInput       from "../../../../components/Admin/Forms/CheckBoxGroupInput"
    import DropDownInput            from "../../../../components/Admin/Forms/DropDownInput"
    import InputComponent           from "../../../../components/Admin/Forms/Input"
    import InputError               from "../../../../components/Admin/Forms/InputError"
    import LabelComponent           from "../../../../components/Admin/Forms/Label"
    import RadioGroupInput          from "../../../../components/Admin/Forms/RadioGroupInput"
    import SelectDatePicker         from "../../../../components/Admin/Forms/SelectDatePicker"
    import SelectInput              from "../../../../components/Admin/Forms/SelectInput"
    import TimePicker               from "../../../../components/Admin/Forms/TimePicker"
    import CardHeader               from "../../../../components/Admin/General/CardHeader"
    import Header                   from "../../../../components/Admin/Layout/AdminHeader"
    import SidebarRegistration      from "../../../../components/Admin/Layout/Sidebar"
    import H3                       from "../../../../components/Admin/typography/H3"

    export default {
        name: "TestBookingNew.vue",
        components: {
            SelectDropdown,
            CheckBoxGroupInput,
            H3,
            Icon,
            CardHeader,
            PrimaryButton,
            CancelButton,
            TimePicker,
            SelectInput,
            InputError,
            DatePicker,
            SelectDatePicker,
            SidebarRegistration,
            Header,
            InputComponent,
            LabelComponent,
            DropDownInput,
            RadioGroupInput,
        },
        props: {
            // form: { type: Object, required: true },
            modelValue: { type: Object, required: false, default: () => ({}) },
            doctorInformation: { type: Object, required: false, default: () => ({}) },
            errors: { type: Object, required: false, default: () => ({}) },
            showButtons: { type: Boolean, required: false, default: false },
            showAppointmentDate: { type: Boolean, required: false, default: true },
        },
        inheritAttrs: false,
        emits: ["next", "back", "update:modelValue", "update:doctorInformation"],
        setup(props, { emit }) {
            const testOptions = inject("testOptions")
            const pregnancyOptions = inject("pregnancyOptions")
            const yesNoOptions = inject("yesNoOptions")
            const stateOptions = inject("stateOptions")

            const currentDate = new Date()

            const datePickerConfig = {
                type: "string",
                mask: "MM-DD-YYYY", // Uses 'iso' if missing
            }
            const dateTimePickerConfig = {
                type: "string",
                mask: "MM-DD-YYYY h:mmA", // Uses 'iso' if missing
            }

            const appState = reactive({
                appointment_date: null,
                appointment_time: null,
                name: [],
                is_first_test: null,
                is_health_care_worker: null,
                has_symptom: null,
                symptom_date: null,
                is_hospitalized: null,
                is_in_icu: null,
                is_in_congregate_care: null,
                is_pregnant: null,
            })

            const resultValue = computed(() => {
                return {
                    // appointment_date: appState.appointment_date,
                    // appointment_time: appState.appointment_time,
                    name: appState.name,
                    is_first_test: appState.is_first_test,
                    is_health_care_worker: appState.is_health_care_worker,
                    has_symptom: appState.has_symptom,
                    symptom_date: appState.symptom_date,
                    is_hospitalized: appState.is_hospitalized,
                    is_in_icu: appState.is_in_icu,
                    is_in_congregate_care: appState.is_in_congregate_care,
                    is_pregnant: appState.is_pregnant,
                    doctor_information: {
                        ordering_facility: appState.ordering_facility,
                        doctor_name: appState.doctor_name,
                        doctor_street_address_one: appState.doctor_street_address_one,
                        doctor_street_address_two: appState.doctor_street_address_two,
                        city: appState.city,
                        state: appState.state,
                        zip_code: appState.zip_code,
                        phone_number: appState.phone_number,
                    },
                }
            })

            watch(resultValue, () => {
                emit("update:modelValue", resultValue.value)
            })

            let { modelValue } = toRefs(props)

            watch(() => modelValue.value, (newValue, oldValue) => {
                // appState.appointment_date = newValue.appointment_date
                // appState.appointment_time = newValue.appointment_time
                appState.name = newValue.name ?? []
                appState.is_first_test = newValue.is_first_test
                appState.is_health_care_worker = newValue.is_health_care_worker
                appState.has_symptom = newValue.has_symptom
                appState.symptom_date = newValue.symptom_date
                appState.is_hospitalized = newValue.is_hospitalized
                appState.is_in_icu = newValue.is_in_icu
                appState.is_in_congregate_care = newValue.is_in_congregate_care
                appState.is_pregnant = newValue.is_pregnant

            }, { deep: true, immediate: true })

            function resetSymptoms() {
                if (!appState.has_symptom) {
                    appState.symptom_date = null
                }
            }

            const hasSymptom = () => {
                if (!appState.has_symptom) {
                    return true
                }

                return !!appState.symptom_date
            }

            // const hasAppointment = () => {
            //     if (!props.showAppointmentDate) {
            //         return true
            //     }
            //
            //     return !!appState.appointment_date
            // }

            const rules = computed(() => ({
                // appointment_date: {
                //     requiredIf: helpers.withMessage("Required", hasAppointment),
                // },
                name: {
                    required,
                },
                is_first_test: {
                    required,
                },
                is_health_care_worker: {
                    required,
                },
                has_symptom: {
                    required,
                },
                symptom_date: {
                    requiredIf: helpers.withMessage("Required", hasSymptom),
                },
                is_hospitalized: {
                    required,
                },
                is_in_icu: {
                    required,
                },
                is_in_congregate_care: {
                    required,
                },
                is_pregnant: {
                    required,
                },
            }))

            const v$ = useVuelidate(rules, appState)

            return {
                ...toRefs(appState),
                currentDate,
                dateTimePickerConfig,
                datePickerConfig,
                resetSymptoms,
                v$,
                getFromObject,
                testOptions,
                pregnancyOptions,
                yesNoOptions,
                stateOptions,
            }
        },
        methods: {
            nextPage() {
                this.v$.$touch()
                if (this.v$.$error) return

                this.$emit("next")
            },
            goBack() {
                this.$emit("back")
            },
        },
    }
</script>

<style scoped>

</style>

<template>
    <admin-layout active-link="patients">
        <div class="card--large !p-0">
            <!-- title and dropdown -->
            <div class="table-head-container">
                <h1 class="heading-primary !text-grey-1100" v-text="'Patients'"/>
            </div>
            <!-- table -->
            <ant-table
                :columns="columns"
                :data-source="patientListTableData"
                rowKey="id"
                :pagination="state.pagination"
                :loading="loading"
                @change="onChange"
                :scroll="{ x: 1500 }">

                <template #action="{ record }">
                    <action-button @click="goToEditPatient(record.id)" action-type="edit"
                                   tooltip-text="Edit patient"/>
                </template>
                <template #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
                    <div class="grid gap-2 p-2">

                        <a-input
                            class="placeholder-grey-400"
                            ref="searchInput"
                            :placeholder="`Search ${column.dataIndex}`"
                            :value="selectedKeys[0]"
                            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                        />
                        <div class="flex items-center justify-between gap-2">


                            <a-button
                                class="btn-icon"

                                type="primary"
                                size="small"
                                @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                            >
                                <Icon name="search"/>
                                Search
                            </a-button>
                            <a-button class="ant-reset" size="small" @click="handleReset(clearFilters)">
                                Reset
                            </a-button>
                        </div>

                    </div>
                </template>
                <template #filterIcon="filtered">
                    <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }"/>
                </template>
                <template #patientName="{record}">
                    <PatientName :name="record.name"/>
                </template>
                <template #customRender="{ text, column }">
                            <span v-if="searchText && searchedColumn === column.dataIndex">
                                <template
                                    v-for="(fragment, i) in text
                                    .toString()
                                    .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
                                >
                                    <mark
                                        v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                                        class="highlight"
                                        :key="i"
                                    >
                                      {{ fragment }}
                                    </mark>
                                    <template v-else>{{ fragment }}</template>
                                </template>
                          </span>
                    <template v-else>
                        {{ text }}
                    </template>
                </template>
                <template #email="{record}">
                    <span class="!normal-case">{{record.email}}</span>
                </template>
                <template #thumbnail="{record}">
                    <div class="flex item-center">
                        <div v-if="record.thumbnail === '/images/default_avatar.png'"
                             class="bg-grey-200 rounded-full p-1 inline-flex text-grey-300 w-6 h-6 overflow-hidden !inline-flex flex-center">
                            <Icon name="avatar" class="!h-8 !w-8 mt-1"/>
                        </div>
                        <img v-else alt="profile-pic" class="w-6 h-6 rounded-full object-cover"
                             :src="record.thumbnail"/>
                    </div>
                </template>
                <template #testName="{ record }">
                    <p
                        v-for="(test, index) in record.sample_tests"
                        :key="index">
                        {{ test.test_name }}
                    </p>
                </template>
                <template #reportDate="{ record }">
                    <p
                        v-for="(test, index) in record.sample_tests"
                        :key="index">
                        {{ test.result_date }}
                    </p>
                </template>
                <template #results="{ record }">
                    <div
                        v-for="(sampleTest, index) in record.sample_tests"
                        :key="index">
                        <p
                            v-for="(result, test) in sampleTest.result"
                            :key="test"
                            v-text="`${test}: ${result}`"
                        />
                    </div>
                </template>
            </ant-table>
        </div>
    </admin-layout>
</template>

<script>
    import {
        computed,
        onMounted,
        reactive,
        ref,
    } from "vue"
import {get as getFromObject} from "lodash";
import AdminLayout from "../../../components/Admin/Layout/AdminLayout";
import {SearchOutlined} from "@ant-design/icons-vue"
import {default as AButton} from "ant-design-vue/lib/button"
import {default as AInput} from "ant-design-vue/lib/input"
import AntTable from "ant-design-vue/lib/table"
import Icon from "../../../components/Admin/General/Icon"
import Modal from "../../../components/Admin/Modal"
import PrimaryButton from "../../../components/Admin/Buttons/PrimaryButton";
import SecondaryButton from "../../../components/Admin/Buttons/SecondaryButton";
import {Inertia} from "@inertiajs/inertia";
import InputError from "../../../components/Admin/Forms/InputError";
import LabelComponent from "../../../components/Admin/Forms/Label";
import ActionButton from "../../../components/Admin/Buttons/ActionButton";
import PatientName from "../../../components/Admin/General/PatientName";

export default {
    name: "Index",
    components: {
        ActionButton,
        PatientName,
        SecondaryButton,
        PrimaryButton,
        Icon,
        AdminLayout,
        AntTable,
        SearchOutlined,
        AButton,
        AInput,
        Modal,
        InputError,
        LabelComponent,
    },
    props: {
        patients: {
            type: Object, required: false, default: () => ({})
        },
        raceOptions: Object,
        genderOptions: Object,
    },
    setup(props) {
        const targetUrl = reactive({
            patientIndex: route("admin.patient.index"),
        });
        const state = reactive({
            searchText: '',
            searchedColumn: '',
            pagination: {
                current: 1,
                total: 0,
                pageSize: 20,
                from: 1,
            },
            queries: {
                patient_name: "",
            },
            showPatientExistsModal: false,
        });
        const sortedInfo = ref();
        const searchInput = ref();
        const filteredInfo = ref();
        const patientListTableData = ref([])

        const loading = ref(false);

        const itemsWithSno = computed(() => {
            return []
            // return props.patients.data.map((d, index) => ({...d, sno: index + 1}))
        });
        const columns = computed(() => {
            const sorted = sortedInfo.value || {};
            const filtered = filteredInfo.value || {};
            return [
                {
                    title: 'S.No.',
                    dataIndex: 'sno',
                    key: 'sno',
                    fixed: "left",
                },
                {
                    title: "Action",
                    width: "9em",
                    slots: {
                        customRender: "action",
                    },
                    fixed: "left",
                },
                {
                    title: 'Avatar',
                    width: "6rem",
                    dataIndex: 'thumbnail',
                    key: 'thumbnail',
                    slots: {
                        customRender: "thumbnail",
                    },
                },
                {
                    title: 'Req#',
                    width: "6rem",
                    dataIndex: 'requisition_code',
                    key: 'requisition_code',
                },
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                    slots: {
                        filterDropdown: 'filterDropdown',
                        filterIcon: 'filterIcon',
                        customRender: "patientName",
                    },
                    // sorter: (a, b) => a.name.localeCompare(b.name),
                    // sortDirections: ["descend", "ascend"],
                    ellipsis: true,
                    onFilter: (value, record) =>
                        record.name.toString().toLowerCase().includes(value.toLowerCase()),
                    onFilterDropdownVisibleChange: visible => {
                        if (visible) {
                            setTimeout(() => {
                                searchInput.value.focus();
                            }, 0);
                        }
                    },
                },
                {
                    title: 'DOB',
                    width: "8rem",
                    dataIndex: 'dob',
                    key: 'dob',
                    // sorter: (a, b) => a.dob.localeCompare(b.dob),
                    // sortDirections: ["descend", "ascend"],
                },
                {
                    title: 'Gender',
                    width: "10rem",
                    dataIndex: 'gender',
                    key: 'gender',
                    // filters: [
                    //     {
                    //         text: 'Male',
                    //         value: props.genderOptions.male,
                    //     },
                    //     {
                    //         text: 'Female',
                    //         value: props.genderOptions.female,
                    //     },
                    //     {
                    //         text: 'Other',
                    //         value: props.genderOptions.other,
                    //     },
                    // ],
                    // filteredValue: filtered.gender || null,
                    // onFilter: (value, record) => record.gender.includes(value),
                    // sorter: (a, b) => a.gender.localeCompare(b.gender),
                    // sortDirections: ["descend", "ascend"],
                },
                {
                    title: "Test Name",
                    slots: {
                        customRender: "testName",
                    },
                    width: "10rem",
                },
                {
                    title: "Result",
                    slots: {
                        customRender: "results",
                    },
                    width: "10rem",
                    ellipsis: true,
                },
                {
                    title: "Report date",
                    slots: {
                        customRender: "reportDate",
                    },
                    width: "10rem",
                },
                {
                    title: 'Phone Number',
                    dataIndex: 'phone_number',
                    key: 'phone',
                    // sorter: (a, b) => a.phone_number.localeCompare(b.phone_number),
                    // sortDirections: ["descend", "ascend"],
                },
                {
                    title: 'Email',
                    dataIndex: 'email',
                    key: 'email',
                    // sorter: (a, b) => a.email.localeCompare(b.email),
                    // sortDirections: ["descend", "ascend"],
                    slots: {
                        customRender: "email",
                    },
                    ellipsis: true,
                },
            ];
        });
        const handleSearch = (selectedKeys, confirm, dataIndex) => {
            confirm()
            state.searchText = selectedKeys[0]
            state.searchedColumn = dataIndex
        }

        const handleReset = clearFilters => {
            clearFilters()
            state.searchText = ""
        }
        const onChange = (pagination, filters, sorter) => {
            state.pagination = {...pagination}
            state.queries.patient_name = getFromObject(filters, 'name.0','')
            filteredInfo.value = filters;
            sortedInfo.value = sorter;
            loadData()
        };
        const goToEditPatient = (id) => {
            Inertia.get(route('admin.patient.edit', id))
        }

        const loadPatientListTableData = (dataArray)  => {
            if (!dataArray || !Array.isArray(dataArray)) {
                return
            }
            patientListTableData.value = []
           patientListTableData.value = dataArray.map((d, index) => ({...d, sno: index + 1}))
        }

        const loadData = () => {
            loading.value = true
            axios.get("/api/patients", {
                params: {
                    page: state.pagination.current,
                    ...state.queries,
                },
            }).then(({data}) => {
                state.pagination.current = (getFromObject(data, "meta.current_page", 1))
                state.pagination.total = (getFromObject(data, "meta.total", 0))
                state.pagination.pageSize = (getFromObject(data, "meta.per_page", 20))
                state.pagination.from = (getFromObject(data, "meta.from", 1))

                loadPatientListTableData(getFromObject(data, "data", []))

                loading.value = false
            }).catch((error) => {
                loading.value = false
                console.error(error)
            })
        }
        onMounted(() => {
            loadData()
        })
        return {
            state,
            targetUrl,
            getFromObject,
            columns,
            patientListTableData,
            itemsWithSno,
            handleSearch,
            handleReset,
            searchInput,
            onChange,
            searchText: '',
            searchedColumn: '',
            loading,
            goToEditPatient,
        };
    },
};
</script>

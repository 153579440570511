<template>
    <admin-layout :if-has-breadcrumb="true" active-link="clients">
        <template #breadcrumb>
            <bread-crumb>
                <bread-crumb-item :href="clientIndexUrl" label="Clients"/>
                <bread-crumb-item label="Edit Client Information"/>
            </bread-crumb>
        </template>
        <div v-if="form" class="card--large !overflow-visible">
                <h1 class="heading-primary !text-grey-1100 !pb-8" v-text="'Edit Client Information'"/>

            <form @submit.prevent="submitUpdateForm(form.id)">
                <div class="grid lg:grid-cols-3 gap-y-6 gap-10">
                    <div>
                        <label-component :value="'Ordering Facility'"/>
                        <input-component
                            type="text"
                            name="facility_name"
                            v-model="form.facility_name"/>
                        <input-error
                            v-if="getFromObject(form.errors, 'facility_name')"
                            :message="getFromObject(form.errors, 'facility_name')"/>
                        <input-error v-if="v$.facility_name.$error" :message="v$.facility_name.$errors[0].$message"/>
                    </div>
                    <div>
                        <label-component :value="'Doctor’s First Name'"/>
                        <input-component
                            type="text"
                            name="first_name"
                            v-model="form.first_name"/>
                        <input-error
                            v-if="getFromObject(form.errors, 'first_name')"
                            :message="getFromObject(form.errors, 'first_name')"/>
                        <input-error v-if="v$.first_name.$error" :message="v$.first_name.$errors[0].$message"/>
                    </div>
                    <div>
                        <label-component :value="'Doctor’s Last Name'"/>
                        <input-component
                            type="text"
                            name="last_name"
                            v-model="form.last_name"/>
                        <input-error
                            v-if="getFromObject(form.errors, 'last_name')"
                            :message="getFromObject(form.errors, 'last_name')"/>
                        <input-error v-if="v$.last_name.$error" :message="v$.last_name.$errors[0].$message"/>
                    </div>
                    <div>
                        <label-component :value="'Street Address 1'"/>
                        <input-component
                            type="text"
                            name="street_address_one"
                            v-model="form.street_address_one"/>
                        <input-error
                            v-if="getFromObject(form.errors, 'street_address_one')"
                            :message="getFromObject(form.errors, 'street_address_one')"/>
                        <input-error v-if="v$.street_address_one.$error" :message="v$.street_address_one.$errors[0].$message"/>
                    </div>
                    <div>
                        <label-component :value="'Street Address 2 (Optional)'"/>
                        <input-component
                            type="text"
                            name="street_address_two"
                            v-model="form.street_address_two"/>
                        <input-error
                            v-if="getFromObject(form.errors, 'street_address_two')"
                            :message="getFromObject(form.errors, 'street_address_two')"/>
                        <input-error v-if="v$.street_address_two.$error" :message="v$.street_address_two.$errors[0].$message"/>
                    </div>

                    <div>
                        <label-component :value="'City'"/>
                        <input-component
                            type="text"
                            name="city"
                            v-model="form.city"/>
                        <input-error
                            v-if="getFromObject(form.errors, 'city')"
                            :message="getFromObject(form.errors, 'city')"/>
                        <input-error v-if="v$.city.$error" :message="v$.city.$errors[0].$message"/>
                    </div>
                    <div>
                        <label-component :value="'State'"/>
                        <select-input
                            :searchable="true"
                            place-holder="Select a state"
                            name="state"
                            :options="stateOptions"
                            v-model="form.state"/>
                        <input-error
                            v-if="getFromObject(form.errors, 'state')"
                            :message="getFromObject(form.errors, 'state')"/>
                        <input-error v-if="v$.state.$error" :message="v$.state.$errors[0].$message"/>
                    </div>

                    <div>
                        <label-component :value="'Zip Code'"/>
                        <input-component
                            type="text"
                            name="zip_code"
                            v-model="form.zip_code"/>
                        <input-error
                            v-if="getFromObject(form.errors, 'zip_code')"
                            :message="getFromObject(form.errors, 'zip_code')"/>
                        <input-error v-if="v$.zip_code.$error" :message="v$.zip_code.$errors[0].$message"/>
                    </div>
                    <div>
                        <label-component :value="'Phone Number'"/>
                        <input-component
                            type="text"
                            name="phone_number"
                            v-model="form.phone_number"/>
                        <input-error
                            v-if="getFromObject(form.errors, 'phone_number')"
                            :message="getFromObject(form.errors, 'phone_number')"/>
                        <input-error v-if="v$.phone_number.$error" :message="v$.phone_number.$errors[0].$message"/>
                    </div>
                    <div>
                        <label-component :value="'User'"/>
                        <select-input
                            :searchable="true"
                            place-holder="Select a user"
                            name="client_user_id"
                            :options="clientUserOptions"
                            v-model="form.client_user_id"/>
                        <input-error
                            v-if="getFromObject(form.errors, 'client_user_id')"
                            :message="getFromObject(form.errors, 'client_user_id')"/>
                    </div>
                </div>
                <div class="mt-6 pt-6 flex items-center justify-between border-t border-pinkShade-200">
                       <span>
                           <span class="text-pinkShade-100">*</span>
                           <span class="text-sm" v-text="'* All fields are required unless specified optional'"/>
                       </span>

                    <div class="flex items-center gap-8">
                        <cancel-button :inertia-link="true" :href="clientIndexUrl" class="btn">Cancel</cancel-button>
                        <primary-button @click="submitUpdateForm(form.id)" class="btn" :disabled="form.processing">
                            <span v-if="form.processing">Updating</span>
                            <span v-else>Update</span>
                        </primary-button>
                    </div>
                </div>
            </form>
        </div>
    </admin-layout>
</template>

<script>
import AdminLayout from "../../../components/Admin/Layout/AdminLayout";
import BreadCrumb from "../../../components/Admin/BreadCrumb/BreadCrumb";
import BreadCrumbItem from "../../../components/Admin/BreadCrumb/BreadCrumbItem";
import CancelButton from "../../../components/Admin/Buttons/CancelButton";
import PrimaryButton from "../../../components/Admin/Buttons/PrimaryButton";
import {useForm} from "@inertiajs/inertia-vue3"
import {useVuelidate} from "@vuelidate/core"
import {
    helpers,
    required,
} from "@vuelidate/validators"
import {get as getFromObject} from "lodash"
import {computed} from "vue"
import LabelComponent from "../../../components/Admin/Forms/Label";
import InputComponent from "../../../components/Admin/Forms/Input";
import SelectInput from "../../../components/Admin/Forms/SelectInput";
import DropDownInput from "../../../components/Admin/Forms/DropDownInput";
import InputError from "../../../components/Front/Forms/InputError";

export default {
    name: "Edit",
    components: {InputError, DropDownInput, SelectInput, InputComponent, LabelComponent, PrimaryButton, CancelButton, BreadCrumbItem, BreadCrumb, AdminLayout},
    props: {
        stateList: {type: Object, required: true},
        client: {type: Object, required: true},
        clientUserList: { type: Object, required: true },
    },
    setup(props) {
        const clientIndexUrl = route('admin.clients.index')
        const saveNewClientUrl = route('admin.clients.store')

        const stateOptions = new Map()
        Object.keys(props.stateList).forEach(key => stateOptions.set(key, props.stateList[key]))

        const clientUserOptions = new Map()
        Object.keys(props.clientUserList).forEach(key => clientUserOptions.set(key, props.clientUserList[key]))

        const form = useForm("NewClientForm", {
            id:props.client.data.id,
            facility_name: props.client.data.facility_name,
            first_name: props.client.data.first_name,
            last_name: props.client.data.last_name,
            street_address_one: props.client.data.street_address_one,
            street_address_two: props.client.data.street_address_two,
            city: props.client.data.city,
            state: props.client.data.state,
            zip_code: props.client.data.zip_code,
            phone_number: props.client.data.phone_number,
            client_user_id: props.client.data.user_id
        })

        const rules = computed(() => ({
            facility_name: {
                required,
            },
            first_name: {
                required,
            },
            last_name: {
                required,
            },
            street_address_one: {
                required,
            },
            street_address_two: {

            },
            city: {
                required,
            },
            state: {
                required,
            },
            zip_code: {
                required,
            },
            phone_number: {
                required,
            },
        }))

        const v$ = useVuelidate(rules, form)

        return {
            clientIndexUrl,
            saveNewClientUrl,
            v$,
            form,
            stateOptions,
            getFromObject,
            clientUserOptions,
        }
    },
    methods: {
        submitUpdateForm(id) {
            if (this.form.processing) return

            this.v$.$touch()
            if (this.v$.$error) return

            this.form.post(route('admin.clients.update',id))
        },
    }
}
</script>

<style scoped>

</style>

<template>
    <client-layout>
        <div class="card !px-0">
            <completed-results-table/>
        </div>
    </client-layout>
</template>

<script>
    import ClientLayout          from "../../../components/Client/Layout/ClientLayout"
    import CompletedResultsTable from "./Partials/CompletedResultsTable"
    export default {
        name: "ClientDashboard",
        components: { CompletedResultsTable, ClientLayout },
    }
</script>

<style scoped>

</style>

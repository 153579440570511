<template>
    <admin-layout active-link="appointments">
        <div class="card--large !px-0 !pt-0 !pb-8">
            <div class="table-head-container">
                <h1 class="font-bold mt-0 text-grey-1100 leading-none" style="font-size: 32px;"
                    v-text="'Appointments'"/>
            </div>
            <!-- Tab -->
            <div class="tab mb-8">
                <div class="tab__list px-8 gap-2" aria-label="Tabs">
                    <div
                        @click="showTab(TAB_PENDING_APPOINTMENTS)"
                        :class="currentTab === TAB_PENDING_APPOINTMENTS  ? 'tab__item is--active !rounded-b-none bg-white !text-sm':'tab__item bg-white !rounded-b-none !text-sm'"
                    >
                        <span> Pending Appointments</span>
                    </div>
                    <div
                        @click="showTab(TAB_COMPLETED_APPOINTMENTS)"
                        :class="currentTab === TAB_COMPLETED_APPOINTMENTS  ?
                         'tab__item is--active !rounded-b-none bg-white !text-sm':'tab__item bg-white !rounded-b-none !text-sm'"
                    >
                        <span> Completed Appointments</span>
                    </div>
                </div>
            </div>
            <div class="overflow-x-auto table-tooltip-break">
                <AntTable v-if="currentTab === TAB_PENDING_APPOINTMENTS"
                          :columns="appointmentListTableColumns"
                          :dataSource="appointmentPendingListTableData"
                          :pagination="pendingAppointments.pagination"
                          :loading="pendingAppointments.loading"
                          :scroll="{ x: 1500, y: 700 }"
                          @change="handlePendingAppointmentsChange"
                >
                    <template #filterSearch="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
                        <div class="grid gap-2 p-2">
                            <a-input
                                class="placeholder-grey-400"
                                ref="searchInput"
                                :placeholder="`Enter ${column.dataIndex.split('_').join(' ')}`"
                                :value="selectedKeys[0]"
                                @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                                @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                            >

                            </a-input>
                            <div class="flex items-center justify-between gap-2">
                                <a-button
                                    class="btn-icon"
                                    type="primary"
                                    size="small"
                                    @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                                >
                                    <Icon class="w-3.5 h-3.5" name="search"/>
                                    Search
                                </a-button>
                                <a-button class="ant-reset" size="small" @click="handleReset(clearFilters)">
                                    Reset
                                </a-button>
                            </div>
                        </div>

                    </template>
                    <template #filterIcon="filtered">
                        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }"/>
                    </template>
                    <template #customRender="{ text, column }">
                            <span v-if="searchText && searchedColumn === column.dataIndex">
                                <template
                                    v-for="(fragment, i) in text
                                    .toString()
                                    .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
                                >
                                    <mark
                                        v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                                        class="highlight"
                                        :key="i"
                                    >
                                      {{ fragment }}
                                    </mark>
                                    <template v-else>{{ fragment }}</template>
                                </template>
                          </span>
                        <template v-else>
                            {{ text }}
                        </template>
                    </template>
                    <template #testName="{ record }">
                        <p
                            v-for="(test, index) in record.tests"
                            :key="index">
                            {{ test.test_name }}
                        </p>
                    </template>
                    <template #patientName="{record}">
                        <PatientName :name="record.patient_name"/>
                    </template>
                    <template #action="{ record }">
                        <action-button-wrapper class="whitespace-wrap text-grey-500">
                            <action-button action-type="plus" tooltip-text="Create Requisition"
                                           @click="createRequisition(record)">
                            </action-button>
                            <action-button action-type="delete" tooltip-text="Delete"
                                           @click="openAppointmentDeleteModal('delete',record)">
                            </action-button>
                        </action-button-wrapper>
                    </template>
                    <template #appointmentStatus="{ record }">
                        <div class="status-mark-wrap flex items-center">
                                        <span class="status-mark block rounded-full w-1.5 h-1.5 bg-success-100"
                                              v-if="record.status==='confirmed'"/>
                            <span class="status-mark block rounded-full w-1.5 h-1.5 bg-success-100"
                                  v-if="record.status==='rescheduled'"/>
                            <span class="status-mark block rounded-full w-1.5 h-1.5 bg-warning-600"
                                  v-if="record.status==='pending'"/>
                            <span class="ml-1 status-text leading-none capitalize" v-text="record.status"/>
                        </div>
                    </template>
                </AntTable>
                <AntTable v-else
                          :columns="completedAppointmentListTableColumns"
                          :dataSource="appointmentCompletedListTableData"
                          :pagination="completedAppointments.pagination"
                          :loading="completedAppointments.loading"
                          :scroll="{ x: 1500, y: 700 }"
                          @change="handleCompletedAppointmentsChange"
                >
                    <template #filterSearch="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
                        <div class="grid gap-2 p-2">
                            <a-input
                                class="placeholder-grey-400"
                                ref="completedTableSearchInput"
                                :placeholder="`Enter ${column.dataIndex.split('_').join(' ')}`"
                                :value="selectedKeys[0]"
                                @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                                @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                            >

                            </a-input>
                            <div class="flex items-center justify-between gap-2">
                                <a-button
                                    class="btn-icon"
                                    type="primary"
                                    size="small"
                                    @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                                >
                                    <Icon class="w-3.5 h-3.5" name="search"/>
                                    Search
                                </a-button>
                                <a-button class="ant-reset" size="small" @click="handleReset(clearFilters)">
                                    Reset
                                </a-button>
                            </div>
                        </div>
                    </template>
                    <template #patientName="{record}">
                        <PatientName :name="record.patient_name"/>
                    </template>
                    <template #filterIcon="filtered">
                        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }"/>
                    </template>
                    <template #customRender="{ text, column }">
                            <span v-if="searchText && searchedColumn === column.dataIndex">
                                <template
                                    v-for="(fragment, i) in text
                                    .toString()
                                    .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
                                >
                                    <mark
                                        v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                                        class="highlight"
                                        :key="i"
                                    >
                                      {{ fragment }}
                                    </mark>
                                    <template v-else>{{ fragment }}</template>
                                </template>
                          </span>
                        <template v-else>
                            {{ text }}
                        </template>
                    </template>
                    <template #testName="{ record }">
                        <p
                            v-for="(test, index) in record.tests"
                            :key="index">
                            {{ test.test_name }}
                        </p>
                    </template>
                    <template #appointmentStatus="{ record }">
                        <div class="status-mark-wrap flex items-center">
                                        <span class="status-mark block rounded-full w-1.5 h-1.5 bg-success-100"
                                              v-if="record.status==='confirmed'"/>
                            <span class="status-mark block rounded-full w-1.5 h-1.5"
                                  v-if="record.status==='rescheduled'"/>
                            <span class="status-mark block rounded-full w-1.5 h-1.5 bg-warning-600"
                                  v-if="record.status==='pending'"/>
                            <span class="ml-1 status-text leading-none capitalize" v-text="record.status"/>
                        </div>

                    </template>
                </AntTable>
            </div>
        </div>
    </admin-layout>
    <modal class="modal--appointment" :show="showAppointmentDeleteModal" @close="closeAppointmentDeleteModal">
        <template v-slot:title>
            <h2 class="heading-primary heading-primary-md text-center">Are you sure want to delete this appointment
                ?</h2>
        </template>
        <div class="btn-wrap items-center justify-center px-16 pt-2 !gap-6">
            <secondary-button class="!border-grey-500 !text-grey-500  btn--outline !px-8 !py-2 !text-sm"
                              @click="closeAppointmentDeleteModal">
                Cancel
            </secondary-button>
            <primary-button  class="!py-2" @click="deleteSchedule(deleteAppointment.status,deleteAppointment.id)"
                            v-text="'Delete'"/>
        </div>
    </modal>
</template>

<script>
import {SearchOutlined} from "@ant-design/icons-vue"
import {Inertia} from "@inertiajs/inertia"
import {DatePicker} from "v-calendar"
import {default as AButton} from "ant-design-vue/lib/button"
import {default as AInput} from "ant-design-vue/lib/input"
import AntTable from "ant-design-vue/lib/table"
import FilterDropdown from "ant-design-vue/lib/table/filterDropdown"
import {
    computed,
    onMounted,
    reactive,
    ref,
    toRefs,
    watch,
} from "vue"
import PrimaryButton from "../../../components/Admin/Buttons/PrimaryButton"
import SecondaryButton from "../../../components/Admin/Buttons/SecondaryButton"
import CancelButton from "../../../components/Admin/Buttons/CancelButton"
import Icon from "../../../components/Admin/General/Icon"
import Pagination from "../../../components/Admin/General/Pagination"
import AdminLayout from "../../../components/Admin/Layout/AdminLayout"
import Modal from "../../../components/Admin/Modal"
import LabelComponent from "../../../components/Front/Forms/Label"
import InputComponent from "../../../components/Admin/Forms/Input"
import InputError from "../../../components/Front/Forms/InputError"
import {get as getFromObject} from "lodash"
import ActionButton from "../../../components/Admin/Buttons/ActionButton"
import ActionButtonWrapper from "../../../components/Admin/Buttons/ActionButtonWrapper"
import PatientName from "../../../components/Admin/General/PatientName"

const STATUS_ALL = "all"
const STATUS_PENDING = "pending"
const STATUS_CONFIRMED = "confirmed"
const STATUS_RESCHEDULE = "rescheduled"
const DELETE = "delete"
const TAB_PENDING_APPOINTMENTS = "pending_appointments"
const TAB_COMPLETED_APPOINTMENTS = "completed_appointments"

export default {
    name: "Index.vue",
    props: {
        appointmentStatus: {},
        errors: {},
    },
    components: {
        ActionButtonWrapper,
        ActionButton,
        PatientName,
        AntTable,
        AInput,
        AButton,
        SearchOutlined,
        FilterDropdown,
        DatePicker,
        Icon,
        SecondaryButton,
        Modal,
        CancelButton,
        PrimaryButton,
        AdminLayout,
        Pagination,
        LabelComponent,
        InputComponent,
        InputError,
    },
    setup(props) {
        const appState = reactive({
            currentTab: TAB_PENDING_APPOINTMENTS,
            id: null,
            status: null,
            appointment_date: null,
            searchText: "",
            searchedColumn: "",
            pendingAppointments: {
                pagination: {
                    current: 1,
                    total: 0,
                    pageSize: 20,
                    from: 1,
                },
                queries: {
                    patient_name: '',
                },
                loading: true,
            },
            completedAppointments: {
                pagination: {
                    current: 1,
                    total: 0,
                    pageSize: 20,
                    from: 1,
                },
                queries: {
                    patient_name: '',
                },
                loading: true,
            },
            showAppointmentDeleteModal: false,
            deleteAppointment: {
                id: null,
                status: null,
            },
            targetUrl: {
                appointmentIndex: route("appointment.index"),
                requisitionByReference: route("admin.requisitions.form.existing-booking"),
            },
            isActive: {
                yes: "!bg-tertiary-100 text-grey-100",
                no: "bg-pinkShade-100 text-grey-700",
            }
        })

        const searchInput = ref()
        const completedTableSearchInput = ref()

        const createRequisition = (record) => {
            Inertia.get(appState.targetUrl.requisitionByReference, {
                reference_id: getFromObject(record, "id"),
            })
        }

        function deleteSchedule(status, record) {
            appState.deleteAppointment.id = record.id
            appState.deleteAppointment.status = status

            const targetUrl = route("appointment.approval", {
                appointment: record.id,
                status: status,
            })
            Inertia.post(targetUrl, appState.deleteAppointment, {
                onSuccess: () => {
                    loadPendingAppointmentsData()
                }
            })
            appState.showAppointmentDeleteModal = false
        }

        const filteredInfo = ref()
        const sortedInfo = ref()

        const completedTableFilteredInfo = ref()
        const completedTableSortedInfo = ref()

        const appointmentListTableColumns = computed(() => {
            const filtered = filteredInfo.value || {}
            const sorted = sortedInfo.value || {}
            return [
                {
                    title: "S.No.",
                    dataIndex: "key",
                    key: "key",
                    fixed: "left",
                    width: "7em",
                },
                {
                    title: "Action",
                    slots: {
                        customRender: "action",
                    },
                    fixed: "left",
                    width: appState.currentTab === TAB_PENDING_APPOINTMENTS ? "10rem" : "0rem",
                },
                {
                    title: "Reference ID",
                    dataIndex: "id",
                    key: "id",
                    width: "8em",
                    sorter: (a, b) => {
                        return a.id - b.id
                    },
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: "Patient Name",
                    dataIndex: "patient_name",
                    key: "patient_name",
                    width: "10em",
                    sorter: (a, b) => a.patient_name.localeCompare(b.patient_name),
                    sortDirections: ["descend", "ascend"],
                    slots: {
                        filterDropdown: "filterSearch",
                        filterIcon: "filterIcon",
                        customRender: "patientName",
                    },
                    onFilter: (value, record) =>
                        record.patient_name.toString().toLowerCase().includes(value.toLowerCase()),
                    onFilterDropdownVisibleChange: visible => {
                        if (visible) {
                            setTimeout(() => {
                                searchInput.value.focus()
                            }, 0)
                        }
                    },
                },
                {
                    title: "DOB",
                    dataIndex: "dob",
                    key: "dob",
                    width: "9em",
                    sorter: (a, b) => a.dob_raw.localeCompare(b.dob_raw),
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: "Phone Number",
                    dataIndex: "phone_number",
                    key: "phone_number",
                    width: "12em",
                    sorter: (a, b) => a.phone_number.localeCompare(b.phone_number),
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: "Test Name",
                    slots: {
                        customRender: "testName",
                    },
                    width: "10rem",

                },
                {
                    title: "Created Date",
                    dataIndex: "created_at",
                    key: "created_at",
                    width: "15em",
                    defaultSortOrder: "descend",
                    sorter: (a, b) => a.created_at_raw.localeCompare(b.created_at_raw),
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: "Status",
                    key: "status",
                    width: "9em",
                    slots: {
                        customRender: "appointmentStatus",
                    },
                },
            ]
        })

        const completedAppointmentListTableColumns = computed(() => {
            const filtered = completedTableFilteredInfo.value || {}
            const sorted = completedTableSortedInfo.value || {}
            return [
                {
                    title: "S.No.",
                    width: "7em",
                    dataIndex: "key",
                    key: "key",
                    fixed: "left",
                },
                {
                    title: "Reference ID",
                    dataIndex: "id",
                    key: "id",
                    width: "9em",
                    sorter: (a, b) => {
                        return a.id - b.id
                    },
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: "Patient Name",
                    dataIndex: "patient_name",
                    key: "patient_name",
                    width: "10em",
                    sorter: (a, b) => a.patient_name.localeCompare(b.patient_name),
                    sortDirections: ["descend", "ascend"],
                    slots: {
                        filterDropdown: "filterSearch",
                        filterIcon: "filterIcon",
                        customRender: "patientName",
                    },
                    onFilter: (value, record) =>
                        record.patient_name.toString().toLowerCase().includes(value.toLowerCase()),
                    onFilterDropdownVisibleChange: visible => {
                        if (visible) {
                            setTimeout(() => {
                                completedTableSearchInput.value.focus()
                            }, 0)
                        }
                    },
                },
                {
                    title: "DOB",
                    dataIndex: "dob",
                    key: "dob",
                    width: "9em",
                    sorter: (a, b) => a.dob_raw.localeCompare(b.dob_raw),
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: "Phone Number",
                    dataIndex: "phone_number",
                    key: "phone_number",
                    width: "12em",
                    sorter: (a, b) => a.phone_number.localeCompare(b.phone_number),
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: "Test Name",
                    slots: {
                        customRender: "testName",
                    },
                    width: "10rem",

                },
                {
                    title: "Created Date",
                    dataIndex: "created_at",
                    key: "created_at",
                    width: "15em",
                    defaultSortOrder: "descend",
                    sorter: (a, b) => a.created_at_raw.localeCompare(b.created_at_raw),
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: "Status",
                    key: "status",
                    width: "9em",
                    // dataIndex: "status",
                    slots: {
                        customRender: "appointmentStatus",
                    },
                },

            ]
        })

        function showTab(tab) {
            appState.currentTab = tab
        }

        const loadOrder = (sorter) => {
            if (!sorter) {
                return ""
            }
            const order = getFromObject(sorter, "order", "ascend")
            const column = getFromObject(sorter, "field", "")
            return order === "ascend" ? `${column}` : `-${column}`
        }

        const handlePendingAppointmentsChange = (pager, filters, sorter) => {
            appState.pendingAppointments.pagination = {...pager}
            appState.pendingAppointments.queries.order = loadOrder(sorter)
            // console.log(filters)
            appState.pendingAppointments.queries.patient_name = getFromObject(filters, 'patient_name.0', '')

            loadPendingAppointmentsData()
        }

        const handleCompletedAppointmentsChange = (pager, filters, sorter) => {
            appState.completedAppointments.pagination = {...pager}
            appState.completedAppointments.queries.order = loadOrder(sorter)
            // console.log(filters)
            appState.completedAppointments.queries.patient_name = getFromObject(filters, 'patient_name.0', '')

            loadCompletedAppointmentsData()
        }

        const appointmentPendingListTableData = ref([])
        const appointmentCompletedListTableData = ref([])

        //
        // watch(() => props.completedAppointments, (newValue, oldValue) => {
        //     let tmpDataArr = Array.from(props.completedAppointments.data)
        //
        //     appointmentCompletedListTableData.value = []
        //
        //     tmpDataArr.forEach((item, index) => {
        //         appointmentCompletedListTableData.value.push({
        //             key: index + 1,
        //             id: item.id,
        //             patient_name: item.patient_name,
        //             dob: item.dob_formatted,
        //             dob_raw: item.dob_raw,
        //             phone_number: item.phone_number,
        //             created_at: item.created_at_formatted,
        //             created_at_raw: item.created_at_raw,
        //             appointment_date: item.appointment_date_formatted,
        //             appointment_date_raw: item.appointment_date_raw,
        //             tests: item.tests,
        //             status: item.status,
        //         })
        //     })
        // }, {
        //     deep: true,
        //     immediate: true,
        // })

        const handleSearch = (selectedKeys, confirm, dataIndex) => {
            confirm()
            appState.searchText = selectedKeys[0]
            appState.searchedColumn = dataIndex
        }

        const handleReset = clearFilters => {
            clearFilters()
            appState.searchText = ""
        }

        function closeAppointmentDeleteModal() {
            appState.showAppointmentDeleteModal = false
        }

        function openAppointmentDeleteModal(status, record) {
            appState.deleteAppointment.id = record
            appState.deleteAppointment.status = status
            appState.showAppointmentDeleteModal = true
        }

        const loadPendingAppointmentsListTableData = (dataArray) => {
            if (!dataArray || !Array.isArray(dataArray)) {
                return
            }

            appointmentPendingListTableData.value = []

            dataArray.forEach((item, index) => {
                appointmentPendingListTableData.value.push({
                    key: index + appState.pendingAppointments.pagination.from,
                    id: item.id,
                    patient_name: item.patient_name,
                    dob: item.dob_formatted,
                    dob_raw: item.dob_raw,
                    phone_number: item.phone_number,
                    created_at: item.created_at_formatted,
                    created_at_raw: item.created_at_raw,
                    appointment_date: item.appointment_date_formatted,
                    appointment_date_raw: item.appointment_date_raw,
                    tests: item.tests,
                    status: item.status,
                })
            })

        }

        const loadCompletedAppointmentsListTableData = (dataArray) => {
            if (!dataArray || !Array.isArray(dataArray)) {
                return
            }

            appointmentCompletedListTableData.value = []

            dataArray.forEach((item, index) => {
                appointmentCompletedListTableData.value.push({
                    key: index + appState.completedAppointments.pagination.from,
                    id: item.id,
                    patient_name: item.patient_name,
                    dob: item.dob_formatted,
                    dob_raw: item.dob_raw,
                    phone_number: item.phone_number,
                    created_at: item.created_at_formatted,
                    created_at_raw: item.created_at_raw,
                    appointment_date: item.appointment_date_formatted,
                    appointment_date_raw: item.appointment_date_raw,
                    tests: item.tests,
                    status: item.status,
                })
            })

        }

        const loadPendingAppointmentsData = () => {
            appState.pendingAppointments.loading = true
            axios.get("/api/appointments/pending", {
                params: {
                    page: appState.pendingAppointments.pagination.current,
                    ...appState.pendingAppointments.queries,
                },
            }).then(({data}) => {
                // console.log(data)
                appState.pendingAppointments.pagination.current = (getFromObject(data, "meta.current_page", 1))
                appState.pendingAppointments.pagination.total = (getFromObject(data, "meta.total", 0))
                appState.pendingAppointments.pagination.pageSize = (getFromObject(data, "meta.per_page", 20))
                appState.pendingAppointments.pagination.from = (getFromObject(data, "meta.from", 1))

                loadPendingAppointmentsListTableData(getFromObject(data, "data", []))

                appState.pendingAppointments.loading = false
            }).catch((error) => {
                appState.pendingAppointments.loading = false
                // console.error(error)
            })
        }

        const loadCompletedAppointmentsData = () => {
            appState.completedAppointments.loading = true
            axios.get("/api/appointments/completed", {
                params: {
                    page: appState.completedAppointments.pagination.current,
                    ...appState.completedAppointments.queries,
                },
            }).then(({data}) => {
                // console.log(data)
                appState.completedAppointments.pagination.current = (getFromObject(data, "meta.current_page", 1))
                appState.completedAppointments.pagination.total = (getFromObject(data, "meta.total", 0))
                appState.completedAppointments.pagination.pageSize = (getFromObject(data, "meta.per_page", 20))
                appState.completedAppointments.pagination.from = (getFromObject(data, "meta.from", 1))

                loadCompletedAppointmentsListTableData(getFromObject(data, "data", []))

                appState.completedAppointments.loading = false
            }).catch((error) => {
                appState.completedAppointments.loading = false
                // console.error(error)
            })
        }

        onMounted(() => {
            loadPendingAppointmentsData()
            loadCompletedAppointmentsData()
        })

        return {
            ...toRefs(appState),
            getFromObject,
            showTab,
            appointmentListTableColumns,
            completedAppointmentListTableColumns,
            appointmentPendingListTableData,
            appointmentCompletedListTableData,
            handleSearch,
            handleReset,
            searchText: "",
            searchInput,
            completedTableSearchInput,
            searchedColumn: "",
            STATUS_ALL,
            STATUS_CONFIRMED,
            STATUS_PENDING,
            STATUS_RESCHEDULE,
            TAB_PENDING_APPOINTMENTS,
            TAB_COMPLETED_APPOINTMENTS,
            handlePendingAppointmentsChange,
            handleCompletedAppointmentsChange,
            openAppointmentDeleteModal,
            closeAppointmentDeleteModal,
            deleteSchedule,
            createRequisition,
        }
    },
}
</script>

<style lang="scss">
.modal--appointment .modal-content {
    padding: 70px 40px;
    width: 45%;
}

.ant-calendar-picker-container {
    z-index: 999999 !important;
}
</style>

<template>
    <div class="pattern bg-pinkShade-100 text-grey-700">
        <WebHeader  :is-user="true"/>
        <div class="content-area p-6 sm:p-10">
            <slot/>
        </div>
        <Footer/>
    </div>
</template>

<script>
import WebHeader        from "../Layout/Header"
import SidebarComponent from "./WebSidebar"
import Footer           from "../General/Footer"

export default {
    name: "WebLayout",
    components: { WebHeader, SidebarComponent, Footer },
    mounted() {
        document.querySelector('body').classList.add('patient-dashboard');
    },
}
</script>


<template>
    <div>
        <Header/>
        <div class="registration registration-welcome relative flex">
            <div
                class="container--large w-full pattern  px-6 md:!px-0  flex items-center bg-white">
                <div class="w-full py-8 sm:px-8 md:px-26 lg:w-3/5 grid gap-8">
                    <div class="grid gap-4 sm:gap-6">
                        <div>
                            <h3 class="font-bold text-2xl !text-base-100">Crisper <span
                                class="text-tertiary-100">Diagnostics</span></h3>
                            <h2 class="heading-primary pt-2 sm:pt-0 !font-normal !text-grey-900">Online Registration for
                                covid-19 Test</h2>
                        </div>
                    </div>
                    <div class="btn-wrap">
                        <primary-button href="/patient-registration" class="!text-sm"
                                        v-text="'Book new Appointment'"/>
<!--                        To-Do : Temporary disable of links to login page.-->
<!--                        <secondary-button :inertia-link="false" href="/login" class="!px-12 !text-sm"-->
<!--                                          v-text="'Existing Patient'"/>-->
                    </div>
                </div>
                <div class="hidden lg:block lg:w-2.5/5 h-full">
                    <img class="img-thumbnail h-full w-full object-fit" src="/images/img-reg-page.jpg"
                         alt="Crisper Diagnostic">
                </div>
            </div>
        </div>
        <Footer class="sm:absolute bottom-0 right-0 w-full"/>
    </div>
</template>

<script>
import PrimaryButton from "../../components/Front/Buttons/PrimaryButton"
import SecondaryButton from "../../components/Front/Buttons/SecondaryButton"
import Header from "../../components/Front/Layout/Header"
import Footer from "../../components/Front/General/Footer"

export default {
    name: "Index.vue",
    components: {SecondaryButton, PrimaryButton, Header, Footer},
}
</script>

<template>
    <modal :show="showModal" @close="closeModal">
        <template #title>
            <h2 class="heading-primary heading-primary-md">Upload Result</h2>
        </template>
        <template #default>
            <div v-if="isPCR(testName)"  class="w-full sm:w-1/2">
                <h1 class="header-primary heading-primary-sm !text-grey-1000" v-text="'Test Type : SARS-CoV-2 RT-PCR'"/>
                <label-component value="Result"/>
                <select-input
                    :options="resultOptions"
                    :name="`test-${id}`"
                    v-model="resultValue['sars-cov-2-rt-pcr']"
                    :allow-empty="false"
                />
            </div>
            <div v-else class="grid sm:grid-cols-2 gap-8 pt-3">
                <div>
                    <h1 class="header-primary !text-base !text-grey-1000 !pb-3"
                        v-text="'Test Type : IgG Antibody'"/>
                    <label-component value="Result"/>
                    <select-input
                        :options="resultOptions"
                        :name="`test-${id}`"
                        v-model="resultValue['igg-antibody']"
                        :allow-empty="false"
                    />
                </div>
                <div>
                    <h1 class="header-primary !text-base !text-grey-1000 !pb-3"
                        v-text="'Test Type : IgM Antibody'"/>
                    <label-component value="Result"/>
                    <select-input
                        :options="resultOptions"
                        :name="`test-${id}`"
                        v-model="resultValue['igm-antibody']"
                        :allow-empty="false"
                    />
                </div>
            </div>
            <div class="pt-10 flex items-center gap-4 justify-end">
                <secondary-button @click="closeModal">
                    Cancel
                </secondary-button>
                <primary-button class="!px-8" @click="saveResult">
                    Save
                </primary-button>
            </div>
        </template>
    </modal>

</template>

<script>
    import PrimaryButton   from "../../../../components/Admin/Buttons/PrimaryButton"
    import SecondaryButton from "../../../../components/Admin/Buttons/SecondaryButton"
    import LabelComponent  from "../../../../components/Admin/Forms/Label"
    import SelectInput     from "../../../../components/Admin/Forms/SelectInput"
    import Modal     from "../../../../components/Admin/Modal"
    import { isPCR } from "../../../../helpers"
    export default {
        name: "UpdateResultModal",
        components: { PrimaryButton, SecondaryButton, SelectInput, LabelComponent, Modal },
        props: {
            showModal: {
                type: Boolean,
                required: false,
                default: false,
            },
            id: {
                type: [Number, String],
                required: true,
            },
            testName: {
                type: String,
                required: true
            },
            resultValue: {
                type: Object,
                required: true,
            },
            resultOptions: {
                type: Map,
                required: true,
            }
        },
        emits: ['close', 'save'],
        setup(props, {emit}) {
            function closeModal() {
                emit('close')
            }

            function saveResult() {
                emit('save', props.resultValue)
            }

            return {
                isPCR,
                closeModal,
                saveResult
            }
        }
    }
</script>

<style scoped>

</style>

<template>
    <admin-layout :if-has-breadcrumb="true" :active-link="'requisitions'">
        <template #breadcrumb>
            <bread-crumb>
                <bread-crumb-item :href="requisitionIndexUrl" label="Requisitions"/>
                <bread-crumb-item label="Requisition by Reference ID"/>
            </bread-crumb>
        </template>
        <h1 class="pb-8 font-bold mt-0 text-grey-1100 leading-none" style="font-size: 32px;"
            v-text="'Existing Reference Requisition'"/>
        <!-- TODO: Extract Component -->
<!--        <div class="search-req mb-6 w-full !px-0 !py-2.5 card&#45;&#45;large flex relative !rounded">-->
<!--            <div class="w-full pr-6">-->
<!--                <input-component v-model="searchText" placeholder="Search by Reference ID"-->
<!--                                 class="!border-0 !px-4 !placeholder-grey-600" type="search"-->
<!--                                 @focusout="showSearchResults=false" @keydown.enter="searchBooking"/>-->
<!--                <button class="absolute top-0 right-0 h-full pl-4 pr-5 py-3 text-tertiary-100" type="button"-->
<!--                        @click="searchBooking">-->
<!--                    <Icon class="!w-6 !h-6" name="search"/>-->
<!--                </button>-->
<!--            </div>-->
<!--            <div v-if="showSearchResults"-->
<!--                 class="hidden search-result custom-scrollbar absolute w-full top-18 left-0  bg-white dark:bg-gray-800 !rounded shadow card&#45;&#45;large !p-4">-->
<!--                <h5 class="font-bold pb-3 text-grey-900">Search Result (<span v-text="searchValues.length"/>)</h5>-->
<!--                <ul v-if="searchValues.length > 0" class="search-result-list divide divide-y pr-6 ">-->
<!--                    <li v-for="(value, index) in searchValues || []" :key="value.id"-->
<!--                        class="flex flex-row border-pinkShade-200">-->
<!--                        <div class="select-none cursor-pointer flex flex-1 items-center py-4"-->
<!--                             @click="setPatient(value)">-->
<!--                            <div class="text-grey-700 text-sm flex gap-4">-->
<!--                                <span>-->
<!--                                    {{ getFromObject(value, "basic_information.name") }}-->
<!--                                </span>-->
<!--                                <span class="self-end">-->
<!--                                    &lt;!&ndash;                                    {{ value.booking_id }}&ndash;&gt;-->
<!--                                    #{{ value.id }}-->
<!--                                </span>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </li>-->
<!--                </ul>-->
<!--                <p v-else>Not found</p>-->
<!--            </div>-->
<!--        </div>-->
        <div>
            <div v-if="hasBooking" class="card--large">
                <form @submit.prevent="submitForm">
                    <div class="grid gap-10">
                        <basic-information-new v-model="form.basic_information" :errors="form.errors"
                                               :show-buttons="false"/>
                        <test-booking-new v-model="form.test_information" :errors="form.errors"
                                          :show-appointment-date="true"
                                          :show-buttons="false"/>
                        <doctor-information v-model="form.doctor_information"/>
                        <payment-method-new :show-file-input="true" v-model="form.payment_method" :errors="form.errors"/>
                        <div>
                            <card-header title="Sample"/>
                            <requisition-sample v-for="(sample, sampleIndex) in form.samples"
                                                :key="sampleIndex"
                                                :index="sampleIndex"
                                                :sample="sample"
                                                :name="'samples.' + sampleIndex"
                                                :sample-count="form.samples.length"
                                                :errors="form.errors"
                                                @input="form.samples[sampleIndex] = $event"
                                                @add="addSample"
                                                @remove="removeSample(sampleIndex)"
                            />
                        </div>
                    </div>
                    <div class="pt-8 flex items-center justify-between">
                            <span class="text-xs">
                <span class="text-tertiary-100" v-text="'*'"/>
                All fields are required unless specified optional
            </span>
                        <div class="flex items-center gap-6">
                            <cancel-button :inertia-link="true" :href="requisitionIndexUrl">Cancel</cancel-button>
                            <primary-button @click="submitForm" class="btn" :disabled="form.processing">
                                <span v-if="form.processing">Creating Requisition</span>
                                <span v-else>Create Requisition</span>
                            </primary-button>
                        </div>
                    </div>
                </form>
            </div>
            <div v-else-if="hasBookingIdEmpty">
                <p>The reference ID doesn't exist</p>
            </div>
            <EmptySearch v-else/>
        </div>
    </admin-layout>
</template>

<script>
import {useForm} from "@inertiajs/inertia-vue3"
import {useVuelidate} from "@vuelidate/core"
import {get as getFromObject} from "lodash"
import {
    provide,
    reactive,
    ref,
    toRefs,
    watch,
} from "vue"
import BreadCrumb from "../../../components/Admin/BreadCrumb/BreadCrumb"
import BreadCrumbItem from "../../../components/Admin/BreadCrumb/BreadCrumbItem"
import CancelButton from "../../../components/Admin/Buttons/CancelButton"
import PrimaryButton from "../../../components/Admin/Buttons/PrimaryButton"
import InputComponent from "../../../components/Admin/Forms/Input"
import CardHeader from "../../../components/Admin/General/CardHeader"
import Icon from "../../../components/Admin/General/Icon"
import AdminLayout from "../../../components/Admin/Layout/AdminLayout"
import BasicInformationNew from "./Partials/BasicInformationNew"
import EmptySearch from "./Partials/EmptySearch"
import PaymentMethodNew from "./Partials/PaymentMethodNew"
import RequisitionSample from "./Partials/RequisitionSample"
import TestBookingNew from "./Partials/TestBookingNew"
import DoctorInformation from "./Partials/DoctorInformation";
import scrollToSmoothly from "../../../script";

export default {
    name: "ExistingBookingRequisition",
    components: {
        DoctorInformation,
        CardHeader,
        EmptySearch,
        Icon,
        InputComponent,
        RequisitionSample,
        CancelButton,
        BreadCrumbItem,
        BreadCrumb,
        PaymentMethodNew,
        TestBookingNew,
        BasicInformationNew,
        AdminLayout,
        PrimaryButton,
    },
    props: {
        raceList: {type: Object, required: true},
        genderList: {type: Object, required: true},
        ethnicityList: {type: Object, required: true},
        stateList: {type: Object, required: true},
        testList: {type: Object, required: true},
        pregnancyList: {type: Object, required: true},
        paymentMethodList: {type: Object, required: true},
        paymentStatusList: {type: Object, required: true},
        sampleTypeList: {type: Object, required: true},
        bookings: {type: Object, required: false, default: () => ({})},
        clients: {type: Object, required: false, default: () => ({})},
        referenceId: {type: String, required: false, default: ""}
    },
    setup(props) {
        const hasBooking = ref(false)
        const currentId = ref(0)
        const bookingDetails = ref({})
        const requisitionIndexUrl = route("admin.requisitions.index")
        // const saveExistingBookingUrl = route("admin.requisitions.form.existing-booking.store", currentId)

        const appState = reactive({
            showSearchResults: false,
            searchResults: [],
            searchText: "",
            hasBookingIdEmpty: false,
        })

        const form = useForm("ExistingBookingRequisitionForm", {
            id: null,
            patient_id: null,
            basic_information: {
                email: null,
                first_name: null,
                last_name: null,
                dob: null,
                gender: null,
                phone_number: null,
                race: null,
                ethnicity: null,
                street_address_one: null,
                street_address_two: null,
                city: null,
                state: null,
                zip_code: null,
            },
            test_id: null,
            test_information: {
                name: [],
                appointment_date: null,
                is_first_test: null,
                is_health_care_worker: null,
                has_symptom: null,
                symptom_date: null,
                is_hospitalized: null,
                is_in_icu: null,
                is_in_congregate_care: null,
                is_pregnant: null,
            },
            doctor_information: {
                client_id: null,
                facility_name: null,
                first_name: null,
                last_name: null,
                doctor_street_address_one: null,
                doctor_street_address_two: null,
                city: null,
                state: null,
                zip_code: null,
                phone_number: null,
            },
            payment_method: {
                payment_type: null,
                driver_license: null,
                payment_status: null,
                insurance_name: null,
                insurance_holder_name: null,
                insurance_id: null,
                insurance_group: null,
                insurance_document: null,
                insurance_document_existing: null,
            },
            samples: [
                {
                    sample_date: null,
                    sample_time: null,
                    type: null,
                    collected_by: null,
                },
            ],
        }, {
            bag: "ExistingBookingRequisitionForm",
            resetOnSuccess: true,
        })

        function addSample() {
            form.samples.push({})
        }

        function removeSample(index) {
            form.samples.splice(index, 1)
        }

        function resetForm() {
            form.reset()
        }

        const bookingsList = getFromObject(props, "bookings.data", [])

        const genderOptions = new Map()
        const raceOptions = new Map()
        const ethnicityOptions = new Map()
        const stateOptions = new Map()
        const testOptions = new Map()
        const pregnancyOptions = new Map()
        const paymentMethodOptions = new Map()
        const paymentStatusOptions = new Map()
        const yesNoOptions = new Map()
        const sampleTypeOptions = new Map()

        Object.keys(props.genderList).forEach(key => genderOptions.set(key, props.genderList[key]))
        Object.keys(props.raceList).forEach(key => raceOptions.set(key, props.raceList[key]))
        Object.keys(props.ethnicityList).forEach(key => ethnicityOptions.set(key, props.ethnicityList[key]))
        Object.keys(props.stateList).forEach(key => stateOptions.set(key, props.stateList[key]))
        Object.keys(props.pregnancyList).forEach(key => pregnancyOptions.set(key, props.pregnancyList[key]))
        Object.keys(props.testList).forEach(key => testOptions.set(key, props.testList[key]))
        Object.keys(props.paymentMethodList).forEach(key => paymentMethodOptions.set(key, props.paymentMethodList[key]))
        Object.keys(props.paymentStatusList).forEach(key => paymentStatusOptions.set(key, props.paymentStatusList[key]))
        Object.keys(props.sampleTypeList).forEach(key => sampleTypeOptions.set(key, props.sampleTypeList[key]))

        yesNoOptions.set(true, "Yes")
        yesNoOptions.set(false, "No")

        provide("raceOptions", raceOptions)
        provide("genderOptions", genderOptions)
        provide("ethnicityOptions", ethnicityOptions)
        provide("stateOptions", stateOptions)
        provide("pregnancyOptions", pregnancyOptions)
        provide("testOptions", testOptions)
        provide("paymentMethodOptions", paymentMethodOptions)
        provide("paymentStatusOptions", paymentStatusOptions)
        provide("yesNoOptions", yesNoOptions)
        provide("sampleTypeOptions", sampleTypeOptions)
        provide("form", form)
        provide("clients", props.clients)

        function fireSearch(e) {
            axios.get(route("admin.requisitions.existing-booking.filter"), {
                params: {
                    booking_id: e.target.value,
                },
            }).then(response => {
                hasBooking.value = true
                bookingDetails.value = getFromObject(response, "data.data")
                console.log(bookingDetails)
            }).catch(error => {
                console.log(error)
                hasBooking.value = false

            })
        }

        watch(() => bookingDetails.value, (newValue, oldValue) => {
                form.id = newValue.id
                form.patient_id = getFromObject(newValue, "patient_id")
                form.basic_information.email = getFromObject(newValue, "basic_information.email")
                form.basic_information.first_name = getFromObject(newValue, "basic_information.first_name")
                form.basic_information.last_name = getFromObject(newValue, "basic_information.last_name")
                form.basic_information.dob = getFromObject(newValue, "basic_information.dob_formatted")
                form.basic_information.gender = getFromObject(newValue, "basic_information.gender")
                form.basic_information.phone_number = getFromObject(newValue, "basic_information.phone_number")
                form.basic_information.race = getFromObject(newValue, "basic_information.race")
                form.basic_information.ethnicity = getFromObject(newValue, "basic_information.ethnicity")
                form.basic_information.street_address_one = getFromObject(newValue, "basic_information.street_address_one")
                form.basic_information.street_address_two = getFromObject(newValue, "basic_information.street_address_two")
                form.basic_information.city = getFromObject(newValue, "basic_information.city")
                form.basic_information.state = getFromObject(newValue, "basic_information.state")
                form.basic_information.zip_code = getFromObject(newValue, "basic_information.zip_code")

                form.payment_method.payment_type = getFromObject(newValue, "payment_method.payment_type")
                form.payment_method.driver_license = getFromObject(newValue, "payment_method.driver_license")
                form.payment_method.payment_status = getFromObject(newValue, "payment_method.payment_status")
                form.payment_method.insurance_name = getFromObject(newValue, "payment_method.insurance_name")
                form.payment_method.insurance_holder_name = getFromObject(newValue, "payment_method.insurance_holder_name")
                form.payment_method.insurance_id = getFromObject(newValue, "payment_method.insurance_id")
                form.payment_method.insurance_group = getFromObject(newValue, "payment_method.insurance_group")
                form.payment_method.insurance_document = getFromObject(newValue, "payment_method.insurance_document")
                form.payment_method.insurance_document_existing = getFromObject(newValue, "payment_method.insurance_document_existing")

                // form.test_information.appointment_date = getFromObject(newValue, "test_information.appointment_date")
                form.test_id = getFromObject(newValue, "test_information.id")
                form.test_information.name = getFromObject(newValue, "test_information.name", [])
                form.test_information.is_first_test = getFromObject(newValue, "test_information.is_first_test")
                form.test_information.is_health_care_worker = getFromObject(newValue, "test_information.is_health_care_worker")
                form.test_information.has_symptom = getFromObject(newValue, "test_information.has_symptom")
                form.test_information.symptom_date = getFromObject(newValue, "test_information.symptom_date")
                form.test_information.is_hospitalized = getFromObject(newValue, "test_information.is_hospitalized")
                form.test_information.is_in_icu = getFromObject(newValue, "test_information.is_in_icu")
                form.test_information.is_in_congregate_care = getFromObject(newValue, "test_information.is_in_congregate_care")
                form.test_information.is_pregnant = getFromObject(newValue, "test_information.is_pregnant")
            },
        )

        const v = useVuelidate()

        return {
            v, ...toRefs(appState),
            fireSearch,
            getFromObject,
            bookingsList,
            currentId,
            resetForm,
            hasBooking,
            form,
            requisitionIndexUrl,
            addSample,
            removeSample,
        }
    },
    methods: {
        submitForm() {
            this.v.$touch()

            this.$nextTick(() => {
                const inputError = document.querySelectorAll('.input-error')[0]
                if (inputError) {
                    scrollToSmoothly(inputError.offsetTop - 84);
                }
            })

            if (this.v.$error) return

            this.form.post(route("admin.requisitions.form.existing-booking.store", this.form.id))
        },

        // fireLocalSearch: function(value) {
        //     this.showSearchResults = true
        //     if (this.searchValues.length === 1) {
        //         this.resetForm()
        //         this.setBooking(this.searchValues[0])
        //     }
        // },
        searchBooking() {
            if (!this.searchText) {
                this.hasBookingIdEmpty = false
                this.hasBooking = false
                return
            }
            this.hasBookingIdEmpty = false
            const search = Object.values(this.bookingsList).find(item => {
                if (!Number.isInteger(item.id)) {
                    return false
                }
                return item.id.toString() === this.searchText.toLowerCase()
            })
            if (search) {
                this.hasBookingId = true
                this.resetForm()
                this.setBooking(search)
                this.showSearchResults = false
            } else {
                this.hasBooking = false
                this.hasBookingIdEmpty = true
            }
        },
        //Todo: refactor here
        setBooking(booking) {
            let clientFacility = getFromObject(booking, 'doctor_information.ordering_facility')
            let clientDetails = getFromObject(this.clients, 'data', []);
            let clientData = clientDetails.find(item => {
                return item.facility_name.toLowerCase().includes(clientFacility)
            })
            this.form.patient_id = getFromObject(booking, "patient_id")
            this.form.id = getFromObject(booking, "id")
            this.form.basic_information.email = getFromObject(booking, "basic_information.email")
            this.form.basic_information.first_name = getFromObject(booking, "basic_information.first_name")
            this.form.basic_information.last_name = getFromObject(booking, "basic_information.last_name")
            this.form.basic_information.dob = getFromObject(booking, "basic_information.dob_formatted")
            this.form.basic_information.gender = getFromObject(booking, "basic_information.gender")
            this.form.basic_information.phone_number = getFromObject(booking, "basic_information.phone_number")
            this.form.basic_information.race = getFromObject(booking, "basic_information.race")
            this.form.basic_information.ethnicity = getFromObject(booking, "basic_information.ethnicity")
            this.form.basic_information.street_address_one = getFromObject(booking, "basic_information.street_address_one")
            this.form.basic_information.street_address_two = getFromObject(booking, "basic_information.street_address_two")
            this.form.basic_information.city = getFromObject(booking, "basic_information.city")
            this.form.basic_information.state = getFromObject(booking, "basic_information.state")
            this.form.basic_information.zip_code = getFromObject(booking, "basic_information.zip_code")

            this.form.payment_method.payment_type = getFromObject(booking, "payment_method.payment_type")
            this.form.payment_method.driver_license = getFromObject(booking, "payment_method.driver_license")
            this.form.payment_method.payment_status = getFromObject(booking, "payment_method.payment_status")
            this.form.payment_method.insurance_name = getFromObject(booking, "payment_method.insurance_name")
            this.form.payment_method.insurance_holder_name = getFromObject(booking, "payment_method.insurance_holder_name")
            this.form.payment_method.insurance_id = getFromObject(booking, "payment_method.insurance_id")
            this.form.payment_method.insurance_group = getFromObject(booking, "payment_method.insurance_group")
            this.form.payment_method.insurance_document = getFromObject(booking, "payment_method.insurance_document")
            this.form.payment_method.insurance_document_existing = getFromObject(booking, "payment_method.insurance_document_existing")
            // this.form.test_information.appointment_date = getFromObject(booking, "test_information.appointment_date")
            this.form.test_id = getFromObject(booking, "test_information.id")
            this.form.test_information.name = getFromObject(booking, "test_information.name", [])
            this.form.test_information.is_first_test = getFromObject(booking, "test_information.is_first_test")
            this.form.test_information.is_health_care_worker = getFromObject(booking, "test_information.is_health_care_worker")
            this.form.test_information.has_symptom = getFromObject(booking, "test_information.has_symptom")
            this.form.test_information.symptom_date = getFromObject(booking, "test_information.symptom_date")
            this.form.test_information.is_hospitalized = getFromObject(booking, "test_information.is_hospitalized")
            this.form.test_information.is_in_icu = getFromObject(booking, "test_information.is_in_icu")
            this.form.test_information.is_in_congregate_care = getFromObject(booking, "test_information.is_in_congregate_care")
            this.form.test_information.is_pregnant = getFromObject(booking, "test_information.is_pregnant")

            this.form.doctor_information.client_id = getFromObject(clientData, 'id')
            this.form.doctor_information.facility_name = getFromObject(clientData, 'facility_name')
            this.form.doctor_information.first_name = getFromObject(clientData, 'first_name')
            this.form.doctor_information.last_name = getFromObject(clientData, 'last_name')
            this.form.doctor_information.doctor_street_address_one = getFromObject(clientData, 'street_address_one')
            this.form.doctor_information.doctor_street_address_two = getFromObject(clientData, 'street_address_two')
            this.form.doctor_information.city = getFromObject(clientData, 'city')
            this.form.doctor_information.state = getFromObject(clientData, 'state')
            this.form.doctor_information.zip_code = getFromObject(clientData, 'zip_code')
            this.form.doctor_information.phone_number = getFromObject(clientData, 'phone_number')

            this.hasBooking = true
        },
    },
    watch: {
        referenceId: {
            handler: function (value) {
                if (!value) {
                    return
                }
                this.searchText = value
                this.searchBooking()
            },
            immediate: true,
        }
    },
    computed: {
        searchValues: function () {
            return Object.values(this.bookingsList).filter(item => {
                if (!Number.isInteger(item.id)) {
                    return false
                }
                return item.id.toString().includes(this.searchText.toLowerCase())
            })
        },
    },
}
</script>

<style scoped>

</style>



<template>
  <div class="pt-8 pb-20 flex">
    <div class="p-6 m-auto w-full text-center sm:w-4/5">
      <div>
        <svg
          class="mx-auto w-48 sm:w-56 h-24 sm:h-28"
          viewBox="0 0 229 111"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M140.359 46.5H135.328V44.6406C135.328 43.7346 134.594 43 133.688 43C132.781 43 132.047 43.7346 132.047 44.6406V46.5H125.484V44.6406C125.484 43.7346 124.75 43 123.844 43C122.938 43 122.203 43.7346 122.203 44.6406V46.5H115.641V44.6406C115.641 43.7346 114.906 43 114 43C113.094 43 112.359 43.7346 112.359 44.6406V46.5H105.797V44.6406C105.797 43.7346 105.062 43 104.156 43C103.25 43 102.516 43.7346 102.516 44.6406V46.5H95.9531V44.6406C95.9531 43.7346 95.2186 43 94.3125 43C93.4064 43 92.6719 43.7346 92.6719 44.6406V46.5H87.6406C86.7346 46.5 86 47.2346 86 48.1406V97.3594C86 98.2654 86.7346 99 87.6406 99H140.359C141.265 99 142 98.2654 142 97.3594V48.1406C142 47.2346 141.265 46.5 140.359 46.5ZM92.6719 49.7812V51.4219C92.6719 52.3279 93.4064 53.0625 94.3125 53.0625C95.2186 53.0625 95.9531 52.3279 95.9531 51.4219V49.7812H102.516V51.4219C102.516 52.3279 103.25 53.0625 104.156 53.0625C105.062 53.0625 105.797 52.3279 105.797 51.4219V49.7812H112.359V51.4219C112.359 52.3279 113.094 53.0625 114 53.0625C114.906 53.0625 115.641 52.3279 115.641 51.4219V49.7812H122.203V51.4219C122.203 52.3279 122.938 53.0625 123.844 53.0625C124.75 53.0625 125.484 52.3279 125.484 51.4219V49.7812H132.047V51.4219C132.047 52.3279 132.781 53.0625 133.688 53.0625C134.594 53.0625 135.328 52.3279 135.328 51.4219V49.7812H138.719V56.3438H89.2812V49.7812H92.6719ZM89.2812 95.7188V59.625H138.719V95.7188H89.2812Z"
            fill="#5E4AE3"
          />
          <path
            d="M104.156 66.1875H97.5938C96.6877 66.1875 95.9531 66.9221 95.9531 67.8281V74.3906C95.9531 75.2967 96.6877 76.0313 97.5938 76.0313H104.156C105.062 76.0313 105.797 75.2967 105.797 74.3906V67.8281C105.797 66.9221 105.062 66.1875 104.156 66.1875ZM102.516 72.75H99.2344V69.4688H102.516V72.75Z"
            fill="#DFDBF9"
          />
          <path
            d="M130.406 66.1875H123.844C122.938 66.1875 122.203 66.9221 122.203 67.8281V74.3906C122.203 75.2967 122.938 76.0313 123.844 76.0313H130.406C131.312 76.0313 132.047 75.2967 132.047 74.3906V67.8281C132.047 66.9221 131.312 66.1875 130.406 66.1875ZM128.766 72.75H125.484V69.4688H128.766V72.75Z"
            fill="#DFDBF9"
          />
          <path
            d="M117.281 79.2031H110.719C109.813 79.2031 109.078 79.9377 109.078 80.8438V87.4062C109.078 88.3123 109.813 89.0469 110.719 89.0469H117.281C118.187 89.0469 118.922 88.3123 118.922 87.4062V80.8438C118.922 79.9377 118.187 79.2031 117.281 79.2031ZM115.641 85.7656H112.359V82.4844H115.641V85.7656Z"
            fill="#DFDBF9"
          />
          <path
            d="M117.281 66.1875H110.719C109.813 66.1875 109.078 66.9221 109.078 67.8281V74.3906C109.078 75.2967 109.813 76.0313 110.719 76.0313H117.281C118.187 76.0313 118.922 75.2967 118.922 74.3906V67.8281C118.922 66.9221 118.187 66.1875 117.281 66.1875ZM115.641 72.75H112.359V69.4688H115.641V72.75Z"
            fill="#DFDBF9"
          />
          <path
            d="M130.406 79.2031H123.844C122.938 79.2031 122.203 79.9377 122.203 80.8438V87.4062C122.203 88.3123 122.938 89.0469 123.844 89.0469H130.406C131.312 89.0469 132.047 88.3123 132.047 87.4062V80.8438C132.047 79.9377 131.312 79.2031 130.406 79.2031ZM128.766 85.7656H125.484V82.4844H128.766V85.7656Z"
            fill="#DFDBF9"
          />
          <path
            d="M105.191 80.752C104.488 80.1804 103.455 80.2871 102.883 80.99L99.5476 85.093L98.6341 84.3438C97.9334 83.7691 96.8997 83.8716 96.3252 84.5721C95.7506 85.2728 95.8529 86.3065 96.5535 86.881L98.741 88.6748C99.4452 89.2523 100.483 89.1445 101.054 88.4411L105.429 83.0599C106.001 82.3568 105.894 81.3236 105.191 80.752Z"
            fill="#5E4AE3"
          />
          <g opacity="0.2">
            <path
              d="M195.222 18.3112C195.662 17.4949 195.704 16.5278 195.341 15.5879C195.128 15.0357 194.486 14.6904 193.908 14.8165C193.329 14.9427 193.033 15.4927 193.246 16.0449C193.57 16.8823 193.119 17.7192 192.242 17.9105C191.258 18.1253 190.474 18.6931 190.035 19.5096C189.595 20.3259 189.553 21.293 189.916 22.2329C190.023 22.509 190.237 22.7334 190.496 22.8729C190.755 23.0125 191.06 23.0673 191.349 23.0042C191.928 22.878 192.224 22.328 192.01 21.7758C191.687 20.9384 192.138 20.1016 193.015 19.9102C193.999 19.6955 194.783 19.1276 195.222 18.3112Z"
              fill="#382C88"
            />
            <path
              d="M145 0C143.346 0 142 1.34576 142 3C142 4.65424 143.346 6.00009 145 6.00009C146.654 6.00009 148 4.65424 148 3C148 1.34576 146.654 0 145 0ZM145 4.10985C144.388 4.10985 143.89 3.61196 143.89 3C143.89 2.38803 144.388 1.89024 145 1.89024C145.612 1.89024 146.11 2.38813 146.11 3C146.11 3.61196 145.612 4.10985 145 4.10985Z"
              fill="#382C88"
            />
            <path
              d="M50.0369 16C48.1071 16 46.537 17.5701 46.537 19.5C46.537 20.3678 45.8309 21.0738 44.9631 21.0738C44.4313 21.0738 44 21.505 44 22.0369C44 22.5688 44.4313 23 44.9631 23C46.893 23 48.4631 21.4299 48.4631 19.5C48.4631 18.6322 49.1691 17.9262 50.0369 17.9262C50.5687 17.9262 51 17.495 51 16.9631C51 16.4312 50.5688 16 50.0369 16Z"
              fill="#382C88"
            />
            <path
              d="M103.317 12.5937C101.563 12.5937 101.56 15.4063 103.317 15.4063C105.071 15.4063 105.074 12.5937 103.317 12.5937Z"
              fill="#382C88"
            />
            <path
              d="M107.558 10C105.804 10 105.801 12.8127 107.558 12.8127C109.312 12.8127 109.315 10 107.558 10Z"
              fill="#382C88"
            />
            <path
              d="M107.683 15.1873C105.929 15.1873 105.926 18 107.683 18C109.437 17.9999 109.44 15.1873 107.683 15.1873Z"
              fill="#382C88"
            />
            <path
              d="M226.309 75.0246H221.127L219.538 69.9308C219.172 68.7576 218.17 68 216.984 68C216.983 68 216.981 68 216.98 68C215.793 68.0016 214.79 68.762 214.427 69.9373L212.853 75.0246H207.691C206.504 75.0246 205.501 75.7834 205.136 76.9578C204.771 78.1321 205.157 79.3577 206.119 80.0801L210.307 83.2237L208.706 88.333C208.338 89.506 208.721 90.7322 209.681 91.4571C210.161 91.8191 210.71 92 211.26 92C211.81 92 212.361 91.8181 212.841 91.4545L217.02 88.289L221.178 91.4307C222.138 92.1564 223.379 92.1556 224.338 91.4288C225.298 90.702 225.679 89.4747 225.309 88.3021L223.707 83.2263L227.885 80.0766C228.845 79.3529 229.229 78.1276 228.863 76.955C228.498 75.7823 227.495 75.0246 226.309 75.0246ZM226.709 78.3897L221.938 81.9856C221.586 82.2513 221.439 82.7235 221.574 83.1538L223.404 88.9518C223.543 89.3936 223.278 89.6536 223.158 89.7447C223.038 89.8359 222.718 90.0187 222.356 89.7453L217.608 86.1578C217.257 85.8921 216.781 85.8923 216.429 86.1584L211.661 89.7704C211.299 90.0441 210.98 89.8621 210.859 89.771C210.739 89.6799 210.473 89.4206 210.612 88.9785L212.44 83.1466C212.575 82.7161 212.427 82.2442 212.074 81.9791L207.292 78.3905C206.93 78.1182 206.997 77.7459 207.043 77.5985C207.089 77.4511 207.244 77.1081 207.691 77.1081H213.584C214.019 77.1081 214.405 76.816 214.538 76.3851L216.335 70.5749C216.472 70.132 216.834 70.0838 216.983 70.0836H216.984C217.133 70.0836 217.494 70.1316 217.632 70.5733L219.445 76.3876C219.579 76.8173 219.964 77.1082 220.398 77.1082H226.309C226.756 77.1082 226.911 77.4507 226.957 77.5979C227.003 77.7452 227.071 78.1171 226.709 78.3897Z"
              fill="#382C88"
            />
            <path
              d="M27 111C29.2056 111 31 109.206 31 107C31 104.794 29.2056 103 27 103C24.7944 103 23 104.794 23 107C23 109.206 24.7944 111 27 111ZM27 105.105C28.0447 105.105 28.8947 105.955 28.8947 107C28.8947 108.045 28.0447 108.895 27 108.895C25.9553 108.895 25.1053 108.045 25.1053 107C25.1053 105.955 25.9554 105.105 27 105.105Z"
              fill="#382C88"
            />
            <path
              d="M61.4853 76.4136C59.7197 77.4394 57.683 78.2765 55.5731 78.0082C55.0262 77.9386 54.4825 77.7815 53.9837 77.5455C54.2781 77.3706 54.5636 77.1787 54.8375 76.9694C56.7023 75.5443 58.6592 72.1624 55.9574 70.515C53.6032 69.0797 50.6138 70.8621 49.895 73.1854C49.5099 74.4302 49.7354 75.6942 50.3446 76.7819C49.654 76.8676 48.9508 76.8452 48.2735 76.7001C46.995 76.4264 46.449 78.3145 47.7314 78.5891C49.0898 78.8798 50.4737 78.8288 51.7754 78.4783C52.2458 78.8654 52.7729 79.1866 53.3341 79.4186C56.4234 80.6951 59.7774 79.6953 62.5145 78.1051C63.6362 77.4535 62.6122 75.7588 61.4853 76.4136ZM53.6231 75.4043C53.2329 75.7229 52.7978 75.9908 52.3356 76.2074C52.0378 75.7862 51.8347 75.3 51.7665 74.755C51.5974 73.4026 52.7913 71.7423 54.3506 71.9863C56.4227 72.3107 54.3909 74.7774 53.6231 75.4043Z"
              fill="#382C88"
            />
            <path
              d="M175.709 103.798L172.202 100.291C172.016 100.105 171.763 100 171.499 100C171.236 100 170.983 100.105 170.797 100.291L167.291 103.798C166.903 104.186 166.903 104.815 167.291 105.203L170.797 108.709C170.991 108.903 171.245 109 171.5 109C171.754 109 172.008 108.903 172.202 108.709L175.709 105.203C175.895 105.017 176 104.764 176 104.501C176 104.237 175.895 103.984 175.709 103.798ZM171.5 106.602L169.398 104.5L171.5 102.398L173.602 104.5L171.5 106.602Z"
              fill="#382C88"
            />
            <path
              d="M168 50C167.448 50 167 50.4477 167 51V52.3334C167 52.8857 167.448 53.3334 168 53.3334C168.552 53.3334 169 52.8857 169 52.3334V51C169 50.4477 168.552 50 168 50Z"
              fill="#382C88"
            />
            <path
              d="M168 56.6667C167.448 56.6667 167 57.1144 167 57.6667V59C167 59.5523 167.448 60 168 60C168.552 60 169 59.5523 169 59V57.6667C169 57.1144 168.552 56.6667 168 56.6667Z"
              fill="#382C88"
            />
            <path
              d="M172 56C172.552 56 173 55.5523 173 55C173 54.4477 172.552 54 172 54H170.667C170.114 54 169.667 54.4477 169.667 55C169.667 55.5523 170.114 56 170.667 56H172Z"
              fill="#382C88"
            />
            <path
              d="M163 55C163 55.5523 163.448 56 164 56H165.333C165.886 56 166.333 55.5523 166.333 55C166.333 54.4477 165.886 54 165.333 54H164C163.448 54 163 54.4477 163 55Z"
              fill="#382C88"
            />
            <path
              d="M6.1333 49.5005C6.27895 50.3802 6.96783 51.0376 7.88842 51.1757C8.0039 51.1931 8.11843 51.2015 8.23147 51.2015C9.01915 51.2015 9.728 50.7899 10.0957 50.0951L11.8036 46.8688L15.5539 47.4401C16.4737 47.5803 17.3402 47.1585 17.7631 46.3663C18.186 45.5739 18.0341 44.6554 17.376 44.0263L14.6797 41.4494L16.4039 38.2204C16.8275 37.4269 16.6751 36.5076 16.0152 35.8786C15.3552 35.2494 14.3933 35.1059 13.564 35.5126L10.1969 37.1648L7.50977 34.5965C6.85096 33.9669 5.88937 33.8219 5.05972 34.2274C4.23019 34.6329 3.79024 35.4629 3.93866 36.342L4.54466 39.9297L1.15395 41.5774C0.323773 41.9809 -0.118409 42.8097 0.0275679 43.6888C0.173438 44.5679 0.862101 45.2252 1.78226 45.3632L5.54195 45.9274L6.1333 49.5005ZM6.19864 41.4043C6.61022 41.2043 6.8396 40.773 6.76597 40.3373L6.04565 36.0692L9.24194 39.1241C9.56832 39.4359 10.0679 39.511 10.4785 39.3094L14.4868 37.3432L12.4361 41.1838C12.2267 41.5758 12.3056 42.0521 12.6312 42.3634L15.8352 45.4264L11.3729 44.7468C10.9166 44.6774 10.4663 44.8974 10.2577 45.2912L8.22371 49.1321L7.51997 44.8803C7.44794 44.4452 7.09075 44.1041 6.6354 44.0359L2.16526 43.3645L6.19864 41.4043Z"
              fill="#382C88"
            />
          </g>
        </svg>
      </div>
      <h2 class="heading-primary heading-primary-md my-6 !text-grey-900">
        There are no appointment yet
      </h2>
      <p class="my-6 text-grey-500">
        Please book your appointment for COVID test.  <br>
        When you book an
        appointment, you’ll see your data here
      </p>
      <div class="flex justify-center">
   <secondary-button
        :href="addAppointmentUrl"
        class="!border-dashed btn-icon !text-xs !py-3 !px-5 !gap-1.5 hover:opacity-80"
      >
        <Icon class="w-3 h-3" name="plus" />
        Book Appointment
      </secondary-button>
    </div>
      </div>
  </div>
</template>

<script>
import SecondaryButton from "../../../../components/Front/Buttons/SecondaryButton"
import Icon from "../../../../components/Front/General/Icon"

export default {
    name: "ReportEmpty",
    components: {SecondaryButton,Icon},
    props: {
        addAppointmentUrl : {Type: String,required: true,}
    }
}
</script>

<template>
    <div>
        <div class="form-header">
            <Icon name="basic-information-2"/>
            <h3 class="heading-primary heading-primary-sm" v-text="'Personal Information'"/>
        </div>

        <div class="md:grid md:grid-cols-3 gap-10 gap-y-6">
            <div v-if="!isFamilyMember"
                :class="(v$.email.$error || emailExists) ? 'form-group input-error' : 'form-group'">
                <label-component :value="'Email'"/>
                <input-component
                    type="email"
                    name="email"
                    v-model="email"
                    @focusout="focusOutCheck(checkEmail)"/>
                <input-error
                    v-if="getFromObject(errors, 'basic_information.email')"
                    :message="getFromObject(errors, 'basic_information.email')"/>
                <input-error
                    v-if="v$.email.$error"
                    :message="v$.email.$errors[0].$message"/>
                <input-error v-if="emailExists" :message="`Email Already Exists`"/>
            </div>

            <div :class="(v$.first_name.$error || getFromObject(errors, 'basic_information.first_name')) ? 'form-group input-error' : 'form-group'">
                <label-component :value="'First Name'"/>
                <input-component
                    type="text"
                    name="first_name"
                    v-model="first_name"
                    @focusout="v$.first_name.$touch(); checkExistingUser()"/>
                <input-error
                    v-if="getFromObject(errors, 'basic_information.first_name')"
                    :message="getFromObject(errors, 'basic_information.first_name')"/>
                <input-error
                    v-if="v$.first_name.$error"
                    :message="v$.first_name.$errors[0].$message"/>
            </div>
            <div :class="(v$.last_name.$error || getFromObject(errors, 'basic_information.last_name')) ? 'form-group input-error' : 'form-group'">

                <label-component :value="'Last Name'"/>
                <input-component
                    type="text"
                    name="last_name"
                    v-model="last_name"
                    @focusout="focusOutCheck(v$.last_name.$touch)"/>
                <input-error
                    v-if="getFromObject(errors, 'basic_information.last_name')"
                    :message="getFromObject(errors, 'basic_information.last_name')"/>
                <input-error
                    v-if="v$.last_name.$error"
                    :message="v$.last_name.$errors[0].$message"/>
            </div>
            <div :class="(v$.dob.$error || getFromObject(errors, 'basic_information.dob')) ? 'form-group input-error' : 'form-group'">
                <label-component :value="'Date of Birth'"/>
                <select-date-picker :get-from-object="getFromObject(errors, 'basic_information.dob')" v-model="dob" @focusout="v$.dob.$touch"/>
                <input-error
                    v-if="getFromObject(errors, 'basic_information.dob')"
                    :message="getFromObject(errors, 'basic_information.dob')"/>
                <input-error v-if="v$.dob.$error" :message="v$.dob.$errors[0].$message"/>
            </div>
            <div :class="(v$.gender.$error || getFromObject(errors, 'basic_information.gender')) ? 'form-group input-error' : 'form-group'">
                <label-component :value="'Gender'"/>
                <radio-group-input
                    name="gender"
                    :options="genderOptions"
                    v-model="gender"
                    @focusout="v$.gender.$touch"/>
                <input-error
                    v-if="getFromObject(errors, 'basic_information.gender')"
                    :message="getFromObject(errors, 'basic_information.gender')"/>
                <input-error
                    v-if="v$.gender.$error"
                    :message="v$.gender.$errors[0].$message"/>
            </div>

            <div :class="(v$.gender.phone_number || getFromObject(errors, 'basic_information.phone_number')) ? 'form-group input-error' :
            'form-group'">

                <label-component :value="'Phone Number'"/>
                <input-component
                    type="text"
                    name="phone_number"
                    v-model="phone_number"
                    @focusout="v$.phone_number.$touch"/>
                <input-error
                    v-if="getFromObject(errors, 'basic_information.phone_number')"
                    :message="getFromObject(errors, 'basic_information.phone_number')"/>
                <input-error
                    v-if="v$.phone_number.$error"
                    :message="v$.phone_number.$errors[0].$message"/>
            </div>
            <div :class="(v$.race.$error || getFromObject(errors, 'basic_information.race')) ? 'form-group input-error' : 'form-group'">

                <label-component :value="'Race'"/>
                <select-input
                    name="race"
                    v-model="race"
                    :options="raceOptions"
                    place-holder="Select Race"
                    @focusout="v$.race.$touch"/>
                <input-error
                    v-if="getFromObject(errors, 'basic_information.race')"
                    :message="getFromObject(errors, 'basic_information.race')"/>
                <input-error v-if="v$.race.$error" :message="v$.race.$errors[0].$message"/>
            </div>
            <div :class="(v$.ethnicity.$error || getFromObject(errors, 'basic_information.ethnicity')) ? 'form-group input-error' : 'form-group'">

                <label-component :value="'Ethnicity'"/>
                <select-input
                    name="ethnicity"
                    v-model="ethnicity"
                    :options="ethnicityOptions"
                    place-holder="Select ethnicity"
                    @focusout="v$.ethnicity.$touch"/>
                <input-error v-if="getFromObject(errors, 'basic_information.ethnicity')"
                             :message="getFromObject(errors, 'basic_information.ethnicity')"/>
                <input-error
                    v-if="v$.ethnicity.$error"
                    :message="v$.ethnicity.$errors[0].$message"/>
            </div>

            <div :class="(v$.street_address_one.$error || getFromObject(errors, 'basic_information.street_address_one')) ? 'form-group input-error' :
            'form-group'">

                <label-component :value="'Street Address 1'"/>
                <input-component
                    type="text"
                    name="street_address_one"
                    v-model="street_address_one"
                    @focusout="v$.street_address_one.$touch"/>
                <input-error
                    v-if="getFromObject(errors, 'basic_information.street_address_one')"
                    :message="getFromObject(errors, 'basic_information.street_address_one')"/>
                <input-error
                    v-if="v$.street_address_one.$error"
                    :message="v$.street_address_one.$errors[0].$message"/>
            </div>
            <div>
                <label-component :value="'Street Address 2 (Optional)'"/>
                <input-component
                    type="text"
                    name="street_address_two"
                    v-model="street_address_two"
                    @focusout="v$.street_address_two.$touch"/>
                <input-error
                    v-if="getFromObject(errors, 'basic_information.street_address_two')"
                    :message="getFromObject(errors, 'basic_information.street_address_two')"/>
                <input-error
                    v-if="v$.street_address_two.$error"
                    :message="v$.street_address_two.$errors[0].$message"/>
            </div>

            <div :class="(v$.city.$error || getFromObject(errors, 'basic_information.city')) ? 'form-group input-error' : 'form-group'">

                <label-component :value="'City'"/>
                <input-component
                    type="text"
                    name="city"
                    v-model="city"
                    @focusout="v$.city.$touch"/>
                <input-error
                    v-if="getFromObject(errors, 'basic_information.city')"
                    :message="getFromObject(errors, 'basic_information.city')"/>
                <input-error
                    v-if="v$.city.$error"
                    :message="v$.city.$errors[0].$message"/>
            </div>
            <div :class="(v$.state.$error || getFromObject(errors, 'basic_information.state')) ? 'form-group input-error' : 'form-group'">

                <label-component :value="'State'"/>
                <select-input
                    :searchable="true"
                    name="state"
                    v-model="state"
                    :options="stateOptions"
                    place-holder="Select state"
                    @focusout="v$.state.$touch"/>
                <input-error
                    v-if="getFromObject(errors, 'basic_information.state')"
                    :message="getFromObject(errors, 'basic_information.state')"/>
                <input-error
                    v-if="v$.state.$error"
                    :message="v$.state.$errors[0].$message"/>
            </div>
            <div :class="(v$.zip_code.$error || getFromObject(errors, 'basic_information.zip_code')) ? 'form-group input-error' : 'form-group'">

                <label-component :value="'ZipCode'"/>
                <input-component
                    type="text"
                    name="zip_code"
                    v-model="zip_code"
                    @focusout="v$.zip_code.$touch"/>
                <input-error
                    v-if="getFromObject(errors, 'basic_information.zip_code')"
                    :message="getFromObject(errors, 'basic_information.zip_code')"/>
                <input-error
                    v-if="v$.zip_code.$error"
                    :message="v$.zip_code.$errors[0].$message"/>
            </div>

            <div v-if="showButtons" class="pt-8 flex justify-between">
                 <span class="text-xs text-grey-400">
                            <span class="text-tertiary-100" v-text="'*'"/>
                            All fields are required unless specified optional
                        </span>
                    <div
                        v-if="showButtons"
                        class="flex gap-8">
                        <cancel-button @click.prevent="cancel">Cancel</cancel-button>
                        <primary-button
                            @click.prevent="nextPage"
                            class="btn px-10">
                            Next
                        </primary-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Inertia }              from "@inertiajs/inertia"
    import { useVuelidate }         from "@vuelidate/core"
    import {
        email, helpers,
        required, requiredIf,
    } from "@vuelidate/validators"
    import { get as getFromObject } from "lodash"

    import {
        computed,
        inject,
        reactive,
        ref,
        toRefs,
        watch,
    } from "vue"

    import CancelButton        from "../../../../components/Admin/Buttons/CancelButton"
    import PrimaryButton       from "../../../../components/Admin/Buttons/PrimaryButton"
    import DropDownInput       from "../../../../components/Admin/Forms/DropDownInput"
    import InputComponent      from "../../../../components/Admin/Forms/Input"
    import InputError          from "../../../../components/Admin/Forms/InputError"
    import LabelComponent      from "../../../../components/Admin/Forms/Label"
    import RadioGroupInput     from "../../../../components/Admin/Forms/RadioGroupInput"
    import SelectDatePicker    from "../../../../components/Admin/Forms/SelectDatePicker"
    import SelectInput         from "../../../../components/Admin/Forms/SelectInput"
    import CardHeader          from "../../../../components/Admin/General/CardHeader"
    import Header              from "../../../../components/Admin/Layout/AdminHeader"
    import SidebarRegistration from "../../../../components/Admin/Layout/Sidebar"
    import Icon from "../../../../components/Admin/General/Icon";

    export default {
        name: "BasicInformationNew",
        components: {
            Icon,
            SelectDatePicker,
            CardHeader,
            PrimaryButton,
            CancelButton,
            InputError,
            SelectInput,
            SidebarRegistration,
            Header,
            InputComponent,
            LabelComponent,
            DropDownInput,
            RadioGroupInput,
        },
        props: {
            modelValue: { type: Object, required: false, default: () => ({}) },
            errors: { type: Object, required: false, default: () => ({}) },
            showButtons: { type: Boolean, required: false, default: false },
            isFamilyMember: { type: Boolean, required: false, default: false },
        },
        inheritAttrs: false,
        emits: ["next", "update:modelValue", "check-existing"],
        setup(props, { emit }) {
            const raceOptions = inject("raceOptions")
            const genderOptions = inject("genderOptions")
            const ethnicityOptions = inject("ethnicityOptions")
            const stateOptions = inject("stateOptions")
            const isFamilyMember = props.isFamilyMember

            const dobPickerConfig = ref({
                type: "string",
                mask: "MM-DD-YYYY",
            })

            const appState = reactive({
                email: null,
                first_name: null,
                last_name: null,
                dob: null,
                gender: null,
                phone_number: null,
                race: null,
                ethnicity: null,
                street_address_one: null,
                street_address_two: null,
                city: null,
                state: null,
                zip_code: null,
                emailExists: false,
            })

            const resultValue = computed(() => {
                return {
                    email: appState.email,
                    first_name: appState.first_name,
                    last_name: appState.last_name,
                    dob: appState.dob,
                    gender: appState.gender,
                    phone_number: appState.phone_number,
                    race: appState.race,
                    ethnicity: appState.ethnicity,
                    street_address_one: appState.street_address_one,
                    street_address_two: appState.street_address_two,
                    city: appState.city,
                    state: appState.state,
                    zip_code: appState.zip_code,
                }
            })

            watch(resultValue, () => {
                emit("update:modelValue", resultValue.value)
            })

            let { modelValue } = toRefs(props)

            watch(() => modelValue.value, (newValue, oldValue) => {
                appState.email = newValue.email
                appState.first_name = newValue.first_name
                appState.last_name = newValue.last_name
                appState.dob = newValue.dob
                appState.gender = newValue.gender
                appState.phone_number = newValue.phone_number
                appState.race = newValue.race
                appState.ethnicity = newValue.ethnicity
                appState.street_address_one = newValue.street_address_one
                appState.street_address_two = newValue.street_address_two
                appState.city = newValue.city
                appState.state = newValue.state
                appState.zip_code = newValue.zip_code

            }, { deep: true, immediate: true })

            // const basic_information = getFromObject(props, "form.basic_information")

            const requiredIfNotFamilyMember = (value) => {
                if (isFamilyMember) {
                    return true
                }

                return !!value
            }

            const rules = computed(() => ({
                email: {
                    requiredIfNotFamilyMember: helpers.withMessage("Required", requiredIfNotFamilyMember),
                    email,
                },
                first_name: {
                    required,
                },
                last_name: {
                    required,
                },
                dob: {
                    required,
                },
                gender: {
                    required,
                },
                phone_number: {
                    required,
                },
                race: {
                    required,
                },
                ethnicity: {
                    required,
                },
                street_address_one: {
                    required,
                },
                street_address_two: {},
                city: {
                    required,
                },
                state: {
                    required,
                },
                zip_code: {
                    required,
                },
            }))

            const v$ = useVuelidate(rules, appState)

            function cancel() {
                Inertia.get("/")
            }

            return {
                getFromObject,
                ...toRefs(appState),
                dobPickerConfig,
                cancel,
                raceOptions,
                genderOptions,
                ethnicityOptions,
                stateOptions,
                v$,
            }
        },
        methods: {
            nextPage() {
                this.v$.$touch()
                if (this.v$.$error) return

                this.$emit("next")
            },

            focusOutCheck(func) {
                func()
                this.checkExistingUser()
            },

            checkEmail() {
                if(this.email === "") return

                this.v$.email.$touch()

                const value = this.email

                if (value === "" || this.v$.email.$error) return true
            },

            checkExistingUser() {
                if(!this.email || !this.first_name || !this.last_name){
                    return
                }

                this.$emit("check-existing", {
                    first_name: this.first_name,
                    last_name: this.last_name,
                    email: this.email,
                })
            },
        },
    }
</script>

<style scoped>

</style>

<template>
    <admin-layout :if-has-breadcrumb="true" active-link="users">
        <template #breadcrumb>
            <bread-crumb>
                <bread-crumb-item :href="userIndexUrl" label="Users"/>
                <bread-crumb-item label="Add New User "/>
            </bread-crumb>
        </template>
        <div class="card--large !overflow-visible">
            <h1 class="heading-primary !text-grey-1100 !pb-8" v-text="'Add New User'"/>
            <form @submit.prevent="submitForm">
                <div class="grid lg:grid-cols-3 gap-y-6 gap-10">
                    <div>
                        <label-component :value="'Name'"/>
                        <input-component
                            type="text"
                            name="name"
                            v-model="form.name"/>
                        <input-error
                            v-show="v$.name.$error"
                            :message="getFromObject(v$, 'name.$errors[0].$message', '')"/>
                    </div>
                    <div>
                        <label-component :value="'Email'"/>
                        <input-component
                            type="email"
                            name="email"
                            v-model="form.email"/>
                        <input-error
                            v-show="v$.email.$error"
                            :message="getFromObject(v$, 'email.$errors[0].$message', '')"/>
                    </div>
                    <div>
                        <label-component :value="'Password'"/>
                        <input-component
                            type="password"
                            name="password"
                            v-model="form.password"/>
                        <input-error
                            v-show="v$.password.$error"
                            :message="getFromObject(v$, 'password.$errors[0].$message', '')"/>
                    </div>
                    <div>
                        <label-component :value="'Confirm Password'"/>
                        <input-component
                            type="password"
                            name="password_confirmation"
                            v-model="form.password_confirmation"/>
                        <input-error
                            v-show="v$.password_confirmation.$error"
                            :message="getFromObject(v$, 'password_confirmation.$errors[0].$message', '')"/>
                    </div>
                    <div>
                        <label-component :value="'Role'"/>
                        <select-input
                            place-holder="Select a role"
                            name="role"
                            :options="roleOptions"
                            v-model="form.role"/>
                        <input-error
                            v-show="v$.role.$error"
                            :message="getFromObject(v$, 'role.$errors[0].$message', '')"/>
                    </div>
                </div>
                <div class="mt-6 pt-6 flex items-center justify-between border-t border-pinkShade-200">
                       <span>
                           <span class="text-pinkShade-100">*</span>
                           <span class="text-sm" v-text="'* All fields are required unless specified optional'"/>
                       </span>

                    <div class="flex items-center gap-8">
                        <cancel-button :inertia-link="true" :href="userIndexUrl" class="btn">Cancel</cancel-button>
                        <primary-button @click="submitForm" class="btn" :disabled="form.processing">
                            <span v-if="form.processing">Submitting</span>
                            <span v-else>Submit</span>
                        </primary-button>
                    </div>
                </div>
            </form>
        </div>
    </admin-layout>
</template>

<script>
    import BreadCrumb              from "Components/Admin/BreadCrumb/BreadCrumb"
    import BreadCrumbItem          from "Components/Admin/BreadCrumb/BreadCrumbItem"
    import AdminHeader             from "Components/Admin/Layout/AdminHeader"
    import AdminLayout             from "Components/Admin/Layout/AdminLayout"
    import CancelButton            from "Components/Admin/Buttons/CancelButton"
    import PrimaryButton           from "Components/Admin/Buttons/PrimaryButton"
    import InputComponent          from "Components/Admin/Forms/Input"
    import InputError              from "Components/Admin/Forms/InputError"
    import LabelComponent          from "Components/Admin/Forms/Label"
    import { useForm }             from "@inertiajs/inertia-vue3"
    import { useVuelidate }        from "@vuelidate/core"
    import { computed, ref }            from "vue"
    import {
        helpers,
        required,
        sameAs,
    }                              from "@vuelidate/validators"
    import SelectInput             from "Components/Admin/Forms/SelectInput"
    import { getFromObject }       from "@/helpers"
    import { message as AMessage } from "ant-design-vue"
    import {capitalize} from "lodash"

    export default {
        name: "UserCreate",
        components: {
            SelectInput,
            InputError,
            InputComponent,
            LabelComponent,
            PrimaryButton,
            CancelButton,
            BreadCrumbItem,
            BreadCrumb,
            AdminLayout,
            AdminHeader,
            AMessage,
        },
        props: {
            roles: {
                type: Array,
                required: true,
            },
        },
        setup(props) {
            const userIndexUrl = route("admin.users.index")
            const userStoreUrl = route("admin.users.store")
            const form = useForm("UserCreateForm", {
                name: "",
                email: "",
                password: "",
                password_confirmation: "",
                role: "",
            })

            const roleOptions = new Map()
            props.roles.forEach(role => roleOptions.set(role, capitalize(role)))

            const externalResults = ref({})
            const rules = computed(() => ({
                name: {
                    required,
                },
                email: {
                    required,
                },
                password: {
                    required,
                },
                password_confirmation: {
                    required,
                    sameAsPassword:  sameAs(form.password),
                },
                role: {
                    required,
                },
            }))

            const v$ = useVuelidate(rules, form, { $externalResults: externalResults })

            return {
                userIndexUrl,
                userStoreUrl,
                form,
                roleOptions,
                v$,
                getFromObject,
                externalResults,
            }
        },
        methods: {
            submitForm() {
                if (this.form.processing) return

                this.v$.$touch()

                if (this.v$.$error) return

                this.form.post(this.userStoreUrl, {
                    onSuccess: () => {
                        AMessage.success("User created")
                    },
                    onError: (error) => {
                        Object.assign(this.externalResults, error)
                        AMessage.error("User creation failed")
                    },
                })
            },
            formHasError(name) {
                return !!this.getError(this.form.errors, name)
            },
            getError(form, name, $default = "") {
                return this.getFromObject(form, name, $default)
            },
        },
    }
</script>

<style scoped>

</style>

<template>
    <header class="header flex items-center bg-blue-900  h-18 fixed z-20 left-0 top-0 w-full">
        <div class="md:w-1/2 text-white px-6">
            <h3 class="font-bold whitespace-nowrap">Crisper <span class="text-tertiary-100">Diagnostics</span></h3>
        </div>
<!--        <div class="ml-auto w-1/2 flex justify-end self-stretch" v-if="isUser">-->
<!--            <div-->
<!--                class="h-full !flex items-center gap-2 py-2 px-6 text-blue-100 text-sm"-->
<!--            >-->
<!--                              <span-->
<!--                                  class="w-10 h-10 text-sm  bg-blue-500 text-white hidden sm:inline-flex items-center justify-center rounded-full"-->
<!--                                  v-text="userName.split(' ').map((n) => n[0]).join(' ')"/>-->
<!--                {{ userName }}-->
<!--            </div>-->
<!--            <a @click="logoutModal= true"-->
<!--               class="cursor-pointer bg-blue-1000 transition hover:bg-blue-1000 duration-300 text-sm py-3 px-4 sm:px-6 flex gap-2 items-center justify-center text-blue-100"-->
<!--            >-->
<!--                <span class="hidden sm:block" v-text="'Logout'"/>-->
<!--                <Icon name="logout" class="!w-5 !h-5"/>-->
<!--            </a>-->
<!--        </div>-->
<!--        <modal class="modal&#45;&#45;logout" :show="logoutModal" @close="logoutModal = false">-->
<!--            <template #title>-->
<!--                <p class="heading-primary heading-primary-md !font-normal !text-black !text-center"-->
<!--                   v-text="'Are you sure you want to logout?'"/>-->
<!--            </template>-->
<!--            <template #default>-->
<!--                <div class="btn-wrap !gap-6 sm:justify-center">-->
<!--                    <secondary-button class="!px-10" @click="logoutModal = false" v-text="'Cancel'"/>-->
<!--                    <primary-button href="/logout" class="!px-8" @click="logoutModal = false" v-text="'Yes,Logout'"/>-->
<!--                </div>-->
<!--            </template>-->
<!--        </modal>-->
    </header>
</template>
<script>
import SecondaryButton from "../../Front/Buttons/SecondaryButton"
import PrimaryButton from "../../Front/Buttons/PrimaryButton"
import Icon from "../General/Icon"
import Dropdown from "../General/Dropdown"
import Modal from "../General/Modal"

export default {
    name: "WebHeader",
    props: {
        isUser: {type: Boolean, required: false},
    },
    components: {Modal, Icon, Dropdown, PrimaryButton, SecondaryButton},
    // inject: ["userName"],
    data() {
        return {
            logoutModal: false,
        }
    },
}
</script>

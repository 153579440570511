<template>
    <div class="grid md:grid-cols-3 gap-5">
        <select-input name="hour" v-model="hour" place-holder="HH" :options="hourList" @update:modelValue="handleOnSelect"/>
        <select-input name="minute" v-model="minute" place-holder="MM" :options="minutesList" @update:modelValue="handleOnSelect"/>
        <select-input name="time" v-model="time" place-holder="" :options="timeList" @update:modelValue="handleOnSelect"/>
    </div>
</template>

<script>
    import SelectInput from "./SelectInput"

    export default {
        name: "TimePicker",
        components: { SelectInput },
        props: {
            modelValue: { type: String, required: false },
        },
        data() {
            return {
                hour: null,
                minute: null,
                time: null,
            }
        },
        watch: {
            modelValue: {
                handler(value) {
                    this.splitTime(value)
                },
                deep: true,
                immediate: true
            }
        },
        computed: {
            hourList() {
                const options = new Map()
                for (let i = 1; i <= 12; i++) {
                    options.set(i, i)
                }
                return options
            },
            minutesList() {
                const options = new Map()

                for (let i = 0; i < 60; i++) {
                    options.set(i, i)
                }

                return options
            },
            timeList() {
                const options = new Map()
                options.set('am', "AM");
                options.set('pm', "PM");
                return options
            },
        },
        methods: {
            splitTime(time) {
                const timeSplit = String(time).match(/((1[0-2]|0?[1-9]):([0-5][0-9])?([AaPp][Mm]))/);

                if (!time || !timeSplit) {
                    this.hour = null
                    this.minute = null
                    this.time = null

                    return
                }

                //
                this.hour = parseInt(timeSplit[2])
                this.minute = parseInt(timeSplit[3])
                this.time = timeSplit[4]
            },
            combineTime() {
                if (!this.hour || !this.minute || !this.time) {
                    return null
                }
                return `${this.hour.toString().padStart(2, "0")}:${this.minute.toString().padStart(2, "0")}${this.time}`
            },

            handleOnSelect() {
                // if (!this.hour || !this.minute || !this.time) {
                //     return
                // }

                this.$emit("update:modelValue", this.combineTime())
            },
        },
    }
</script>

<style scoped>

</style>

<template>
    <div class="search-req mb-6 w-full !px-0 !py-2.5 card--large flex relative !rounded">
        <input-component class="!border-0 !px-4 !placeholder-grey-600" type="search" @change="action" :placeholder="placeholder"/>
        <button class="absolute top-0 right-0 h-full pl-4 pr-5 py-3 text-tertiary-100" type="button">
            <Icon name="search"/>
        </button>
    </div>
</template>

<script>
    import InputComponent from "../../../../components/Admin/Forms/Input"
    import Icon           from "../../../../components/Admin/General/Icon"

    export default {
        name: "SearchRequisition",
        components: {
            InputComponent, Icon,
        },
        props: {
            action: { type: Function, required: true },
            placeholder: { type: String, required: false, default: "Search" },
        },
    }
</script>

<template>
    <div>
        <div class="form-header">
            <Icon name="payment-method-2"/>
            <h3 class="heading-primary heading-primary-sm" v-text="'Payment Method'"/>
        </div>
        <div class="grid gap-8">
            <div class="grid">
                <label-component v-text="'Payment'"/>
                <radio-group-input
                    name="payment_type"
                    :options="paymentMethodOptions"
                    v-model="payment_type" @change="resetPayments"/>
                <input-error
                    v-if="getFromObject(errors, 'payment_method.payment_type')"
                    :message="getFromObject(errors, 'payment_method.payment_type')"/>
                <input-error v-if="v$.payment_type.$error" :message="v$.payment_type.$errors[0].$message"/>
            </div>

            <div v-if="payment_type === PAYMENT_SELF ">
                <h4 class="font-bold mb-8" v-text="'Self Payment'"/>
                <div class="grid md:grid-cols-3 gap-10 gap-y-6">
                    <div
                        :class="(v$.driver_license.$error || getFromObject(errors, 'payment_method.driver_license')) ? 'form-group input-error' : 'form-group'">

                        <label-component v-text="'Driver’s License / Identification Number'"/>
                        <input-component
                            type="text"
                            name="driver_license"
                            v-model="driver_license"/>
                        <input-error
                            v-if="getFromObject(errors, 'payment_method.driver_license')"
                            :message="getFromObject(errors, 'payment_method.driver_license')"/>
                        <input-error v-if="v$.driver_license.$error" :message="v$.driver_license.$errors[0].$message"/>
                    </div>
                    <div
                        :class="(v$.payment_status.$error || getFromObject(errors, 'payment_method.payment_status')) ? 'form-group input-error' : 'form-group'">

                        <label-component v-text="'Payment Status'"/>
                        <radio-group-input
                            name="payment_status"
                            :options="paymentStatusOptions"
                            v-model="payment_status"/>
                        <input-error
                            v-if="getFromObject(errors, 'payment_method.payment_status')"
                            :message="getFromObject(errors, 'payment_method.payment_status')"/>
                        <input-error v-if="v$.payment_status.$error" :message="v$.payment_status.$errors[0].$message"/>
                    </div>
                </div>
            </div>
            <div v-if="payment_type === PAYMENT_INSURANCE">
                <h4 class="font-bold mb-8" v-text="'Insurance'"/>
                <div class="grid md:grid-cols-3 gap-10 gap-y-6">
                    <div
                        :class="(v$.insurance_name.$error || getFromObject(errors, 'payment_method.insurance_name')) ? 'form-group input-error' : 'form-group'">
                        <label-component v-text="'Insurance Name'"/>
                        <input-component type="text" name="insurance_name" v-model="insurance_name"/>
                        <input-error
                            v-if="getFromObject(errors, 'payment_method.insurance_name')"
                            :message="getFromObject(errors, 'payment_method.insurance_name')"/>
                        <input-error v-if="v$.insurance_name.$error" :message="v$.insurance_name.$errors[0].$message"/>
                    </div>
                    <div
                        :class="(v$.insurance_holder_name.$error || getFromObject(errors, 'payment_method.insurance_holder_name')) ? 'form-group input-error' : 'form-group'">
                        <label-component v-text="'Primary Insurance Holder’s Name'"/>
                        <input-component type="text" name="insurance_holder_name" v-model="insurance_holder_name"/>
                        <input-error
                            v-if="getFromObject(errors, 'payment_method.insurance_holder_name')"
                            :message="getFromObject(errors, 'payment_method.insurance_holder_name')"/>
                        <input-error v-if="v$.insurance_holder_name.$error" :message="v$.insurance_holder_name.$errors[0].$message"/>
                    </div>
                    <div
                        :class="(v$.insurance_id.$error || getFromObject(errors, 'payment_method.insurance_id')) ? 'form-group input-error' : 'form-group'">

                        <label-component v-text="'Insurance ID'"/>
                        <input-component type="text" name="insurance_id" v-model="insurance_id"/>
                        <input-error
                            v-if="getFromObject(errors, 'payment_method.insurance_id')"
                            :message="getFromObject(errors, 'payment_method.insurance_id')"/>
                        <input-error v-if="v$.insurance_id.$error" :message="v$.insurance_id.$errors[0].$message"/>
                    </div>
                    <div
                        :class="(v$.insurance_group.$error || getFromObject(errors, 'payment_method.insurance_group')) ? 'form-group input-error' :
                        'form-group'">
                        <label-component v-text="'Insurance Group'"/>
                        <input-component type="text" name="insurance_group" v-model="insurance_group"/>
                        <input-error
                            v-if="getFromObject(errors, 'payment_method.insurance_group')"
                            :message="getFromObject(errors, 'payment_method.insurance_group')"/>
                        <input-error v-if="v$.insurance_group.$error" :message="v$.insurance_group.$errors[0].$message"/>
                    </div>
                    <div
                        :class="(v$.insurance_document.$error || getFromObject(errors, 'payment_method.insurance_document')) ? 'form-group input-error' :
                        'form-group'">
                        <file-input v-if="showFileInput" :path="insurance_document_existing" @remove-existing-file="removeExistingFile" @clear-file="removeFile" @input-file="inputFile"/>
                        <template v-else>
                            <label-component v-text="'Upload Insurance Document (Optional)'"/>
                            <div class="file__input-wrap file__input--secondary rounded">
                                <input type="file" class="file__input" accept="image/*,.pdf" @input="inputFile">
                                <span v-if="hasUploadedFile" class="w-2/3 truncate text-sm" v-text="uploadedFileName"/>
                                <span v-if="!hasUploadedFile" class="pl-2 text-sm">No file Choosen</span>
                                <span class="file__input-trigger" v-text="'Choose File'"/>
                            </div>
                            <span class="block pt-3 text-xs text-grey-500"
                                  v-text="'Max 5MB of PDF or image (jpeg, jpg, png) file can be uploaded '"/>
                        </template>
                        <input-error
                            v-if="getFromObject(errors, 'payment_method.insurance_document')"
                            :message="getFromObject(errors, 'payment_method.insurance_document')"/>
                        <input-error v-if="v$.insurance_document.$error" :message="v$.insurance_document.$errors[0].$message"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { useVuelidate }         from "@vuelidate/core"
    import {
        helpers,
        required,
    }                               from "@vuelidate/validators"
    import { get as getFromObject } from "lodash"
    import {
        computed,
        inject,
        toRefs,
        watch,
        reactive,
    }                               from "vue"
    import Button                   from "../../../../components/Admin/Buttons/Button"
    import CancelButton        from "../../../../components/Admin/Buttons/CancelButton"
    import PrimaryButton       from "../../../../components/Admin/Buttons/PrimaryButton"
    import DropDownInput       from "../../../../components/Admin/Forms/DropDownInput"
    import InputComponent      from "../../../../components/Admin/Forms/Input"
    import InputError          from "../../../../components/Admin/Forms/InputError"
    import LabelComponent      from "../../../../components/Admin/Forms/Label"
    import RadioGroupInput     from "../../../../components/Admin/Forms/RadioGroupInput"
    import Header              from "../../../../components/Admin/Layout/AdminHeader"
    import SidebarRegistration from "../../../../components/Admin/Layout/Sidebar"
    import CardHeader          from "../../../../components/Admin/General/CardHeader"
    import Icon                from "../../../../components/Admin/General/Icon";
    import FileInput           from "../../../../components/Admin/Forms/FileInput"

    const PAYMENT_SELF = "self"
    const PAYMENT_INSURANCE = "insurance"

    export default {
        name: "PaymentMethodNew",
        components: {
            FileInput,
            Icon,
            CardHeader,
            InputError,
            CancelButton,
            SidebarRegistration,
            Header,
            InputComponent,
            LabelComponent,
            DropDownInput,
            RadioGroupInput,
            Button,
            PrimaryButton,
        },
        inheritAttrs: false,
        emits: ["submit", "back", "update:modelValue"],
        props: {
            modelValue: { type: Object, required: false, default: () => ({}) },
            errors: { type: Object, required: false, default: () => ({}) },
            showButtons: { type: Boolean, required: false, default: false },
            showFileInput: { type: Boolean, required: false, default: true },
        },
        setup(props, { emit }) {
            const paymentMethodOptions = inject("paymentMethodOptions")
            const paymentStatusOptions = inject("paymentStatusOptions")

            const appState = reactive({
                payment_type: null,
                driver_license: null,
                payment_status: null,
                insurance_name: null,
                insurance_holder_name: null,
                insurance_id: null,
                insurance_group: null,
                insurance_document: null,
                insurance_document_existing: null,
            })

            const resultValue = computed(() => {
                return {
                    payment_type: appState.payment_type,
                    driver_license: appState.driver_license,
                    payment_status: appState.payment_status,
                    insurance_name: appState.insurance_name,
                    insurance_holder_name: appState.insurance_holder_name,
                    insurance_id: appState.insurance_id,
                    insurance_group: appState.insurance_group,
                    insurance_document: appState.insurance_document,
                    insurance_document_existing: appState.insurance_document_existing,
                }
            })

            const hasFile = computed(() => !!appState.insurance_document_existing)

            watch(resultValue, () => {
                emit("update:modelValue", resultValue.value)
            })

            let { modelValue } = toRefs(props)

            function removeExistingFile() {
                appState.insurance_document_existing = null
            }

            watch(() => modelValue.value, (newValue, oldValue) => {
                appState.payment_type = newValue.payment_type
                appState.driver_license = newValue.driver_license
                appState.payment_status = newValue.payment_status
                appState.insurance_name = newValue.insurance_name
                appState.insurance_holder_name = newValue.insurance_holder_name
                appState.insurance_id = newValue.insurance_id
                appState.insurance_group = newValue.insurance_group
                appState.insurance_document = newValue.insurance_document
                appState.insurance_document_existing = newValue.insurance_document_existing
            }, { deep: true, immediate: true })

            function resetPayments() {
                if (appState.payment_type === PAYMENT_SELF) {
                    appState.insurance_name = null
                    appState.insurance_holder_name = null
                    appState.insurance_id = null
                    appState.insurance_group = null
                    appState.insurance_document = null
                    appState.insurance_document_existing = null
                }

                if (appState.payment_type === PAYMENT_INSURANCE) {
                    appState.driver_license = null
                    appState.payment_status = null
                }
            }

            const isPaymentTypeSelf = (value) => {
                if (!appState.payment_type) {
                    return true
                }

                if (!(appState.payment_type === "self")) {
                    return true
                }

                return !!value
            }

            const isPaymentTypeInsurance = (value) => {
                if (!appState.payment_type) {
                    return true
                }

                if (!(appState.payment_type === "insurance")) {
                    return true
                }

                return !!value
            }

            const isValidFileSize = (options = {}) => {
                return helpers.withParams(options, value => {
                    if (!value) {
                        return true
                    }
                    const fileSizeinKb = value.size / 1024
                    const size = Math.round(fileSizeinKb * 100) / 100 // convert up to 2 decimal place
                    return size <= options.maxFileSizeInB / 1024
                })
            }

            const rules = computed(() => ({
                payment_type: {
                    required,
                },
                driver_license: {
                    requiredIfPaymentTypeSelf: helpers.withMessage("Required if self payment", isPaymentTypeSelf),
                },
                payment_status: {
                    requiredIfPaymentTypeSelf: helpers.withMessage("Required if self payment", isPaymentTypeSelf),
                },
                insurance_name: {
                    requiredIfPaymentTypeSelf: helpers.withMessage("Required if insurance payment", isPaymentTypeInsurance),
                },
                insurance_holder_name: {
                    requiredIfPaymentTypeSelf: helpers.withMessage("Required if insurance payment", isPaymentTypeInsurance),
                },
                insurance_id: {
                    requiredIfPaymentTypeSelf: helpers.withMessage("Required if insurance payment", isPaymentTypeInsurance),
                },
                insurance_group: {
                    requiredIfPaymentTypeSelf: helpers.withMessage("Required if insurance payment", isPaymentTypeInsurance),
                },
                insurance_document: {

                },
            }))

            const v$ = useVuelidate(rules, appState)

            return {
                v$,
                ...toRefs(appState),
                resetPayments,
                PAYMENT_SELF,
                PAYMENT_INSURANCE,
                getFromObject,
                paymentMethodOptions,
                paymentStatusOptions,
                removeExistingFile,
                hasFile
            }
        },
        data() {
            return {
                paymentMethod: "self",
                hasUploadedFile: false,
                uploadedFileName: undefined,
            }
        },
        methods: {
            removeFile() {
                this.insurance_document = null
                this.hasUploadedFile = false
                this.uploadedFileName = ""
            },
            nextPage() {
                this.v$.$touch()
                if (this.v$.$error) return

                this.$emit("next")
            },
            goBack() {
                this.$emit("back")
            },
            inputFile(e) {
                if (e.length > 0) {
                    this.insurance_document = e[0]
                    this.hasUploadedFile = true
                    this.uploadedFileName = this.insurance_document.name
                }
                // if (e.target.files.length > 0) {
                //     this.insurance_document = e.target.files[0]
                //     this.hasUploadedFile = true
                //     this.uploadedFileName = this.insurance_document.name
                // }
            },
        },
    }
</script>
<style scoped>

</style>

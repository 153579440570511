<template>
    <admin-layout :if-has-breadcrumb="true" :active-link="'requisitions'">
        <template #breadcrumb>
            <bread-crumb>
                <bread-crumb-item :href="requisitionIndexUrl" label="Requisitions"/>
                <bread-crumb-item label="Family Member Requisition"/>
            </bread-crumb>
        </template>
        <h1 class="pb-8 font-bold mt-0 text-grey-1100 leading-none" style="font-size: 32px;"
            v-text="'Family Member Requisition'"/>
        <div class="search-req mb-6 w-full !px-0 !py-2.5 card--large flex relative !rounded">
            <search-select @search="onSearch"
                           @select="onSelect"
                           :placeholder="'Search by  family member name'"
                           :options="patientOptions"/>
            <!--            <AntAutocomplete-->
            <!--                class="form-control&#45;&#45;suggestion w-full"-->
            <!--                v-model:value="searchText"-->
            <!--                placeholder="Search by family member name"-->
            <!--                :options="patientOptions"-->
            <!--                @select="onSelect"-->
            <!--                @search="onSearch">-->
            <!--            </AntAutocomplete>-->
        </div>
        <div class="h-full">
            <div v-if="hasPatient" class="card--large">
                <form @submit.prevent="submitForm">
                    <div class="grid gap-10">
                        <div
                            :class="(v$.relation.$error || getFromObject(form, 'errors.relation')) ? 'form-group grid grid-cols-3 gap-10 input-error' : 'form-group grid grid-cols-3 gap-10'">
                            <div>
                                <label-component :value="'Relation'"/>
                                <input-component
                                    type="text"
                                    name="relation"
                                    v-model="form.relation"
                                    @focusout="v$.relation.$touch"/>
                                <input-error
                                    v-if="getFromObject(form, 'errors.relation')"
                                    :message="getFromObject(form, 'errors.relation')"/>
                                <input-error
                                    v-if="v$.relation.$error"
                                    :message="v$.relation.$errors[0].$message"/>
                            </div>

                        </div>
                        <basic-information-new
                            v-model="form.basic_information"
                            :errors="form.errors"
                            :is-family-member="true"
                            :show-buttons="false"/>
                        <test-booking-new v-model="form.test_information" :errors="form.errors"
                                          :show-appointment-date="false" :show-buttons="false"/>
                        <doctor-information v-model="form.doctor_information"/>
                        <payment-method-new v-model="form.payment_method" :errors="form.errors"/>
                        <div>
                            <card-header title="Sample"/>
                            <requisition-sample v-for="(sample, sampleIndex) in form.samples"
                                                :key="sampleIndex"
                                                :index="sampleIndex"
                                                :sample="sample"
                                                :name="'samples.' + sampleIndex"
                                                :sample-count="form.samples.length"
                                                :errors="form.errors"
                                                @input="form.samples[sampleIndex] = $event"
                                                @add="addSample"
                                                @remove="removeSample(sampleIndex)"
                            />
                        </div>
                    </div>
                    <div class="mt-6 pt-6 flex items-center justify-between border-t border-pinkShade-200">
 <span class="text-xs">
                <span class="text-tertiary-100" v-text="'*'"/>
                All fields are required unless specified optional

                        </span>
                        <div class="flex items-center gap-8">
                            <cancel-button :inertia-link="true" :href="requisitionIndexUrl">Cancel</cancel-button>
                            <primary-button @click="submitForm">Submit</primary-button>
                        </div>
                    </div>
                </form>
            </div>
            <EmptySearch v-else/>
        </div>
    </admin-layout>
</template>

<script>
import {useForm} from "@inertiajs/inertia-vue3"
import {useVuelidate} from "@vuelidate/core"
import {get as getFromObject} from "lodash"
import {
    computed,
    provide,
    reactive,
    ref,
    toRefs,
    watch,
} from "vue"
import BreadCrumb from "../../../components/Admin/BreadCrumb/BreadCrumb"
import BreadCrumbItem from "../../../components/Admin/BreadCrumb/BreadCrumbItem"
import CancelButton from "../../../components/Admin/Buttons/CancelButton"
import PrimaryButton from "../../../components/Admin/Buttons/PrimaryButton"
import CardHeader from "../../../components/Admin/General/CardHeader"
import AdminLayout from "../../../components/Admin/Layout/AdminLayout"
import SecondaryButton from "../../../components/Admin/Buttons/SecondaryButton"
import InputComponent from "../../../components/Admin/Forms/Input"
import Icon from "../../../components/Admin/General/Icon"
import BasicInformationNew from "./Partials/BasicInformationNew"
import EmptySearch from "./Partials/EmptySearch"
import PaymentMethodNew from "./Partials/PaymentMethodNew"
import TestBookingNew from "./Partials/TestBookingNew"
import RequisitionSample from "./Partials/RequisitionSample"
import SearchRequisition from "./Partials/SearchRequisition";
import SearchResults from "./Partials/SearchResults";
import DoctorInformation from "./Partials/DoctorInformation";
import "ant-design-vue/lib/auto-complete/style/index.css"
import {SelectOption as AntSelectOption} from 'ant-design-vue/lib/select'
import {default as AntAutocomplete} from 'ant-design-vue/lib/auto-complete'
import {SearchOutlined} from '@ant-design/icons-vue';
import LabelComponent from "../../../components/Front/Forms/Label";
import InputError from "../../../components/Front/Forms/InputError";
import {email, helpers, required} from "@vuelidate/validators";
import SearchSelect from "../../../components/Admin/Forms/SearchSelect"
import scrollToSmoothly from "../../../script";

export default {
    name: "FamilyMemberRequisition",
    components: {
        SearchSelect,
        InputError,
        LabelComponent,
        DoctorInformation,
        SearchResults,
        SearchRequisition,
        EmptySearch,
        Icon,
        CardHeader,
        CancelButton,
        InputComponent,
        RequisitionSample,
        SecondaryButton,
        BreadCrumbItem,
        BreadCrumb,
        PaymentMethodNew,
        TestBookingNew,
        BasicInformationNew,
        AdminLayout,
        PrimaryButton,
        SearchOutlined,
        AntAutocomplete,
        AntSelectOption
    },
    props: {
        raceList: {type: Object, required: true},
        genderList: {type: Object, required: true},
        ethnicityList: {type: Object, required: true},
        stateList: {type: Object, required: true},
        testList: {type: Object, required: true},
        pregnancyList: {type: Object, required: true},
        paymentMethodList: {type: Object, required: true},
        paymentStatusList: {type: Object, required: true},
        sampleTypeList: {type: Object, required: true},
        patients: {type: Object, required: false, default: () => ({})},
        clients: {type: Object, required: false, default: () => ({})}
    },
    setup(props) {
        const hasPatient = ref(false)
        const currentId = ref(0)
        const patientDetails = ref({})
        const patientOptions = ref([])
        const requisitionIndexUrl = route("admin.requisitions.index")
        const saveExistingPatientUrl = computed(() => {
            return route("admin.requisitions.form.new-patient.store", currentId.value)
        })

        const appState = reactive({
            guardianId: "",
        })

        const form = useForm("FamilyMemberRequisitionForm", {
            id: null,
            guardian_id: null,
            relation: null,
            basic_information: {
                email: null,
                first_name: null,
                last_name: null,
                dob: null,
                gender: null,
                phone_number: null,
                race: null,
                ethnicity: null,
                street_address_one: null,
                street_address_two: null,
                city: null,
                state: null,
                zip_code: null,
            },
            test_information: {
                name: [],
                is_first_test: null,
                is_health_care_worker: null,
                has_symptom: null,
                symptom_date: null,
                is_hospitalized: null,
                is_in_icu: null,
                is_in_congregate_care: null,
                is_pregnant: null,
            },
            payment_method: {
                payment_type: "self",
                driver_license: null,
                payment_status: null,
                insurance_name: null,
                insurance_holder_name: null,
                insurance_id: null,
                insurance_group: null,
                insurance_document: null,
            },
            doctor_information: {
                client_id: null,
                facility_name: null,
                first_name: null,
                last_name: null,
                doctor_street_address_one: null,
                doctor_street_address_two: null,
                city: null,
                state: null,
                zip_code: null,
                phone_number: null,
            },
            samples: [
                {
                    test_type: null,
                    collected_on: null,
                    received_on: null,
                    type: null,
                    collected_by: null,
                },
            ],
        }, {
            bag: "ExistingPatientRequisitionForm",
            resetOnSuccess: true,
        })

        function addSample() {
            form.samples.push({
                test_type: null,
                collected_on: null,
                received_on: null,
                type: null,
                collected_by: null,
            })
        }

        function removeSample(index) {
            form.samples.splice(index, 1)
        }

        function resetForm() {
            form.reset()
        }

        const patients = getFromObject(props, "patients.data", [])

        const genderOptions = new Map()
        const raceOptions = new Map()
        const ethnicityOptions = new Map()
        const stateOptions = new Map()
        const testOptions = new Map()
        const pregnancyOptions = new Map()
        const paymentMethodOptions = new Map()
        const paymentStatusOptions = new Map()
        const yesNoOptions = new Map()
        const sampleTypeOptions = new Map()

        Object.keys(props.genderList).forEach(key => genderOptions.set(key, props.genderList[key]))
        Object.keys(props.raceList).forEach(key => raceOptions.set(key, props.raceList[key]))
        Object.keys(props.ethnicityList).forEach(key => ethnicityOptions.set(key, props.ethnicityList[key]))
        Object.keys(props.stateList).forEach(key => stateOptions.set(key, props.stateList[key]))
        Object.keys(props.pregnancyList).forEach(key => pregnancyOptions.set(key, props.pregnancyList[key]))
        Object.keys(props.testList).forEach(key => testOptions.set(key, props.testList[key]))
        Object.keys(props.paymentMethodList).forEach(key => paymentMethodOptions.set(key, props.paymentMethodList[key]))
        Object.keys(props.paymentStatusList).forEach(key => paymentStatusOptions.set(key, props.paymentStatusList[key]))
        Object.keys(props.sampleTypeList).forEach(key => sampleTypeOptions.set(key, props.sampleTypeList[key]))
        yesNoOptions.set(true, "Yes")
        yesNoOptions.set(false, "No")

        provide("raceOptions", raceOptions)
        provide("genderOptions", genderOptions)
        provide("ethnicityOptions", ethnicityOptions)
        provide("stateOptions", stateOptions)
        provide("pregnancyOptions", pregnancyOptions)
        provide("testOptions", testOptions)
        provide("paymentMethodOptions", paymentMethodOptions)
        provide("paymentStatusOptions", paymentStatusOptions)
        provide("yesNoOptions", yesNoOptions)
        provide("sampleTypeOptions", sampleTypeOptions)
        provide("form", form)
        provide("clients", props.clients)

        function fireSearch(e) {
            axios.get(route("admin.requisitions.existing-patient.filter"), {
                params: {
                    search: e.target.value,
                },
            }).then(response => {
                hasPatient.value = true
                patientDetails.value = getFromObject(response, "data.data")
                console.log(patientDetails)
            }).catch(error => {
                hasPatient.value = false
            })
        }

        watch(() => patientDetails.value, (newValue, oldValue) => {
            form.id = newValue.id
            form.basic_information.email = newValue.email
            form.basic_information.first_name = newValue.first_name
            form.basic_information.last_name = newValue.last_name
            form.basic_information.dob = newValue.dob_formatted
            form.basic_information.gender = newValue.gender
            form.basic_information.phone_number = newValue.phone_number
            form.basic_information.race = newValue.race
            form.basic_information.ethnicity = newValue.ethnicity
            form.basic_information.street_address_one = newValue.street_address_one
            form.basic_information.street_address_two = newValue.street_address_two
            form.basic_information.city = newValue.city
            form.basic_information.state = newValue.state
            form.basic_information.zip_code = newValue.zip_code
            console.log(currentId.value, newValue.id)
        })

        const onSearch = searchText => {
            let tmpArray = []
            props.patients.data.forEach(item => {
                if (item.name && item.name.toLowerCase().includes(searchText.toLowerCase())) {
                    tmpArray.push({
                        key: item.id,
                        value: item.name,
                        dob: item.dob,
                    })
                }
            })
            patientOptions.value = tmpArray
        }

        const onSelect = value => {
            let patient = props.patients.data.find(item => item.id === value)
            if (patient) {
                setPatient(patient)
            }
        }

        const rules = computed(() => ({
            relation: {
                required,
            }
        }))

        const v$ = useVuelidate(rules, form)

        function setPatient(patient) {
            form.guardian_id = patient.id
            form.basic_information.email = null
            form.basic_information.first_name = null
            form.basic_information.last_name = null
            form.basic_information.dob = null
            form.basic_information.gender = null
            form.basic_information.phone_number = patient.phone_number
            form.basic_information.race = null
            form.basic_information.ethnicity = null
            form.basic_information.street_address_one = patient.street_address_one
            form.basic_information.street_address_two = patient.street_address_two
            form.basic_information.city = patient.city
            form.basic_information.state = patient.state
            form.basic_information.zip_code = patient.zip_code

            hasPatient.value = true
            appState.guardianId = patient.id
        }

        return {
            v$,
            patients,
            resetForm,
            onSearch,
            onSelect,
            patientOptions,
            fireSearch,
            hasPatient,
            form,
            requisitionIndexUrl,
            saveExistingPatientUrl,
            addSample,
            removeSample,
            getFromObject,
        }
    },
    methods: {
        submitForm() {
            this.v$.$touch()

            this.$nextTick(() => {
                const inputError = document.querySelectorAll('.input-error')[0]
                if (inputError) {
                    scrollToSmoothly(inputError.offsetTop - 84);
                }
            })

            if (this.v$.$error) return

            this.form.post(route("admin.requisitions.form.family-member.store"))
        },

        fireLocalSearch: function (value) {
            this.showSearchResults = true
            if (this.searchValues.length === 1) {
                this.resetForm()
                this.setPatient(this.searchValues[0])
            }
        },

        searchPatient: function () {
            const search = Object.values(this.patients).find(item => {
                return item.name.toLowerCase() === this.searchText.toLowerCase()
            })
            if (search) {
                this.resetForm()
                this.setPatient(search)
            }
        },

        // setPatient(patient) {
        //     this.form.id = patient.id
        //     this.form.basic_information.email = patient.email
        //     this.form.basic_information.first_name = patient.first_name
        //     this.form.basic_information.last_name = patient.last_name
        //     this.form.basic_information.dob = patient.dob_formatted
        //     this.form.basic_information.gender = patient.gender
        //     this.form.basic_information.phone_number = patient.phone_number
        //     this.form.basic_information.race = patient.race
        //     this.form.basic_information.ethnicity = patient.ethnicity
        //     this.form.basic_information.street_address_one = patient.street_address_one
        //     this.form.basic_information.street_address_two = patient.street_address_two
        //     this.form.basic_information.city = patient.city
        //     this.form.basic_information.state = patient.state
        //     this.form.basic_information.zip_code = patient.zip_code
        //
        //     this.hasPatient = true
        //     this.showSearchResults = false
        // },
        handleSearchClick(value) {
            this.setPatient(value)
        },
    },
    computed: {
        searchValues: function () {
            return Object.values(this.patients).filter(item => {
                return item.name.toLowerCase().includes(this.searchText.toLowerCase())
            })
        },
    },
}
</script>

<style scoped>

</style>


<template>
    <!--suppress HtmlFormInputWithoutLabel -->
    <input
        ref="input"
        class="
            form-control
            p-3
            placeholder-grey-400
            text-grey-900
            bg-white
            text-sm
            outline-none
            border border-grey-300
            focus:border-tertiary-100
            focus:outline-none
            w-full
            transition
            transition-all
            duration-150
        "
        :value="modelValue"
        :type="type"
        v-bind="$attrs"
        @input="$emit('update:modelValue', $event.target.value)"
    />
</template>

<script>
    export default {
        name: "InputComponent",

        props: {
            modelValue: { type: [Number, String], required: false, default: null },
            type: { type: String, required: false, default: "text" },
        },

        emits: ["update:modelValue"],

        methods: {
            focus() {
                this.$refs.input.focus()
            },
        },
    }
</script>

<style lang="scss">
    .form-control {
        border-radius: 8px;

        &:focus,
        &:focus-visible {
            outline: none;
        }
    }
</style>

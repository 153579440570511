<template>
    <admin-layout :if-has-breadcrumb="true" active-link="requisitions">
        <template #breadcrumb>
            <bread-crumb>
                <bread-crumb-item :href="requisitionIndexUrl" label="Requisitions"/>
                <bread-crumb-item label="Add New Patient Sample"/>
            </bread-crumb>
        </template>
        <div class="card--large">
            <h1 class="heading-primary !text-grey-1100 pb-8" v-text="'Add Requisitions for New Patient'"/>
            <form @submit.prevent="submitForm">
                <div class="grid gap-10">
                    <basic-information-new
                        v-model="form.basic_information"
                        :errors="form.errors"
                        :show-buttons="false"
                        @focusout="v.$touch"
                        @check-existing="checkExistingUser"
                    />
                    <test-booking-new v-model="form.test_information" :errors="form.errors"
                                      :show-appointment-date="false"/>
                    <doctor-information v-model="form.doctor_information"/>
                    <payment-method-new v-model="form.payment_method" :errors="form.errors"/>
                    <div>
                        <div class="form-header">
                            <Icon name="sample-2"/>
                            <h3 class="heading-primary heading-primary-sm" v-text="'Sample'"/>
                        </div>

                        <requisition-sample v-for="(sample, sampleIndex) in form.samples"
                                            :key="sampleIndex"
                                            :index="sampleIndex"
                                            :sample="sample"
                                            :name="'samples.' + sampleIndex"
                                            :sample-count="form.samples.length"
                                            :errors="form.errors"
                                            @input="form.samples[sampleIndex] = $event"
                                            @add="addSample"
                                            @remove="removeSample(sampleIndex)"
                        />

                    </div>
                </div>
                <form-footer>
                    <cancel-button :inertia-link="true" :href="requisitionIndexUrl" v-text="'Cancel'"/>
                    <primary-button @click="submitForm" class="btn" :disabled="form.processing">
                        <span v-if="form.processing">Creating Requisition</span>
                        <span v-else>Create Requisition</span>
                    </primary-button>
                </form-footer>
            </form>
        </div>
    </admin-layout>
    <modal class="modal" :show="showPatientExistsModal"
           @close="closePatientExistsModal">
        <template v-slot:title>
            <h2 class="heading-primary heading-primary-md text-center !text-xl !text-left">Patient Exists</h2>
        </template>
        <div class="grid gap-4">
            <div>
                Patient already exists for this name and email address
            </div>
        </div>
    </modal>
</template>

<script>
    import { useForm }              from "@inertiajs/inertia-vue3"
    import { useVuelidate }         from "@vuelidate/core"
    import { get as getFromObject } from "lodash"
    import { provide, ref }              from "vue"
    import BreadCrumb               from "../../../components/Admin/BreadCrumb/BreadCrumb"
    import BreadCrumbItem           from "../../../components/Admin/BreadCrumb/BreadCrumbItem"
    import CancelButton             from "../../../components/Admin/Buttons/CancelButton"
    import PrimaryButton            from "../../../components/Admin/Buttons/PrimaryButton"
    import SecondaryButton          from "../../../components/Admin/Buttons/SecondaryButton"
    import CardHeader               from "../../../components/Admin/General/CardHeader"
    import Icon                     from "../../../components/Admin/General/Icon"
    import AdminLayout              from "../../../components/Admin/Layout/AdminLayout"
    import Modal                    from "../../../components/Admin/Modal"
    import BasicInformationNew      from "./Partials/BasicInformationNew"
    import PaymentMethodNew         from "./Partials/PaymentMethodNew"
    import RequisitionSample        from "./Partials/RequisitionSample"
    import TestBookingNew           from "./Partials/TestBookingNew"
    import DoctorInformation        from "./Partials/DoctorInformation"
    import FormFooter               from "../../../components/Admin/Layout/FormFooter"
    import scrollToSmoothly         from "../../../script"
    import {message as AMessage} from 'ant-design-vue';

    export default {
        name: "NewPatientRequisition",
        components: {
            Modal,
            FormFooter,
            DoctorInformation,
            CancelButton,
            RequisitionSample,
            SecondaryButton,
            BreadCrumbItem,
            BreadCrumb,
            PaymentMethodNew,
            TestBookingNew,
            BasicInformationNew,
            AdminLayout,
            PrimaryButton,
            CardHeader,
            Icon,
            AMessage
        },
        props: {
            raceList: { type: Object, required: true },
            genderList: { type: Object, required: true },
            ethnicityList: { type: Object, required: true },
            stateList: { type: Object, required: true },
            testList: { type: Object, required: true },
            pregnancyList: { type: Object, required: true },
            paymentMethodList: { type: Object, required: true },
            paymentStatusList: { type: Object, required: true },
            sampleTypeList: { type: Object, required: true },
            clients: { type: Object, required: false, default: () => ({}) },
        },
        setup(props) {
            const acceptPatientExist = ref(false)
            const showPatientExistsModal = ref(false)
            const requisitionIndexUrl = route("admin.requisitions.index")
            const saveNewPatientUrl = route("admin.requisitions.form.new-patient.store")
            const form = useForm("NewPatientRequisitionForm", {
                basic_information: {
                    email: null,
                    first_name: null,
                    last_name: null,
                    dob: null,
                    gender: null,
                    phone_number: null,
                    race: null,
                    ethnicity: null,
                    street_address_one: null,
                    street_address_two: null,
                    city: null,
                    state: null,
                    zip_code: null,
                },
                test_information: {
                    appointment_date: null,
                    appointment_time: null,
                    name: [],
                    is_first_test: null,
                    is_health_care_worker: null,
                    has_symptom: null,
                    symptom_date: null,
                    is_hospitalized: null,
                    is_in_icu: null,
                    is_in_congregate_care: null,
                    is_pregnant: null,
                },
                doctor_information: {
                    client_id: null,
                    facility_name: null,
                    first_name: null,
                    last_name: null,
                    doctor_street_address_one: null,
                    doctor_street_address_two: null,
                    city: null,
                    state: null,
                    zip_code: null,
                    phone_number: null,
                },
                payment_method: {
                    payment_type: "self",
                    driver_license: null,
                    payment_status: null,
                    insurance_name: null,
                    insurance_holder_name: null,
                    insurance_id: null,
                    insurance_group: null,
                    insurance_document: [],
                },
                samples: [
                    {
                        test_type: null,
                        collected_on: null,
                        received_on: null,
                        type: null,
                        collected_by: null,
                    },
                ],
            })

            function addSample() {
                form.samples.push({})
            }

            function removeSample(index) {
                form.samples.splice(index, 1)
            }

            function checkExistingUser(data) {
                axios.post("/api/check-existing-patient", data).then(response => {
                    // const hide = AMessage.error("Patient already exists for given name and email",  0)
                    // setTimeout(hide, 2500);
                    showPatientExistsModal.value = true
                }).catch(error => console.log(error))
            }

            const genderOptions = new Map()
            const raceOptions = new Map()
            const ethnicityOptions = new Map()
            const stateOptions = new Map()
            const testOptions = new Map()
            const pregnancyOptions = new Map()
            const paymentMethodOptions = new Map()
            const paymentStatusOptions = new Map()
            const yesNoOptions = new Map()
            const sampleTypeOptions = new Map()

            Object.keys(props.genderList).forEach(key => genderOptions.set(key, props.genderList[key]))
            Object.keys(props.raceList).forEach(key => raceOptions.set(key, props.raceList[key]))
            Object.keys(props.ethnicityList).forEach(key => ethnicityOptions.set(key, props.ethnicityList[key]))
            Object.keys(props.stateList).forEach(key => stateOptions.set(key, props.stateList[key]))
            Object.keys(props.pregnancyList).forEach(key => pregnancyOptions.set(key, props.pregnancyList[key]))
            Object.keys(props.testList).forEach(key => testOptions.set(key, props.testList[key]))
            Object.keys(props.paymentMethodList).forEach(key => paymentMethodOptions.set(key, props.paymentMethodList[key]))
            Object.keys(props.paymentStatusList).forEach(key => paymentStatusOptions.set(key, props.paymentStatusList[key]))
            Object.keys(props.sampleTypeList).forEach(key => sampleTypeOptions.set(key, props.sampleTypeList[key]))

            yesNoOptions.set(true, "Yes")
            yesNoOptions.set(false, "No")

            provide("raceOptions", raceOptions)
            provide("genderOptions", genderOptions)
            provide("ethnicityOptions", ethnicityOptions)
            provide("stateOptions", stateOptions)
            provide("pregnancyOptions", pregnancyOptions)
            provide("testOptions", testOptions)
            provide("paymentMethodOptions", paymentMethodOptions)
            provide("paymentStatusOptions", paymentStatusOptions)
            provide("yesNoOptions", yesNoOptions)
            provide("sampleTypeOptions", sampleTypeOptions)
            provide("form", form)
            provide("clients", props.clients)

            const v = useVuelidate()

            const closePatientExistsModal = () => {
                showPatientExistsModal.value = false
                acceptPatientExist.value = true
            }

            return {
                v,
                form,
                requisitionIndexUrl,
                saveNewPatientUrl,
                addSample,
                removeSample,
                getFromObject,
                checkExistingUser,
                showPatientExistsModal,
                closePatientExistsModal
            }
        },
        methods: {
            getErrors(index) {
                console.log(this.form.errors?.samples)
            },
            submitForm() {
                if (this.form.processing) return

                this.v.$touch()

                this.$nextTick(() => {
                    const inputError = document.querySelectorAll(".input-error")[0]
                    if (inputError) {
                        scrollToSmoothly(inputError.offsetTop - 84)
                    }
                })

                if (this.v.$error) return

                this.form.post(this.saveNewPatientUrl)
            },
        },
    }
</script>

<style scoped>

</style>

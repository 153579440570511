<template>
    <label class="block capitalize text-grey-500 text-sm mb-2 font-bold">
        <span v-if="value">{{ value }}</span>
        <span v-else><slot/></span>
    </label>
</template>

<script>
    export default {
        name: "LabelComponent",
        props: {
            value: { type: String, required: false, default: null },
        },
    }

</script>

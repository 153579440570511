<template>
    <Multiselect
        v-model="optionsData.value"
        v-bind="optionsData"
        label="name"
        @input="handleOnInput"
        :placeholder="placeHolder"
        @select="handleOnSelect"
        :searchable="searchable"
        trackBy="name"
        :disabled="disabled"
    />
</template>

<script>
import Multiselect from "@vueform/multiselect"
import {reactive} from "vue"

export default {
    name: "SelectInput",
    components: {Multiselect},
    props: {
        modelValue: {type: [Number, String], required: false, default: null},
        placeHolder: {type: String, required: false, default: ""},
        options: {type: [Object, Map], required: true},
        searchable: {type: Boolean, required: false},
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup() {
        const optionsData = reactive({
            value: 0,
            options: [],
        })

        return {
            optionsData
        }
    },
    watch: {
        modelValue: {
            handler(value) {
                this.optionsData.options = []
                const arr = Object.fromEntries(this.options)
                for (const key in arr) {
                    if (arr.hasOwnProperty(key)) {
                        this.optionsData.options.push(
                            {
                                name: arr[key],
                                value: key,
                            },
                        )
                    }
                }
                this.optionsData.value = value
            },
            deep: true,
            immediate: true,
        }
    },

    mounted() {
        this.optionsData.options = []
        this.optionsData.value = this.modelValue;
        const arr = Object.fromEntries(this.options)
        for (const key in arr) {
            if (arr.hasOwnProperty(key)) {
                this.optionsData.options.push(
                    {
                        name: arr[key],
                        value: key,
                    },
                )
            }
        }
    },

    methods: {
        handleOnInput() {
            this.$emit("update:modelValue", this.optionsData.value)
        },

        handleOnSelect() {
            this.$emit("update:modelValue", this.optionsData.value)
        },
    },
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

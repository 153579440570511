<template>
    <!--suppress HtmlFormInputWithoutLabel -->
    <input ref="input"
           class="form-control
            p-3
            placeholder-grey-400
            text-grey-900
            bg-white
            text-sm
            outline-none
            border border-grey-300
            focus:border-secondary-100
            focus:outline-none
            w-full
            transition
            transition-all
            duration-150"
           :value="modelValue"
           :type="type"
           :disabled="disabled"
           v-bind="$attrs"
           @focusout="$emit('focusout')"
           @input="$emit('update:modelValue', $event.target.value)">
</template>

<script>
    export default {
        name: "InputComponent",

        props: {
            modelValue: { type: [Number, String], required: false, default: null },
            type: { type: String, required: false, default: "text" },
            disabled: { type: Boolean, required: false, default: false }
        },

        emits: ["update:modelValue", "focusout"],

        methods: {
            focus() {
                this.$refs.input.focus()
            },
        },
    }
</script>

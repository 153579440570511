<template>
    <modal class="modal--req-delete" :show="show"
           @close="closeRequisitionDeleteModal">
        <template v-slot:title>
            <h2 class="heading-primary heading-primary-md text-center !text-xl !text-left">
                Are you sure want to delete this requisition?
            </h2>
        </template>
        <div class="grid gap-4">
            <div>
                <label class="block text-sm pb-1.5">
                    Please type <b>delete</b> to confirm.
                </label>
                <input-component v-model="modalInput" class="!w-3/5 !py-2 !rounded-md"/>
            </div>
            <button-component
                class="!px-4 bg-red-100  !text-red-600 !rounded-md text-left !cursor-default"
                :class="modalClass"
                @click="deleteRequisition"
            >
                    I understand the consequences, delete this requisition.
            </button-component>
        </div>
    </modal>
</template>

<script>
    import Modal           from "../../../../components/Admin/Modal"
    import InputComponent  from "../../../../components/Admin/Forms/Input"
    import ButtonComponent from "../../../../components/Admin/Buttons/Button"
    import { ref, computed } from "vue"

    export default {
        name: "DeleteRequisitionModal",
        components: {
            ButtonComponent,
            InputComponent,
            Modal,
        },
        props: {
            show: {
                type: Boolean,
                required: false,
                default: false,
            }
        },
        emits: ['close', 'delete'],
        setup(props, {emit}) {
            const showRequisitionDeleteModal = ref(false)
            const deleteText = "delete"
            const modalInput = ref("")

            const modalClass = computed(() => {
                if(modalInput.value === "delete") {
                    return 'bg-red-200 !text-red-700 !cursor-pointer'
                }
                return ''
            })

            const closeRequisitionDeleteModal = () => {
                modalInput.value = ""
                emit('close')
            }

            const deleteRequisition = () => {
                if(modalInput.value === "delete") {
                    modalInput.value = ""
                    emit('delete')
                }
            }

            return {
                modalInput,
                modalClass,
                showRequisitionDeleteModal,
                closeRequisitionDeleteModal,
                deleteText,
                deleteRequisition,
            }
        },
    }
</script>

<style scoped>

</style>

<template>
    <button-component :inertia-link="inertiaLink"
                      :type="type"
                      :method="method"
                      :href="href"
                      :disabled="disabled"
                      class="bg-tertiary-100 hover:bg-tertiary-200 active:bg-tertiary-200 !text-white !py-2.5 rounded-xl px-8"
                      v-bind="$attrs">
        <slot/>
    </button-component>
</template>

<script>
    import ButtonComponent from "./Button"

    export default {
        name: "PrimaryButton",
        inheritAttrs: false,

        components: { ButtonComponent },

        props: {
            inertiaLink: { type: Boolean, required: false, default: true },
            type: { type: String, required: false, default: "button" },
            method: { type: String, required: false, default: "get" },
            href: { type: String, required: false, default: null },
            disabled: { type: Boolean, required: false, default: false },
        },
    }
</script>

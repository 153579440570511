<template>
    <div class="form-header flex items-center pb-4 mb-6">
        <Icon class="mr-3 !w-7 h-7" name="covid-test-booking-1"/>
        <h3 class="heading-primary heading-primary-sm" v-text="'Covid Test Booking'"/>
    </div>
    <div class="grid gap-8">
        <div class="hr-line">
            <h3 class="heading-secondary-sm pb-6" v-text="'Test Information'"/>
            <div class="grid md:grid gap-7">
                <div :class="(v$.name.$error || getFromObject(errors, 'test_information.name')) ? 'form-group input-error' : 'form-group'">
                    <div class="grid gap-4 sm:flex  justify-between sm:items-center">
                        <label-component class="!mb-0" :value="'Test Name'"/>
                        <div>
                            <check-box-group-input
                                class="!pt-0 gap-4 custom-checkbox custom-checkbox--secondary  custom-checkbox-single"
                                name="test-name"
                                :options="testOptions"
                                v-model="name"/>
                        </div>
                    </div>
                    <div class="text-right">
                        <input-error class="text-right"
                                     v-if="getFromObject(errors, 'test_information.name')"
                                     :message="getFromObject(errors, 'test_information.name')"/>
                        <input-error class="text-right"
                                     v-if="v$.name.$error"
                                     :message="v$.name.$errors[0].$message"/>
                    </div>
                </div>

                <div  :class="v$.is_first_test.$error ? 'form-group input-error' : 'form-group'">
                    <div class="grid gap-4 sm:flex  justify-between sm:items-center">
                        <label-component class="!mb-0"
                                         :value="'First Test'"/>
                        <div>
                            <radio-group-input
                                class="!grid !gap-6 sm:!gap-3 !grid-cols-2 !pt-0"
                                name="is-first-test"
                                :options="yesNoOptions"
                                v-model="is_first_test"/>

                        </div>
                    </div>
                    <input-error class="text-right"
                                 v-if="v$.is_first_test.$error"
                                 :message="v$.is_first_test.$errors[0].$message"/>
                </div>
                <div :class="(v$.is_health_care_worker.$error || getFromObject(errors, 'test_information.is_health_care_worker')) ? 'form-group input-error' : 'form-group'">
                    <div class="grid gap-4 sm:flex  justify-between sm:items-center">
                        <label-component class="!mb-0"
                                         :value="'Health Care Workers'"/>
                        <radio-group-input
                            class="!grid !gap-6 sm:!gap-3  !grid-cols-2 !pt-0"
                            name="health-care-workers"
                            :options="yesNoOptions"
                            v-model="is_health_care_worker"/>


                    </div>
                    <input-error class="text-right"
                                 v-if="getFromObject(errors, 'test_information.is_health_care_worker')"
                                 :message="getFromObject(errors, 'test_information.is_health_care_worker')"/>
                    <input-error class="text-right"
                                 v-if="v$.is_health_care_worker.$error"
                                 :message="v$.is_health_care_worker.$errors[0].$message"/>
                </div>

                <div :class="(v$.has_symptom.$error || getFromObject(errors, 'test_information.has_symptom')) ? 'form-group input-error' : 'form-group'">
                    <div class="grid gap-4 sm:flex  justify-between sm:items-center">
                        <label-component class="!mb-0" :value="'Symptoms'"/>

                        <radio-group-input
                            class="!grid !gap-6 sm:!gap-3  !grid-cols-2 !pt-0"
                            name="symptom"
                            :options="yesNoOptions"
                            v-model="has_symptom"
                            @change="resetSymptoms"/>


                    </div>
                    <input-error class="text-right"
                                 v-if="getFromObject(errors, 'test_information.has_symptom')"
                                 :message="getFromObject(errors, 'test_information.has_symptom')"/>
                    <input-error class="text-right"
                                 v-if="v$.has_symptom.$error"
                                 :message="v$.has_symptom.$errors[0].$message"/>
                </div>
                <div v-if="has_symptom"
                     :class="(v$.symptom_date.$error || getFromObject(errors, 'test_information.symptom_date')) ? 'form-group input-error' : 'form-group'">
                    <div class="grid gap-4 sm:flex  justify-between sm:items-center">
                        <label-component class="!mb-0"
                                         :value="'Symptoms onset date'"/>
                        <date-picker name="symptom-date"
                                     v-model="symptom_date"
                                     mode="date"
                                     :max-date="currentDate"
                                     :model-config="datePickerConfig"
                                     :popover="{ visibility: 'focus' }"
                                     :masks="{ input: 'MM-DD-YYYY'}">
                            <template v-slot="{ inputValue, inputEvents }">
                                <div class="form-icon form-icon-no-border">
                                    <Icon name="date"/>
                                    <input-component
                                        :value="inputValue"
                                        v-on="inputEvents"
                                        placeholder="Select Date"
                                        class="!border-0 !w-28 pl-8 !pr-0 !py-0"
                                    />
                                </div>
                            </template>
                        </date-picker>

                    </div>
                    <input-error class="text-right"
                                 v-if="getFromObject(errors, 'test_information.symptom_date')"
                                 :message="getFromObject(errors, 'test_information.symptom_date')"/>
                    <input-error class="text-right"
                                 v-if="v$.symptom_date.$error"
                                 :message="v$.symptom_date.$errors[0].$message"/>
                </div>

                <div :class="(v$.is_hospitalized.$error || getFromObject(errors, 'test_information.is_hospitalized')) ? 'form-group input-error' : 'form-group'">
                    <div class="grid gap-4 sm:flex  justify-between sm:items-center">
                        <label-component class="!mb-0" :value="'Hospitalized'"/>
                        <radio-group-input
                            class="!grid !gap-6 sm:!gap-3 !grid-cols-2 !pt-0"
                            name="hospitalized"
                            :options="yesNoOptions"
                            v-model="is_hospitalized"
                        />
                    </div>
                    <input-error class="text-right"
                                 v-if="getFromObject(errors, 'test_information.is_hospitalized')"
                                 :message="getFromObject(errors, 'test_information.is_hospitalized')"/>
                    <input-error class="text-right"
                                 v-if="v$.is_hospitalized.$error"
                                 :message="v$.is_hospitalized.$errors[0].$message"/>
                </div>

                <div :class="(v$.is_in_icu.$error || getFromObject(errors, 'test_information.is_in_icu')) ? 'form-group input-error' : 'form-group'">
                    <div class="grid gap-4 sm:flex  justify-between sm:items-center">
                        <label-component class="!mb-0" :value="'ICU'"/>
                        <radio-group-input
                            class="!grid !gap-6 sm:!gap-3 !grid-cols-2 !pt-0"
                            name="icu"
                            :options="yesNoOptions"
                            v-model="is_in_icu"/>
                    </div>
                    <input-error class="text-right"
                                 v-if="getFromObject(errors, 'test_information.is_in_icu')"
                                 :message="getFromObject(errors, 'test_information.is_in_icu')"/>
                    <input-error class="text-right"
                                 v-if="v$.is_in_icu.$error"
                                 :message="v$.is_in_icu.$errors[0].$message"/>
                </div>
                <div :class="(v$.is_in_congregate_care.$error || getFromObject(errors, 'test_information.is_in_congregate_care')) ? 'form-group input-error' : 'form-group'">
                    <div class="grid gap-4 sm:flex  justify-between sm:items-center">
                        <label-component class="!mb-0" :value="'Are you living in congregate care setting?'"/>
                        <radio-group-input
                            class="!gap-6 sm:!gap-3 !grid-cols-2 !pt-0"
                            name="congregate-care "
                            :options="yesNoOptions"
                            v-model="is_in_congregate_care"/>
                    </div>
                    <input-error class="text-right"
                                 v-if="getFromObject(errors, 'test_information.is_in_congregate_care')"
                                 :message="getFromObject(errors, 'test_information.is_in_congregate_care')"/>
                    <input-error class="text-right"
                                 v-if="v$.is_in_congregate_care.$error"
                                 :message="v$.is_in_congregate_care.$errors[0].$message"/>
                </div>

                <div v-show="!isMale" :class="(v$.is_pregnant.$error || getFromObject(errors, 'test_information.is_pregnant')) ? 'form-group input-error' : 'form-group'">
                    <div class="grid gap-4 sm:flex  justify-between sm:items-center">
                        <label-component class="!mb-0" :value="'Are you Pregnant?'"/>
                        <radio-group-input class="!pt-0"
                                           name="pregnant"
                                           :options="pregnancyOptions"
                                           v-model="is_pregnant"/>

                    </div>
                    <input-error class="text-right"
                                 v-if="getFromObject(errors, 'test_information.is_pregnant')"
                                 :message="getFromObject(errors, 'test_information.is_pregnant')"/>
                    <input-error class="text-right"
                                 v-if="v$.is_pregnant.$error"
                                 :message="v$.is_pregnant.$errors[0].$message"/>
                </div>
            </div>
        </div>
    </div>
    <form-footer v-if="showButtons">
        <cancel-button @click="goBack"><span v-if="showCancel">Cancel</span><span v-else>Back</span></cancel-button>
        <primary-button @click="nextPage">Next</primary-button>
    </form-footer>
</template>

<script>
import {useVuelidate} from "@vuelidate/core"
import {helpers, required,} from "@vuelidate/validators"
import {get as getFromObject} from "lodash"
import {DatePicker} from "v-calendar"
import {computed, inject, reactive, ref, toRefs, watch} from "vue"
import CancelButton from "../../../../components/Front/Buttons/CancelButton"
import PrimaryButton from "../../../../components/Front/Buttons/PrimaryButton"
import DropDownInput from "../../../../components/Front/Forms/DropDownInput"
import InputComponent from "../../../../components/Front/Forms/Input"
import InputError from "../../../../components/Front/Forms/InputError"
import LabelComponent from "../../../../components/Front/Forms/Label"
import RadioGroupInput from "../../../../components/Front/Forms/RadioGroupInput"
import SelectInput from "../../../../components/Front/Forms/SelectInput"
import TimePicker from "../../../../components/Front/Forms/TimePicker"
import CheckBoxGroupInput from "../../../../components/Front/Forms/CheckBoxGroupInput"
import H3 from "../../../../components/Front/typography/H3"
import CardHeader from "../../../../components/Front/General/CardHeader"
import Header from "../../../../components/Front/Layout/Header"
import SidebarRegistration from "../../../../components/Front/Layout/SidebarRegistration"
import Icon from "../../../../components/Front/General/Icon"
import FormFooter from "../../../../components/Front/Layout/FormFooter";
import scrollToSmoothly from "../../../../script";

export default {
    name: "TestBooking.vue",
    components: {
        FormFooter, H3,
        CardHeader,
        PrimaryButton,
        CancelButton,
        TimePicker,
        SelectInput,
        InputError,
        DatePicker,
        SidebarRegistration,
        Header,
        InputComponent,
        LabelComponent,
        DropDownInput,
        RadioGroupInput,
        CheckBoxGroupInput,
        Icon,
    },
    props: {
        modelValue: {type: Object, required: false, default: () => ({})},
        doctorInformation: {type: Object, required: false, default: () => ({})},
        errors: {type: Object, required: false, default: () => ({})},
        showButtons: {type: Boolean, required: false, default: false},
        showAppointmentDate: {type: Boolean, required: false, default: true},
        isMale: {type: Boolean, required: false, default: false},
        showCancel: {type: Boolean, required: false, default: false}
    },
    inheritAttrs: false,
    emits: ["next", "back", "update:modelValue", "update:doctorInformation"],
    setup(props, {emit}) {
        const testOptions = inject("testOptions")
        const pregnancyOptions = inject("pregnancyOptions")
        const yesNoOptions = inject("yesNoOptions")
        const stateOptions = inject("stateOptions")

        const currentDate = new Date()

        const datePickerConfig = {
            type: "string",
            mask: "MM-DD-YYYY", // Uses 'iso' if missing
        }
        const dateTimePickerConfig = {
            type: "string",
            mask: "MM/DD/YYYY h:mmA", // Uses 'iso' if missing
        }

        const appState = reactive({
            appointment_date: null,
            name: [],
            is_first_test: null,
            is_health_care_worker: null,
            has_symptom: null,
            symptom_date: null,
            is_hospitalized: null,
            is_in_icu: null,
            is_in_congregate_care: null,
            is_pregnant: null,
            ordering_facility: null,
            doctor_first_name: null,
            doctor_last_name: null,
            doctor_street_address_one: null,
            doctor_street_address_two: null,
            city: null,
            state: null,
            zip_code: null,
            phone_number: null,
        })

        const resultValue = computed(() => {
            return {
               // appointment_date: appState.appointment_date,
                name: appState.name ?? [],
                is_first_test: appState.is_first_test,
                is_health_care_worker: appState.is_health_care_worker,
                has_symptom: appState.has_symptom,
                symptom_date: appState.symptom_date,
                is_hospitalized: appState.is_hospitalized,
                is_in_icu: appState.is_in_icu,
                is_in_congregate_care: appState.is_in_congregate_care,
                is_pregnant: appState.is_pregnant,
                doctor_information: {
                    ordering_facility: appState.ordering_facility,
                    doctor_first_name: appState.doctor_first_name,
                    doctor_last_name: appState.doctor_last_name,
                    doctor_street_address_one: appState.doctor_street_address_one,
                    doctor_street_address_two: appState.doctor_street_address_two,
                    city: appState.city,
                    state: appState.state,
                    zip_code: appState.zip_code,
                    phone_number: appState.phone_number,
                },
            }
        })

        watch(resultValue, () => {
            emit("update:modelValue", resultValue.value)
        })

        let {modelValue} = toRefs(props)
        // console.log(modelValue.value);

        watch(() => modelValue.value, (newValue, oldValue) => {
           // appState.appointment_date = newValue.appointment_date
            appState.name = newValue.name
            appState.is_first_test = newValue.is_first_test
            appState.is_health_care_worker = newValue.is_health_care_worker
            appState.has_symptom = newValue.has_symptom
            appState.symptom_date = newValue.symptom_date
            appState.is_hospitalized = newValue.is_hospitalized
            appState.is_in_icu = newValue.is_in_icu
            appState.is_in_congregate_care = newValue.is_in_congregate_care
            appState.is_pregnant = newValue.is_pregnant
            appState.ordering_facility = newValue.doctor_information.ordering_facility
            appState.doctor_first_name = newValue.doctor_information.doctor_first_name
            appState.doctor_last_name = newValue.doctor_information.doctor_last_name
            appState.doctor_street_address_one = newValue.doctor_information.doctor_street_address_one
            appState.doctor_street_address_two = newValue.doctor_information.doctor_street_address_two
            appState.city = newValue.doctor_information.city
            appState.state = newValue.doctor_information.state
            appState.zip_code = newValue.doctor_information.zip_code
            appState.phone_number = newValue.doctor_information.phone_number

        }, {deep: true, immediate: true})

        function resetSymptoms() {
            if (!appState.has_symptom) {
                appState.symptom_date = null
            }
        }

        const hasSymptom = () => {
            if (!appState.has_symptom) {
                return true
            }

            return !!appState.symptom_date
        }
        const isMale = ref(false)
        watch(() => props.isMale, (nVal, oVal) => {
            isMale.value = nVal
        }, {
            deep: true,
            immediate: true,
        })

        const hasPregnant = () => {
            if (isMale.value === true) {
                return true
            }
            return !!appState.is_pregnant
        }

        const hasDoctorInformation = (value) => {
            if (!appState.ordering_facility) {
                return true
            }

            return !!value
        }
        // const appointmentTimeLimitValidation = (value) => {
        //     const parseDate = new Date(Date.parse(value.replace(/[AP]/, " $&")));
        //     const start_time = '9'
        //     const end_time = '17'
        //     if (parseDate.getHours() >= start_time && parseDate.getHours() <= end_time) {
        //         if (parseDate.getHours() === 17) {
        //             if (parseDate.getMinutes() === 0) {
        //                 return !!value
        //             } else {
        //                 return false
        //             }
        //         }
        //         return !!value
        //     }
        //     return false
        // }

        const rules = computed(() => ({
            // appointment_date: {
            //     required,
            //     requiredIf: helpers.withMessage("Please select time in between 9am - 5pm", appointmentTimeLimitValidation),
            // },
            name: {
                required,
            },
            is_first_test: {
                required,
            },
            is_health_care_worker: {
                required,
            },
            has_symptom: {
                required,
            },
            symptom_date: {
                requiredIf: helpers.withMessage("Required", hasSymptom),
            },
            is_hospitalized: {
                required,
            },
            is_in_icu: {
                required,
            },
            is_in_congregate_care: {
                required,
            },
            is_pregnant: {
                requiredIf: helpers.withMessage("Required", hasPregnant),
            },
            doctor_first_name: {
                requiredIfOrderingFacility: helpers.withMessage("Required when ordering facility is entered.", hasDoctorInformation),
            },
            doctor_last_name: {
                requiredIfOrderingFacility: helpers.withMessage("Required when ordering facility is entered.", hasDoctorInformation),
            },
            doctor_street_address_one: {
                requiredIfOrderingFacility: helpers.withMessage("Required when ordering facility is entered.", hasDoctorInformation),
            },
            doctor_street_address_two: {},
            city: {
                requiredIfOrderingFacility: helpers.withMessage("Required when ordering facility is entered.", hasDoctorInformation),
            },
            state: {
                requiredIfOrderingFacility: helpers.withMessage("Required when ordering facility is entered.", hasDoctorInformation),
            },
            zip_code: {
                requiredIfOrderingFacility: helpers.withMessage("Required when ordering facility is entered.", hasDoctorInformation),
            },
            phone_number: {
                requiredIfOrderingFacility: helpers.withMessage("Required when ordering facility is entered.", hasDoctorInformation),
            },
        }))

        const v$ = useVuelidate(rules, appState)

        return {
            ...toRefs(appState),
            currentDate,
            dateTimePickerConfig,
            datePickerConfig,
            resetSymptoms,
            hasSymptom,
            v$,
            getFromObject,
            testOptions,
            pregnancyOptions,
            yesNoOptions,
            stateOptions,
        }
    },
    methods: {
        nextPage() {
            if (this.isMale) {
                this.is_pregnant = "no"
            }

            this.v$.$touch()

            this.$nextTick(() => {
              const inputError = document.querySelectorAll('.input-error')[0]
              if (inputError) {
                scrollToSmoothly(inputError.offsetTop - 84);
              }
            })

            if (this.v$.$error) return

            this.$emit("next")
        },
        goBack() {
            this.$emit("back")
        },
    },
}
</script>

<style scoped>

</style>

<template>
    <admin-layout active-link="requisitions">
        <div class="card--large !p-0">
            <!-- title and dropdown -->
            <div class="table-head-container">
                <h1 class="heading-primary !text-grey-1100" v-text="'Requisitions'"/>
                <div class="flex items-center gap-4">
                    <requisition-list-filter-dropdown :date-range="dateRange"/>
                    <create-requisition-dropdown/>
                </div>
            </div>

            <!-- table -->
            <div class="overflow-x-auto table-tooltip-break"
             style="min-height: 200px;">
                <requisition-list-table/>
            </div>
        </div>
    </admin-layout>
    <delete-requisition-modal
        :show="showRequisitionDeleteModal"
        @close="closeRequisitionDeleteModal"
        @delete="deleteRequisition"
    />
</template>

<script>
    import { SearchOutlined } from "@ant-design/icons-vue"
    import { Inertia }        from "@inertiajs/inertia"
    import { message as AMessage} from  "ant-design-vue"

    import { get as getFromObject }      from "lodash"
    import {
        reactive,
        ref,
        watch,
        onMounted,
        provide,
    }                                    from "vue"
    import PrimaryButton                 from "../../../components/Admin/Buttons/PrimaryButton"
    import InputComponent                from "../../../components/Admin/Forms/Input"
    import LabelComponent                from "../../../components/Admin/Forms/Label"
    import RadioGroupInput               from "../../../components/Admin/Forms/RadioGroupInput"
    import Dropdown                      from "../../../components/Admin/General/Dropdown"
    import Icon                          from "../../../components/Admin/General/Icon"
    import Pagination                    from "../../../components/Admin/General/Pagination"
    import AdminLayout                   from "../../../components/Admin/Layout/AdminLayout"
    import ActionButton                  from "../../../components/Admin/Buttons/ActionButton"
    import PatientName                   from "../../../components/Admin/General/PatientName"
    import Modal                         from "../../../components/Admin/Modal"
    import CancelButton                  from "../../../components/Admin/Buttons/CancelButton"
    import ActionButtonWrapper           from "../../../components/Admin/Buttons/ActionButtonWrapper"
    import ButtonComponent               from "../../../components/Admin/Buttons/Button"
    import useAntTable                   from "../../../composables/useAntTable"
    import useDatePicker                 from "../../../composables/useDatePicker"
    import {
        loadOrder,
        preparePaginationFromData,
    }                                    from "../../../helpers"
    import CreateRequisitionDropdown     from "./Partials/CreateRequisitionDropdown"
    import DeleteRequisitionModal        from "./Partials/DeleteRequisitionModal"
    import RequisitionListFilterDropdown from "./Partials/RequisitionListFilterDropdown"
    import RequisitionListTable          from "./Partials/RequisitionListTable"

    export default {
        name: "Index",
        components: {
            DeleteRequisitionModal,
            RequisitionListTable,
            CreateRequisitionDropdown,
            RequisitionListFilterDropdown,
            ActionButtonWrapper,
            CancelButton,
            Modal,
            PatientName,
            ActionButton,
            Pagination,
            Icon,
            Dropdown,
            PrimaryButton,
            AdminLayout,
            InputComponent,
            LabelComponent,
            RadioGroupInput,
            SearchOutlined,
            ButtonComponent,
        },
        props: {
            requisitions: { type: Object, required: false, default: () => ({}) },
        },
        setup(props) {
            const { masks, mode, datePickerConfig, dateRange, updateDateRange, clearDateRange } = useDatePicker()
            provide("masks", masks)
            provide("mode", mode)
            provide("datePickerConfig", datePickerConfig)
            provide("updateDateRange", updateDateRange)

            const  searchInput = ref()

            const requisitionTableColumns = [
                {
                    title: "Action",
                    width: "10em",
                    slots: {
                        customRender: "action",
                    },
                    fixed: "left",
                },
                {
                    title: "REQ#",
                    dataIndex: "req_code",
                    key: "req_code",
                    width: "8em",
                    // sorter: (a, b) => a.req_code - b.req_code,
                    // sortDirections: ["descend", "ascend"],
                },
                {
                    title: "Patient Name",
                    dataIndex: "patient_name",
                    width: "16em",
                    key: "patient_name",
                    // sorter: (a, b) => a.patient_name.localeCompare(b.patient_name),
                    // sortDirections: ["descend", "ascend"],
                    slots: {
                        filterDropdown: "filterDropdown",
                        filterIcon: "filterIcon",
                        customRender: "patientName",
                    },
                    onFilter: (value, record) =>
                        record.patient_name.toString().toLowerCase().includes(value.toLowerCase()),
                    onFilterDropdownVisibleChange: visible => {
                        if (visible) {
                            setTimeout(() => {
                                searchInput.value.focus()
                            }, 0)
                        }
                    },
                },
                {
                    title: "DOB",
                    dataIndex: "dob",
                    key: "dob",
                    width: "8em",
                    sorter: (a, b) => a.dob_raw.localeCompare(b.dob_raw),
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: "Sample Collection On",
                    width: "12em",
                    slots: {
                        customRender: "sampleCollectedOn",
                    },
                },
                {
                    title: "Requisition Date",
                    dataIndex: "requisition_date",
                    width: "12em",
                    defaultSortOrder: "descend",
                    key: "requisition_date",
                    sorter: (a, b) => a.requisition_date_raw.localeCompare(b.requisition_date_raw),
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: "Test Names",
                    width: "16em",
                    slots: {
                        customRender: "testName",
                    },
                },
                {
                    title: "Ordering Facility",
                    dataIndex: "ordering_facility",
                    width: "12em",
                    key: "ordering_facility",
                    // sorter: (a, b) => a.ordering_facility.localeCompare(b.ordering_facility),
                    // sortDirections: ["descend", "ascend"],
                },
                {
                    title: "Email",
                    dataIndex: "email",
                    key: "email",
                    sorter: (a, b) => a.email.localeCompare(b.email),
                    sortDirections: ["descend", "ascend"],
                    slots: {
                        customRender: "email",
                    },
                },
            ]

            const {
                tableData: requisitionListTableData,
                tableColumns: requisitionListTableColumns,
                state: requisitionTableState,
                handleSearch,
                handleReset,
                updateQueries,
                updatePagination,
                updateSearchText,
                updateQueryDateRange,
                updateSingleQuery,
                clearSearch,
                filteredInfo,
                sortedInfo,
            } = useAntTable(requisitionTableColumns)

            const handleChange = (pager, filters, sorter) => {
                updatePagination({ ...pager })

                updateSingleQuery("order", loadOrder(sorter))
                updateSingleQuery("patient_name", getFromObject(filters, "patient_name.0", ""))
                updateSingleQuery("req_code", getFromObject(filters, "req_code.0", ""))

                filteredInfo.value = filters
                sortedInfo.value = sorter
                loadData()
            }

            const state = reactive({
                showRequisitionDeleteModal: false,
            })

            const clearQueries = () => {
                updateQueries({
                    order: "-requisition_date",
                    patient_name: "",
                    req_code: "",
                    from: "",
                    to: "",
                })
            }

            const clearAllFilters = ()  => {
                filteredInfo.value = {
                    patient_name:   [],
                }
                clearQueries()
                clearDateRange()
                clearSearch()
                loadData()
            }

            const applyFilters = ()  => {
                updateQueryDateRange(getFromObject(dateRange, "start", ""), getFromObject(dateRange, "end", ""))
                loadData()
            }

            provide("clearAllFilters", clearAllFilters)
            provide("applyFilters", applyFilters)

            const loadRequisitionListTableData = (dataArray) => {
                if (!dataArray || !Array.isArray(dataArray)) {
                    return
                }

                requisitionListTableData.value = []

                dataArray.forEach((item, index) => {
                    requisitionListTableData.value.push(prepareRequisitionListTableData(item, index))
                })
            }

            const prepareRequisitionListTableData = (item, index) => {
                return {
                    key: index + requisitionTableState.pagination.from,
                    req_id: item.req_id,
                    req_code: item.req_code,
                    patient_name: item.patient_name,
                    dob: item.dob,
                    dob_raw: item.dob_raw,
                    requisition_date: item.requisition_date,
                    requisition_date_raw: item.requisition_date_raw,
                    samples: item.samples,
                    ordering_facility: item.ordering_facility,
                    email: item.email,
                }
            }

            const loadData = () => {
                requisitionTableState.loading = true
                axios.get("/api/requisitions", {
                    params: {
                        page: requisitionTableState.pagination.current,
                        ...requisitionTableState.queries,
                    },
                }).then(({ data }) => {
                    updatePagination(preparePaginationFromData(data))
                    loadRequisitionListTableData(getFromObject(data, "data", []))

                    requisitionTableState.loading = false
                }).catch((error) => {
                    requisitionTableState.loading = false
                })
            }

            onMounted(() => {
                clearQueries()
                loadData()
            })

            function showRequisitionDetail(requisition_id) {
                Inertia.get(route("admin.requisitions.show", requisition_id))

            }

            function handleShowRequisitionDeleteModal(requisition_id) {
                state.showRequisitionDeleteModal = true
            }

            function handleCloseRequisitionDeleteModal() {
                state.showRequisitionDeleteModal = false
            }

            //requisition delete

            const requisitionDeleteId = ref(null)
            const showRequisitionDeleteModal = ref(false)

            const closeRequisitionDeleteModal = () => {
                requisitionDeleteId.value = null
                showRequisitionDeleteModal.value = false
            }

            const openRequisitionDeleteModal = (requisitionId) => {
                requisitionDeleteId.value = requisitionId
                showRequisitionDeleteModal.value = true
            }

            const deleteRequisition = () => {
                if (!requisitionDeleteId.value || !Number.isInteger(requisitionDeleteId.value)) {
                }
                Inertia.delete(route("admin.requisitions.delete", requisitionDeleteId.value), {
                    onSuccess: () => {
                        loadData()
                        showRequisitionDeleteModal.value = false
                        AMessage.success("Requisition has been deleted")
                    },
                    onError: () => {
                        AMessage.error("Error deleting requisition")
                    },
                    preserveState: true,
                    preserveScroll: true,
                })
            }

            provide("requisitionListTableColumns", requisitionListTableColumns)
            provide("requisitionListTableData", requisitionListTableData)
            provide("requisitionTableState", requisitionTableState)
            provide("handleChange", handleChange)
            provide("handleSearch", handleSearch)
            provide("handleReset", handleReset)
            provide('searchInput', searchInput)
            provide('filteredInfo', filteredInfo)
            provide('filteredInfo', filteredInfo)
            provide('sortedInfo', sortedInfo)

            provide('showRequisitionDetail', showRequisitionDetail)
            provide('openRequisitionDeleteModal', openRequisitionDeleteModal)

            return {
                getFromObject,
                showRequisitionDetail,
                handleShowRequisitionDeleteModal,
                handleCloseRequisitionDeleteModal,
                requisitionListTableColumns,
                requisitionListTableData,
                handleSearch,
                handleReset,
                searchText: requisitionTableState.searchText,
                searchInput,
                searchedColumn: requisitionTableState.searchedColumn,
                showRequisitionDeleteModal,
                openRequisitionDeleteModal,
                closeRequisitionDeleteModal,
                deleteRequisition,
                handleChange,
                dateRange,
                state,
                requisitionTableState,
            }
        },
    }
</script>


<style lang="scss">
    .modal--req-delete .modal-content {
        width: 40%;
    }

</style>

<template>
    <teleport to="body">
        <modal class="modal--upload-test-batch" :show="showModal" @close="closeModal">
            <template #title>
                <h2 class="heading-primary heading-primary-md">Upload Result Csv</h2>
            </template>
            <template #default>
                <div class="grid gap-6">
                    <a-upload
                        accept=".csv"
                        class="grid gap-2"
                        :file-list="fileList"
                        :remove="handleRemove"
                        :before-upload="beforeUpload">
                        <a-button
                            :disabled="fileList.length > 0">
                            <upload-outlined></upload-outlined>
                            Select File
                        </a-button>
                    </a-upload>
                    <a-button
                        type="primary"
                        class="!bg-tertiary-100 !hover:bg-tertiary-200 !active:bg-tertiary-200"
                        :disabled="fileList.length === 0 || fileList.length > 1"
                        :loading="uploading"
                        style="margin-top: 16px"
                        @click="handleUpload"
                    >
                    <span class="text-white">
                        {{ uploading ? "Uploading" : "Start Upload" }}
                    </span>
                    </a-button>
                </div>
            </template>
        </modal>
    </teleport>
</template>

<script>
    import { Inertia }            from "@inertiajs/inertia"
    import { useForm }            from "@inertiajs/inertia-vue3"
    import Modal                  from "Components/Admin/Modal"
    import { UploadOutlined }     from "@ant-design/icons-vue"
    import { message }            from "ant-design-vue"
    import { ref }                from "vue"
    import { default as AInput }  from "ant-design-vue/lib/input"
    import { default as AButton } from "ant-design-vue/lib/button"
    import { default as AUpload } from "ant-design-vue/lib/upload"

    export default {
        name: "UploadTestBatchCsvModal",
        components: {
            Modal,
            UploadOutlined,
            AInput,
            AUpload,
            AButton,
        },
        props: {
            showModal: {
                type: Boolean,
                required: false,
                default: false,
            },
            id: {
                type: [Number, String],
                required: true,
            },
        },
        emits: ["close", "success"],
        setup(props, { emit }) {
            const fileList = ref([])

            const uploadForm = useForm({
                upload: null,
            })

            const uploading = ref(false)

            const closeModal = () => {
                fileList.value = []
                uploading.value = false
                emit("close")
            }

            const saveSuccess = () => {
                fileList.value = []
                uploading.value = false
                emit("success")
            }

            const handleRemove = file => {
                const index = fileList.value.indexOf(file)
                const newFileList = fileList.value.slice()
                newFileList.splice(index, 1)
                fileList.value = newFileList
            }

            const beforeUpload = file => {
                fileList.value = [...fileList.value, file]
                return false
            }

            const handleUpload = () => {
                uploadForm.upload = fileList.value.shift()
                uploading.value = true

                uploadForm.post(route("admin.test-batch.upload", props.id), {
                    errorBag: "uploadCSV",
                    preserveState: true,
                    preserveScroll: true,
                    onSuccess: () => {
                        fileList.value = []
                        uploading.value = false
                        uploadForm.upload = null
                        message.success("upload successfully.")
                        closeModal()
                    },
                    onError: (errors) => {
                        uploading.value = false
                        uploadForm.upload = null
                        message.error("upload failed.")
                    },
                })
            }

            return {
                fileList,
                uploading,
                handleRemove,
                beforeUpload,
                handleUpload,
                closeModal,
            }
        },
    }
</script>

<style scoped lang="scss">
    .anticon.anticon-delete {
        color: red;
    }
</style>

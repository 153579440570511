<template>
    <div class="dropdown__menu filter-dropdown" @blur="open = false">
        <button-component @click.stop="toggle" class="btn-icon">
            <Icon class="w-3.5 h-3.5" name="filter"/>
            Filters
        </button-component>
        <div :class="listClass" @click.stop>
            <div class="px-6 pt-8">
                <div class="py-4 border-b border-grey-200">
                    <label-component class="!text-xs !text-grey-900" value="Test Name"/>
                    <check-box-group-input name="test-name"
                                           :options="['SARS-CoV-2 RT-PCR','Covid Antibody Rapid Test']"/>
                </div>
                <div class="py-4 border-b border-grey-200">
                    <label-component class="!text-xs !text-grey-900" value="Sample Type"/>
                    <check-box-group-input name="sample-type"
                                           :options="['Nasopharyngeal Swab','Oral Swab']"/>
                </div>
                <div class="flex items-center justify-between py-4">
                    <button class="btn-link">
                        Clear all filters
                    </button>
                    <div>
                        <cancel-button class="!text-xs" @click="showMenu= false">
                            Cancel
                        </cancel-button>
                        <primary-button class="!rounded !py-0.5 !px-4 !text-xs">
                            Apply Filter
                        </primary-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Dropdown           from "../../../../components/Admin/General/Dropdown.vue"
    import Icon               from "../../../../components/Admin/General/Icon.vue"
    import ButtonComponent    from "../../../../components/Admin/Buttons/Button"
    import CancelButton       from "../../../../components/Admin/Buttons/CancelButton"
    import PrimaryButton      from "../../../../components/Admin/Buttons/PrimaryButton"
    import LabelComponent     from "../../../../components/Admin/Forms/Label"
    import CheckBoxGroupInput from "../../../../components/Admin/Forms/CheckBoxGroupInput"

    export default {
        name: "Filter",
        components: { Dropdown, Icon, LabelComponent, CheckBoxGroupInput, ButtonComponent, PrimaryButton, CancelButton },
        data() {
            return {
                showMenu: false,
            }
        },
        computed: {
            listClass: function() {
                if (this.showMenu) {
                    return "dropdown dropdown--show"
                }
                return "dropdown"
            },
        },
        methods: {
            toggle: function() {
                this.showMenu = !this.showMenu
                this.showMenu && this.$nextTick(() => {
                    document.addEventListener("click", this.hide)
                })
            },
            hide: function() {
                this.showMenu = false
                document.removeEventListener("click", this.hide)
            },
        },

    }
</script>

<template>
    <div class="table-head table-head-container !pl-0 !pt-0">
        <h1 class="heading-primary heading-primary-md !leading-none px-10"
            v-text="'Results'"/>
    </div>
    <div class="overflow-x-auto">
        <AntTable
            :columns="completedResultTableColumns"
            :dataSource="completedResultTableData"
            :pagination="completedResultTableState.pagination"
            :loading="completedResultTableState.loading"
            @change="handleCompletedResultsChange">
            <template #filterSearch="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
                <div class="grid gap-2 p-2">
                    <a-input
                        class="placeholder-grey-400"
                        ref="searchInput"
                        :placeholder="`Enter ${column.dataIndex.split('_').join(' ')}`"
                        :value="selectedKeys[0]"
                        @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                        @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                    />
                    <div class="flex items-center justify-between gap-2">
                        <a-button
                            type="primary"
                            size="small"
                            class="btn-icon"
                            @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                        >
                            <Icon class="w-3.5 h-3.5" name="search"/>
                            Search
                        </a-button>
                        <a-button size="small" class="ant-reset" @click="handleReset(clearFilters)">
                            Reset
                        </a-button>
                    </div>
                </div>
            </template>
            <template #patientName="{record}">
                <patient-name :name="record.patient_name"/>
            </template>
            <template #filterIcon="filtered">
                <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }"/>
            </template>
            <template #customRender="{ text, column }">
                                <span v-if="searchText && searchedColumn === column.dataIndex">
                                    <template
                                        v-for="(fragment, i) in text
                                        .toString()
                                        .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
                                    >
                                        <mark
                                            v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                                            class="highlight"
                                            :key="i"
                                        >
                                            {{ fragment }}
                                        </mark>
                                        <template v-else>{{ fragment }}</template>
                                    </template>
                                </span>
                <template v-else>
                    {{ text }}
                </template>
            </template>
            <template #testName="{ record }">
                <p
                    v-for="(test, index) in record.sample_tests"
                    :key="index">
                    {{ test.test_name }}
                </p>
            </template>
            <template #testDate="{ record }">
                <p
                    v-for="(test, index) in record.sample_tests"
                    :key="index">
                    {{ test.result_date }}
                </p>
            </template>
            <template #results="{ record }">
                <div
                    v-for="(sampleTest, index) in record.sample_tests"
                    :key="index">
                    <action-button-wrapper v-if="isResultApproved(sampleTest)">
                        <action-button target="_blank"
                                       :href="showResult(record.sample_id)"
                                       :tooltip-text="'View result'"
                                       :action-type="'view'"
                        />
                    </action-button-wrapper>
                    <div class="status-mark-wrap flex items-center" v-else>
                        <span class="status-mark block rounded-full w-1.5 h-1.5 bg-warning-600"/>
                        <span class="ml-1 status-text leading-none capitalize" v-text="'Pending'"/>
                    </div>
                </div>
            </template>
        </AntTable>
    </div>
</template>
<script>
    import useAntTable              from "../../../../composables/useAntTable"
    import AntTable                 from "ant-design-vue/lib/table"
    import { default as AButton }   from "ant-design-vue/lib/button"
    import { default as AInput }    from "ant-design-vue/lib/input"
    import { SearchOutlined }       from "@ant-design/icons-vue"
    import PatientName              from "../../../../components/Admin/General/PatientName"
    import ActionButton             from "../../../../components/Admin/Buttons/ActionButton"
    import ActionButtonWrapper      from "../../../../components/Admin/Buttons/ActionButtonWrapper"
    import Icon                     from "../../../../components/Admin/General/Icon"
    import {
        loadOrder,
        preparePaginationFromData,
    }                               from "../../../../helpers"
    import { get as getFromObject } from "lodash"
    import { onMounted }            from "vue"

    export default {
        name: "CompletedResultsTable",
        components: {
            AntTable,
            SearchOutlined,
            PatientName,
            ActionButton,
            ActionButtonWrapper,
            Icon,
            AButton,
            AInput,
        },
        setup() {
            const columns = [
                {
                    title: "REQ#",
                    dataIndex: "requisition_code",
                    key: "requisition_code",
                    sorter: (a, b) => a.requisition_code - b.requisition_code,
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: "Name",
                    dataIndex: "patient_name",
                    key: "patient_name",
                    sorter: (a, b) => a.patient_name.localeCompare(b.patient_name),
                    sortDirections: ["descend", "ascend"],
                    slots: {
                        filterDropdown: "filterSearch",
                        filterIcon: "filterIcon",
                        customRender: "patientName",
                    },
                    onFilter: (value, record) =>
                        record.patient_name.toString().toLowerCase().includes(value.toLowerCase()),
                    onFilterDropdownVisibleChange: visible => {
                        if (visible) {
                            setTimeout(() => {
                                searchInput.value.focus()
                            }, 0)
                        }
                    },
                },
                {
                    title: "DOB",
                    dataIndex: "dob",
                    key: "dob",
                    sorter: (a, b) => a.dob_raw.localeCompare(b.dob_raw),
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: "Test Name",
                    slots: {
                        customRender: "testName",
                    },
                    width: "10rem",
                },
                {
                    title: "Sample received on",
                    dataIndex: "sample_received_on",
                    key: "sample_received_on",
                    width: "10rem",
                },
                {
                    title: "Test performed date",
                    key: "test_performed_date",
                    slots: {
                        customRender: "testDate",
                    },
                    width: "10rem",
                },
                {
                    title: "Result",
                    slots: {
                        customRender: "results",
                    },
                    width: "8rem",
                },
            ]

            const {
                tableColumns: completedResultTableColumns,
                tableData: completedResultTableData,
                state: completedResultTableState,
                handleSearch,
                handleReset,
                updateQueries,
                updateSearchText,
                updateQueryDateRange,
                updateSingleQuery,
                updatePagination,
                clearSearch,
                filteredInfo,
                sortedInfo,
                searchInput,
            } = useAntTable(columns)

            const handleCompletedResultsChange = (pager, filters, sorter) => {
                updatePagination({ ...pager })
                updateSingleQuery("order", loadOrder(sorter))
                updateSingleQuery("patient_name", getFromObject(filters, "patient_name.0", ""))

                loadCompletedResultsData()
            }

            onMounted(() => {
                loadCompletedResultsData()
            })

            const loadCompletedResultsData = () => {
                completedResultTableState.loading = true
                axios.get("/api/clients/results/completed", {
                    params: {
                        page: completedResultTableState.pagination.current,
                        ...completedResultTableState.queries,
                    },
                }).then(({ data }) => {
                    updatePagination(preparePaginationFromData(data))
                    loadCompletedResultsListTableData(getFromObject(data, "data", []))

                    completedResultTableState.loading = false
                }).catch((error) => {
                    completedResultTableState.loading = false
                    console.error(error)
                })
            }

            const loadCompletedResultsListTableData = (dataArray) => {
                if (!dataArray || !Array.isArray(dataArray)) {
                    return
                }

                completedResultTableData.value = []

                dataArray.forEach((item, index) => {
                    completedResultTableData.value.push(prepareCompletedResultsTableData(item, index))
                })
            }

            const prepareCompletedResultsTableData = (item, index) => {
                return {
                    key: index + completedResultTableState.pagination.from,
                    sample_id: item.id ?? "",
                    test_name: item.test_name ?? "",
                    patient_name: item.patient_name ?? "",
                    dob: item.dob ?? "",
                    dob_raw: item.dob_raw ?? "",
                    sample_received_on: item.sample_received_on ?? "",
                    sample_received_on_raw: item.sample_received_on_raw ?? "",
                    sample_type: item.sample_type ?? "",
                    requisition_no: item.requisition_id ?? "",
                    requisition_code: item.requisition_code ?? "",
                    is_mail: item.is_mail,
                    sample_tests: getFromObject(item, "sample_tests", []),
                }
            }

            const showResult = (sampleId) => {
                return route("client.show", sampleId)
            }

            const isResultApproved = (sampleTest) => {
                return getFromObject(sampleTest, 'status') === 'result_approved'
            }

            return {
                completedResultTableColumns,
                completedResultTableData,
                completedResultTableState,
                handleCompletedResultsChange,
                handleSearch,
                handleReset,
                searchInput,
                showResult,
                isResultApproved,
            }
        },
    }
</script>

<style scoped>

</style>

<template>
    <div class="radio-btn-group grid md:grid-cols-3 gap-5 pt-3">
        <div v-for="(option) in optionsKeys" :key="option" class="flex justify-end">
            <input
                :id="name + '_' + option"
                :checked="option === modelValue"
                :name="name"
                :required="required"
                :value="option"
                class=""
                type="radio"
                @change="updateValue(option)">
            <label class="capitalize" :for="name+'_' + option">{{ getLabel(option) }}</label>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RadioGroupInput",
        props: {
            name: {
                type: String,
                default: null,
            },
            modelValue: {
                type: [String, Number, Boolean, Object],
                default: null,
            },
            options: {
                type: [Array, Object, Map],
                required: true,
            },
            required: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            optionsKeys: function() {
                return this.options instanceof Map ? Array.from(this.options.keys()) : Object.keys(this.options)
            },
        },

        methods: {
            getLabel(key) {
                return (this.options instanceof Map ? this.options.get(key) : this.options[key]) || key
            },
            updateValue(value) {
                this.$emit("change", value)
                this.$emit("update:modelValue", value)
            },
        },
    }
</script>

<style scoped>

</style>

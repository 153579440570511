<template>
    <li>
        <Link v-if="href" :href="href" class="text-tertiary-100" v-text="label"/>
        <span v-else v-text="label"/>
    </li>
    <li v-if="href" class="flex items-center mx-1">
        <Icon class="w-3 h-2.5" name="arrow-left"/>
    </li>
</template>

<script type="text/ecmascript-6">
import Icon from "../General/Icon"
import {Link} from '@inertiajs/inertia-vue3'

export default {
    name: "BreadCrumbItem",
    components: {Icon, Link},
    props: {
        label: {type: String, required: true},
        href: {type: String, required: false, default: ""},
    },
}
</script>

<template>
    <div class="inline-flex gap-2 items-center">
        <slot/>
    </div>
</template>

<script>
import {Link} from '@inertiajs/inertia-vue3'
import Icon from "../General/Icon";

export default {
    name: "ActionButtonWrapper",
}
</script>

<template>
    <header
        class="header header--admin bg-pinkShade-1000 flex justify-between items-center flex  bg-white  pl-6  h-18 fixed z-50 left-0 top-0 w-full">
        <div>
            <a href="/"
               class="text-white md:block text-left md:pb-2 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
            >
                Crisper <span class="text-tertiary-100">Diagnostics</span>
            </a>
        </div>
        <div class="ml-auto w-1/2 flex justify-end self-stretch">
            <div
                class="h-full !flex items-center gap-2 py-2 px-6 text-blue-100 text-sm"
            >
                              <span
                                  class="w-10 h-10 text-sm  bg-tertiary-100 text-white hidden sm:inline-flex items-center justify-center rounded-full"
                                  v-text="'CR'"/>
                Crisper Diagnostics
            </div>
            <a @click="isLogoutModalOpen = true"
               class="cursor-pointer bg-pinkShade-900 transition hover:bg-pinkShade-1100 duration-300 text-sm py-3 px-6 flex gap-2 items-center justify-center text-blue-100"
            >
                Logout
                <Icon name="logout" class="!w-5 !h-5"/>
            </a>
        </div>
        <modal class="modal--logout" :show="isLogoutModalOpen" @close="closeLogoutModal">
            <template #title>
                <p class="heading-primary heading-primary-md !font-normal !text-black !text-center">Are you sure you
                    want to logout?</p>
            </template>
            <template #default>
                <div class="btn-wrap !gap-6 sm:justify-center">
                    <secondary-button class="!px-10 !border-grey-500 !text-grey-500  btn--outline text-base"
                                      @click="closeLogoutModal">
                        Cancel
                    </secondary-button>
                    <primary-button href="/logout" @click="closeLogoutModal">
                        Yes,Logout
                    </primary-button>
                </div>
            </template>
        </modal>
    </header>
</template>

<script>
import PrimaryButton from "../../Admin/Buttons/PrimaryButton"
import SecondaryButton from "../../Admin/Buttons/SecondaryButton"
import Icon from "../General/Icon"
import Dropdown from "../General/Dropdown"
import Modal from "../../Admin/Modal"

export default {
    name: "AdminHeader",
    props: {
        isAdminHeader: {type: Boolean, required: false},
    },
    components: {Modal, Icon, Dropdown, PrimaryButton, SecondaryButton},
    data() {
        return {
            isLogoutModalOpen: false,
        }
    },

    methods: {
        closeLogoutModal() {
            this.isLogoutModalOpen = false
        },
    }
}
</script>


<template>
    <admin-layout active-link="review">
        <div class="card--large !p-0">
            <div>
                <!-- title and dropdown -->
                <div class="table-head-container">
                    <h1 class="heading-primary !text-grey-1100 " v-text="'Review'"/>
                </div>
                <!-- table -->
                <div class="tab">
                    <div class="tab__list px-8 gap-2" aria-label="Tabs">
                        <div
                            @click="showTab(TAB_PENDING_RESULTS)"
                            :class="currentTab === TAB_PENDING_RESULTS  ? 'tab__item is--active !rounded-b-none bg-white !text-sm':'tab__item bg-white !rounded-b-none !text-sm'"
                        >
                            <span> Pending Review</span>
                        </div>
                        <div
                            @click="showTab(TAB_COMPLETED_RESULTS)"
                            :class="currentTab === TAB_COMPLETED_RESULTS  ?
                         'tab__item is--active !rounded-b-none bg-white !text-sm':'tab__item bg-white !rounded-b-none !text-sm'"
                        >
                            <span>Approved</span>
                        </div>
                    </div>
                </div>
                <div class="table-head table-head-container !pl-0 !py-10">
                    <h1 class="heading-primary heading-primary-sm !leading-none px-10"
                        v-text="currentTab === TAB_PENDING_RESULTS  ? 'Pending Review' : 'Approved'"
                    />

                    <dropdown
                        v-if="currentTab === TAB_PENDING_RESULTS"
                        class="filter-dropdown"
                        ref="filterdropdown"
                        :show-drop-down-menu="showFilterDropDown">
                        <template v-slot:trigger>
                            <button-component class="btn-icon" @click="showFilterDropDown=true">
                                <Icon class="w-3.5 h-3.5" name="filter"/>
                                Filters
                            </button-component>
                        </template>
                        <template v-slot:content="{showMenu}">
                            <div class="px-8 py-6">
                                <date-picker v-model="pendingResultDateRange" is-range
                                             :model-config="datePickerConfig"
                                             :masks="masks"
                                             @change="handleEndOpenChange"
                                             :popover="{ visibility: 'focus' }"
                                >
                                    <template v-slot="{ inputValue, inputEvents }">
                                        <span class="block !pb-3" v-text="'Collection date'"/>
                                        <div class="grid grid-cols-2 gap-4">
                                            <div>
                                                <label-component value="From"/>

                                                <div class="form-icon">
                                                    <input
                                                        :value="inputValue.start"
                                                        v-on="inputEvents.start"
                                                        class="form-control"
                                                        placeholder="Select date"
                                                    />
                                                    <Icon name="date-time"/>
                                                </div>
                                            </div>
                                            <div>
                                                <label-component value="To"/>
                                                <div class="form-icon">
                                                    <input
                                                        :value="inputValue.end"
                                                        v-on="inputEvents.end"
                                                        class="form-control"
                                                        placeholder="Select date"
                                                    />
                                                    <Icon name="date-time"/>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </date-picker>
                                <div class="px-8 py-6 flex items-center justify-between">
                                    <button class="btn-link" @click="clearPendingTabFilters">
                                        Clear all filters
                                    </button>
                                    <div>
                                        <cancel-button class="!text-xs" @click="$refs.filterdropdown.toggle()">
                                            Cancel
                                        </cancel-button>
                                        <primary-button class="!rounded !py-0.5 !px-4 !text-xs" @click="applyPendingTabFilters">
                                            Apply Filter
                                        </primary-button>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </dropdown>
                </div>
                <div class="overflow-x-auto table-tooltip-break">
                    <AntTable
                        v-if="currentTab === TAB_PENDING_RESULTS"
                        :columns="pendingResultTableColumns"
                        :dataSource="pendingResultTableData"
                        :pagination="pendingResults.pagination"
                        :loading="pendingResults.loading"
                        @change="handlePendingResultsChange"
                    >
                        <template #filterSearch="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
                            <div class="grid gap-2 p-2">
                                <a-input
                                    ref="searchInput"
                                    :placeholder="`Enter ${column.dataIndex.split('_').join(' ')}`"
                                    :value="selectedKeys[0]"
                                    class="placeholder-grey-400"
                                    @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                                    @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                                />
                                <div class="flex items-center justify-between gap-2">

                                    <a-button
                                        type="primary"
                                        size="small"
                                        class="btn-icon"
                                        @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                                    >
                                        <Icon class="w-3.5 h-3.5" name="search"/>
                                        Search
                                    </a-button>
                                    <a-button size="small" class="ant-reset" @click="handleReset(clearFilters)">
                                        Reset
                                    </a-button>
                                </div>
                            </div>
                        </template>
                        <template #filterIcon="filtered">
                            <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }"/>
                        </template>
                        <template #customRender="{ text, column }">
                        <span v-if="searchText && searchedColumn === column.dataIndex">
                            <template
                                v-for="(fragment, i) in text
                                .toString()
                                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
                            >
                                <mark
                                    v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                                    class="highlight"
                                    :key="i"
                                >
                                    {{ fragment }}
                                </mark>
                                <template v-else>{{ fragment }}</template>
                            </template>
                        </span>
                            <template v-else>
                                {{ text }}
                            </template>
                        </template>
                        <template #action="{ record }">
                            <action-button action-type="view" tooltip-text="View result"
                                           @click="openApproveResultModal(record.sample_id)"/>
                        </template>
                        <template #patientName="{record}">
                            <PatientName :name="record.patient_name"/>
                        </template>
                        <template #testName="{ record }">
                            <p
                                v-for="(test, index) in record.sample_tests"
                                :key="index">
                                {{ test.test_name }}
                            </p>
                        </template>
                        <template #testDate="{ record }">
                            <p
                                v-for="(test, index) in record.sample_tests"
                                :key="index">
                                {{ test.result_date }}
                            </p>
                        </template>
                    </AntTable>
                    <AntTable
                        v-if="currentTab === TAB_COMPLETED_RESULTS"
                        :columns="completedResultTableColumns"
                        :dataSource="completedResultTableData"
                        :pagination="completedResults.pagination"
                        :loading="completedResults.loading"
                        @change="handleCompletedResultsChange">
                        <template #filterSearch="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
                            <div class="grid gap-2 p-2">
                                <a-input
                                    class="placeholder-grey-400"
                                    ref="searchInput"
                                    :placeholder="`Enter ${column.dataIndex.split('_').join(' ')}`"
                                    :value="selectedKeys[0]"
                                    @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                                    @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                                />
                                <div class="flex items-center justify-between gap-2">
                                    <a-button
                                        type="primary"
                                        size="small"
                                        class="btn-icon"
                                        @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                                    >
                                        <Icon class="w-3.5 h-3.5" name="search"/>
                                        Search
                                    </a-button>
                                    <a-button size="small" class="ant-reset" @click="handleReset(clearFilters)">
                                        Reset
                                    </a-button>
                                </div>
                            </div>
                        </template>
                        <template #patientName="{record}">
                            <PatientName :name="record.patient_name"/>
                        </template>
                        <template #filterIcon="filtered">
                            <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }"/>
                        </template>
                        <template #customRender="{ text, column }">
                            <span v-if="searchText && searchedColumn === column.dataIndex">
                                <template
                                    v-for="(fragment, i) in text
                                    .toString()
                                    .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
                                >
                                    <mark
                                        v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                                        class="highlight"
                                        :key="i"
                                    >
                                        {{ fragment }}
                                    </mark>
                                    <template v-else>{{ fragment }}</template>
                                </template>
                            </span>
                            <template v-else>
                                {{ text }}
                            </template>
                        </template>
                        <template #action="{ record }">
                            <action-button-wrapper>
                                <action-button target="_blank"
                                               :href="showResult(record.sample_id)"
                                               :tooltip-text="'View result'"
                                               :action-type="'view'"
                                />
                                <action-button
                                    :href="downloadPdf(record.sample_id,record.requisition_code)"
                                    :tooltip-text="'Download result'"
                                    :action-type="'download'"/>
                                <action-button v-if="record.is_mail==='Mail not sent'"
                                               @click="sendEmailOfPdf(record.sample_id,record.requisition_code)"
                                               :tooltip-text="'Send email'"
                                               :action-type="'email'"/>

                                <action-button v-else
                                               @click="sendEmailOfPdf(record.sample_id,record.requisition_code)"
                                               :tooltip-text="'Re-send email'"
                                               :action-type="'email'"/>
                            </action-button-wrapper>
                        </template>
                        <template #testName="{ record }">
                            <p
                                v-for="(test, index) in record.sample_tests"
                                :key="index">
                                {{ test.test_name }}
                            </p>
                        </template>
                        <template #testDate="{ record }">
                            <p
                                v-for="(test, index) in record.sample_tests"
                                :key="index">
                                {{ test.result_date }}
                            </p>
                        </template>
                        <template #results="{ record }">
                            <div
                                v-for="(sampleTest, index) in record.sample_tests"
                                :key="index">
                                <p
                                    v-for="(result, test) in sampleTest.result"
                                    :key="test"
                                    v-text="`${test}: ${result}`"
                                />
                            </div>
                        </template>
                    </AntTable>
                </div>
            </div>
        </div>
    </admin-layout>
    <modal :show="showApproveResultModal" @close="closeApproveResultModal">
        <template #title>
            <p class="heading-primary heading-primary-sm">Results</p>
        </template>
        <template #default>
            <div class="grid gap-8">
                <AntTable :columns="approveResultTableColumns" :data-source="approveResultTableData" size="small"
                          :pagination="false">
                    <template #view="{ record }">
                            <span>
                                <action-button action-type="view" target="_blank" :href="showResult(record.sample_id)"
                                               tooltip-text="View result"/>
                            </span>
                    </template>
                    <template #results="{ record }">
                        <p
                            v-for="(result, test) in record.result"
                            :key="test"
                            v-text="`${test}: ${result}`"
                        />
                    </template>
                    <template #action="{ record }">
                            <div>
                                <span v-if="record.status === RESULT_APPROVED" class="text-green-700">Approved</span>
                                <div class="flex gap-2" v-else>
                                    <action-button tooltip-text="Accept result" action-type="accept"
                                                   @click="approveResult(record.id)"/>
                                    <action-button tooltip-text="Edit Result" action-type="edit"
                                                   @click="editResult(record)"/>
                                </div>
                            </div>
                    </template>
                </AntTable>
                <div>
                    <label-component value="Remarks"></label-component>
                    <textarea class="form-control resize-none" v-model="sampleApprovalRemarks"/>
                </div>
                <div
                    class="flex gap-3 justify-end"
                >
                    <secondary-button @click="closeApproveResultModal">
                        Cancel
                    </secondary-button>
                    <primary-button

                        @click="saveRemarks(approveModalSampleID)"
                    >
                        Save
                    </primary-button>
                </div>
            </div>
        </template>
    </modal>
    <update-result-modal
        :id="updateResultModalRecord.id"
        :show-modal="showUpdateResultModal"
        :test-name="updateResultModalRecord.test_name"
        :result-value="updateResultModalRecord.result"
        :result-options="updateResultModalRecord.resultOptions"
        @close="closeUpdateResultModal"
        @save="saveResult"
    />
</template>

<script>
    import {useForm} from "@inertiajs/inertia-vue3"
    import { Inertia }              from "@inertiajs/inertia"
    import AntTable                 from "ant-design-vue/lib/table"
    import { SearchOutlined }       from "@ant-design/icons-vue"
    import { default as AButton }   from "ant-design-vue/lib/button"
    import { default as AInput }    from "ant-design-vue/lib/input"
    import FilterDropdown           from "ant-design-vue/lib/table/filterDropdown"
    import { message as AMessage }  from "ant-design-vue"
    import {
        reactive,
        ref,
        toRefs,
        watch,
        computed,
        onMounted,
    }                               from "vue"
    import ButtonComponent          from "../../../components/Admin/Buttons/Button"
    import CancelButton             from "../../../components/Admin/Buttons/CancelButton"
    import PrimaryButton            from "../../../components/Admin/Buttons/PrimaryButton"
    import ActionButton             from "../../../components/Admin/Buttons/ActionButton"
    import SecondaryButton          from "../../../components/Admin/Buttons/SecondaryButton"
    import InputComponent           from "../../../components/Admin/Forms/Input"
    import LabelComponent           from "../../../components/Admin/Forms/Label"
    import Icon                     from "../../../components/Admin/General/Icon"
    import Pagination               from "../../../components/Admin/General/Pagination"
    import AdminLayout              from "../../../components/Admin/Layout/AdminLayout"
    import Modal                    from "../../../components/Admin/Modal"
    import { get as getFromObject } from "lodash"
    import ActionButtonWrapper      from "../../../components/Admin/Buttons/ActionButtonWrapper"
    import PatientName              from "../../../components/Admin/General/PatientName"
    import { DatePicker }           from "v-calendar"
    import Dropdown  from "../../../components/Admin/General/Dropdown"
    import {
        IGG_ANTIBODY,
        IGM_ANTIBODY,
        SARS_COV_2_RT_PCR,
    }                from "../../../constants/tests"
    import { isPCR } from "../../../helpers"
    import UpdateResultModal        from "./Partials/UpdateResultModal"

    const TAB_PENDING_RESULTS = "pending_results"
    const TAB_COMPLETED_RESULTS = "completed_results"
    const RESULT_APPROVED = "result_approved"
    const SEND_EMAIL = "send_pdf"
    const DOWNLOAD_PDF = "download_pdf"

    export default {
        name: "Index",
        components: {
            UpdateResultModal,
            CancelButton,
            ActionButtonWrapper,
            ActionButton,
            PatientName,
            InputComponent,
            LabelComponent,
            Modal,
            Icon,
            Pagination,
            AdminLayout,
            AntTable,
            AInput,
            AButton,
            PrimaryButton,
            SecondaryButton,
            SearchOutlined,
            FilterDropdown,
            AMessage,
            Dropdown,
            ButtonComponent,
            DatePicker,
        },
        props: {
            // pendingResults: { type: Object, required: true },
            // completedResults: { type: Object, required: true },
        },
        setup(props) {
            const appState = reactive({
                showUpdateResultModal: false,
                updateResultModalRecord: {
                    id: '',
                    test_name: '',
                    result: {},
                    resultOptions: new Map(),
                },
                showApproveResultModal: false,
                approveModalSampleID: null,
                sampleApprovalRemarks: "",
                currentTab: TAB_PENDING_RESULTS,
                searchValue: "",
                searchText: "",
                searchedColumn: "",
                showFilterDropDown: false,
                pendingResultDateRange: {
                    start: null,
                    end: null,
                },
                pendingResults: {
                    data: [],
                    pagination: {
                        current: 1,
                        total: 0,
                        pageSize: 20,
                        from: 1,
                    },
                    queries: {
                        patient_name: null,
                        from: null,
                        to: null,
                    },
                    loading: true,
                },
                completedResults: {
                    data: [],
                    pagination: {
                        current: 1,
                        total: 0,
                        pageSize: 20,
                        from: 1,
                    },
                    queries: {
                        patient_name: "",
                    },
                    loading: true,
                },
            })

            const updateResultForm = useForm("updateResultForm", {
                test_name: "",
                result: "",
            })

            const searchInput = ref()

            const masks = reactive({
                input: "YYYY-MM-DD",
            })

            const datePickerConfig = {
                type: "string",
                mask: "MM-DD-YYYY", // Uses 'iso' if missing
            }

            const handleEndOpenChange = () => {
                console.log({ range })
            }

            const query = reactive({
                searchIndex: null,
                limit: 15,
                sort_by: null,
                sort_of: null,
            })

            const filteredInfo = ref()
            const sortedInfo = ref()

            const pendingResultTableColumns = computed(() => {
                const filtered = filteredInfo.value || {}
                const sorted = sortedInfo.value || {}
                return [
                    {
                        title: "Action",
                        key: "action",
                        slots: {
                            customRender: "action",
                        },
                    },
                    {
                        title: "REQ#",
                        dataIndex: "requisition_code",
                        key: "requisition_code",
                        sorter: (a, b) => a.requisition_code - b.requisition_code,
                        sortDirections: ["descend", "ascend"],
                    },
                    {
                        title: "Mail Sent",
                        dataIndex: "is_mail",
                        key: "is_mail",
                        // sorter: (a, b) => a.isMail - b.isMail,
                        // sortDirections: ["descend", "ascend"],
                    },
                    {
                        title: "Name",
                        dataIndex: "patient_name",
                        key: "patient_name",
                        sorter: (a, b) => a.patient_name.localeCompare(b.patient_name),
                        sortDirections: ["descend", "ascend"],
                        slots: {
                            filterDropdown: "filterSearch",
                            filterIcon: "filterIcon",
                            customRender: "patientName",
                        },
                        onFilter: (value, record) =>
                            record.patient_name.toString().toLowerCase().includes(value.toLowerCase()),
                        onFilterDropdownVisibleChange: visible => {
                            if (visible) {
                                setTimeout(() => {
                                    searchInput.value.focus()
                                }, 0)
                            }
                        },
                    },
                    {
                        title: "DOB",
                        dataIndex: "dob",
                        key: "dob",
                        sorter: (a, b) => a.dob_raw.localeCompare(b.dob_raw),
                        sortDirections: ["descend", "ascend"],
                    },
                    {
                        title: "Test Name",
                        slots: {
                            customRender: "testName",
                        },
                        width: "10rem",
                    },
                    {
                        title: "Specimen Received Date",
                        dataIndex: "sample_received_on",
                        key: "sample_received_on",
                        // sorter: (a, b) => a.sample_received_on_raw.localeCompare(b.sample_received_on_raw),
                        // sortDirections: ["descend", "ascend"],
                    },
                    {
                        title: "Test performed date",
                        slots: {
                            customRender: "testDate",
                        },
                        width: "10rem",
                    },
                ]
            })

            const completedResultTableColumns = computed(() => {
                const filtered = filteredInfo.value || {}
                const sorted = sortedInfo.value || {}
                return [
                    {
                        title: "Action",
                        key: "action",
                        slots: {
                            customRender: "action",
                        },
                    },
                    {
                        title: "REQ#",
                        dataIndex: "requisition_code",
                        key: "requisition_code",
                        sorter: (a, b) => a.requisition_code - b.requisition_code,
                        sortDirections: ["descend", "ascend"],
                    },
                    {
                        title: "Mail Sent",
                        dataIndex: "is_mail",
                        key: "is_mail",
                        // sorter: (a, b) => a.isMail - b.isMail,
                        // sortDirections: ["descend", "ascend"],
                    },
                    {
                        title: "Name",
                        dataIndex: "patient_name",
                        key: "patient_name",
                        sorter: (a, b) => a.patient_name.localeCompare(b.patient_name),
                        sortDirections: ["descend", "ascend"],
                        slots: {
                            filterDropdown: "filterSearch",
                            filterIcon: "filterIcon",
                            customRender: "patientName",
                        },
                        onFilter: (value, record) =>
                            record.patient_name.toString().toLowerCase().includes(value.toLowerCase()),
                        onFilterDropdownVisibleChange: visible => {
                            if (visible) {
                                setTimeout(() => {
                                    searchInput.value.focus()
                                }, 0)
                            }
                        },
                    },
                    {
                        title: "DOB",
                        dataIndex: "dob",
                        key: "dob",
                        sorter: (a, b) => a.dob_raw.localeCompare(b.dob_raw),
                        sortDirections: ["descend", "ascend"],
                    },
                    {
                        title: "Test Name",
                        slots: {
                            customRender: "testName",
                        },
                        width: "10rem",
                    },
                    {
                        title: "Test performed date",
                        key: "test_performed_date",
                        slots: {
                            customRender: "testDate",
                        },
                        width: "10rem",
                    },
                    {
                        title: "Result",
                        slots: {
                            customRender: "results",
                        },
                        width: "10rem",
                    },
                ]
            })

            const pendingResultTableData = ref([])

            let completedResultTableData = ref([])

            function showTab(tab) {
                appState.searchValue = ""
                appState.searchText = ""
                appState.searchedColumn = ""
                handleCompletedResultsChange()
                handlePendingResultsChange()
                appState.currentTab = tab
            }

            let approveResultTableData = ref([])

            const approveResultTableColumns = [
                {
                    title: "",
                    key: "view",
                    slots: {
                        customRender: "view",
                    },
                },
                {
                    title: "Test Name",
                    dataIndex: "test_name",
                    key: "test_name",
                },
                {
                    title: "Result Date",
                    dataIndex: "result_date",
                    key: "result_date",
                },
                {
                    title: "Result",
                    dataIndex: "result",
                    key: "result",
                    slots: {
                        customRender: "results",
                    },
                },
                {
                    title: "Action",
                    key: "action",
                    slots: {
                        customRender: "action",
                    },
                },
            ]

            function openApproveResultModal(sampleId) {
                appState.showApproveResultModal = true
                appState.approveModalSampleID = sampleId
                let sample = appState.pendingResults.data.find(item => item.id === sampleId)
                appState.sampleApprovalRemarks = sample.remarks
                sample.sample_tests.forEach(item => {
                    approveResultTableData.value.push({
                        key: item.id,
                        id: item.id,
                        sample_id: sampleId,
                        test_name: item.test_name,
                        test_name_raw: item.test_name_raw,
                        result: item.result,
                        result_date: item.result_date,
                        result_date_raw: item.result_date_raw,
                        status: item.status,
                    })
                })
            }

            const getResultOptions = (testName) => {
                const resultOptions = new Map()
                if (isPCR(testName)) {
                    resultOptions.set("detected", "Detected")
                    resultOptions.set("not_detected", "Not Detected")
                } else {
                    resultOptions.set("positive", "Positive")
                    resultOptions.set("negative", "Negative")
                }

                return resultOptions
            }

            function editResult(record) {
                appState.updateResultModalRecord.id = getFromObject(record, 'id')
                appState.updateResultModalRecord.test_name = getFromObject(record, 'test_name_raw')
                appState.updateResultModalRecord.result = getFromObject(record, 'result')
                appState.updateResultModalRecord.resultOptions = getResultOptions(appState.updateResultModalRecord.test_name)
                openUpdateResultModal()
            }

            function openUpdateResultModal() {
                appState.showUpdateResultModal = true
            }

            function closeUpdateResultModal() {
                appState.showUpdateResultModal = false
            }

            function approveResult(sampleTestId) {
                const targetUrl = route("api.results.approve", {
                    sample_test_id: sampleTestId,
                    status: RESULT_APPROVED,
                })

                axios.post(targetUrl).then(response => {
                        loadCompletedResultsData()
                        loadPendingResultsData()

                        let sample = getFromObject(response, "data.data", {})
                        approveResultTableData.value = []
                        appState.sampleApprovalRemarks = sample.remarks
                        sample.sample_tests.forEach(item => {
                            approveResultTableData.value.push({
                                key: item.id,
                                id: item.id,
                                sample_id: getFromObject(sample, "id"),
                                test_name: item.test_name,
                                test_name_raw: item.test_name_raw,
                                result: item.result,
                                result_date: item.result_date,
                                result_date_raw: item.result_date_raw,
                                status: item.status,
                            })
                        })
                    },
                )
            }

            function sendEmailOfPdf(sampleTestId, pdfName) {
                Inertia.get(route("admin.results.pdf", {
                    _query: {
                        sample_test_id: sampleTestId,
                        action: SEND_EMAIL,
                        pdf_name: pdfName,
                    },
                }), {}, {
                    preserveState: true,
                    preserveScroll: true,
                    onSuccess: page => {
                        loadCompletedResultsData()
                        AMessage.success("Email sent")
                    },
                })
            }

            function downloadPdf(sampleTestId, pdfName) {
                return (route("admin.results.pdf", {
                    _query: {
                        sample_test_id: sampleTestId,
                        action: DOWNLOAD_PDF,
                        pdf_name: pdfName,
                    },
                }))
            }

            function saveRemarks(sampleId) {
                if (!sampleId) {
                    return
                }

                if (!appState.sampleApprovalRemarks) {
                    let sample = findBySampleId(appState.pendingResults.data, sampleId)

                    if (!sample || !Array.isArray(getFromObject(sample, "sample_tests"))) {
                        closeApproveResultModal()
                        return
                    }
                    let sampleCompleted = sample.sample_tests.every(item => {
                        item.status === RESULT_APPROVED
                    })

                    // console.log(sampleCompleted)

                    if (sampleCompleted) {
                        closeApproveResultModal()
                    }
                }

                const targetUrl = route("admin.results.save_remarks", {
                    sample_id: sampleId,
                })
                Inertia.post(targetUrl, {
                    remarks: appState.sampleApprovalRemarks,
                }, {
                    onSuccess: () => {
                        loadCompletedResultsData()
                        loadPendingResultsData()
                        closeApproveResultModal()
                    },
                })
            }

            function setUploadResultForm(record) {
                updateResultForm.test_name  = appState.updateResultModalRecord.test_name
                if(isPCR(appState.updateResultModalRecord.test_name)) {
                    updateResultForm.result = {
                        [SARS_COV_2_RT_PCR]: {
                            test_result: getFromObject(record, `${SARS_COV_2_RT_PCR}`)
                        }
                    }
                }
                else {
                    updateResultForm.result = {
                        [IGG_ANTIBODY]: {
                            test_result: getFromObject(record, `${IGG_ANTIBODY}`)
                        },
                        [IGM_ANTIBODY]: {
                            test_result: getFromObject(record, `${IGM_ANTIBODY}`)
                        },
                    }
                }

            }

            function saveResult(result) {
                setUploadResultForm(result)

                updateResultForm.post(route("admin.results.update", appState.updateResultModalRecord.id), {
                    preserveScroll: true,
                    onSuccess: () => {
                        loadPendingResultsData()
                        closeUpdateResultModal()
                    },
                    onError: () => {
                        console.log("Error")
                    },
                })
            }

            const clearApprovalModal = () => {
                appState.approveModalSampleID = null
                appState.sampleApprovalRemarks = ""
                approveResultTableData.value = []
                appState.showApproveResultModal = false
            }

            function closeApproveResultModal() {
                clearApprovalModal()
            }

            const handleSearch = (selectedKeys, confirm, dataIndex) => {
                confirm()
                appState.searchText = selectedKeys[0]
                appState.searchedColumn = dataIndex
            }

            const handleReset = clearFilters => {
                clearFilters()
                appState.searchText = ""
            }

            const findBySampleId = (resultList, sampleId) => {
                if (!Array.isArray(resultList)) {
                    return null
                }

                return resultList.find((item) => {
                    return item.id === sampleId
                })
            }

            function showResult(id) {
                return route("admin.results.show", id)
            }

            const loadOrder = (sorter) => {
                if (!sorter) {
                    return ""
                }
                const order = getFromObject(sorter, "order", "ascend")
                const column = getFromObject(sorter, "field", "")
                return order === "ascend" ? `${column}` : `-${column}`
            }

            const handlePendingResultsChange = (pager, filters, sorter) => {
                appState.pendingResults.pagination = { ...pager }
                appState.pendingResults.queries.order = loadOrder(sorter)
                // console.log(filters)
                appState.pendingResults.queries.patient_name = getFromObject(filters, "patient_name.0", "")

                loadPendingResultsData()
            }

            const handleCompletedResultsChange = (pager, filters, sorter) => {
                appState.completedResults.pagination = { ...pager }
                appState.completedResults.queries.order = loadOrder(sorter)
                // console.log(filters)
                appState.completedResults.queries.patient_name = getFromObject(filters, "patient_name.0", "")

                loadCompletedResultsData()
            }

            const loadPendingResultsListTableData = (dataArray) => {
                if (!dataArray || !Array.isArray(dataArray)) {
                    return
                }
                appState.pendingResults.data = dataArray

                pendingResultTableData.value = []

                dataArray.forEach((item, index) => {
                    pendingResultTableData.value.push({
                        key: index + appState.pendingResults.pagination.from,
                        sample_id: item.id ?? "",
                        test_name: item.test_name ?? "",
                        patient_name: item.patient_name ?? "",
                        dob: item.dob ?? "",
                        dob_raw: item.dob_raw ?? "",
                        sample_received_on: item.sample_received_on ?? "",
                        sample_received_on_raw: item.sample_received_on_raw ?? "",
                        sample_type: item.sample_type ?? "",
                        requisition_no: item.requisition_id ?? "",
                        requisition_code: item.requisition_code ?? "",
                        is_mail: item.is_mail,
                        sample_tests: getFromObject(item, "sample_tests", []),
                    })
                })
            }

            const loadCompletedResultsListTableData = (dataArray) => {

                if (!dataArray || !Array.isArray(dataArray)) {
                    return
                }
                appState.completedResults.data = dataArray

                completedResultTableData.value = []

                dataArray.forEach((item, index) => {
                    completedResultTableData.value.push({
                        key: index + appState.completedResults.pagination.from,
                        sample_id: item.id ?? "",
                        test_name: item.test_name ?? "",
                        patient_name: item.patient_name ?? "",
                        dob: item.dob ?? "",
                        dob_raw: item.dob_raw ?? "",
                        sample_received_on: item.sample_received_on ?? "",
                        sample_received_on_raw: item.sample_received_on_raw ?? "",
                        sample_type: item.sample_type ?? "",
                        requisition_no: item.requisition_id ?? "",
                        requisition_code: item.requisition_code ?? "",
                        is_mail: item.is_mail,
                        sample_tests: getFromObject(item, 'sample_tests', [])
                    })
                })

                // console.log({completedResultTableData})

            }

            const loadPendingResultsData = () => {
                appState.pendingResults.loading = true
                axios.get("/api/results/pending", {
                    params: {
                        page: appState.pendingResults.pagination.current,
                        ...appState.pendingResults.queries,
                    },
                }).then(({ data }) => {
                    // console.log(data)
                    appState.pendingResults.pagination.current = (getFromObject(data, "meta.current_page", 1))
                    appState.pendingResults.pagination.total = (getFromObject(data, "meta.total", 0))
                    appState.pendingResults.pagination.pageSize = (getFromObject(data, "meta.per_page", 20))
                    appState.pendingResults.pagination.from = (getFromObject(data, "meta.from", 1))

                    loadPendingResultsListTableData(getFromObject(data, "data", []))

                    appState.pendingResults.loading = false
                }).catch((error) => {
                    appState.pendingResults.loading = false
                    console.error(error)
                })
            }

            async function getCompletedResultsData() {
                const response = await axios.get("/api/results/completed", {
                    params: {
                        page: appState.completedResults.pagination.current,
                        ...appState.completedResults.queries,
                    },
                })

                return getFromObject(response, "data.data", [])
            }

            const loadCompletedResultsData = () => {
                appState.completedResults.loading = true
                axios.get("/api/results/completed", {
                    params: {
                        page: appState.completedResults.pagination.current,
                        ...appState.completedResults.queries,
                    },
                }).then(({ data }) => {
                    appState.completedResults.pagination.current = (getFromObject(data, "meta.current_page", 1))
                    appState.completedResults.pagination.total = (getFromObject(data, "meta.total", 0))
                    appState.completedResults.pagination.pageSize = (getFromObject(data, "meta.per_page", 20))
                    appState.completedResults.pagination.from = (getFromObject(data, "meta.from", 1))

                    loadCompletedResultsListTableData(getFromObject(data, "data", []))

                    appState.completedResults.loading = false
                }).catch((error) => {
                    appState.completedResults.loading = false
                    console.error(error)
                })
            }

            function applyPendingTabFilters() {
                appState.pendingResults.queries.from = getFromObject(appState, "pendingResultDateRange.start", "")
                appState.pendingResults.queries.to = getFromObject(appState, "pendingResultDateRange.end", "")
                loadPendingResultsData()
                appState.showFilterDropDown = false
            }

            function clearPendingTabFilters() {
                appState.pendingResults.queries = {
                    patient_name: null,
                    from: null,
                    to: null,
                }
                searchInput.value = null
                filteredInfo.value = null
                sortedInfo.value = null
                appState.searchText = ""
                appState.searchedColumn = ""
                appState.pendingResultDateRange.start = ""
                appState.pendingResultDateRange.end  = ""
                appState.showFilterDropDown = false
                loadPendingResultsData()
            }

            onMounted(() => {
                loadPendingResultsData()
                loadCompletedResultsData()
            })

            return {
                ...toRefs(appState),
                openApproveResultModal,
                closeApproveResultModal,
                query,
                pendingResultTableColumns,
                completedResultTableColumns,
                pendingResultTableData,
                completedResultTableData,
                approveResultTableColumns,
                approveResultTableData,
                handlePendingResultsChange,
                handleCompletedResultsChange,
                approveResult,
                saveRemarks,
                TAB_PENDING_RESULTS,
                TAB_COMPLETED_RESULTS,
                showTab,
                RESULT_APPROVED,
                searchInput,
                handleReset,
                handleSearch,
                showResult,
                sendEmailOfPdf,
                downloadPdf,
                handleEndOpenChange,
                masks,
                datePickerConfig,
                applyPendingTabFilters,
                clearPendingTabFilters,
                closeUpdateResultModal,
                editResult,
                saveResult
            }
        },
    }
</script>

<style scoped>

</style>

<template>
    <div>
        <hr v-if="index > 0" class="border border-grey-300 border-dashed my-6">
        <div class="grid gap-6">
            <div class="grid md:grid-cols-3 gap-10 gap-y-6">
                <div
                    :class="(v$.test_type.$error || getFromObject(errors, 'samples.test_type')) ? 'form-group input-error' : 'form-group'">

                    <label-component :value="'Test Type'"/>
                    <select-input name="test-type" v-model="test_type" :options="testTypeOptions"
                                  place-holder="Select Test Type"/>
                    <input-error
                        v-if="getFromObject(errors, `samples.${index}.test_type`)"
                        :message="getFromObject(errors, `samples.${index}.test_type`)"/>
                    <input-error v-if="v$.test_type.$error" :message="v$.test_type.$errors[0].$message"/>
                </div>
                <div
                    :class="(v$.collected_on.$error || getFromObject(errors, 'samples.collected_on')) ? 'form-group input-error' : 'form-group'">
                    <label-component v-text="'Sample Collection Date and Time'"/>
                    <date-picker name="collected-on"
                                 v-model="collected_on"
                                 mode="dateTime"
                                 :max-date="currentDate"
                                 :model-config="datePickerConfig"
                                 :popover="{ visibility: 'focus' }"
                                 :masks="{ inputDateTime: 'MM-DD-YYYY h:mmA'}">
                        <template v-slot="{ inputValue, inputEvents }">
                            <div class="form-icon">
                                <input-component
                                    :value="inputValue"
                                    v-on="inputEvents"
                                    placeholder="Select Date and Time"
                                />
                                <Icon name="date-time"/>
                            </div>
                        </template>
                    </date-picker>
                    <input-error
                        v-if="getFromObject(errors, `samples.${index}.collected_on`)"
                        :message="getFromObject(errors, `samples.${index}.collected_on`)"/>
                    <input-error v-if="v$.collected_on.$error" :message="v$.collected_on.$errors[0].$message"/>
                </div>
                <div
                    :class="(v$.received_on.$error || getFromObject(errors, 'samples.received_on')) ? 'form-group input-error' : 'form-group'">

                    <label-component v-text="'Sample Received Date and Time'"/>
                    <date-picker
                        name="received-on"
                        v-model="received_on"
                        mode="dateTime"
                        :is24hr="false"
                        :max-date="currentDate"
                        :model-config="datePickerConfig"
                        :popover="{ visibility: 'focus' }"
                        :masks="{ inputDateTime: 'MM-DD-YYYY h:mmA'}">
                        <template v-slot="{ inputValue, inputEvents }">
                            <div class="form-icon">
                                <input-component
                                    :value="inputValue"
                                    v-on="inputEvents"
                                    placeholder="Select Date and Time"
                                />
                                <Icon name="date-time"/>
                            </div>
                        </template>
                    </date-picker>
                    <input-error
                        v-if="getFromObject(errors, `samples.${index}.received_on`)"
                        :message="getFromObject(errors, `samples.${index}.received_on`)"/>
                    <input-error v-if="v$.received_on.$error" :message="v$.received_on.$errors[0].$message"/>
                </div>
                <div
                    :class="(v$.type.$error || getFromObject(errors, `samples.${index}.type`)) ? 'form-group input-error' : 'form-group'">

                    <label-component :value="'Sample Type'"/>
                    <select-input name="type" v-model="type" :options="sampleTypeOptions"
                                  place-holder="Select Sample Type"/>
                    <input-error
                        v-if="getFromObject(errors, `samples.${index}.type`)"
                        :message="getFromObject(errors, `samples.${index}.type`)"/>
                    <input-error v-if="v$.type.$error" :message="v$.type.$errors[0].$message"/>
                </div>
                <div
                    :class="(v$.collected_by.$error ||getFromObject(errors, `samples.${index}.collected_by`)) ? 'form-group input-error' : 'form-group'">

                    <label-component :value="'Sample Collected By'"/>
                    <input-component type="text" name="collected_by" v-model="collected_by"/>
                    <input-error
                        v-if="getFromObject(errors, `samples.${index}.collected_by`)"
                        :message="getFromObject(errors, `samples.${index}.collected_by`)"/>
                    <input-error v-if="v$.collected_by.$error" :message="v$.collected_by.$errors[0].$message"/>
                </div>
            </div>
            <div :class="showAddButton && index > 0 ? 'grid md:grid-cols-2 gap-10': ''">
                <div v-if="showAddButton" @click="addSample">
                    <add-button>Add another sample</add-button>
                </div>
                <delete-button
                    v-if="showDeleteButton && sampleCount > 1 && sampleCount !== index"
                    @click="removeSample">Delete
                    this sample
                </delete-button>
            </div>
        </div>
    </div>
</template>

<script>
import {useVuelidate} from "@vuelidate/core"
import {required} from "@vuelidate/validators"
import {get as getFromObject} from "lodash"
import {DatePicker} from "v-calendar"
import {
    computed,
    inject,
    reactive,
    toRefs,
    watch,
} from "vue"
import AddButton from "../../../../components/Admin/Buttons/AddButton"
import DeleteButton from "../../../../components/Admin/Buttons/DeleteButton"
import InputComponent from "../../../../components/Admin/Forms/Input"
import InputError from "../../../../components/Admin/Forms/InputError"
import LabelComponent from "../../../../components/Admin/Forms/Label"
import SelectInput from "../../../../components/Admin/Forms/SelectInput"
import Icon from "../../../../components/Admin/General/Icon"

export default {
    name: "RequisitionSample",
    components: {AddButton, DeleteButton, SelectInput, InputError, DatePicker, InputComponent, LabelComponent, Icon},
    props: {
        name: {type: String, required: true},
        index: {type: Number, required: false, default: 0},
        sample: {type: Object, required: false, default: () => ({})},
        sampleCount: {type: Number, required: false, default: 0},
        selectedTests: {type: Array, required: false, default: () => ([])},
        errors: {required: true},
        isEdit: {required: false, default: false},
    },
    inheritAttrs: false,
    emits: ["input", "add", "remove"],
    setup(props, {emit}) {
        const sampleTypeOptions = inject("sampleTypeOptions")
        const testOptions = inject("testOptions")

        const currentDate = new Date()
        const datePickerConfig = {
            type: "string",
            mask: "MM-DD-YYYY h:mmA", // Uses 'iso' if missing
        }

        const appState = reactive({
            id: null,
            test_type: null,
            collected_on: currentDate,
            received_on: currentDate,
            type: null,
            collected_by: null,
            test_names: [],
        })

        const resultValue = computed(() => {
            return {
                id: appState.id,
                test_type: appState.test_type,
                collected_on: appState.collected_on,
                received_on: appState.received_on,
                type: appState.type,
                collected_by: appState.collected_by,
            }
        })

        watch(resultValue, () => {
            emit("input", resultValue.value)
        })

        function addSample() {
            emit("add")
        }

        function removeSample() {
            emit("remove")
        }

        let modelValue = getFromObject(props, "sample")

        // watch(() => modelValue, (newValue, oldValue) => {
        //     console.log(props.sample)
        //     console.log(modelValue, newValue)
        //     appState.test_type = newValue.test_type
        //     appState.collected_on = newValue.collected_on ?? currentDate
        //     appState.received_on = newValue.received_on
        //     appState.type = newValue.type
        //     appState.collected_by = newValue.collected_by
        // }, { deep: true, immediate: true })

        const rules = computed(() => ({
            collected_on: {required},
            received_on: {required},
            test_type: {
                required,
            },
            type: {required},
            collected_by: {required},
        }))

        const v$ = useVuelidate(rules, appState)

        return {
            ...toRefs(appState),
            v$,
            testOptions,
            currentDate,
            datePickerConfig,
            getFromObject,
            sampleTypeOptions,
            addSample,
            removeSample,
        }
    },
    watch: {
        sample: {
            handler(value) {
                this.id = value.id ?? null
                this.test_type = value.test_type
                this.collected_on = value.collected_on
                this.received_on = value.received_on
                this.type = value.type
                this.collected_by = value.collected_by
            }
            ,
            immediate: true,
        },
    },
    computed: {
        testTypeOptions() {
            if (!Array.isArray(this.selectedTests)) {
                return this.testOptions
            }

            if (this.selectedTests.length === 0) {
                return this.testOptions
            }

            const options = new Map()

            if (this.selectedTests.length === 1 && this.testOptions.has(this.selectedTests[0])) {
                options.set(this.selectedTests[0], this.testOptions.get(this.selectedTests[0]))
                return options
            }

            this.selectedTests.forEach(key => options.set(key, this.testOptions.get(key)))
            return options
        },
        showAddButton() {
            return this.index === (this.sampleCount - 1)
        },
        showRemoveButton() {
            return this.index > 0
        },
        showDeleteButton() {
            return !(this.isEdit && this.id);
        }
    },
}
</script>

<style scoped>

</style>

<template>
    <div class="form-header flex items-center pb-4 mb-6">
        <Icon class="mr-3 !w-7 h-7" name="payment-method-1"/>
        <h3 class="heading-primary heading-primary-sm" v-text="'Payment Method'"/>
    </div>
    <div class="grid gap-8">
        <div class="grid ">
            <h3 class="heading-secondary-sm pb-6" v-text="'Payment'"/>
            <radio-group-input
                name="payment_type"
                :options="paymentMethodOptions"
                v-model="payment_type"
                @change="resetPayments"/>
            <input-error
                v-if="getFromObject(errors, 'payment_method.payment_type')"
                :message="getFromObject(errors, 'payment_method.payment_type')"/>
            <input-error
                v-if="v$.payment_type.$error"
                :message="v$.payment_type.$errors[0].$message"/>
        </div>
        <div v-if="payment_type === PAYMENT_SELF">
            <h3 class="heading-secondary-sm pb-6" v-text="'Self Payment'"/>
            <div class="grid md:grid-cols-2 gap-6">
                <div
                    :class="(v$.driver_license.$error || getFromObject(errors, 'payment_method.driver_license')) ? 'form-group input-error' : 'form-group'">
                    <label-component v-text="'Driver’s License / Identification Number'"/>
                    <input-component
                        type="text"
                        name="driver_license"
                        v-model="driver_license"/>
                    <input-error
                        v-if="getFromObject(errors, 'payment_method.driver_license')"
                        :message="getFromObject(errors, 'payment_method.driver_license')"/>
                    <input-error v-if="v$.driver_license.$error" :message="v$.driver_license.$errors[0].$message"/>
                </div>
            </div>
        </div>
        <div v-if="payment_type === PAYMENT_INSURANCE">
            <h4 class="font-bold mb-8" v-text="'Insurance'"/>
            <div class="grid md:grid-cols-2 gap-6">
                <div :class="(v$.insurance_name.$error || getFromObject(errors, 'payment_method.insurance_name')) ? 'form-group input-error' :
                    'form-group'">
                    <label-component v-text="'Insurance Name'"/>
                    <input-component type="text" name="insurance_name" v-model="insurance_name"/>
                    <input-error
                        v-if="getFromObject(errors, 'payment_method.insurance_name')"
                        :message="getFromObject(errors, 'payment_method.insurance_name')"/>
                    <input-error v-if="v$.insurance_name.$error" :message="v$.insurance_name.$errors[0].$message"/>
                </div>
                <div :class="(v$.insurance_holder_name.$error || getFromObject(errors, 'payment_method.insurance_holder_name')) ? 'form-group input-error' :
                    'form-group'">
                    <label-component v-text="'Primary Insurance Holder’s Name'"/>
                    <input-component type="text" name="insurance_holder_name" v-model="insurance_holder_name"/>
                    <input-error
                        v-if="getFromObject(errors, 'payment_method.insurance_holder_name')"
                        :message="getFromObject(errors, 'payment_method.insurance_holder_name')"/>
                    <input-error v-if="v$.insurance_holder_name.$error"
                                 :message="v$.insurance_holder_name.$errors[0].$message"/>
                </div>

                <div :class="(v$.insurance_id.$error || getFromObject(errors, 'payment_method.insurance_id')) ? 'form-group input-error' :
                    'form-group'">
                    <label-component v-text="'Insurance ID'"/>
                    <input-component type="text" name="insurance_id" v-model="insurance_id"/>
                    <input-error
                        v-if="getFromObject(errors, 'payment_method.insurance_id')"
                        :message="getFromObject(errors, 'payment_method.insurance_id')"/>
                    <input-error v-if="v$.insurance_id.$error" :message="v$.insurance_id.$errors[0].$message"/>
                </div>
                <div :class="(v$.insurance_group.$error || getFromObject(errors, 'payment_method.insurance_group')) ? 'form-group input-error' :
                    'form-group'">
                    <label-component v-text="'Insurance Group'"/>
                    <input-component type="text" name="insurance_group" v-model="insurance_group"/>
                    <input-error
                        v-if="getFromObject(errors, 'payment_method.insurance_group')"
                        :message="getFromObject(errors, 'payment_method.insurance_group')"/>
                    <input-error v-if="v$.insurance_group.$error" :message="v$.insurance_group.$errors[0].$message"/>
                </div>

                <div :class="(v$.insurance_document.$error || getFromObject(errors, 'payment_method.insurance_document')) ?
                    'form-group input-error' :
                    'form-group'">
<!--                    <label-component v-text="'Upload Insurance Document (Optional)'"/>-->
<!--                    <div class="file__input-wrap rounded">-->
<!--                        <input type="file" class="file__input" accept="image/*,.pdf" @input="inputFile">-->
<!--                        <span v-if="hasUploadedFile" v-text="uploadedFileName"/>-->
<!--                        <span v-if="!hasUploadedFile" class="pl-2 text-sm">No file Choosen</span>-->
<!--                        <span class="file__input-trigger" v-text="'Choose File'"/>-->
<!--                    </div>-->
                    <file-input @input-file="inputFile" @clear-file="clearFile"/>
<!--                    <label-component class="pt-2 !pb-0 !text-xs"-->
<!--                                     :value="'Max 5MB of PDF or image (jpeg, jpg, png) file can be uploaded '"></label-component>-->
                    <input-error
                        v-if="getFromObject(errors, 'payment_method.insurance_document')"
                        :message="getFromObject(errors, 'payment_method.insurance_document')"/>
                    <input-error v-if="v$.insurance_document.$error"
                                 :message="v$.insurance_document.$errors[0].$message"/>
                </div>
            </div>
        </div>
    </div>
    <form-footer v-if="showButtons">
        <cancel-button @click="goBack">Back</cancel-button>
        <primary-button @click="nextPage">Next</primary-button>
    </form-footer>
</template>

<script>
import {useVuelidate} from "@vuelidate/core"
import {
    helpers,
    required,
} from "@vuelidate/validators"
import {
    get as getFromObject,
    isArray,
} from "lodash"
import {
    computed,
    inject,
    reactive,
    toRefs,
    watch,
} from "vue"
import Button              from "../../../../components/Front/Buttons/Button"
import CancelButton        from "../../../../components/Front/Buttons/CancelButton"
import PrimaryButton       from "../../../../components/Front/Buttons/PrimaryButton"
import DropDownInput       from "../../../../components/Front/Forms/DropDownInput"
import FileInput           from "../../../../components/Front/Forms/FileInput"
import InputComponent      from "../../../../components/Front/Forms/Input"
import InputError          from "../../../../components/Front/Forms/InputError"
import LabelComponent      from "../../../../components/Front/Forms/Label"
import RadioGroupInput     from "../../../../components/Front/Forms/RadioGroupInput"
import Header              from "../../../../components/Front/Layout/Header"
import SidebarRegistration from "../../../../components/Front/Layout/SidebarRegistration"
import Icon                from "../../../../components/Front/General/Icon"
import FormFooter          from "../../../../components/Front/Layout/FormFooter";
import scrollToSmoothly    from "../../../../script";


const PAYMENT_SELF = "self"
const PAYMENT_INSURANCE = "insurance"

export default {
    name: "PaymentMethod",
    components: {
        FileInput,
        FormFooter,
        InputError,
        CancelButton,
        SidebarRegistration,
        Header,
        InputComponent,
        LabelComponent,
        DropDownInput,
        RadioGroupInput,
        Button,
        PrimaryButton,
        Icon,
    },
    inheritAttrs: false,
    emits: ["submit", "back", "update:modelValue", "next"],
    props: {
        modelValue: {type: Object, required: false, default: () => ({})},
        errors: {type: Object, required: false, default: () => ({})},
        showButtons: {type: Boolean, required: false, default: false},
    },
    setup(props, {emit}) {
        const paymentMethodOptions = inject("paymentMethodOptions")
        const paymentStatusOptions = inject("paymentStatusOptions")

        const appState = reactive({
            payment_type: PAYMENT_SELF,
            driver_license: null,
            insurance_name: null,
            insurance_holder_name: null,
            insurance_id: null,
            insurance_group: null,
            insurance_document: null,
        })

        const resultValue = computed(() => {
            return {
                payment_type: appState.payment_type,
                driver_license: appState.driver_license,
                insurance_name: appState.insurance_name,
                insurance_holder_name: appState.insurance_holder_name,
                insurance_id: appState.insurance_id,
                insurance_group: appState.insurance_group,
                insurance_document: appState.insurance_document,
            }
        })

        watch(resultValue, () => {
            emit("update:modelValue", resultValue.value)
        })

        let {modelValue} = toRefs(props)

        watch(() => modelValue.value, (newValue, oldValue) => {
            appState.payment_type = newValue.payment_type
            appState.driver_license = newValue.driver_license
            appState.insurance_name = newValue.insurance_name
            appState.insurance_holder_name = newValue.insurance_holder_name
            appState.insurance_id = newValue.insurance_id
            appState.insurance_group = newValue.insurance_group
            appState.insurance_document = newValue.insurance_document
        }, {deep: true, immediate: true})

        function resetPayments() {
            if (appState.payment_type === PAYMENT_SELF) {
                appState.insurance_name = null
                appState.insurance_holder_name = null
                appState.insurance_id = null
                appState.insurance_group = null
                appState.insurance_document = null
            }

            if (appState.payment_type === PAYMENT_INSURANCE) {
                appState.driver_license = null
            }
        }

        const hasPaymentTypeSelf = (value) => {
            if (!appState.payment_type) {
                return true
            }

            if (!(appState.payment_type === "self")) {
                return true
            }

            return !!value
        }

        const hasPaymentTypeInsurance = (value) => {
            if (!appState.payment_type) {
                return true
            }

            if (!(appState.payment_type === "insurance")) {
                return true
            }

            return !!value
        }

        const isValidFileSize = (options = {}) => {
            return helpers.withParams(options, value => {
                if (!value) {
                    return true
                }
                const fileSizeinKb = value.size / 1024
                const size = Math.round(fileSizeinKb * 100) / 100 // convert up to 2 decimal place
                return size <= options.maxFileSizeInB / 1024
            })
        }

        const rules = computed(() => ({
            payment_type: {
                required,
            },
            driver_license: {
                // requiredIfPaymentTypeSelf: helpers.withMessage("Required", hasPaymentTypeSelf),
            },
            insurance_name: {
                // requiredIfPaymentTypeSelf: helpers.withMessage("Required", hasPaymentTypeInsurance),
            },
            insurance_holder_name: {
                // requiredIfPaymentTypeSelf: helpers.withMessage("Required", hasPaymentTypeInsurance),
            },
            insurance_id: {
                // requiredIfPaymentTypeSelf: helpers.withMessage("Required", hasPaymentTypeInsurance),
            },
            insurance_group: {
                // requiredIfPaymentTypeSelf: helpers.withMessage("Required", hasPaymentTypeInsurance),
            },
            insurance_document: {
                // requiredIfPaymentTypeSelf: helpers.withMessage("Required", hasPaymentTypeInsurance),
            },
        }))

        const v$ = useVuelidate(rules, appState)

        return {
            v$,
            ...toRefs(appState),
            resetPayments,
            PAYMENT_SELF,
            PAYMENT_INSURANCE,
            getFromObject,
            paymentMethodOptions,
            paymentStatusOptions,
        }
    },
    data() {
        return {
            paymentMethod: "self",
            hasUploadedFile: false,
            uploadedFileName: undefined,
        }
    },
    methods: {
        nextPage() {
            this.v$.$touch()

            this.$nextTick(() => {
                const inputError = document.querySelectorAll('.input-error')[0]
                if (inputError) {
                    scrollToSmoothly(inputError.offsetTop - 84);
                }
            })


            if (this.v$.$error) return

            this.$emit("next")
        },
        goBack() {
            this.$emit("back")
        },
        inputFile(e) {
            if (e.length > 0) {
                this.insurance_document = e[0]
                this.hasUploadedFile = true
                this.uploadedFileName = this.insurance_document.name
            }
        },

        clearFile() {
            this.insurance_document = null
            this.hasUploadedFile = false
            this.uploadedFileName = ""
        }
    },
}
</script>

<style scoped>

</style>

<template>
    <div class="pattern">
        <Header/>
        <div class="header-footer-height-deduct flex container  !py-6 sm:!py-8">
            <transition
                name="fade" appear>
                <div class="card card--large sm:w-3/5  sm:m-auto text-center !py-10 sm:!py-16 px-4 sm:!px-16">
                    <div class="flex justify-center">
                        <svg class="w-24 h-24" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0 30.0006L38.52 54.5156C38.96 54.8406 39.48 55.0006 40 55.0006C40.52 55.0006 41.04 54.8406 41.48 54.5156L80 30.0006L41.5 0.500627C40.61 -0.164373 39.39 -0.164373 38.5 0.500627L0 30.0006Z"
                                fill="#DFDBF9"/>
                            <path
                                d="M65 0H15C12.245 0 10 2.245 10 5V60C10 61.38 11.12 62.5 12.5 62.5H67.5C68.88 62.5 70 61.38 70 60V5C70 2.245 67.76 0 65 0Z"
                                fill="#F7F6FE"/>
                            <path
                                d="M22.5 15H57.5C58.88 15 60 13.88 60 12.5C60 11.12 58.88 10 57.5 10H22.5C21.12 10 20 11.12 20 12.5C20 13.88 21.12 15 22.5 15Z"
                                fill="#FF7ABE"/>
                            <path
                                d="M57.5 20H22.5C21.12 20 20 21.12 20 22.5C20 23.88 21.12 25 22.5 25H57.5C58.88 25 60 23.88 60 22.5C60 21.12 58.88 20 57.5 20Z"
                                fill="#FF7ABE"/>
                            <path
                                d="M42.5 30H22.5C21.12 30 20 31.12 20 32.5C20 33.88 21.12 35 22.5 35H42.5C43.88 35 45 33.88 45 32.5C45 31.12 43.88 30 42.5 30Z"
                                fill="#FF7ABE"/>
                            <path
                                d="M41.48 54.515C41.04 54.84 40.52 55 40 55C39.48 55 38.96 54.84 38.52 54.515L0 30V75C0 77.76 2.24 80 5 80H75C77.76 80 80 77.76 80 75V30L41.48 54.515Z"
                                fill="#7E6EE9"/>
                            <path
                                d="M75 80H5C2.195 80 0 77.805 0 75C0 74.205 0.38 73.455 1.02 72.985L38.52 47.985C38.96 47.66 39.48 47.5 40 47.5C40.52 47.5 41.04 47.66 41.48 47.985L78.98 72.985C79.62 73.455 80 74.205 80 75C80 77.805 77.805 80 75 80Z"
                                fill="#BFB7F4"/>
                        </svg>
                    </div>
                    <h3 class="heading-primary mt-10 mb-6">
                        Thank You
                        <span class="text-tertiary-100">!</span>
                    </h3>
                    <div class="mt-6 mb-8">
                        <p class="text-grey-700 leading-6">We have received your submission.</p>
                    </div>
                    <button-primary href="/">Back to Homepage</button-primary>
                </div>
            </transition>
        </div>
        <Footer/>
    </div>
</template>

<script>
import InputComponent from "../../components/Front/Forms/Input"
import LabelComponent from "../../components/Front/Forms/Label"
import Icon from "../../components/Front/General/Icon"
import Header from "../../components/Front/Layout/Header"
import SidebarRegistration from "../../components/Front/Layout/SidebarRegistration"
import DropDownInput from "../../components/Front/Forms/DropDownInput"
import RadioGroupInput from "../../components/Front/Forms/RadioGroupInput"
import ButtonPrimary from "../../components/Front/Buttons/PrimaryButton"
import Footer from "../../components/Front/General/Footer";

export default {
    name: "Thanks.vue",
    components: {
        Footer,
        ButtonPrimary,
        Icon,
        SidebarRegistration,
        Header,
        InputComponent,
        LabelComponent,
        DropDownInput,
        RadioGroupInput
    },
    data() {
        return {
            paymentMethod: "self",
        }
    },
}
</script>

<style lang="scss" scoped>

</style>

<template>
    <aside class="sidebar-reg lg:w-1/4">
        <div class="grid gap-6 sm:pt-1">
            <div class="sidebar-reg__title">
                <h3 class="heading-primary heading-primary-md" v-text="'Registration Form'"/>
            </div>
            <div class="sidebar-reg__list  relative grid gap-2 sm:gap-1.5">
                <div>
                    <a :class="classes( 'basic-information',basicInformationCompleted)"
                       href="javascript:;">
                        <Icon class="mr-2.5 block" name="user-1"/>
                        Basic Information
                        <span v-if="basicInformationCompleted">
                             <Icon class="ml-2.5" name="done"/>
                        </span>
                    </a>
                </div>
                <div>
                    <a :class="classes( 'test-booking',testBookingCompleted)"
                       href="javascript:;">
                        <Icon class="mr-2.5 block" name="date-time"/>
                        Covid Test Booking
                        <span v-if="testBookingCompleted">
                                <Icon class="ml-2.5" name="done"/>
                        </span>
                    </a>
                </div>
                <div>
                    <a :class="classes('payment-method',paymentMethodCompleted)"
                       href="javascript:;">
                        <Icon class="mr-2.5 block" name="payment-method"/>

                        Payment Method
                        <span v-if="paymentMethodCompleted">
                            <Icon class="ml-2.5" name="done"/>
                        </span>
                    </a>
                </div>
                <div>
                    <a :class="classes('disclaimer')"
                       href="javascript:;">
                        <Icon class="mr-2.5 block" name="disclaimer"/>
                        Disclaimer
                    </a>
                </div>
            </div>
        </div>
    </aside>
</template>

<script>
    import Icon from "../General/Icon"

    export default {
        name: "SidebarRegistration",
        components: { Icon },
        props: {
            title: { type: String, required: false },
            currentPageTitle: { type: String, required: true },
            basicInformationCompleted: { type: Boolean, required: false, default: false },
            testBookingCompleted: { type: Boolean, required: false, default: false },
            paymentMethodCompleted: { type: Boolean, required: false, default: false },
        },
        methods: {
            classes(pageTitle, ifCompleted) {
                let str
                if (this.currentPageTitle === pageTitle) {
                    str = "text-white bg-secondary-100 flex relative rounded py-3 px-4 sm:py-4 whitespace-nowrap  active"
                } else {
                    str = "flex relative rounded py-3 px-4 sm:py-4 whitespace-nowrap text-grey-600"
                }
                if (ifCompleted) {
                    str = "text-grey-1000 bg-grey-200 flex relative  rounded py-3 px-4 sm:py-4 whitespace-nowrap"
                }
                return str
            },

        },
    }
</script>

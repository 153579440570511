<template>
    <dropdown class="user-dropdown flex">
        <template v-slot:trigger>
            <a
                class="user-dropdown__trigger h-full !flex items-center gap-2 py-2 px-6 bg-pinkShade-900 text-pinkShade-200"
            >
                            <span
                                class="w-10 h-10 text-sm  hover:border-pinkShade-200 border-2 border-pinkShade-200 inline-flex items-center justify-center rounded-full"
                            >
                    <Icon name="user"/>
                    </span>
                <Icon name="caret-down" class="w-2 h-1"/>
            </a>
        </template>
        <template v-slot:content>
            <li class="dropdown__item">
                <a
                    href="/logout"
                    class="dropdown__link text-sm !py-3"
                >
                    Log out
                </a>
            </li>
        </template>
    </dropdown>
</template>
<script>
    import Icon     from "../General/Icon"
    import Dropdown from "../General/Dropdown"

    export default {
        components: { Icon, Dropdown },
    }
</script>


<template>
    <admin-layout :if-has-breadcrumb="true" :active-link="'requisitions'">
        <template #breadcrumb>
            <bread-crumb>
                <bread-crumb-item :href="requisitionIndexUrl" label="Requisitions"/>
                <bread-crumb-item label="Existing Patient Requisition"/>
            </bread-crumb>
        </template>
        <h1 class="heading-primary !text-grey-1100 pb-8"
            v-text="'Existing Patient Requisition'"/>
        <div
            class="search-req mb-6 w-full !px-0 !py-2.5 card--large flex relative !rounded">
            <search-select @search="onSearch"
                           @select="onSelect"
                           :placeholder="'Search by patient name'"
                           :options="patientOptions"
            />
        </div>
        <div class="h-full">
            <div v-if="hasPatient" class="card--large">
                <form @submit.prevent="submitForm">
                    <div class="grid gap-10">
                        <basic-information-new v-model="form.basic_information" :errors="form.errors"
                                               :show-buttons="false"/>
                        <test-booking-new v-model="form.test_information" :errors="form.errors"
                                          :show-appointment-date="false" :show-buttons="false"/>
                        <doctor-information v-model="form.doctor_information"/>
                        <payment-method-new v-model="form.payment_method" :errors="form.errors"/>
                        <div>
                            <card-header title="Sample"/>
                            <requisition-sample v-for="(sample, sampleIndex) in form.samples"
                                                :key="sampleIndex"
                                                :index="sampleIndex"
                                                :sample="sample"
                                                :name="'samples.' + sampleIndex"
                                                :sample-count="form.samples.length"
                                                :errors="form.errors"
                                                @input="form.samples[sampleIndex] = $event"
                                                @add="addSample"
                                                @remove="removeSample(sampleIndex)"
                            />
                        </div>
                    </div>
                    <form-footer>
                        <cancel-button :inertia-link="true" :href="requisitionIndexUrl">Cancel</cancel-button>
                        <primary-button @click="submitForm" class="btn" :disabled="form.processing">
                            <span v-if="form.processing">Creating Requisition</span>
                            <span v-else>Create Requisition</span>
                        </primary-button>
                    </form-footer>
                </form>
            </div>
            <EmptySearch v-else/>
        </div>
    </admin-layout>
</template>

<script>
import {useForm} from "@inertiajs/inertia-vue3"
import {useVuelidate} from "@vuelidate/core"
import {get as getFromObject} from "lodash"
import {
    computed,
    provide,
    reactive,
    ref,
    toRefs,
    watch,
} from "vue"
import BreadCrumb from "../../../components/Admin/BreadCrumb/BreadCrumb"
import BreadCrumbItem from "../../../components/Admin/BreadCrumb/BreadCrumbItem"
import CancelButton from "../../../components/Admin/Buttons/CancelButton"
import PrimaryButton from "../../../components/Admin/Buttons/PrimaryButton"
import CardHeader from "../../../components/Admin/General/CardHeader"
import AdminLayout from "../../../components/Admin/Layout/AdminLayout"
import SecondaryButton from "../../../components/Admin/Buttons/SecondaryButton"
import InputComponent from "../../../components/Admin/Forms/Input"
import Icon from "../../../components/Admin/General/Icon"
import BasicInformationNew from "./Partials/BasicInformationNew"
import EmptySearch from "./Partials/EmptySearch"
import PaymentMethodNew from "./Partials/PaymentMethodNew"
import TestBookingNew from "./Partials/TestBookingNew"
import RequisitionSample from "./Partials/RequisitionSample"
import SearchRequisition from "./Partials/SearchRequisition";
import SearchResults from "./Partials/SearchResults";
import DoctorInformation from "./Partials/DoctorInformation";
import {default as AntAutocomplete} from 'ant-design-vue/lib/auto-complete'
import {SearchOutlined} from '@ant-design/icons-vue';
import {default as AInput} from "ant-design-vue/lib/input"
import SearchSelect from "../../../components/Admin/Forms/SearchSelect";
import FormFooter from "../../../components/Admin/Layout/FormFooter";
import scrollToSmoothly from "../../../script";

export default {
    name: "ExistingPatientRequisition",
    components: {
        SearchSelect,
        FormFooter,
        DoctorInformation,
        SearchResults,
        SearchRequisition,
        EmptySearch,
        Icon,
        CardHeader,
        CancelButton,
        InputComponent,
        RequisitionSample,
        SecondaryButton,
        BreadCrumbItem,
        BreadCrumb,
        PaymentMethodNew,
        TestBookingNew,
        BasicInformationNew,
        AdminLayout,
        PrimaryButton,
        SearchOutlined,
        AntAutocomplete,
        AInput,
    },
    props: {
        raceList: {type: Object, required: true},
        genderList: {type: Object, required: true},
        ethnicityList: {type: Object, required: true},
        stateList: {type: Object, required: true},
        testList: {type: Object, required: true},
        pregnancyList: {type: Object, required: true},
        paymentMethodList: {type: Object, required: true},
        paymentStatusList: {type: Object, required: true},
        sampleTypeList: {type: Object, required: true},
        patients: {type: Object, required: false, default: () => ({})},
        clients: {type: Object, required: false, default: () => ({})}
    },
    setup(props) {
        const hasPatient = ref(false)
        const currentId = ref(0)
        const patientDetails = ref({})
        const patientOptions = ref([])
        const requisitionIndexUrl = route("admin.requisitions.index")
        const saveExistingPatientUrl = computed(() => {
            return route("admin.requisitions.form.new-patient.store", currentId.value)
        })

        const form = useForm("ExistingPatientRequisitionForm", {
            id: null,
            basic_information: {
                email: null,
                first_name: null,
                last_name: null,
                dob: null,
                gender: null,
                phone_number: null,
                race: null,
                ethnicity: null,
                street_address_one: null,
                street_address_two: null,
                city: null,
                state: null,
                zip_code: null,
            },
            test_information: {
                name: [],
                is_first_test: null,
                is_health_care_worker: null,
                has_symptom: null,
                symptom_date: null,
                is_hospitalized: null,
                is_in_icu: null,
                is_in_congregate_care: null,
                is_pregnant: null,
            },
            payment_method: {
                payment_type: "self",
                driver_license: null,
                payment_status: null,
                insurance_name: null,
                insurance_holder_name: null,
                insurance_id: null,
                insurance_group: null,
                insurance_document: null,
            },
            doctor_information: {
                client_id: null,
                facility_name: null,
                first_name: null,
                last_name: null,
                doctor_street_address_one: null,
                doctor_street_address_two: null,
                city: null,
                state: null,
                zip_code: null,
                phone_number: null,
            },
            samples: [
                {
                    test_type: null,
                    collected_on: null,
                    received_on: null,
                    type: null,
                    collected_by: null,
                },
            ],
        }, {
            bag: "ExistingPatientRequisitionForm",
            resetOnSuccess: true,
        })

            function addSample() {
                form.samples.push({
                    test_type: null,
                    collected_on: null,
                    received_on: null,
                    type: null,
                    collected_by: null,
                })
            }

            function removeSample(index) {
                form.samples.splice(index, 1)
            }

            function resetForm() {
                form.reset()
            }

            const patients = getFromObject(props, "patients.data", [])

        const genderOptions = new Map()
        const raceOptions = new Map()
        const ethnicityOptions = new Map()
        const stateOptions = new Map()
        const testOptions = new Map()
        const pregnancyOptions = new Map()
        const paymentMethodOptions = new Map()
        const paymentStatusOptions = new Map()
        const yesNoOptions = new Map()
        const sampleTypeOptions = new Map()

        Object.keys(props.genderList).forEach(key => genderOptions.set(key, props.genderList[key]))
        Object.keys(props.raceList).forEach(key => raceOptions.set(key, props.raceList[key]))
        Object.keys(props.ethnicityList).forEach(key => ethnicityOptions.set(key, props.ethnicityList[key]))
        Object.keys(props.stateList).forEach(key => stateOptions.set(key, props.stateList[key]))
        Object.keys(props.pregnancyList).forEach(key => pregnancyOptions.set(key, props.pregnancyList[key]))
        Object.keys(props.testList).forEach(key => testOptions.set(key, props.testList[key]))
        Object.keys(props.paymentMethodList).forEach(key => paymentMethodOptions.set(key, props.paymentMethodList[key]))
        Object.keys(props.paymentStatusList).forEach(key => paymentStatusOptions.set(key, props.paymentStatusList[key]))
        Object.keys(props.sampleTypeList).forEach(key => sampleTypeOptions.set(key, props.sampleTypeList[key]))
        yesNoOptions.set(true, "Yes")
        yesNoOptions.set(false, "No")

        provide("raceOptions", raceOptions)
        provide("genderOptions", genderOptions)
        provide("ethnicityOptions", ethnicityOptions)
        provide("stateOptions", stateOptions)
        provide("pregnancyOptions", pregnancyOptions)
        provide("testOptions", testOptions)
        provide("paymentMethodOptions", paymentMethodOptions)
        provide("paymentStatusOptions", paymentStatusOptions)
        provide("yesNoOptions", yesNoOptions)
        provide("sampleTypeOptions", sampleTypeOptions)
        provide("form", form)
        provide("clients", props.clients)

        function fireSearch(e) {
            axios.get(route("admin.requisitions.existing-patient.filter"), {
                params: {
                    search: e.target.value,
                },
            }).then(response => {
                hasPatient.value = true
                patientDetails.value = getFromObject(response, "data.data")
            }).catch(error => {
                hasPatient.value = false
            })
        }

        watch(() => patientDetails.value, (newValue, oldValue) => {
            form.id = newValue.id
            form.basic_information.email = newValue.email
            form.basic_information.first_name = newValue.first_name
            form.basic_information.last_name = newValue.last_name
            form.basic_information.dob = newValue.dob_formatted
            form.basic_information.gender = newValue.gender
            form.basic_information.phone_number = newValue.phone_number
            form.basic_information.race = newValue.race
            form.basic_information.ethnicity = newValue.ethnicity
            form.basic_information.street_address_one = newValue.street_address_one
            form.basic_information.street_address_two = newValue.street_address_two
            form.basic_information.city = newValue.city
            form.basic_information.state = newValue.state
            form.basic_information.zip_code = newValue.zip_code
        })

        const onSearch = searchText => {
            let tmpArray = []
            props.patients.data.forEach(item => {
                if (item.name && item.name.toLowerCase().includes(searchText.toLowerCase())) {
                    tmpArray.push({
                        key: item.id,
                        value: item.name,
                        dob: item.dob,
                    })
                }
            })
            patientOptions.value = tmpArray
        }

        const onSelect = value => {
            let patient = props.patients.data.find(item => item.id === value)
            if (patient) {
                setPatient(patient)
            }
        }

        const v = useVuelidate()

        function setPatient(patient) {
            form.id = patient.id
            form.basic_information.email = patient.email
            form.basic_information.first_name = patient.first_name
            form.basic_information.last_name = patient.last_name
            form.basic_information.dob = patient.dob_formatted
            form.basic_information.gender = patient.gender
            form.basic_information.phone_number = patient.phone_number
            form.basic_information.race = patient.race
            form.basic_information.ethnicity = patient.ethnicity
            form.basic_information.street_address_one = patient.street_address_one
            form.basic_information.street_address_two = patient.street_address_two
            form.basic_information.city = patient.city
            form.basic_information.state = patient.state
            form.basic_information.zip_code = patient.zip_code

            form.test_information.name = getFromObject(patient, 'last_requisition.test_information.name')
            form.test_information.is_first_test = getFromObject(patient, 'last_requisition.test_information.is_first_test')
            form.test_information.is_health_care_worker = getFromObject(patient, 'last_requisition.test_information.is_health_care_worker')
            form.test_information.has_symptom = getFromObject(patient, 'last_requisition.test_information.has_symptom')
            form.test_information.symptom_date = getFromObject(patient, 'last_requisition.test_information.symptom_date')
            form.test_information.is_hospitalized = getFromObject(patient, 'last_requisition.test_information.is_hospitalized')
            form.test_information.is_in_icu = getFromObject(patient, 'last_requisition.test_information.is_in_icu')
            form.test_information.is_in_congregate_care = getFromObject(patient, 'last_requisition.test_information.is_in_congregate_care')
            form.test_information.is_pregnant = getFromObject(patient, 'last_requisition.test_information.is_pregnant')


            form.payment_method.payment_type = getFromObject(patient, 'last_requisition.payment_method.payment_type')
            form.payment_method.driver_license = getFromObject(patient, 'last_requisition.payment_method.driver_license')
            form.payment_method.payment_status = getFromObject(patient, 'last_requisition.payment_method.payment_status')
            form.payment_method.insurance_name = getFromObject(patient, 'last_requisition.payment_method.insurance_name')
            form.payment_method.insurance_holder_name = getFromObject(patient, 'last_requisition.payment_method.insurance_holder_name')
            form.payment_method.insurance_id = getFromObject(patient, 'last_requisition.payment_method.insurance_id')
            form.payment_method.insurance_group = getFromObject(patient, 'last_requisition.payment_method.insurance_group')

            form.doctor_information.client_id = getFromObject(patient,"last_requisition.doctor_information.client_id")
            form.doctor_information.facility_name = getFromObject(patient,"last_requisition.doctor_information.facility_name")
            form.doctor_information.first_name = getFromObject(patient,"last_requisition.doctor_information.first_name")
            form.doctor_information.last_name = getFromObject(patient,"last_requisition.doctor_information.last_name")
            form.doctor_information.doctor_street_address_one = getFromObject(patient,"last_requisition.doctor_information.doctor_street_address_one")
            form.doctor_information.doctor_street_address_two = getFromObject(patient,"last_requisition.doctor_information.doctor_street_address_two")
            form.doctor_information.city = getFromObject(patient,"last_requisition.doctor_information.city")
            form.doctor_information.state = getFromObject(patient,"last_requisition.doctor_information.state")
            form.doctor_information.zip_code = getFromObject(patient,"last_requisition.doctor_information.zip_code")
            form.doctor_information.phone_number = getFromObject(patient,"last_requisition.doctor_information.phone_number")

            hasPatient.value = true
        }

        return {
            v,
            patients,
            resetForm,
            onSearch,
            onSelect,
            patientOptions,
            fireSearch,
            hasPatient,
            form,
            requisitionIndexUrl,
            saveExistingPatientUrl,
            addSample,
            removeSample,
        }
    }
    ,
    methods: {
        submitForm() {
            this.v.$touch()

            this.$nextTick(() => {
                const inputError = document.querySelectorAll('.input-error')[0]
                if (inputError) {
                    scrollToSmoothly(inputError.offsetTop - 84);
                }
            })

            if (this.v.$error) return

            this.form.post(route("admin.requisitions.form.existing-patient.store", this.form.id))
        }
        ,

        fireLocalSearch: function (value) {
            if (this.searchValues.length === 1) {
                this.resetForm()
                this.setPatient(this.searchValues[0])
            }
        }
        ,

        searchPatient: function () {
            const search = Object.values(this.patients).find(item => {
                return item.name.toLowerCase() === this.searchText.toLowerCase()
            })
            if (search) {
                this.resetForm()
                this.setPatient(search)
            }
        }
        ,

    }
    ,
    computed: {
        searchValues: function () {
            return Object.values(this.patients).filter(item => {
                return item.name.toLowerCase().includes(this.searchText.toLowerCase())
            })
        }
        ,
    }
    ,
}
</script>



<template>
    <admin-layout active-link="users">
        <div class="card--large !px-0 !pt-0 !pb-8">
            <div class="table-head-container">
                <h1 class="font-bold mt-0 text-grey-1100 leading-none" style="font-size: 32px;" v-text="'Users'"/>
                <primary-button
                    class="flex items-center gap-2 !py-2 !px-5"
                    @click="goToAddUser">
                    <Icon class="w-3.5 h-3.5" name="plus"/>
                    Add User
                </primary-button>
            </div>
            <div class="overflow-x-auto">
                <ant-table :columns="clientListTableColumns" :data-source="users.data" row-key="id">
                <template #role="{ record }">
                    <p
                        v-for="(role, index) in record.role"
                        v-text="role"
                        :key="index"
                    />
                </template>
                </ant-table>
            </div>
        </div>
    </admin-layout>
</template>

<script>
    import AdminLayout   from "../../../components/Admin/Layout/AdminLayout"
    import PrimaryButton from "../../../components/Admin/Buttons/PrimaryButton"
    import Icon          from "../../../components/Admin/General/Icon"
    import AntTable      from "ant-design-vue/lib/table"
    import { computed }  from "vue"
    import { Inertia }            from "@inertiajs/inertia"

    export default {
        name: "UserIndex",
        components: { Icon, PrimaryButton, AdminLayout, AntTable },
        props: ["users"],
        setup() {
            const goToAddUser = () => {
                Inertia.get(route('admin.users.create'))
            }

            const clientListTableColumns = computed(() => {
                return [
                    {
                        title: "Name",
                        dataIndex: "name",
                    },
                    {
                        title: "email",
                        dataIndex: "email",
                    },
                    {
                        title: "Role",
                        dataIndex: "role",
                        slots: {
                            customRender: "role",
                        },
                    },
                ]
            })

            return {
                goToAddUser,
                clientListTableColumns
            }
        },
    }
</script>

<style scoped>

</style>

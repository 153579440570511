<template>
    <div>
        <div class="table-head-container !py-10">
            <h1 class="heading-primary heading-primary-sm !leading-none"
                v-text="'Batch'"
            />
        </div>
        <div class="overflow-x-auto">
            <AntTable
                :columns="workOrderColumns"
                :data-source="workOrdersTableData"
                :pagination="false"
                class="components-table-demo-nested">
                <template #filterSearch="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
                    <div class="grid gap-2 p-2">
                        <a-input
                            class="placeholder-grey-400"
                            ref="searchInput"
                            :placeholder="`Enter ${column.dataIndex.split('_').join(' ')}`"
                            :value="selectedKeys[0]"
                            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                        >

                        </a-input>
                        <div class="flex items-center justify-between gap-2">
                            <a-button
                                class="btn-icon"
                                type="primary"
                                size="small"
                                @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                            >
                                <Icon class="w-3.5 h-3.5" name="search"/>
                                Search
                            </a-button>
                            <a-button class="ant-reset" size="small" @click="handleReset(clearFilters)">
                                Reset
                            </a-button>
                        </div>
                    </div>

                </template>
                <template #filterIcon="filtered">
                    <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }"/>
                </template>
                <template #customRender="{ text, column }">
                            <span v-if="searchText && searchedColumn === column.dataIndex">
                                <template
                                    v-for="(fragment, i) in text
                                    .toString()
                                    .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
                                >
                                    <mark
                                        v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                                        class="highlight"
                                        :key="i"
                                    >
                                      {{ fragment }}
                                    </mark>
                                    <template v-else>{{ fragment }}</template>
                                </template>
                          </span>
                    <template v-else>
                        {{ text }}
                    </template>
                </template>
                <template #workOrderAction="{record}">
                    <action-button-wrapper>
                        <action-button
                            class="btn-action--primary"
                            action-type="download"
                            tooltip-text="Download result"
                            :href="downloadWorkOrder(record.id)"/>
                        <action-button
                            v-if="record.status !== 'completed'"
                            class="btn-action--primary"
                            action-type="upload"
                            tooltip-text="Upload result"
                            @click="uploadWorkOrderCSV(record.id)"/>
                    </action-button-wrapper>
                </template>
                <template #status="{record}">
                    <div class="status-mark-wrap flex items-center" v-if="record.status === 'completed'">
                                 <span class="status-mark block rounded-full w-1.5 h-1.5 bg-success-100"
                                 />
                        <span class="ml-1 status-text leading-none capitalize" v-text="'Completed'"/>
                    </div>
                    <div class="status-mark-wrap flex items-center" v-else>
                        <span class="status-mark block rounded-full w-1.5 h-1.5 bg-warning-600"/>
                        <span class="ml-1 status-text leading-none capitalize" v-text="'Pending'"/>
                    </div>
                </template>
                <template #expandedRowRender="{record, index, indent, expanded}">
                    <AntTable :columns="workOrdersSampleTestsColumns" :data-source="record.nestedData"
                              :pagination="false">
                        <template #sampleTestAction="{ record }">
                            <p class="capitalize text-success-100" v-if="record.status === 'completed'"
                               v-text="`completed`"/>
                            <p class="capitalize text-success-100" v-else-if="record.status === 'result_approved'"
                               v-text="`Result Approved`"/>
                            <action-button class="btn-action--primary" action-type="upload" tooltip-text="Upload result"
                                           @click="uploadResult(record.id, record.test_name)" v-else/>
                        </template>
                    </AntTable>
                </template>
            </AntTable>
        </div>
    </div>
    <upload-test-batch-csv-modal
        :show-modal="showTestBatchCSVUploadModal"
        :id="testBatchCSVUploadId"
        @close="closeUploadTestBatchCSVModal"
        @success="uploadTestBatchSuccess"
    />
</template>

<script>
    import { SearchOutlined }      from "@ant-design/icons-vue"
    import { default as AButton }  from "ant-design-vue/lib/button"
    import { default as AInput }   from "ant-design-vue/lib/input"
    import ActionButtonWrapper     from "../../../../components/Admin/Buttons/ActionButtonWrapper"
    import Icon                    from "../../../../components/Admin/General/Icon"
    import AntTable                from "ant-design-vue/lib/table"
    import FilterDropdown          from "ant-design-vue/lib/table/filterDropdown"
    import {
        ref,
        computed,
        reactive,
        watch,
        toRefs,
    }                              from "vue"
    import ActionButton            from "../../../../components/Admin/Buttons/ActionButton"
    import UploadTestBatchCsvModal from "./UploadTestBatchCsvModal"

    export default {
        name: "WorkOrdersTab",
        components: {
            ActionButtonWrapper,
            UploadTestBatchCsvModal,
            ActionButton,
            Icon,
            AntTable,
            AButton,
            AInput,
            SearchOutlined,
            FilterDropdown,
        },
        props: {
            workOrders: { type: Array, required: true },
        },
        emits: ["uploadResult", "refresh"],
        setup(props, { emit }) {
            const appState = reactive({
                searchText: "",
                searchedColumn: "",
                showTestBatchCSVUploadModal: false,
                testBatchCSVUploadId: 0,
            })

            const filteredInfo = ref()
            const sortedInfo = ref()
            const searchInput = ref()

            const workOrderColumns = computed(() => {
                const filtered = filteredInfo.value || {}
                const sorted = sortedInfo.value || {}
                return [
                    {
                        title: "S.N",
                        dataIndex: "sn",
                        key: "sn",
                    },
                    {
                        title: "Action",
                        slots: {
                            customRender: "workOrderAction",
                        },
                    },
                    {
                        title: "Batch",
                        dataIndex: "work_order_name",
                        key: "work_order_name",
                        sorter: (a, b) => a.work_order_name.localeCompare(b.work_order_name),
                        sortDirections: ["descend", "ascend"],
                        slots: {
                            filterDropdown: "filterSearch",
                            filterIcon: "filterIcon",
                            customRender: "customRender",
                        },
                        onFilter: (value, record) => {
                            return record.work_order_name.toString().toLowerCase().includes(value.toLowerCase())
                        },
                        onFilterDropdownVisibleChange: visible => {
                            if (visible) {
                                setTimeout(() => {
                                    searchInput.value.focus()
                                }, 0)
                            }
                        },
                    },
                    {
                        title: "Batch ID",
                        dataIndex: "id",
                        key: "id",
                        sorter: (a, b) => a.id - b.id,
                        sortDirections: ["descend", "ascend"],
                    },
                    {
                        title: "Created On",
                        dataIndex: "created_on",
                        key: "created_on",
                        sorter: (a, b) => a.created_on.localeCompare(b.created_on),
                        sortDirections: ["descend", "ascend"],
                    },
                    {
                        title: "Created By",
                        dataIndex: "created_by",
                        key: "created_by",
                        sorter: (a, b) => a.created_by.localeCompare(b.created_by),
                        sortDirections: ["descend", "ascend"],
                    },
                    {
                        title: "Status",
                        slots: {
                            customRender: "status",
                        },
                        dataIndex: "status",
                        key: "status",
                        sorter: (a, b) => a.status.localeCompare(b.status),
                        sortDirections: ["descend", "ascend"],
                    },
                ]
            })

            const workOrdersTableData = ref([])

            const workOrdersSampleTestsColumns = computed(() => {
                return [
                    {
                        title: "REQ ",
                        dataIndex: "requisition_code",
                        key: "requisition_code",
                        sorter: (a, b) => a.requisition_code - b.requisition_code,
                        sortDirections: ["descend", "ascend"],
                        defaultSortOrder: "descend",
                    },
                    {
                        title: "Patient Name",
                        dataIndex: "patient_name",
                        key: "patient_name",
                        sorter: (a, b) => a.patient_name.localeCompare(b.patient_name),
                        sortDirections: ["descend", "ascend"],
                    },
                    {
                        title: "Test Name",
                        dataIndex: "test_name",
                        key: "test_name",
                    },
                    {
                        title: "Sample ID",
                        dataIndex: "sample_id",
                        key: "sample_id",
                    },
                    {
                        title: "Sample Type",
                        dataIndex: "sample_type_formatted",
                        key: "sample_type_formatted",
                    },
                    {
                        title: "Sample Collected On",
                        dataIndex: "sample_collected_on",
                        key: "sample_collected_on",
                    },
                    {
                        title: "Received Date",
                        dataIndex: "received_date",
                        key: "received_date",
                    },
                    {
                        title: "Action",
                        slots: {
                            customRender: "sampleTestAction",
                        },
                    },

                ]
            })
            const workOrdersSampleTestsData = ref([])

            watch(() => props.workOrders, (newValue, oldValue) => {
                workOrdersTableData.value = []
                workOrdersSampleTestsData.value = []

                let i = 0

                props.workOrders.forEach((item, index) => {
                    let nestedData = []

                    if (Array.isArray(item.tests)) {
                        item.tests.forEach((nestedItem, nestedIndex) => {
                            nestedData.push({
                                work_order_id: item.id,
                                key: i,
                                id: nestedItem.id,
                                requisition_id: nestedItem.requisition_id ?? "",
                                requisition_code: nestedItem.requisition_code ?? "",
                                test_name: nestedItem.test_name ?? "",
                                sample_id: nestedItem.sample_id ?? "",
                                sample_type_formatted: nestedItem.sample_type ?? "",
                                sample_collected_on: nestedItem.sample_collected_on ?? "",
                                received_date: nestedItem.received_date ?? "",
                                status: nestedItem.status ?? "",
                                patient_name: nestedItem.patient_name ?? "Nothing",
                            })
                            ++i
                        })
                    }

                    workOrdersTableData.value.push({
                        key: item.id,
                        sn: index + 1,
                        id: item.id,
                        requisition_code: item.requisition_code,
                        work_order_name: item.name,
                        created_on: item.created_on,
                        created_on_formatted: item.created_on_formatted,
                        created_by: item.created_by,
                        status: item.status,
                        nestedData: nestedData,
                    })
                })
            }, {
                deep: true,
                immediate: true,
            })

            const handleSearch = (selectedKeys, confirm, dataIndex) => {
                confirm()
                appState.searchText = selectedKeys[0]
                appState.searchedColumn = dataIndex
            }

            const handleReset = clearFilters => {
                clearFilters()
                appState.searchText = ""
            }

            const fireSearch = () => {
                filteredInfo.value = {
                    work_order_name: [appState.searchText],
                }
            }

            function getDataForRow(record) {
                console.log(record.key)
                return workOrdersSampleTestsData.value.filter((item) => item.work_order_id === record.key)
            }

            function downloadWorkOrder(id) {
                return (route("admin.test-batch.download.csv", id))
            }

            const uploadResult = (id, test_name) => {
                emit("uploadResult", {
                    id: id,
                    test_name: test_name,
                })
            }

            const uploadWorkOrderCSV = (testBatchId) => {
                appState.showTestBatchCSVUploadModal = true
                appState.testBatchCSVUploadId = testBatchId
            }

            const closeUploadTestBatchCSVModal = () => {
                appState.showTestBatchCSVUploadModal = false
                appState.testBatchCSVUploadId = 0
            }

            const uploadTestBatchSuccess = () => {
                appState.showTestBatchCSVUploadModal = false
                appState.testBatchCSVUploadId = 0
                emit("refresh")
            }

            return {
                ...toRefs(appState),
                fireSearch,
                workOrderColumns,
                workOrdersTableData,
                workOrdersSampleTestsColumns,
                workOrdersSampleTestsData,
                getDataForRow,
                downloadWorkOrder,
                uploadResult,
                handleSearch,
                handleReset,
                searchInput,
                uploadWorkOrderCSV,
                closeUploadTestBatchCSVModal,
                uploadTestBatchSuccess,
            }
        },
    }
</script>

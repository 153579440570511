<template>
    <button-component :inertia-link="inertiaLink"
                      :type="type"
                      :method="method"
                      :href="href"
                      class="!border-blue-300 !text-grey-600"
                      v-bind="$attrs">
        <slot/>
    </button-component>
</template>

<script type="text/ecmascript-6">
    import ButtonComponent from "./Button"

    export default {
        name: "CancelButton",
        inheritAttrs: false,

        components: { ButtonComponent },

        props: {
            inertiaLink: { type: Boolean, required: false, default: true },
            type: { type: String, required: false, default: "button" },
            method: { type: String, required: false, default: "get" },
            href: { type: String, required: false, default: null },
        },
    }
</script>

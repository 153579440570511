/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap")
import {
    createApp,
    h,
}             from "vue"

import {
    App,
    plugin,

} from "@inertiajs/inertia-vue3"

import { InertiaProgress } from "@inertiajs/progress"

InertiaProgress.init()

const el = document.getElementById("app")

createApp({
    render: () => h(App, {
        initialPage: JSON.parse(el.dataset.page),
        resolveComponent: name => require(`./Pages/${name}`).default,
    }),
}).use(plugin).mount(el)

<template>
    <div
        class="sidebar md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative z-40 py-4 px-6"
    >
        <div
            class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
        >


            <!-- Collapse -->
            <div
                class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded"
                v-bind:class="collapseShow"
            >

                <div
                    class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200"
                >
                    <div class="flex flex-wrap">
                        <div class="w-6/12">

                        </div>
                        <div class="w-6/12 flex justify-end">
                            <!-- mobile menu toggler -->
                            <button
                                class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                                type="button"
                                v-on:click="toggleCollapseShow('bg-white m-2 py-3 px-6')">
                                <Icon name="menu"/>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- Navigation -->
                <ul class="grid gap-2 md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
                    <li class="items-center">
                        <a :href="sideBarUrl.assignment" :class="linkClasses(true)">
                            <Icon name="appointments"/>
                            <span> Appointments</span>
                        </a>
                    </li>
                    <li class="items-center">
                        <a href="#" :class="linkClasses()">
                            <Icon name="test-1"/>
                            <span>
                              Tests
                           </span>
                        </a>
                    </li>
                    <li class="items-center">
                        <a href="#" :class="linkClasses()">
                            <Icon name="result"/>
                            <span>Results</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>


<script>
import UserDropdown from "../General/UserDropdown"
import Icon from "./../General/Icon"

export default {
    name: "WebSidebar",
    components: {
        Icon,
        UserDropdown,
    },
    data() {
        return {
            collapseShow: "hidden",
            sideBarUrl:{
              'assignment': route('patient.appointment.index'),
            },
        }
    },
    methods: {
        toggleCollapseShow: function (classes) {
            this.collapseShow = classes
        },
        linkClasses(activeLink) {
            if (activeLink) {
                return "!text-tertiary-100 flex items-center gap-2 text-grey-900 hover:text-blueGray-500 text-sm  py-3 block"
            }
            return "flex items-center gap-2 text-grey-900 hover:text-blueGray-500 text-sm  py-3 block"
        },
    },
}
</script>

<template>
    <admin-layout :if-has-breadcrumb="false" :active-link="'requisitions'">
        <div>
            <div class="card--large">
                <form @submit.prevent="submitForm">
                    <div class="grid gap-10">
                        <basic-information-new v-model="form.basic_information" :errors="form.errors" :show-buttons="false" :is-family-member="isFamilyMember"/>
                        <test-booking-new v-model="form.test_information" :errors="form.errors" :show-appointment-date="true"
                                          :show-buttons="false"/>
                        <doctor-information v-model="form.doctor_information"/>
                        <payment-method-new v-model="form.payment_method" :errors="form.errors"/>
                        <div>
                            <card-header title="Sample"/>
                            <requisition-sample v-for="(sample, sampleIndex) in form.samples"
                                                :is-edit="true"
                                                :key="sampleIndex"
                                                :index="sampleIndex"
                                                :sample="sample"
                                                :name="'samples.' + sampleIndex"
                                                :sample-count="form.samples.length"
                                                :errors="form.errors"
                                                @input="form.samples[sampleIndex] = $event"
                                                @add="addSample"
                                                @remove="removeSample(sampleIndex)"
                            />
                        </div>
                    </div>
                    <div class="pt-8 flex items-center justify-between">
                             <span class="text-sm text-tertiary-100">*
                                 <span class="text-grey-400" v-text="'All fields are required unless specified optional'"/></span>
                        <div class="flex items-center gap-6">
                            <cancel-button :inertia-link="true" :href="requisitionIndexUrl">Cancel</cancel-button>
                            <primary-button @click="submitForm" class="btn px-10">Submit</primary-button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </admin-layout>
</template>

<script>
import {useForm} from "@inertiajs/inertia-vue3"
import {useVuelidate} from "@vuelidate/core"
import {get as getFromObject} from "lodash"
import {provide, reactive, ref, toRefs, watch, computed} from "vue"
import BreadCrumb from "../../../components/Admin/BreadCrumb/BreadCrumb"
import BreadCrumbItem from "../../../components/Admin/BreadCrumb/BreadCrumbItem"
import PrimaryButton from "../../../components/Admin/Buttons/PrimaryButton"
import CancelButton from "../../../components/Admin/Buttons/CancelButton"
import InputComponent from "../../../components/Admin/Forms/Input"
import CardHeader from "../../../components/Admin/General/CardHeader"
import Icon from "../../../components/Admin/General/Icon"
import AdminLayout from "../../../components/Admin/Layout/AdminLayout"
import BasicInformationNew from "./Partials/BasicInformationNew"
import PaymentMethodNew from "./Partials/PaymentMethodNew"
import RequisitionSample from "./Partials/RequisitionSample"
import TestBookingNew from "./Partials/TestBookingNew"
import EmptySearch from "./Partials/EmptySearch"
import DoctorInformation from "./Partials/DoctorInformation";

export default {
    name: "EditViewRequisition",
    components: {
        DoctorInformation,
        CardHeader,
        EmptySearch,
        Icon,
        InputComponent,
        RequisitionSample,
        CancelButton,
        BreadCrumbItem,
        BreadCrumb,
        PaymentMethodNew,
        TestBookingNew,
        BasicInformationNew,
        AdminLayout,
        PrimaryButton,
    },
    props: {
        raceList: {type: Object, required: true},
        genderList: {type: Object, required: true},
        ethnicityList: {type: Object, required: true},
        stateList: {type: Object, required: true},
        testList: {type: Object, required: true},
        pregnancyList: {type: Object, required: true},
        paymentMethodList: {type: Object, required: true},
        paymentStatusList: {type: Object, required: true},
        sampleTypeList: {type: Object, required: true},
        bookings: {type: Object, required: true},
        clients: {type: Object, required: true},
    },
    setup(props) {
        const currentId = ref(0)
        const bookingDetails = ref({})
        const requisitionIndexUrl = route("admin.requisitions.index")
        // const saveExistingBookingUrl = route("admin.requisitions.form.existing-booking.store", currentId)

        const appState = reactive({
            showSearchResults: false,
            searchResults: [],
            searchText: "",
        })

        const requisitionData = getFromObject(props, "bookings.data", {})

        const isFamilyMember = computed(() => {
            return getFromObject(requisitionData, 'has_guardian', false)
        })

        const form = useForm("EditRequisitionForm", {
            id: getFromObject(requisitionData, 'id', null),
            patient_id: getFromObject(requisitionData, 'basic_information.id', null),
            basic_information: {
                email: getFromObject(requisitionData, 'basic_information.email', null),
                first_name: getFromObject(requisitionData, 'basic_information.first_name', null),
                last_name: getFromObject(requisitionData, 'basic_information.last_name', null),
                dob: getFromObject(requisitionData, 'basic_information.dob', null),
                gender: getFromObject(requisitionData, 'basic_information.gender', null),
                phone_number: getFromObject(requisitionData, 'basic_information.phone_number', null),
                race: getFromObject(requisitionData, 'basic_information.race', null),
                ethnicity: getFromObject(requisitionData, 'basic_information.ethnicity', null),
                street_address_one: getFromObject(requisitionData, 'basic_information.street_address_one', null),
                street_address_two: getFromObject(requisitionData, 'basic_information.street_address_two', null),
                city: getFromObject(requisitionData, 'basic_information.city', null),
                state: getFromObject(requisitionData, 'basic_information.state', null),
                zip_code: getFromObject(requisitionData, 'basic_information.zip_code', null),

            },
            test_id: getFromObject(requisitionData, 'test_information.id', null),
            appointment_id: getFromObject(requisitionData, 'appointment.id', null),
            test_information: {
                test_id: getFromObject(requisitionData, 'test_information.id', null),
                name: getFromObject(requisitionData, 'test_information.name', null),
                appointment_date: getFromObject(requisitionData, 'appointment.appointment_date', null),
                is_first_test: getFromObject(requisitionData, 'test_information.is_first_test', null),
                is_health_care_worker: getFromObject(requisitionData, 'test_information.is_health_care_worker', null),
                has_symptom: getFromObject(requisitionData, 'test_information.has_symptom', null),
                symptom_date: getFromObject(requisitionData, 'test_information.symptom_date', null),
                is_hospitalized: getFromObject(requisitionData, 'test_information.is_hospitalized', null),
                is_in_icu: getFromObject(requisitionData, 'test_information.is_in_icu', null),
                is_in_congregate_care: getFromObject(requisitionData, 'test_information.is_in_congregate_care', null),
                is_pregnant: getFromObject(requisitionData, 'test_information.is_pregnant', null),
            },
            doctor_information: {
                client_id: getFromObject(requisitionData, 'doctor_information.id', null),
                facility_name: getFromObject(requisitionData, 'doctor_information.ordering_facility', null),
                full_name: getFromObject(requisitionData, 'doctor_information.full_name', null),
                first_name: getFromObject(requisitionData, 'doctor_information.first_name', null),
                last_name: getFromObject(requisitionData, 'doctor_information.last_name', null),
                doctor_street_address_one: getFromObject(requisitionData, 'doctor_information.doctor_street_address_one', null),
                doctor_street_address_two: getFromObject(requisitionData, 'doctor_information.doctor_street_address_two', null),
                city: getFromObject(requisitionData, 'doctor_information.city', null),
                state: getFromObject(requisitionData, 'doctor_information.state', null),
                zip_code: getFromObject(requisitionData, 'doctor_information.zip_code', null),
                phone_number: getFromObject(requisitionData, 'doctor_information.phone_number', null),
            },
            payment_method: {
                payment_type: getFromObject(requisitionData, 'payment.payment_type', null),
                driver_license: getFromObject(requisitionData, 'payment.driver_license', null),
                payment_status: getFromObject(requisitionData, 'payment.payment_status', null),
                insurance_name: getFromObject(requisitionData, 'payment.insurance_name', null),
                insurance_holder_name: getFromObject(requisitionData, 'payment.insurance_holder_name', null),
                insurance_id: getFromObject(requisitionData.payment, 'insurance_id', null),
                insurance_group: getFromObject(requisitionData.payment, 'insurance_group', null),
                insurance_document: getFromObject(requisitionData, 'payment.insurance_document', null),
            },
            samples: getFromObject(requisitionData, 'samples', null),
        }, {
            bag: "EditRequisitionForm",
            resetOnSuccess: true,
        })

        function addSample() {
            form.samples.push({})
        }

        function removeSample(index) {
            form.samples.splice(index, 1)
        }

        function resetForm() {
            form.reset()
        }

        const bookingsList = getFromObject(props, "bookings.data", [])

        const genderOptions = new Map()
        const raceOptions = new Map()
        const ethnicityOptions = new Map()
        const stateOptions = new Map()
        const testOptions = new Map()
        const pregnancyOptions = new Map()
        const paymentMethodOptions = new Map()
        const paymentStatusOptions = new Map()
        const yesNoOptions = new Map()
        const sampleTypeOptions = new Map()

        Object.keys(props.genderList).forEach(key => genderOptions.set(key, props.genderList[key]))
        Object.keys(props.raceList).forEach(key => raceOptions.set(key, props.raceList[key]))
        Object.keys(props.ethnicityList).forEach(key => ethnicityOptions.set(key, props.ethnicityList[key]))
        Object.keys(props.stateList).forEach(key => stateOptions.set(key, props.stateList[key]))
        Object.keys(props.pregnancyList).forEach(key => pregnancyOptions.set(key, props.pregnancyList[key]))
        Object.keys(props.testList).forEach(key => testOptions.set(key, props.testList[key]))
        Object.keys(props.paymentMethodList).forEach(key => paymentMethodOptions.set(key, props.paymentMethodList[key]))
        Object.keys(props.paymentStatusList).forEach(key => paymentStatusOptions.set(key, props.paymentStatusList[key]))
        Object.keys(props.sampleTypeList).forEach(key => sampleTypeOptions.set(key, props.sampleTypeList[key]))

        yesNoOptions.set(true, "Yes")
        yesNoOptions.set(false, "No")

        provide("raceOptions", raceOptions)
        provide("genderOptions", genderOptions)
        provide("ethnicityOptions", ethnicityOptions)
        provide("stateOptions", stateOptions)
        provide("pregnancyOptions", pregnancyOptions)
        provide("testOptions", testOptions)
        provide("paymentMethodOptions", paymentMethodOptions)
        provide("paymentStatusOptions", paymentStatusOptions)
        provide("yesNoOptions", yesNoOptions)
        provide("sampleTypeOptions", sampleTypeOptions)
        provide("form", form)
        provide("clients", props.clients)

        watch(() => bookingDetails.value, (newValue, oldValue) => {
                form.id = newValue.id
                form.patient_id = getFromObject(newValue, "patient_id")
                form.basic_information.email = getFromObject(newValue, "basic_information.email")
                form.basic_information.first_name = getFromObject(newValue, "basic_information.first_name")
                form.basic_information.last_name = getFromObject(newValue, "basic_information.last_name")
                form.basic_information.dob = getFromObject(newValue, "basic_information.dob_formatted")
                form.basic_information.gender = getFromObject(newValue, "basic_information.gender")
                form.basic_information.phone_number = getFromObject(newValue, "basic_information.phone_number")
                form.basic_information.race = getFromObject(newValue, "basic_information.race")
                form.basic_information.ethnicity = getFromObject(newValue, "basic_information.ethnicity")
                form.basic_information.street_address_one = getFromObject(newValue, "basic_information.street_address_one")
                form.basic_information.street_address_two = getFromObject(newValue, "basic_information.street_address_two")
                form.basic_information.city = getFromObject(newValue, "basic_information.city")
                form.basic_information.state = getFromObject(newValue, "basic_information.state")
                form.basic_information.zip_code = getFromObject(newValue, "basic_information.zip_code")

                form.payment_method.payment_type = getFromObject(newValue, "payment_method.payment_type")
                form.payment_method.driver_license = getFromObject(newValue, "payment_method.driver_license")
                form.payment_method.payment_status = getFromObject(newValue, "payment_method.payment_status")
                form.payment_method.insurance_name = getFromObject(newValue, "payment_method.insurance_name")
                form.payment_method.insurance_holder_name = getFromObject(newValue, "payment_method.insurance_holder_name")
                form.payment_method.insurance_id = getFromObject(newValue, "payment_method.insurance_id")
                form.payment_method.insurance_group = getFromObject(newValue, "payment_method.insurance_group")
                form.payment_method.insurance_document = getFromObject(newValue, "payment_method.insurance_document")

                form.test_information.appointment_date = getFromObject(newValue, "test_information.appointment_date")
                form.test_id = getFromObject(newValue, "test_information.id")
                form.test_information.name = getFromObject(newValue, "test_information.name", [])
                form.test_information.is_first_test = getFromObject(newValue, "test_information.is_first_test")
                form.test_information.is_health_care_worker = getFromObject(newValue, "test_information.is_health_care_worker")
                form.test_information.has_symptom = getFromObject(newValue, "test_information.has_symptom")
                form.test_information.symptom_date = getFromObject(newValue, "test_information.symptom_date")
                form.test_information.is_hospitalized = getFromObject(newValue, "test_information.is_hospitalized")
                form.test_information.is_in_icu = getFromObject(newValue, "test_information.is_in_icu")
                form.test_information.is_in_congregate_care = getFromObject(newValue, "test_information.is_in_congregate_care")
                form.test_information.is_pregnant = getFromObject(newValue, "test_information.is_pregnant")

                form.test_information.doctor_information.ordering_facility = getFromObject(newValue,
                    "test_information.doctor_information.ordering_facility")
                form.test_information.doctor_information.doctor_name = getFromObject(newValue,
                    "test_information.doctor_information.doctor_name")
                form.test_information.doctor_information.doctor_street_address_one = getFromObject(newValue,
                    "test_information.doctor_information.doctor_street_address_one")
                form.test_information.doctor_information.doctor_street_address_two = getFromObject(newValue,
                    "test_information.doctor_information.doctor_street_address_two")
                form.test_information.doctor_information.city = getFromObject(newValue, "test_information.doctor_information.city")
                form.test_information.doctor_information.state = getFromObject(newValue, "test_information.doctor_information.state")
                form.test_information.doctor_information.zip_code = getFromObject(newValue,
                    "test_information.doctor_information.zip_code")
                form.test_information.doctor_information.phone_number = getFromObject(newValue,
                    "test_information.doctor_information.phone_number")
            },
        )

        const v = useVuelidate()

        return {
            v, ...toRefs(appState),
            getFromObject,
            bookingsList,
            currentId,
            resetForm,
            form,
            requisitionIndexUrl,
            addSample,
            removeSample,
            isFamilyMember,
        }
    },
    methods: {
        submitForm() {
            this.v.$touch()
            if (this.v.$error) return

            this.form.post(route("admin.requisitions.update", this.form.id))
        },
    },

}
</script>

<style scoped>

</style>

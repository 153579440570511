import { ref, reactive, computed } from "vue"
import { setObject }               from "../../helpers"
export default function useAntTable(columns = []) {
    const filteredInfo  =  ref()
    const sortedInfo  =  ref()
    const searchInput = ref()
    const tableData = ref([])
    const state = reactive({
        scroll: {
            x: 1500,
            y:  700,
        },
        searchText: "",
        searchedColumn: "",
        pagination: {
            current: 1,
            total: 0,
            pageSize: 20,
            from: 1,
        },
        queries: {
            from: null,
            to: null,
            order: '',
        },
        loading: true,
    })

    const tableColumns = computed(() => {
        const filtered = filteredInfo.value || {}
        const sorted = sortedInfo.value || {}
        return columns
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        state.searchText = selectedKeys[0]
        state.searchedColumn = dataIndex
    }

    const handleReset = clearFilters => {
        clearFilters()
        state.searchText = ""
    }

    const updateQueries = (queries) => {
        state.queries  = queries
    }

    const updateSearchText = (newText) => {
        state.searchText = newText
    }

    const updateQueryDateRange = (from, to) => {
        state.queries.from = from
        state.queries.to = to
    }

    const updateSingleQuery = (query, value) => {
        setObject(state.queries, query, value)
    }

    const updatePagination = (pagination) => {
        state.pagination = pagination
    }

    const clearSearch = () => {
        state.searchText = ""
        state.searchedColumn = ""
    }

    return {
        tableData,
        tableColumns,
        searchInput,
        state,
        handleSearch,
        handleReset,
        updateQueries,
        updateSearchText,
        updateQueryDateRange,
        updateSingleQuery,
        updatePagination,
        clearSearch,
        filteredInfo,
        sortedInfo
    }
}

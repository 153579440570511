<template>
  <div class="flex gap-1 items-center">
    <Icon class="!h-3 !w-3" name="user-1"/>
    {{ name }}
  </div>
</template>

<script type="text/ecmascript-6">
import Icon from "./Icon";

export default {
  name: "PatientName",
  components: {Icon,},
  props: {
    name: {type: String, required: true},
  },
}
</script>

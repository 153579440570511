<template>
    <div
        class="form-footer mt-6 pt-6 flex items-center justify-between border-t border-pinkShade-200">
      <span v-if="showRequirementText" class="text-xs text-grey-500">
        <span class="text-tertiary-100" v-text="'*'"/>
        All fields are required unless specified optional
    </span>
        <div class="form-btn-wrap  flex items-center gap-1 ml-auto">
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    name: "FormFooter",
    props: {showRequirementText: {type: Boolean, required: false, default: true}}
}
</script>

<template>
    <div class="flex ">
        <div
            class="h-full !flex items-center gap-2 py-2 px-6 text-blue-100 text-sm"
        >
                              <span
                                  class="w-10 h-10 text-sm  bg-blue-500 text-white inline-flex items-center justify-center rounded-full"
                                  v-text="userName.split(' ').map((n) => n[0]).join(' ')"/>
            {{ userName }}
        </div>
        <a href="/logout"
           class="bg-blue-1000 transition hover:bg-blue-1000 duration-300 text-sm py-3 px-6 flex gap-2 items-center justify-center text-blue-100"
        >
            Logout
            <Icon name="logout"/>
        </a>
    </div>
</template>
<script>
    import Icon                   from "../General/Icon"
    import Dropdown               from "../General/Dropdown"

    export default {
        components: { Icon, Dropdown },
        inject: ["userName"],
    }

</script>

<style scoped lang="scss">

</style>

<template>
    <div>
        <H3 title="Doctor Information (Optional)"/>
        <div class="grid lg:grid-cols-3 gap-y-6 gap-10">
            <div>
                <label-component :value="'Ordering Facility'"/>
                <ant-autocomplete class="form-control--suggestion w-full"
                    v-model:value="facility_name"
                    :options="facilityOptions"
                    @search="onSearch"
                    @select="onSelect"
                />
            </div>
            <div>
                <label-component :value="'Ordering Doctor’s First Name'"/>
                <input-component
                    :disabled="true"
                    type="text"
                    name="doctor_first_name"
                    v-model="first_name"/>
            </div>
            <div>
                <label-component :value="'Ordering Doctor’s Last Name'"/>
                <input-component
                    :disabled="true"
                    type="text"
                    name="doctor_last_name"
                    v-model="last_name"/>
            </div>
            <div>
                <label-component :value="'Doctor Street Address 1'"/>
                <input-component
                    :disabled="true"
                    type="text"
                    name="doctor_street_address_one"
                    v-model="doctor_street_address_one"/>
            </div>
            <div>
                <label-component :value="'Doctor Street Address 2'"/>
                <input-component
                    :disabled="true"
                    type="text"
                    name="doctor_street_address_two"
                    v-model="doctor_street_address_two"/>
            </div>

            <div>
                <label-component :value="'Doctor City'"/>
                <input-component
                    :disabled="true"
                    type="text"
                    name="doctor_city"
                    v-model="city"/>
            </div>
            <div>
                <label-component :value="'Doctor State'"/>
                <select-input
                    :disabled="true"
                    place-holder="Select a state"
                    name="doctor_state"
                    :searchable="true"
                    :options="stateOptions"
                    v-model="state"/>
            </div>

            <div>
                <label-component :value="'Doctor Zip Code'"/>
                <input-component
                    :disabled="true"
                    type="text"
                    name="doctor_zip_code"
                    v-model="zip_code"/>
            </div>
            <div>
                <label-component :value="'Doctor Phone Number'"/>
                <input-component
                    :disabled="true"
                    type="text"
                    name="doctor_phone_number"
                    v-model="phone_number"/>
            </div>
        </div>
    </div>
</template>

<script>
import LabelComponent from "../../../../components/Front/Forms/Label";
import InputComponent from "../../../../components/Front/Forms/Input";
import SelectInput from "../../../../components/Front/Forms/SelectInput";
import {default as AntAutocomplete} from "ant-design-vue/lib/auto-complete"
import H3 from "../../../../components/Admin/typography/H3"
import {inject, reactive, toRefs, ref, computed, watch} from "vue"
import {debounce} from "lodash"

export default {
    name: "DoctorInformation",
    components: {SelectInput, InputComponent, LabelComponent, AntAutocomplete, H3},
    props: {
        modelValue: {type: Object, required: false, default: () => ({})},
    },
    setup(props, {emit}) {
        const stateOptions = inject("stateOptions")
        const facilityOptions = ref([])

        const appState = reactive({
            client_id: null,
            facility_name: null,
            first_name: null,
            last_name: null,
            doctor_street_address_one: null,
            doctor_street_address_two: null,
            city: null,
            state: null,
            zip_code: null,
            phone_number: null,
        })

        const resultValue = computed(() => {
            return {
                client_id: appState.client_id,
                facility_name: appState.facility_name,
                first_name: appState.first_name,
                last_name: appState.last_name,
                doctor_street_address_one: appState.doctor_street_address_one,
                doctor_street_address_two: appState.doctor_street_address_two,
                city: appState.city,
                state: appState.state,
                zip_code: appState.zip_code,
                phone_number: appState.phone_number,
            }
        })

        watch(resultValue, () => {
            emit("update:modelValue", resultValue.value)
        })

        watch(props.modelValue, (newValue)  =>  {
            appState.client_id = newValue.client_id ?? null
            appState.facility_name = newValue.facility_name ?? null
            appState.first_name = newValue.first_name ?? null
            appState.last_name = newValue.last_name ?? null
            appState.doctor_street_address_one = newValue.doctor_street_address_one ?? null
            appState.doctor_street_address_two = newValue.doctor_street_address_two ?? null
            appState.city = newValue.city ?? null
            appState.state = newValue.state ?? null
            appState.zip_code = newValue.zip_code ?? null
            appState.phone_number = newValue.phone_number ?? null
        }, {
            deep: true,
            immediate: true,
        })


        const clients = inject('clients')
        const clientData = ref([])

        if (clients.data && Array.isArray(clients.data)) {
            clientData.value = clients.data
            let tmpArray = []
            clients.data.forEach(item => {
                tmpArray.push({
                    value: item.facility_name
                })
            })
            facilityOptions.value = tmpArray
        }

        const onSearch = searchText => {
            let tmpArray = []
            clients.data.forEach(item => {
                if (item.facility_name && item.facility_name.toLowerCase().includes(searchText.toLowerCase())) {
                    tmpArray.push({
                        key: item.id,
                        value: item.facility_name
                    })
                }
            })
            facilityOptions.value = tmpArray
        }

        const onSelect = value => {
            let selectedItem = clientData.value.find(item => item.facility_name === value)

            appState.client_id = selectedItem.id ?? null
            appState.facility_name = selectedItem.facility_name ?? null
            appState.first_name = selectedItem.first_name ?? null
            appState.last_name = selectedItem.last_name ?? null
            appState.doctor_street_address_one = selectedItem.street_address_one ?? null
            appState.doctor_street_address_two = selectedItem.street_address_two ?? null
            appState.city = selectedItem.city ?? null
            appState.state = selectedItem.state ?? null
            appState.zip_code = selectedItem.zip_code ?? null
            appState.phone_number = selectedItem.phone_number ?? null
        }

        return {
            ...toRefs(appState),
            stateOptions,
            facilityOptions,
            debounce,
            onSearch,
            onSelect
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <h1 class="font-bold text-grey-1000 leading-none" style="font-size: 20px;"  v-text="title"/>
</template>

<script>
    export default {
        name: "H1",
        props: {
            title: { type: String, required: true },
        },
    }
</script>

<template>
    <dropdown>
        <template v-slot:trigger>
            <primary-button
                class="flex items-center gap-2 !py-2 !px-5">
                <Icon
                    class="w-3.5 h-3.5"
                    name="plus"/>
                Add Requisitions
                <Icon
                    class="w-1.5 h-1"
                    name="caret-down"/>
            </primary-button>
        </template>
        <template v-slot:content>
            <li>
                <a
                    class="btn-link btn-link--hover"
                    :inertia-link="true"
                   :href="targetUrl.newPatient"
                >New Patient</a
                >
            </li>
            <li>
                <a
                    class="btn-link btn-link--hover"
                    :inertia-link="true"
                   :href="targetUrl.existingPatient"
                >Existing Patient</a
                >
            </li>
        </template>
    </dropdown>
</template>

<script>
    import PrimaryButton from "../../../../components/Admin/Buttons/PrimaryButton"
    import Dropdown      from "../../../../components/Admin/General/Dropdown"
    import Icon          from "../../../../components/Admin/General/Icon"
    import { reactive }  from "vue"

    export default {
        name: "CreateRequisitionDropdown",
        components: {
            Icon,
            PrimaryButton,
            Dropdown,
        },
        setup() {
            const targetUrl = reactive({
                newPatient: route("admin.requisitions.form.new-patient"),
                existingPatient: route("admin.requisitions.form.existing-patient"),
                booking: route("admin.requisitions.form.existing-booking"),
                requisitionIndex: route("admin.requisitions.index"),
            })

            return {
                targetUrl,
            }
        },
    }
</script>

<style scoped>

</style>

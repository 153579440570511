<template>
    <div class="pattern">
        <Header/>
        <div
            class="container header-footer-height-deduct !px-6 md:!px-12 flex flex-col  gap-6 md:gap-8 items-start !py-8 md:!py-10">
            <div class="sidebar-reg__title">
                <h3 class="heading-primary heading-primary-md" v-text="'Registration Form'"/>
            </div>
            <div class="card card--large w-full">
                <form @submit.prevent="goToNext">
                    <div class="grid gap-16">
                        <div>
                            <basic-information
                                :show-buttons="false"
                                v-model="form.basic_information"
                                :errors="form.errors"
                                @next="goToNext"
                                @back="goToPrevious"
                                @submit="submitForm"/>
                        </div>
                        <div>
                            <test-booking
                                :show-buttons="false"
                                :is-male="isMale"
                                v-model="form.test_information"
                                :errors="form.errors"
                                @next="goToNext"
                                @back="goToPrevious"
                                @submit="submitForm"/>
                        </div>
                        <div>
                            <payment-method
                                :show-buttons="false"
                                v-model="form.payment_method"
                                :errors="form.errors"
                                @next="goToNext"
                                @back="goToPrevious"
                                @submit="submitForm"/>
                        </div>
                        <div>
                            <disclaimer
                                :show-buttons="false"
                                v-model="form.disclaimer.isAccepted"
                                :errors="form.errors"
                                @next="goToNext"
                                @back="goToPrevious"
                                @submit="submitForm"
                                :is-existing-patient="false"/>
                        </div>
                    </div>
                    <form-footer class="!pt-8">
                        <cancel-button
                            @click.prevent="cancel()">
                            Cancel
                        </cancel-button>
                        <primary-button
                            @click.prevent="submitForm">
                            Submit
                        </primary-button>
                    </form-footer>
                </form>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import {Inertia} from "@inertiajs/inertia"
import {useForm} from "@inertiajs/inertia-vue3"
import {
    provide,
    toRefs,
    reactive,
    computed,
} from "vue"
import Header from "../../../components/Front/Layout/Header"
import SidebarRegistration from "../../../components/Front/Layout/SidebarRegistration"
import BasicInformation from "./Partials/BasicInformation"
import PaymentMethod from "./Partials/PaymentMethod"
import Disclaimer from "./Partials/Disclaimer"
import TestBooking from "./Partials/TestBooking"
import Footer from "../../../components/Front/General/Footer";
import FormFooter from "../../../components/Front/Layout/FormFooter";
import CancelButton from "../../../components/Front/Buttons/CancelButton";
import PrimaryButton from "../../../components/Front/Buttons/PrimaryButton";
import {useVuelidate} from "@vuelidate/core";
import scrollToSmoothly from "../../../script";

const BASIC_INFORMATION = "basic-information"
const TEST_BOOKING = "test-booking"
const PAYMENT_METHOD = "payment-method"
const PAYMENT_SELF = "self"
const DISCLAIMER = "disclaimer"

export default {
    name: "AppointmentBooking",
    components: {
        PrimaryButton,
        CancelButton,
        FormFooter,
        SidebarRegistration,
        BasicInformation,
        TestBooking,
        PaymentMethod,
        Disclaimer,
        Header,
        Footer,
    },
    props: {
        raceList: {type: Object, required: true},
        genderList: {type: Object, required: true},
        ethnicityList: {type: Object, required: true},
        stateList: {type: Object, required: true},
        testList: {type: Object, required: true},
        pregnancyList: {type: Object, required: true},
        paymentMethodList: {type: Object, required: true},
        paymentStatusList: {type: Object, required: true},
    },
    setup(props) {
        const form = useForm("AppointmentBooking", {
            basic_information: {
                email: null,
                email_confirmation: null,
                first_name: null,
                last_name: null,
                dob: null,
                gender: null,
                phone_number: null,
                race: null,
                ethnicity: null,
                street_address_one: null,
                street_address_two: null,
                city: null,
                state: null,
                zip_code: null,
            },
            // appointment: {
            //     appointment_date: null,
            //     appointment_time: null,
            // },
            test_information: {
                name: null,
                is_first_test: null,
                is_health_care_worker: null,
                has_symptom: null,
                symptom_date: null,
                is_hospitalized: null,
                is_in_icu: null,
                is_in_congregate_care: null,
                is_pregnant: null,
                doctor_information: {
                    ordering_facility: null,
                    doctor_first_name: null,
                    doctor_last_name: null,
                    doctor_street_address_one: null,
                    doctor_street_address_two: null,
                    city: null,
                    state: null,
                    zip_code: null,
                    phone_number: null,
                },
            },
            payment_method: {
                payment_type: PAYMENT_SELF,
                driver_license: null,
                insurance_name: null,
                insurance_holder_name: null,
                insurance_id: null,
                insurance_group: null,
            },
            disclaimer: {
                isAccepted: null,
            },
        })

        const appState = reactive({
            basicInformationCompleted: false,
            testBookingCompleted: false,
            paymentMethodCompleted: false,
            disclaimerMethodCompleted: false,
        })

        const isMale = computed(() => form.basic_information.gender === 'male')

        const genderOptions = new Map()
        const raceOptions = new Map()
        const ethnicityOptions = new Map()
        const stateOptions = new Map()
        const testOptions = new Map()
        const pregnancyOptions = new Map()
        const paymentMethodOptions = new Map()
        const paymentStatusOptions = new Map()
        const yesNoOptions = new Map()

        Object.keys(props.genderList).forEach(key => genderOptions.set(key, props.genderList[key]))
        Object.keys(props.raceList).forEach(key => raceOptions.set(key, props.raceList[key]))
        Object.keys(props.ethnicityList).forEach(key => ethnicityOptions.set(key, props.ethnicityList[key]))
        Object.keys(props.stateList).forEach(key => stateOptions.set(key, props.stateList[key]))
        Object.keys(props.pregnancyList).forEach(key => pregnancyOptions.set(key, props.pregnancyList[key]))
        Object.keys(props.testList).forEach(key => testOptions.set(key, props.testList[key]))
        Object.keys(props.paymentMethodList).forEach(key => paymentMethodOptions.set(key, props.paymentMethodList[key]))
        Object.keys(props.paymentStatusList).forEach(key => paymentStatusOptions.set(key, props.paymentStatusList[key]))

        yesNoOptions.set(true, "Yes")
        yesNoOptions.set(false, "No")

        provide("raceOptions", raceOptions)
        provide("genderOptions", genderOptions)
        provide("ethnicityOptions", ethnicityOptions)
        provide("stateOptions", stateOptions)
        provide("pregnancyOptions", pregnancyOptions)
        provide("testOptions", testOptions)
        provide("paymentMethodOptions", paymentMethodOptions)
        provide("paymentStatusOptions", paymentStatusOptions)
        provide("yesNoOptions", yesNoOptions)
        provide("form", form)

        function cancel() {
            Inertia.get("/")
        }

        const v = useVuelidate()

        return {
            form,
            ...toRefs(appState),
            cancel,
            isMale,
            v
        }
    },
    provide() {
        return {userName: this.$attrs.user_name}
    },
    data() {
        return {
            componentDisplay: "basic-information",
        }
    },
    methods: {
        goToNext() {
            if (this.componentDisplay === BASIC_INFORMATION) {
                this.basicInformationCompleted = true
                this.componentDisplay = TEST_BOOKING
            } else if (this.componentDisplay === TEST_BOOKING) {
                this.testBookingCompleted = true
                this.componentDisplay = PAYMENT_METHOD
            } else if (this.componentDisplay === PAYMENT_METHOD) {
                this.paymentMethodCompleted = true
                this.componentDisplay = DISCLAIMER
            } else if (this.componentDisplay === DISCLAIMER) {
                this.disclaimerMethodCompleted = true
                if (this.form.disclaimer.isAccepted) {
                    this.submitForm()
                }
            }
        },
        goToPrevious() {
            if (this.componentDisplay === TEST_BOOKING) {
                this.basicInformationCompleted = false
                this.testBookingCompleted = false
                this.componentDisplay = BASIC_INFORMATION
            } else if (this.componentDisplay === PAYMENT_METHOD) {
                this.testBookingCompleted = false
                this.paymentMethodCompleted = false
                this.componentDisplay = TEST_BOOKING
            } else if (this.componentDisplay === DISCLAIMER) {
                this.paymentMethodCompleted = false
                this.disclaimerMethodCompleted = false
                this.componentDisplay = PAYMENT_METHOD
            }
        },
        submitForm() {
            if (this.isMale) {
                this.form.test_information.is_pregnant = "no"
            }

            this.v.$touch()

            this.$nextTick(() => {
                const inputError = document.querySelectorAll('.input-error')[0]
                if (inputError) {
                    scrollToSmoothly(inputError.offsetTop - 84);
                }
            })

            if (this.v.$error) return

            if (!this.form.processing) {
                this.form.post("/patient-registration")
            }
        },
    },
    //TODO: Update to vue 3 setup
    computed: {
        showBasicInformation: function () {
            return this.componentDisplay === BASIC_INFORMATION
        },
        showTestInformation: function () {
            return this.componentDisplay === TEST_BOOKING
        },
        showPaymentMethod: function () {
            return this.componentDisplay === PAYMENT_METHOD
        },
        showDisclaimerMethod: function () {
            return this.componentDisplay === DISCLAIMER
        },
    },
}
</script>

<style scoped>

</style>

<template>
    <div class="grid md:grid-cols-3 gap-5">
        <div :class="monthClass">
            <select-input  @change="checkMonth" :name="`${name}-month`" v-model="month" place-holder="MM" :options="monthList"
                            @update:modelValue="handleOnSelect"/>
        </div>
        <div :class="dayClass">
            <select-input @change="checkDay" :name="`${name}-day`" v-model="day" place-holder="DD" :options="dayList"
                            @update:modelValue="handleOnSelect"/>
        </div>
        <div :class="yearClass">
            <select-input @change="checkYear" :name="`${name}-year`" v-model="year" place-holder="YYYY" :options="yearList"
                            @update:modelValue="handleOnSelect"/>
        </div>
    </div>
</template>

<script>
    import SelectInput  from "./SelectInput"
    import useValidate  from "@vuelidate/core"
    import { required } from "@vuelidate/validators"
    import InputError   from "./InputError"
    import { reactive, toRefs } from "vue"

    export default {
        name: "SelectDatePicker",
        components: { SelectInput, InputError },
        props: {
            name: { type: String, required: false, default: "" },
            modelValue: { type: String, required: false },
            minYear: { type: Number, required: false, default: 1900 },
            maxYear: { type: Number, required: false, default: 2021 },
        },
        setup() {
            const appState = reactive({
                day: null,
                month: null,
                year: null,
                monthClass: "",
                dayClass: "",
                yearClass: "",
            })

            return {
                ...toRefs(appState)
            }
        },
        data() {
            return {
                v$: useValidate(),

            }
        },
        validations() {
            return {
                day: { required },
                month: { required },
                year: { required },
            }
        },
        watch: {
            modelValue: {
                handler(value) {
                    this.splitDate(value)
                },
                immediate: true,
            },
        },
        computed: {
            dayList() {
                const options = new Map()
                for (let i = 1; i <= 31; i++) {
                    options.set(i, i)
                }
                return options
            },
            monthList() {
                const options = new Map()

                for (let i = 1; i <= 12; i++) {
                    options.set(i, i)
                }

                return options
            },
            yearList() {
                const options = new Map()
                for (let i = this.maxYear; i >= this.minYear; i--) {
                    options.set(i, i)
                }
                return options
            },
        },
        methods: {
            checkDay() {
                this.v$.day.$touch()
                this.dayClass = "no-error"

            },
            checkMonth() {
                this.v$.month.$touch()
                this.monthClass = "no-error"
            },
            checkYear() {
                this.v$.year.$touch()
                this.yearClass = "no-error"
            },
            splitDate(date) {
                if (!date) {
                    this.year = null
                    this.month = null
                    this.day = null

                    return
                }

                const [year, month, day] = date.split("-")

                this.year = parseInt(year)
                this.month = parseInt(month)
                this.day = parseInt(day)
            },

            combineDate() {
                if (!this.year || !this.month || !this.day) {
                    return null
                }
                return `${this.year}-${this.month.toString().padStart(2, "0")}-${this.day.toString().padStart(2, "0")}`
            },

            handleOnSelect() {
                // if (!this.year || !this.month || !this.day) {
                //     return
                // }

                this.$emit("update:modelValue", this.combineDate())
            },
        },
    }
</script>


<!--<template>-->
<!--    <Select class="w-full"-->
<!--            @change="handleChange"-->
<!--            option-filter-prop="label"-->
<!--            :placeholder="placeHolder"-->
<!--            :value="value"-->
<!--        >-->
<!--        <select-option v-for="[key,value] in options" :key="key">-->
<!--            {{ value }}-->
<!--        </select-option>-->
<!--    </Select>-->
<!--</template>-->
<template>
    <Select
        placeholder="Select a person"
        option-filter-prop="children"
        style="width: 200px"
        :filter-option="filterOption"
        @change="handleChange"
    >
        <select-option value="jack">
            Jack
        </select-option>
        <select-option value="lucy">
            Lucy
        </select-option>
        <select-option value="tom">
            Tom
        </select-option>
    </Select>
</template>


<script>
    import {
        Select,
        SelectOption,
    } from "ant-design-vue"
    import "ant-design-vue/lib/select/style/index.css"

    export default {
        name: "SelectDropdown",
        props: {
            name: { type: String, required: true },
            modelValue: { type: [Number, String], required: false, default: null },
            placeHolder: { type: String, required: false, default: "" },
            options: { type: Map, required: false, default: () => (new Map()) },
        },
        components: { Select, SelectOption },
        methods: {
            handleChange(value) {

                // this.$emit("update:modelValue", value)
            },
            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                )
            },
        },
    }
</script>

<template>
    <div class="checkbox-group checkbox-group--secondary gap-5 pt-3 flex-col sm:flex-row !items-start sm:!items-center">
        <div v-for="(option) in optionsKeys" :key="option" class="flex">
            <input
                :id="name + '_' + option"
                :name="name"
                :required="required"
                type="checkbox"
                :value="option"
                v-model="value"/>
            <label class="capitalize" :for="name+'_' + option">{{ getLabel(option) }}</label>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CheckBoxGroupInput",
        props: {
            name: {
                type: String,
                default: null,
            },
            modelValue: {
                type: [Array],
                required: false,
                default: () => ([]),
            },
            options: {
                type: [Array, Object, Map],
                required: true,
            },
            required: {
                type: Boolean,
                default: false,
            },
        },
        emits: ["update:modelValue"],
        computed: {
            value: {
                get() {
                    return this.modelValue ?? []
                },
                set(value) {
                    this.$emit("update:modelValue", value)
                },
            },
            optionsKeys: function() {
                return this.options instanceof Map ? Array.from(this.options.keys()) : Object.keys(this.options)
            },
        },

        methods: {
            getLabel(key) {
                return (this.options instanceof Map ? this.options.get(key) : this.options[key]) || key
            },
            emitValue(value) {
                this.$emit("update:modelValue", value)
            },
        },
    }
</script>

<style scoped>

</style>

<template>
    <footer class="text-sm text-center p-6 bg-grey-200 text-grey-1000">
        <b class="text-grey-600">Address</b>: 11404 Old Georgetown Rd, Suite # 206, Rockville,  MD 20852 | <b class="text-grey-600">Email</b>: <a
        href="mailto:info@crisperdiagnostics.com" class="transition hover:text-secondary-100">info@crisperdiagnostics.com</a> | <b
        class="text-grey-600">Phone
    </b>:
        <a href="tel:+1-240-200-9093" class="transition hover:text-secondary-100">+1-240-200-9093</a>
    </footer>
</template>
<script>
    export default {
        name: "Footer",
    }
</script>

<style scoped lang="scss">

</style>

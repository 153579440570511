<template>
    <div
        class="search-result custom-scrollbar absolute w-full top-18 left-0  bg-white dark:bg-gray-800 !rounded shadow card--large !p-4"
        @click.stop>
        <h5 class="font-bold pb-3 text-grey-900">Search Result (<span v-text="searchValues.length"/>)</h5>
        <ul v-if="searchValues.length > 0" class="search-result-list pr-6 ">
            <li v-for="(value, index) in searchValues || []" :key="value.id" class="flex flex-row">
                <div class="select-none cursor-pointer flex flex-1 items-center py-4" @mousedown="handleSearchClick(value)">
                    <div class="text-grey-700 text-sm">
                        <b>{{ value.id }}</b>. {{ value.name }}
                        ( {{ value.dob }})
                    </div>
                </div>
            </li>
        </ul>
        <p v-else>Not found</p>
    </div>
</template>

<script>
export default {
    name: "SearchResults",
    props: {
        searchValues: {
            type: Array, required: false, default: () => {
                []
            }
        }
    },
    emits: ['itemClick'],
    methods: {
        handleSearchClick(value) {
            this.$emit('itemClick', value)
        }
    }
}
</script>

<style scoped>

</style>

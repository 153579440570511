<template>
    <button v-if="linkComponent === 'button'"
            :class="classes"
            :type="type"
            v-bind="$attrs">
        <span v-if="tooltipText" class="tooltip tooltip--right !text-grey-700" v-text="tooltipText"/>
        <Icon :name="actionType"/>
        <slot/>
    </button>
    <component
        v-else
        :is="linkComponent"
        :as="as"
        :class="classes"
        :href="href"
        v-bind="$attrs">
        <Icon :name="actionType"/>
        <span v-if="tooltipText" class="tooltip !text-grey-700" v-text="tooltipText"/>
        <slot/>
    </component>
</template>

<script>
import Icon from "../General/Icon";

export default {
    name: "ButtonComponent",
    inheritAttrs: false,
    components: {Icon,},
    props: {
        tooltipText: {type: String, required: false, default: null},
        actionType: {type: String, required: false, default: null},
        inertiaLink: {type: Boolean, required: false, default: true},
        type: {type: String, required: false, default: "button"},
        as: {type: String, required: false, default: "a"},
        href: {type: String, required: false, default: null},
    },

    computed: {
        classes: function () {
            let str = "btn-action tooltip__wrapper relative "

            if (this.actionType === 'view') {
                str = str + 'btn-action--view'
            } else if (this.actionType === 'pdf') {
                str = str + 'btn-action--pdf'
            } else if (this.actionType === 'accept') {
                str = str + 'btn-action--success'
            } else if (this.actionType === 'delete') {
                str = str + 'btn-action--fail'
            }

            return str;
        },
        linkComponent: function () {
            return this.href ? "a" : "button"
        },
    },
}
</script>

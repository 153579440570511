<template>
    <div class="p-10 flex justify-between items-center">
        <ul class="flex gap-1 p-0 rounded list-none flex-wrap">
            <li>
                <!--  disabled state class  -- text-grey-300 -->
                <a href="#pablo"
                   class="!text-grey-300 hover:text-tertiary-100 text-sm flex w-8 h-8 p-0 rounded items-center justify-center leading-tight relative pagination-button bg-white">
                    <Icon class="rotate-180 transform" name="arrow-left"></Icon>
                </a>
            </li>
            <li>
                <a href="#pablo"
                   class="bg-tertiary-100 !text-white text-grey-500 hover:text-tertiary-100 text-sm flex w-8 h-8 p-0 rounded items-center justify-center leading-tight relative pagination-button bg-white">
                    1
                </a>
            </li>
            <li>
                <a href="#pablo"
                   class="text-grey-500 hover:text-tertiary-100 text-sm flex w-8 h-8 p-0 rounded items-center justify-center leading-tight relative pagination-button bg-white">
                    2
                </a>
            </li>
            <li>
                <a href="#pablo"
                   class="text-grey-500 hover:text-tertiary-100 text-sm flex w-8 h-8 p-0 rounded items-center justify-center leading-tight relative pagination-button bg-white">
                    3
                </a>
            </li>
            <li>
                <a href="#pablo"
                   class="text-grey-500 hover:text-tertiary-100 text-sm flex w-8 h-8 p-0 rounded items-center justify-center leading-tight relative pagination-button bg-white">
                    ...
                </a>
            </li>
            <li>
                <a href="#pablo"
                   class="text-grey-500 hover:text-tertiary-100 text-sm flex w-8 h-8 p-0 rounded items-center justify-center leading-tight relative pagination-button bg-white">
                    4
                </a>
            </li>
            <li>
                <a href="#pablo"
                   class="text-grey-500 hover:text-tertiary-100 text-sm flex w-8 h-8 p-0 rounded items-center justify-center leading-tight relative pagination-button bg-white">
                    5
                </a>
            </li>
            <li>
                <a href="#pablo"
                   class="text-grey-700 hover:text-tertiary-100 text-sm flex w-8 h-8 p-0 rounded items-center justify-center leading-tight relative pagination-button bg-white">
                    <Icon name="arrow-left"/>
                </a>
            </li>
        </ul>
        <div class="flex gap-2 items-center">
            <span class="text-grey-700 text-xs" v-text="'Show per page'"/>
            <div class="form-icon text-grey-900">
                <select class="w-18 px-4 py-1.5 rounded bg-white focus:outline-none bg-white border border-grey-300 cursor-pointer"
                        v-model="value"
                        v-bind="$attrs">
                    <option value="15">15</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
                <Icon name="caret-down" class="w-2 h-1"/>
            </div>
        </div>
    </div>
</template>

<script>
    import Icon from "./../General/Icon"

    export default {
        name: "Pagination",
        components: { Icon },
        props: {
            modelValue: { type: [Number, String], required: false, default: null },
        },
        computed: {
            value: {
                get() {
                    return this.modelValue
                },
                set(value) {
                    this.$emit("update:modelValue", value)
                },
            },
        },
        emits: ["update:modelValue"],
    }
</script>

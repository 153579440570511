<template>
    <div>
        <admin-layout>
            <!-- breadcrumb -->
            <div class="flex items-center gap-3 pb-6 text-sm text-grey-500">
                <a :href="redirectUrl" class="text-tertiary-100 ">Requisitions</a>
                <Icon class="w-3 h-2.5" name="arrow-left"/>
                <span> #<span v-text="appointment.booking_id"/></span>
            </div>

            <div class="card--large ">

                <!-- title -->
                <div class="flex justify-between items-center pb-8">
                    <h1 class="heading-primary !text-grey-1100">
                        Reference ID #<span v-text="appointment.booking_id"/>
                    </h1>
                    <inertia-link :href="editRequisition(requisiton_edit.id)"
                                  class="flex gap-2 self-end items-center text-sm btn-link border border-solid rounded  py-2 px-3 cursor-pointer border-tertiary-100">
                        <Icon name="pencil" class="w-3 h-3"/>
                        Edit Requisition
                    </inertia-link>
                </div>

                <div class="grid gap-10 " v-for="reqInfo in preInformationAboutPatient.data" :key="reqInfo.id">
                    <!-- personal information -->
                    <div>
                        <div class="form-header">
                            <Icon name="basic-information-2"/>
                            <h2 class="heading-primary heading-primary-sm" v-text="'Personal Information'"/>
                        </div>
                        <div class="grid gap-6">

                            <div class="grid md:grid-cols-3 gap-3 gap-y-10">
                                <div>
                                    <label-component :value="'Email Address'"/>
                                    <label-value isCapitalize="lowercase" :value="reqInfo.basic_information.email"/>
                                </div>
                                <div>
                                    <label-component :value="'First Name'"/>
                                    <label-value :value="reqInfo.basic_information.first_name"/>
                                </div>
                                <div>
                                    <label-component :value="'Last Name'"/>
                                    <label-value :value="reqInfo.basic_information.last_name"/>
                                </div>

                                <div>
                                    <label-component :value="'Date Of Birth'"/>
                                    <label-value :value="reqInfo.basic_information.dob"/>
                                </div>
                                <div>
                                    <label-component :value="'Gender'"/>
                                    <label-value :value="reqInfo.basic_information.gender"/>
                                </div>
                                <div>
                                    <label-component :value="'Phone Number'"/>
                                    <label-value :value="reqInfo.basic_information.phone_number"/>
                                </div>
                                <div>
                                    <label-component :value="'Ethnicity'"/>
                                    <label-value :value="reqInfo.basic_information.ethnicity"/>
                                </div>
                                <div>
                                    <label-component :value="'Race'"/>
                                    <label-value :value="reqInfo.basic_information.race"/>
                                </div>
                                <div>
                                    <label-component :value="'Street Address'"/>
                                    <label-value :value="reqInfo.basic_information.street_address_one"/>
                                    <label-value v-if="reqInfo.basic_information.street_address_two" :value="','"/>
                                    <label-value class="capitalize text-grey-700"
                                                 v-if="reqInfo.basic_information.street_address_two"
                                                 :value="reqInfo.basic_information.street_address_two ? reqInfo.basic_information.street_address_two : '-'"/>
                                </div>
                            </div>
                            <div class="grid md:grid-cols-3 gap-3">
                                <div>
                                    <label-component :value="'City'"/>
                                    <label-value :value="reqInfo.basic_information.city"/>
                                </div>
                                <div>
                                    <label-component :value="'State'"/>
                                    <label-value :value="reqInfo.basic_information.state"/>
                                </div>
                                <div>
                                    <label-component :value="'Zip Code'"/>
                                    <label-value :value="reqInfo.basic_information.zip_code"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- covid test -->
                    <div>
                        <div class="form-header">
                            <Icon name="covid-test-booking-2"/>
                            <h2 class="heading-primary heading-primary-sm" v-text="'Covid Test'"/>
                        </div>
                        <div class="grid gap-6">
                            <!-- appointment -->
<!--                            <div>-->
<!--                                <h4 class="pb-8 font-bold text-2xl text-grey-800" style="font-size: 18px;">-->
<!--                                    Appointment</h4>-->
<!--                                <div class="grid gap-6">-->
<!--                                    <div class="grid grid-cols-2 gap-6">-->
<!--                                        <div>-->
<!--                                            <label-component :value="'Appointment Date'"/>-->
<!--                                            <label-value :value="reqInfo.appointment.appointment_date"/>-->
<!--                                        </div>-->
<!--                                        <div>-->
<!--                                            <label-component :value="'Appointment Time'"/>-->
<!--                                            <label-value :value="reqInfo.appointment.appointment_time"/>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="border border-solid border-grey-200 my-2"/>-->
                            <!-- test information -->
                            <div>
                                <h4 class="pb-8 font-bold text-2xl text-grey-800" style="font-size: 18px;">Test
                                    Information</h4>
                                <div class="grid md:grid-cols-3 gap-3 gap-y-10">
                                    <div v-for="test in reqInfo.test_information.name">
                                        <label-component :value="'Test Name'"/>
                                        <label-value :value="test.name"/>
                                    </div>
                                    <div>
                                        <label-component :value="'First Test'"/>
                                        <label-value :value="reqInfo.test_information.is_first_test"/>
                                    </div>
                                    <div>
                                        <label-component :value="'Health Care Workers'"/>
                                        <label-value :value="reqInfo.test_information.is_health_care_worker"/>
                                    </div>
                                    <div>
                                        <label-component :value="'Symptoms'"/>
                                        <label-value :value="reqInfo.test_information.has_symptom"/>
                                    </div>
                                    <div v-if="reqInfo.test_information.symptom_date">
                                        <label-component :value="'Symptoms onset date'"/>
                                        <label-value :value="reqInfo.test_information.symptom_date"/>
                                    </div>
                                    <div>
                                        <label-component :value="'Hospitalized'"/>
                                        <label-value :value="reqInfo.test_information.is_hospitalized"/>
                                    </div>
                                    <div>
                                        <label-component :value="'ICU'"/>
                                        <label-value :value="reqInfo.test_information.is_in_icu"/>
                                    </div>
                                    <div>
                                        <label-component :value="'Are you living in congregate care setting?'"/>
                                        <label-value :value="reqInfo.test_information.is_in_congregate_care"/>
                                    </div>
                                    <div v-if="reqInfo.basic_information.gender === 'Female'">
                                        <label-component :value="'Are you Pregnant?'"/>
                                        <label-value :value="reqInfo.test_information.is_pregnant"/>
                                    </div>
                                </div>
                            </div>
                            <div class="border border-solid border-grey-200 my-2"/>
                            <!-- doctor information -->
                            <div>
                                <h4 class="pb-8 font-bold text-2xl text-grey-800" style="font-size: 18px;">Doctor
                                    Information</h4>
                                <div class="grid gap-6">
                                    <div class="grid md:grid-cols-3 gap-3 gap-y-10">
                                        <div>
                                            <label-component :value="'Ordering Facility'"/>
                                            <label-value :value="reqInfo.doctor_information.ordering_facility ?
                                            reqInfo.doctor_information.ordering_facility : '-'"/>
                                        </div>
                                        <div>
                                            <label-component :value="'Ordering Doctor’s Name'"/>
                                            <label-value :value="reqInfo.doctor_information.doctor_name ? reqInfo.doctor_information.doctor_name :
                                            '-'"/>
                                        </div>
                                        <div>
                                            <label-component :value="'Doctor Street Address'"/>
                                            <label-value :value="reqInfo.doctor_information.doctor_street_address_one"/>
                                            <span v-if="reqInfo.doctor_information.doctor_street_address_one &&
                                                    reqInfo.doctor_information.doctor_street_address_two">,</span>
                                            <label-value v-if="reqInfo.doctor_information.doctor_street_address_two"
                                                         :value="reqInfo.doctor_information.doctor_street_address_two"/>
                                            <span v-if="!reqInfo.doctor_information.doctor_street_address_one &&
                                                    !reqInfo.doctor_information.doctor_street_address_two"
                                                  v-text="'-'"></span>
                                        </div>


                                        <div>
                                            <label-component :value="'Doctor City'"/>
                                            <label-value
                                                :value="reqInfo.doctor_information.city ? reqInfo.doctor_information.city : '-'"/>
                                        </div>
                                        <div>
                                            <label-component :value="'Doctor State'"/>
                                            <label-value
                                                :value="reqInfo.doctor_information.state ? reqInfo.doctor_information.state : '-'"/>
                                        </div>
                                        <div>
                                            <label-component :value="'Doctor Zip Code'"/>
                                            <label-value
                                                :value="reqInfo.doctor_information.zip_code ? reqInfo.doctor_information.zip_code : '-'"/>
                                        </div>

                                        <div>
                                            <label-component :value="'Doctor Phone Number'"/>
                                            <label-value :value="reqInfo.doctor_information.phone_number ? reqInfo.doctor_information.phone_number
                                            : '-'"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- sample -->
                    <div>
                        <div class="form-header">
                            <Icon name="sample-2"/>
                            <h2 class="heading-primary heading-primary-sm" v-text="'Sample'"/>
                        </div>
                        <table>
                            <thead>
                            <th style="width: 56px;" v-text="'S.No'"/>
                            <th v-text="'Collected By'"/>
                            <th v-text="'ID'"/>
                            <th v-text="'Test'"/>
                            <th v-text="'Type'"/>
                            <th v-text="'Collection on'"/>
                            <th v-text="'Received on'"/>
                            </thead>
                            <tbody>
                            <tr v-for="(sample,index) in reqInfo.samples">
                                <td v-text="index+1"/>
                                <td
                                    v-text="sample.collected_by"/>
                                <td v-text="sample.id"/>
                                <td>
                                    <p v-for="sample_test in sample.sample_tests" v-text="sample_test.test_name"></p>
                                </td>
                                <td v-text="sample.type"/>
                                <td
                                    v-text="sample.collected_on"/>
                                <td
                                    v-text="sample.received_on"/>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </admin-layout>
    </div>
</template>

<script>
import {ref} from "vue"
import AdminLayout from "../../../components/Admin/Layout/AdminLayout"
import Icon from "../../../components/Admin/General/Icon"
import CardHeader from "../../../components/Admin/General/CardHeader"
import LabelComponent from "../../../components/Admin/Forms/Label"
import LabelValue from "../../../components/Admin/Forms/LabelValue"
import {Link as InertiaLink} from '@inertiajs/inertia-vue3'

export default {
    name: "Show",
    props: {preInformationAboutPatient: Object},
    components: {
        AdminLayout,
        Icon,
        CardHeader,
        LabelComponent,
        LabelValue,
        InertiaLink,
    },

    setup() {
        const redirectUrl = ref(route("admin.requisitions.index"))

        function editRequisition(id) {
            console.log(id)
            return route("admin.requisitions.edit", id)

        }

        return {
            redirectUrl,
            editRequisition,
        }
    },

    data() {
        return {
            appointment: {
                booking_id: this.preInformationAboutPatient.data[0].appointment.id,
            },
            requisiton_edit: {
                id: this.preInformationAboutPatient.data[0].id,
            },
        }
    },
}
</script>

<template>
    <transition name="fade" appear :duration="{ enter: 200, leave: 200 }">
        <div
            v-if="show"
            class="overflow-auto modal transition duration-500  bg-white shadow ease-in-out fixed inset-0 w-full h-screen flex items-center justify-center z-9999 "
            @click.self="close">
            <div
                class="modal-content relative bg-white shadow  rounded px-8 py-10 flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 max-w-2xl mx-auto ">
                <div class="pb-8"
                >
                    <slot name="title"/>
                    <span @click="close" class="w-6 h-6 ic-close ic-close--modal"/>
                </div>
                <slot></slot>
            </div>
        </div>
    </transition>
</template>

<script>
import Icon from "../Admin/General/Icon"

export default {
    name: "Modal",
    components: {Icon,},
    props: {
        show: {
            type: Boolean, required: true,
        },
    },

    watch: {
        show(value) {
            if (value) {
                return document.querySelector("body").classList.add("overflow-hidden")
            }
            document.querySelector("body").classList.remove("overflow-hidden")
        },
    },

    methods: {
        close() {
            this.$emit("close")
        },
    },
}
</script>

<style scoped>
.modal {
    background: rgba(0,0,0,.2);
}
</style>

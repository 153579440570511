<template>
    <div>
        <div class="table-head-container !py-10">
            <!-- <search-bar v-model="searchText" class="md:w-1/3" @keydown.enter="fireSearch"/> -->
            <h1 class="heading-primary heading-primary-sm !leading-none"
                v-text="'Pending Tests'"
            />
            <div class="flex gap-4 items-center">
                <div class="flex justify-end">
                    <button
                        :class="!showTestBatchButton ? 'transition flex items-center gap-2 !py-2 !px-3 !rounded bg-grey-200 text-sm' :
                                'transition flex items-center gap-2 !py-2 !px-3 !rounded text-sm bg-tertiary-100 hover:bg-tertiary-200 text-white'"
                        :disabled="!showTestBatchButton"
                        @click="createWorkOrder">
                        <Icon class="w-3.5 h-3.5" name="plus"/>
                        Create Batch
                    </button>
                </div>
                <dropdown class="filter-dropdown" ref="filterdropdown" :show-drop-down-menu="showFilterDropDown">
                    <template v-slot:trigger>
                        <button-component class="btn-icon" @click="showFilterDropDown=true">
                            <Icon class="w-3.5 h-3.5" name="filter"/>
                            Filters
                        </button-component>
                    </template>
                    <template v-slot:content="{showMenu}">
                        <div class="px-6 pt-8">
                            <div class="py-4 border-b border-grey-200">
                                <label-component
                                    class="!text-xs !text-grey-900"
                                    value="Test Name"/>
                                <check-box-group-input
                                    class="flex-wrap"
                                    v-model="filters.test_name"
                                    name="test-name"
                                    :options="testOptions"/>
                            </div>
                            <div class="py-4 border-b border-grey-200">
                                <label-component class="!text-xs !text-grey-900" value="Sample Type"/>
                                <check-box-group-input
                                    class="flex-wrap"
                                    v-model="filters.sample_type"
                                    name="sample-type"
                                    :options="sampleOptions"/>
                            </div>
                            <div class="flex items-center justify-between py-4">
                                <button class="btn-link" @click="clearAllFilters">
                                    Clear all filters
                                </button>
                                <div>
                                    <cancel-button class="!text-xs" @click="$refs.filterdropdown.toggle()">
                                        Cancel
                                    </cancel-button>
                                    <primary-button class="!rounded !py-0.5 !px-4 !text-xs" @click="applyFilters">
                                        Apply Filter
                                    </primary-button>
                                </div>
                            </div>
                        </div>
                    </template>
                </dropdown>
            </div>
        </div>
        <div class="overflow-x-auto pending-table">
            <AntTable
                class="pending-test-table"
                :columns="pendingTestsColumns"
                :data-source="pendingTestsTableData"
                :row-selection="pendingTestsRowSelection"
                :pagination="false"
                :filterMultiple="false"
            >
                <template #filterSearch="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
                    <div class="grid gap-2 p-2">
                        <a-input
                            class="placeholder-grey-400"
                            ref="searchInput"
                            :placeholder="`Enter ${column.dataIndex.split('_').join(' ')}`"
                            :value="selectedKeys[0]"
                            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                        >

                        </a-input>
                        <div class="flex items-center justify-between gap-2">
                            <a-button
                                class="btn-icon"
                                type="primary"
                                size="small"
                                @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                            >
                                <Icon class="w-3.5 h-3.5" name="search"/>
                                Search
                            </a-button>
                            <a-button class="ant-reset" size="small" @click="handleReset(clearFilters)">
                                Reset
                            </a-button>
                        </div>
                    </div>

                </template>
                <template #filterIcon="filtered">
                    <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }"/>
                </template>
                <template #patientName="{record}">
                    <PatientName :name="record.patient_name"/>
                </template>
                <template #customRender="{ text, column }">
                            <span v-if="searchText && searchedColumn === column.dataIndex">
                                <template
                                    v-for="(fragment, i) in text
                                    .toString()
                                    .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
                                >
                                    <mark
                                        v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                                        class="highlight"
                                        :key="i"
                                    >
                                      {{ fragment }}
                                    </mark>
                                    <template v-else>{{ fragment }}</template>
                                </template>
                          </span>
                    <template v-else>
                        {{ text }}
                    </template>
                </template>
            </AntTable>
        </div>
    </div>
</template>

<script>
import {SearchOutlined} from "@ant-design/icons-vue"
import {default as AButton} from "ant-design-vue/lib/button"
import {default as AInput} from "ant-design-vue/lib/input"
import AntTable from "ant-design-vue/lib/table"
import FilterDropdown from "ant-design-vue/lib/table/filterDropdown"
import DropDownInput from "../../../../components/Admin/Forms/DropDownInput";
import Dropdown from "../../../../components/Admin/General/Dropdown";
import Icon from "../../../../components/Admin/General/Icon";
import ButtonComponent from "../../../../components/Admin/Buttons/Button";
import LabelComponent from "../../../../components/Admin/Forms/Label";
import CheckboxInput from "../../../../components/Admin/Forms/CheckboxInput";
import CheckBoxGroupInput from "../../../../components/Admin/Forms/CheckBoxGroupInput";
import CancelButton from "../../../../components/Admin/Buttons/CancelButton";
import PrimaryButton from "../../../../components/Admin/Buttons/PrimaryButton";
import {computed, toRefs, reactive, ref, watch} from "vue";
import {get as getFromObject} from "lodash"
import PatientName from "../../../../components/Admin/General/PatientName";

export default {
    name: "PendingTestsTab",
    components: {
        PatientName,
        AntTable,
        AButton,
        AInput,
        SearchOutlined,
        FilterDropdown,
        PrimaryButton,
        CancelButton,
        CheckBoxGroupInput,
        CheckboxInput, LabelComponent, ButtonComponent, Icon, Dropdown, DropDownInput,
    },
    props: {
        pendingTests: {required: true, type: Array},
        filterOptions: {required: true, type: Object},
    },
    emits: ['createWorkOrder'],
    setup(props, {emit}) {
        const appState = reactive({
            selectedTests: [],
            searchText: '',
            searchedColumn: '',
            filters: {
                patient_name: [],
                test_name: [],
                sample_type: [],
            },
            showFilterDropDown: false,
        })
        const showTestBatchButton = computed(() => {
            return appState.selectedTests.length > 0
        })

        const searchInput = ref()

        const filteredInfo = ref()
        const sortedInfo = ref()

        const pendingTestsColumns = computed(() => {
            const filtered = filteredInfo.value || {}
            const sorted = sortedInfo.value || {}
            return [
                {
                    title: "Req Id",
                    width: "6em",
                    dataIndex: "requisition_code",
                    key: "requisition_code",
                    sorter: (a, b) => a.requisition_code - b.requisition_code,
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: "Patient Name",
                    dataIndex: "patient_name",
                    key: "patient_name",
                    sorter: (a, b) => a.patient_name.localeCompare(b.patient_name),
                    sortDirections: ["descend", "ascend"],
                    slots: {
                        filterDropdown: "filterSearch",
                        filterIcon: "filterIcon",
                        customRender: "patientName",
                    },
                    onFilter: (value, record) => {
                        return record.patient_name.toString().toLowerCase().includes(value.toLowerCase())
                    },
                    onFilterDropdownVisibleChange: visible => {
                        if (visible) {
                            setTimeout(() => {
                                searchInput.value.focus()
                            }, 0)
                        }
                    },

                },
                {
                    title: "Test Name",
                    dataIndex: "test_name_formatted",
                    key: "test_name_formatted",
                    sorter: (a, b) => a.test_name.localeCompare(b.test_name),
                    sortDirections: ["descend", "ascend"],
                    filteredValue: filtered.test_name_formatted || null,
                    onFilter: (value, record) => {
                        return record.test_name.toString().toLowerCase().includes(value.toLowerCase())
                    }
                },
                {
                    title: "Sample ID",
                    dataIndex: "sample_id",
                    key: "sample_id",
                    sorter: (a, b) => a.sample_id - b.sample_id,
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: "Sample Type",
                    dataIndex: "sample_type_formatted",
                    key: "sample_type_formatted",
                    sorter: (a, b) => a.sample_type.localeCompare(b.sample_type),
                    sortDirections: ["descend", "ascend"],
                    filteredValue: filtered.sample_type_formatted || null,
                    onFilter: (value, record) => {
                        return record.sample_type.toString().toLowerCase().includes(value.toLowerCase())
                    }
                },
                {
                    title: "Sample Collection On",
                    dataIndex: "sample_collected_on_formatted",
                    key: "sample_collected_on_formatted",
                    sorter: (a, b) => a.sample_collected_on.localeCompare(b.sample_collected_on),
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: "Received Date",
                    dataIndex: "received_date_formatted",
                    key: "received_date_formatted",
                    sorter: (a, b) => a.received_date.localeCompare(b.received_date),
                    sortDirections: ["descend", "ascend"],
                },
            ]
        })

        const pendingTestsTableData = ref([])

        const pendingTestsRowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                appState.selectedTests = [...selectedRowKeys]
                // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected, selectedRows) => {
                // console.log(record, selected, selectedRows);
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
                // console.log(selected, selectedRows, changeRows);
            },
        };


        const testOptionsList = getFromObject(props, "filterOptions.tests", {})
        const sampleOptionsList = getFromObject(props, "filterOptions.samples", {})

        const testOptions = new Map()
        const sampleOptions = new Map()

        Object.keys(testOptionsList).forEach(key => testOptions.set(key, testOptionsList[key]))
        Object.keys(sampleOptionsList).forEach(key => sampleOptions.set(key, sampleOptionsList[key]))

        const clearAllFilters = () => {
            appState.searchText = ""
            appState.filters = {
                patient_name: [],
                test_name: [],
                sample_type: [],
            }
            filteredInfo.value = {
                patient_name: [],
                test_name_formatted: [],
                sample_type_formatted: [],
            }
        }

        const applyFilters = () => {
            filteredInfo.value = {
                patient_name: [appState.searchText],
                test_name_formatted: [...appState.filters.test_name],
                sample_type_formatted: [...appState.filters.sample_type],
            }
        }

        const handleChange = (pagination, filters, sorter) => {
            filteredInfo.value = filters
            sortedInfo.value = sorter
        }

        const handleSearch = (selectedKeys, confirm, dataIndex) => {
            confirm()
            appState.searchText = selectedKeys[0]
            appState.searchedColumn = dataIndex
        }

        const fireSearch = () => {
            filteredInfo.value = {
                patient_name: [appState.searchText],
            }
        }

        watch(() => props.pendingTests, (newValue, oldValue) => {
            pendingTestsTableData.value = []

            props.pendingTests.forEach((item, index) => {
                pendingTestsTableData.value.push({
                    key: item.id,
                    id: item.id,
                    requisition_id: item.requisition_id,
                    requisition_code: item.requisition_code,
                    patient_name: item.patient_name,
                    test_name: item.test_name,
                    test_name_formatted: item.test_name_formatted,
                    sample_id: item.sample_id,
                    sample_type: item.sample_type,
                    sample_type_formatted: item.sample_type_formatted,
                    sample_collected_on: item.sample_collected_on,
                    sample_collected_on_formatted: item.sample_collected_on_formatted,
                    received_date: item.received_date,
                    received_date_formatted: item.received_date_formatted,
                    status: item.status,
                })
            })
        }, {
            deep: true,
            immediate: true,
        })

        const createWorkOrder = () => {
            emit('createWorkOrder', appState.selectedTests)
        }

        const handleReset = clearFilters => {
            clearFilters()
            appState.searchText = ""
        }

        return {
            ...toRefs(appState),
            showTestBatchButton,
            createWorkOrder,
            pendingTestsColumns,
            pendingTestsTableData,
            pendingTestsRowSelection,
            filteredInfo,
            sortedInfo,
            testOptions,
            sampleOptions,
            handleChange,
            fireSearch,
            clearAllFilters,
            applyFilters,
            handleSearch,
            handleReset,
            searchInput
        }
    }
}
</script>

<style lang="scss" scoped>
.pending-table {
    min-height: 270px;
}
</style>

<template>
    <admin-layout :active-link="'tests'">
        <div class="card--large !p-0">
            <!-- title and dropdown -->
            <div class="table-head-container !pb-0">
                <h1
                    class="heading-primary !text-grey-1100"
                    v-text="'Tests'"
                />
            </div>
            <div class="tab">
                <div class="tab__list  px-10 pt-2 gap-2" aria-label="Tabs">
                    <div
                        @click="showTab(TAB_TEST)"
                        :class="currentTab === TAB_TEST  ? 'tab__item is--active !rounded-b-none bg-white !text-sm':'tab__item bg-white !rounded-b-none !text-sm'">
                        <span>Pending Tests</span>
                    </div>
                    <div
                        @click="showTab(TAB_TEST_BATCH)"
                        :class="currentTab === TAB_TEST_BATCH  ?
                        'tab__item is--active !rounded-b-none bg-white !text-sm':'tab__item bg-white !rounded-b-none !text-sm'">
                        <span>Batch</span>
                    </div>
                </div>
            </div>
            <!-- table -->

            <PendingTestsTab
                v-if="currentTab === TAB_TEST"
                :pending-tests="samples.data"
                :filter-options="pendingTestsFilterOptions"
                @create-work-order="openTestBatchModal($event)"
            />
            <WorkOrdersTab
                v-if="currentTab === TAB_TEST_BATCH"
                :work-orders="testBatches.data"
                @upload-result="uploadResult($event,$event)"
                @refresh="refreshWorkOrdersTab"
            />
        </div>
    </admin-layout>
    <modal :show="showTestBatchModal" @close="closeTestBatchModal">
        <template #title>
            <h2 class="heading-primary heading-primary-md">Create Batch</h2>
        </template>
        <template #default>
            <div class="sm:w-3/4">

                <label-component value="Batch(Optional)"/>
                <input-component v-model="testBatchName"/>
            </div>
            <div class="pt-10 flex items-center gap-4 justify-end">
                <secondary-button @click="closeTestBatchModal">
                    Cancel
                </secondary-button>
                <primary-button class="!px-8" @click="saveTestBatch">
                    Save
                </primary-button>
            </div>
        </template>
    </modal>
    <modal :show="showUploadResultModal" @close="closeUploadResultModal">
        <template #title>
            <h2 class="heading-primary heading-primary-md">Upload Result</h2>
        </template>
        <template v-if="isPCR" #default>
            <div class="w-full sm:w-1/2">
                <h1 class="header-primary heading-primary-sm !text-grey-1000" v-text="'Test Type : SARS-CoV-2 RT-PCR'"/>
                <label-component value="Result"/>
                <select-input
                    :options="resultOptions"
                    :name="`test-${resultUploadTestId}`"
                    v-model="resultValue.pcr.results['sars-cov-2-rt-pcr'].test_result"
                />
            </div>
            <div class="pt-10 flex items-center gap-4 justify-end">
                <secondary-button @click="closeUploadResultModal">
                    Cancel
                </secondary-button>
                <primary-button class="!px-8" @click="saveResult(resultValue.pcr.test_name)">
                    Save
                </primary-button>
            </div>
        </template>
        <template v-else #default>
            <div class="grid sm:grid-cols-2 gap-8 pt-3">
                <div>
                    <h1 class="header-primary !text-base !text-grey-1000 !pb-3"
                        v-text="'Test Type : IgG Antibody'"/>
                    <label-component value="Result"/>
                    <select-input
                        :options="resultOptions"
                        :name="`test-${resultUploadTestId}`"
                        v-model="resultValue.antibody.results.igg.test_result"
                    />
                </div>
                <div>
                    <h1 class="header-primary !text-base !text-grey-1000 !pb-3"
                        v-text="'Test Type : IgM Antibody'"/>
                    <label-component value="Result"/>
                    <select-input
                        :options="resultOptions"
                        :name="`test-${resultUploadTestId}`"
                        v-model="resultValue.antibody.results.igm.test_result"
                    />
                </div>
            </div>
            <div class="btn-wrap justify-end pt-10">
                <secondary-button @click="closeUploadResultModal">
                    Cancel
                </secondary-button>
                <primary-button class="!px-6" @click="saveResult(resultValue.antibody.test_name)">
                    Save
                </primary-button>
            </div>
        </template>
    </modal>
</template>

<script>
import {useForm} from "@inertiajs/inertia-vue3"
import {get as getFromObject} from "lodash"
import {
    computed,
    nextTick,
    reactive,
    ref,
    toRefs,
    watch,
} from "vue"
import { Inertia } from '@inertiajs/inertia'
import ButtonComponent from "../../../components/Admin/Buttons/Button"
import CancelButton from "../../../components/Admin/Buttons/CancelButton"
import PrimaryButton from "../../../components/Admin/Buttons/PrimaryButton"
import SecondaryButton from "../../../components/Admin/Buttons/SecondaryButton"
import CheckboxInput from "../../../components/Admin/Forms/CheckboxInput"
import CheckBoxGroupInput from "../../../components/Admin/Forms/CheckBoxGroupInput"


    import InputComponent  from "../../../components/Admin/Forms/Input"
    import LabelComponent  from "../../../components/Admin/Forms/Label"
    import RadioGroupInput from "../../../components/Admin/Forms/RadioGroupInput"
    import SelectInput from "../../../components/Admin/Forms/SelectInput"
    import Icon            from "../../../components/Admin/General/Icon"
    import Pagination      from "../../../components/Admin/General/Pagination"
    import AdminLayout     from "../../../components/Admin/Layout/AdminLayout"
    import Modal           from "../../../components/Admin/Modal"
    import Dropdown        from "../../../components/Admin/General/Dropdown"
    import PendingTestsTab from "./Partials/PendingTestsTab";
    import WorkOrdersTab from "./Partials/WorkOrdersTab";

const TAB_TEST = "test"
const TAB_TEST_BATCH = "test_batch"
const SARS_CoV_2_RT_PCR = 'sars-cov-2-rt-pcr'
const COVID_Antibody_Rapid_Test = 'covid-antibody-rapid-test'

export default {
    name: "Index",
    components: {
        WorkOrdersTab,
        PendingTestsTab,
        Dropdown,
        SelectInput,
        SecondaryButton,
        ButtonComponent,
        CancelButton,
        Modal,
        CheckboxInput,
        CheckBoxGroupInput,
        Pagination,
        Icon,
        PrimaryButton,
        AdminLayout,
        InputComponent,
        LabelComponent,
        RadioGroupInput,
    },
    props: ["samples", "testBatches", "pendingTestsFilterOptions"],
    setup(props) {
        const selectAllCheckBox = ref(null)
        let isPCR = ref(false);
        const appState = reactive({
            isLoading: false,
            sampleTableData: getFromObject(props, "samples.data", []),
            testBatchTableData: getFromObject(props, "testBatches.data", []),
            pagination: {},
            query: {
                search: "test",
            },
            selectedTests: [],
            selectAll: false,
            showTestBatchModal: false,
            testBatchName: "",
            currentTab: TAB_TEST,
            resultValue: {
                pcr: {
                    test_name: SARS_CoV_2_RT_PCR,
                    results: {
                        "sars-cov-2-rt-pcr": {
                            test_result: null,
                        },
                    }
                },
                antibody: {
                    test_name: COVID_Antibody_Rapid_Test,
                    results: {
                        igg: {
                            test_result: null,
                        },
                        igm: {
                            test_result: null,
                        },
                    }
                }
            },
            resultUploadTestId: null,
            showUploadResultModal: false,
        })

        const query = ref({
            search: "",
        })

        watch(() => appState.selectedTests, (newValue, oldValue) => {
            nextTick(function () {
                if (!selectAllCheckBox.value) {
                    return
                }
                if (newValue.length === 0) {
                    selectAllCheckBox.value.indeterminate = false
                    appState.selectAll = false
                }
                if (newValue.length < appState.sampleTableData.length) {
                    selectAllCheckBox.value.indeterminate = true
                    appState.selectAll = true
                }
                if (newValue.length === appState.sampleTableData.length) {
                    selectAllCheckBox.value.indeterminate = false
                    appState.selectAll = true
                }
            })
        })

        const sampleData = toRefs(props.samples)
        const testBatchData = toRefs(props.testBatches)

        watch(() => sampleData, (newValue, oldValue) => {
            appState.sampleTableData = getFromObject(newValue, "data", [])
        }, {
            deep: true,
            immediate: true,
        })

        watch(() => testBatchData, (newValue, oldValue) => {
            appState.testBatchTableData = getFromObject(newValue, "data", [])
        }, {
            deep: true,
            immediate: true,
        })

        function selectTests() {
            if (selectAllCheckBox.value.indeterminate) {
                appState.selectedTests = []

                appState.sampleTableData.forEach(item => {
                    appState.selectedTests.push(item.id)
                })
                return
            }

            if (appState.selectAll) {
                appState.selectedTests = []
                return
            }

            appState.selectedTests = []

            appState.sampleTableData.forEach(item => {
                appState.selectedTests.push(item.id)
            })

        }

        function openTestBatchModal(selectedTests) {
            appState.selectedTests = [...selectedTests]
            appState.showTestBatchModal = true
        }

        function selectSingleTest() {
            console.log(appState.sampleTableData.length, appState.selectedTests.length)
        }

        const testBatchForm = useForm("testBatchForm", {
            tests: [],
            name: "",
        })

        function saveTestBatch() {
            testBatchForm.tests = appState.selectedTests
            testBatchForm.name = appState.testBatchName
            testBatchForm.post(route("admin.test-batch.store"), {
                preserveScroll: true,
                onSuccess: () => {
                    appState.showTestBatchModal = false
                    console.log("success")
                    appState.testBatchTableData = getFromObject(props, "testBatches.data", [])
                    appState.sampleTableData = getFromObject(props, "samples.data", [])
                    appState.currentTab = TAB_TEST_BATCH
                },
                onError: () => {
                    console.log("Error")
                },
            })
        }

        function showTab(tab) {
            if (appState.currentTab !== tab) {
                if (appState.currentTab === TAB_TEST) {
                    appState.currentTab = TAB_TEST_BATCH
                    appState.selectedTests = []
                    appState.selectAll = false
                    appState.showTestBatchModal = false
                    appState.testBatchName = ""
                    return
                }

                if (appState.currentTab === TAB_TEST_BATCH) {
                    appState.currentTab = TAB_TEST
                    appState.selectedTests = []
                    appState.selectAll = false
                    appState.showTestBatchModal = false
                    appState.testBatchName = ""
                }
            }
        }

        function closeTestBatchModal() {
            appState.testBatchName = ""
            appState.showTestBatchModal = false
        }

        const showTestBatchButton = computed(() => {
            return appState.selectedTests.length > 0
        })

        const uploadResultForm = useForm("uploadResultForm", {
            result: "",
        })

        const resultOptions = new Map()
        const testTypeOptions = new Map()

        function uploadResult(...args) {
            const [id, test_name] = args
            appState.resultUploadTestId = id.id
            if (test_name.test_name === "SARS-CoV-2 RT-PCR") {
                resultOptions.set("detected", "Detected")
                resultOptions.set("not_detected", "Not Detected")
                isPCR.value = true
            } else {
                resultOptions.set("positive", "Positive")
                resultOptions.set("negative", "Negative")
                isPCR.value = false
            }
            appState.showUploadResultModal = true
        }

        function closeUploadResultModal() {
            appState.resultUploadTestId = null
            appState.resultValue.antibody.results.igg.test_result = null
            appState.resultValue.antibody.results.igm.test_result = null
            appState.resultValue.pcr.results[SARS_CoV_2_RT_PCR].test_result = null
            resultOptions.clear()
            testTypeOptions.clear()
            isPCR.value = false
            appState.showUploadResultModal = false
        }

        function saveResult(test_type) {
            test_type === COVID_Antibody_Rapid_Test ? uploadResultForm.result = appState.resultValue.antibody : uploadResultForm.result = appState.resultValue.pcr

            uploadResultForm.post(route("admin.tests.result.upload", appState.resultUploadTestId), {
                preserveScroll: true,
                onSuccess: () => {
                    appState.testBatchTableData = getFromObject(props, "testBatches.data", [])
                    closeUploadResultModal()

                    appState.showUploadResultModal = false
                    appState.resultValue.antibody.results.igg.test_result = null
                    appState.resultValue.antibody.results.igm.test_result = null
                    appState.resultValue.pcr[SARS_CoV_2_RT_PCR].test_result = null
                    appState.resultUploadTestId = null

                    testTypeOptions.clear()
                },
                onError: () => {
                    console.log("Error")
                },
            })
        }

        function downloadWorkOrder(id) {
            return (route("admin.test-batch.download.csv", id))
        }

        const refreshWorkOrdersTab = () => {
            Inertia.reload({
                only: ['testBatches']
            })
        }

        return {
            downloadWorkOrder,
            ...toRefs(appState),
            selectTests,
            selectSingleTest,
            selectAllCheckBox,
            showTestBatchButton,
            openTestBatchModal,
            closeTestBatchModal,
            saveTestBatch,
            resultOptions,
            uploadResult,
            closeUploadResultModal,
            saveResult,
            TAB_TEST,
            TAB_TEST_BATCH: TAB_TEST_BATCH,
            showTab,
            isPCR,
            testTypeOptions,
            refreshWorkOrdersTab
        }
    },
}
</script>

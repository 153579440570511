<template>
    <div>
        <div class="grid gap-8">
            <div class="form-header flex items-center border-b border-blue-400 pb-4 !mb-0">
                <Icon class="mr-3 !w-7 h-7" name="disclaimer-1"/>
                <h3 class="heading-primary heading-primary-sm" v-text="'Disclaimer'"/>
            </div>
            <div class="grid gap-8 overflow-auto custom-scrollbar custom-scrollbar--secondary pr-8">
                <p style="line-height: 1.7;">
                    You authorize Crisper Diagnostics LLC to collect your specimen and conduct the intended laboratory
                    test, and you agree to receive your test results electronically via Email you have provided in this
                    form. If you are a parent or guardian of a child, you authorize Crisper Diagnostics LLC to collect
                    the specimen from your child and send the test results via an Email you have provided with this
                    form.
                </p>

            </div>
            <div class="text-sm">
                <div class="custom-checkbox custom-checkbox--secondary">
                    <input id="disclaimer-accept" class="w-3.5 h-3.5" type="checkbox" value="accepted"
                           v-model="resultValue">
                    <label for="disclaimer-accept">I Accept</label>
                </div>
                <input-error v-if="v$.resultValue.$error" :message="v$.resultValue.$errors[0].$message"/>
            </div>
        </div>
        <div v-if="showButtons"
             class="flex gap-6 flex-col md:flex-row items-center md:justify-between mt-6 pt-6 border-t border-blue-400">
            <div class="ml-auto w-full sm:w-auto flex gap-4 justify-end">
                <cancel-button @click="goBack" class="!py-2.5">Back</cancel-button>
                <primary-button @click="nextPage" :disabled="!resultValue" class="btn px-10 !py-2.5" v-text="'Submit'"/>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "../../../../components/Front/Buttons/Button"
import CancelButton from "../../../../components/Front/Buttons/CancelButton"
import PrimaryButton from "../../../../components/Front/Buttons/PrimaryButton"
import Icon from "../../../../components/Front/General/Icon"
import {computed, ref, watch, onMounted} from "vue";
import {useVuelidate} from "@vuelidate/core"
import {helpers, required} from "@vuelidate/validators";
import InputError from "../../../../components/Front/Forms/InputError"
import vueRecaptcha from 'vue3-recaptcha2';

export default {
    name: "DisclaimerMethod",
    components: {
        Button,
        PrimaryButton,
        CancelButton,
        Icon,
        InputError,
        vueRecaptcha
    },
    inheritAttrs: false,
    emits: ["submit", "next", "back", "update:modelValue"],
    props: {
        showButtons: {type: Boolean, required: false, default: false},
        isExistingPatient: {type: Boolean, required: false, default: true},
    },

    setup(props, {emit}) {
        const resultValue = ref();
        //TODO: update here
        const hasCaptchaVerified = ref(true);

        const mustBeChecked = (value) => value === true

        const rules = computed(() => ({
            resultValue: {
                required,
                mustBeTrue: helpers.withMessage("Disclaimer must be checked", mustBeChecked)
            },
        }))
        const v$ = useVuelidate(rules, { resultValue })

        function nextPage() {
            v$.value.$touch()
            if (v$.value.$error) return

            emit("next")
        }

        function goBack() {
            emit("back")
        }

        watch(resultValue, () => {
            emit("update:modelValue", resultValue.value)
        })

        onMounted(() => {
            if (!props.isExistingPatient) {
                let recaptchaScript = document.createElement('script')
                recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js')
                document.head.appendChild(recaptchaScript)
            }
        })

        function recaptchaVerified(response) {
            hasCaptchaVerified.value = true;
        }

        function recaptchaExpired() {
            this.$refs.vueRecaptcha.reset();
            hasCaptchaVerified.value = false;
        }

        function recaptchaFailed() {
            hasCaptchaVerified.value = false;
        }

        return {
            nextPage,
            goBack,
            resultValue,
            v$,
            recaptchaVerified,
            recaptchaExpired,
            recaptchaFailed,
            hasCaptchaVerified,
        }
    },
}
</script>

<style lang="scss" scoped>
.checkbox-default {
    input:checked {
        background: var(--color-secondary-100);
    }
}
</style>

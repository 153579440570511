<template>
    <dropdown class="filter-dropdown" ref="filterdropdown">
        <template v-slot:trigger>
            <button-component class="btn-icon">
                <Icon class="w-3.5 h-3.5" name="filter"/>
                Filters
            </button-component>
        </template>
        <template v-slot:content="{showMenu}">
            <div class="px-8 py-6">
                <date-picker v-model="state.dateRange"
                             is-range
                             mode="date"
                             :masks="masks"
                             :popover="{ visibility: 'focus' }"
                >
                    <template v-slot="{ inputValue, inputEvents }">
                        <span class="block !pb-3" v-text="'Filter by date'"/>
                        <div class="grid grid-cols-2 gap-4">
                            <div>
                                <label-component value="From"/>
                                <div class="form-icon">
                                    <input
                                        :value="inputValue.start"
                                        v-on="inputEvents.start"
                                        class="form-control"
                                        placeholder="Select date"
                                    />
                                    <Icon name="date-time"/>
                                </div>
                            </div>
                            <div>
                                <label-component value="To"/>
                                <div class="form-icon">
                                    <input
                                        :value="inputValue.end"
                                        v-on="inputEvents.end"
                                        class="form-control"
                                        placeholder="Select date"
                                    />
                                    <Icon name="date-time"/>
                                </div>
                            </div>
                        </div>
                    </template>
                </date-picker>
            </div>
            <div class="px-8 py-6 flex items-center justify-between">
                <button class="btn-link" @click="clearAllFilters">
                    Clear all filters
                </button>
                <div>
                    <cancel-button class="!text-xs" @click="$refs.filterdropdown.toggle()">
                        Cancel
                    </cancel-button>
                    <primary-button class="!rounded !py-0.5 !px-4 !text-xs" @click="applyFilters">
                        Apply Filter
                    </primary-button>
                </div>
            </div>
        </template>
    </dropdown>
</template>

<script>
    import Dropdown        from "../../../../components/Admin/General/Dropdown"
    import ButtonComponent from "../../../../components/Admin/Buttons/Button"
    import Icon            from "../../../../components/Admin/General/Icon"
    import LabelComponent  from "../../../../components/Admin/Forms/Label"
    import CancelButton    from "../../../../components/Admin/Buttons/CancelButton"
    import PrimaryButton   from "../../../../components/Admin/Buttons/PrimaryButton"
    import { DatePicker }  from "v-calendar"
    import { reactive, ref, toRefs, inject, computed, watch } from "vue"

    export default {
        name: "RequisitionListFilterDropdown",
        components: {
            ButtonComponent,
            CancelButton,
            DatePicker,
            Dropdown,
            Icon,
            LabelComponent,
            PrimaryButton,
        },
        props: {
            show: {
                type: Boolean,
                required: false,
                default: false,
            },
            dateRange: {
                type: Object,
                required: true,
            }
        },
        setup( props, {emit}) {
            const filterdropdown = ref(null)
            const state = reactive({
                showFilterDropDown: false,
                dateRange: {
                    start: null,
                    end: null,
                }
            })

            const resultValue = computed(() => {
                return {
                    start: state.dateRange.start,
                    end: state.dateRange.end,
                }
            })

            const updateDateRange = inject('updateDateRange')

            watch(resultValue, () => {
                updateDateRange(resultValue.value)
            }, {
                deep: true,
                immediate: true,
            })

            watch(() => props.dateRange, (newVal) => {
                state.dateRange.start = newVal.start
                state.dateRange.end = newVal.end
            }, {
                deep: true,
                immediate: true,
            })

            const masks = inject('masks')
            const mode = inject('mode')
            const datePickerConfig = inject('datePickerConfig')

            const applyFilters = inject('applyFilters')
            const clearAllFilters = inject('clearAllFilters')

            return {
                filterdropdown,
                state,
                applyFilters,
                clearAllFilters,
                masks,
                mode,
                datePickerConfig,
            }
        }
    }
</script>

<style scoped>

</style>

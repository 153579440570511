<template>
    <div
        class="sidebar bg-white">
        <ul>
            <li v-for="(item,key) in navItems" :key="key">
                <a :href="item.href"
                   :class="item.isActive ?
                           'sidebar__link p-5 transition duration-300 bg-tertiary-100 text-white flex items-center gap-2 text-sm block capitalize':
                           'sidebar__link p-5 transition duration-300 flex items-center gap-2 text-grey-900 hover:text-tertiary-100 text-sm block capitalize'">
                    <Icon :name="item.icon" class="!w-5 !h-5"/>
                    <span v-text="item.label"/>
                </a>
            </li>
        </ul>
    </div>
</template>


<script>
    import UserDropdown         from "../General/UserDropdown.vue"
    import Icon                 from "./../General/Icon"

    export default {
        name: "Sidebar",
        components: {
            Icon,
            UserDropdown,
        },
        props: {
            activeLink: { type: String, required: false },
        },
        data() {
            return {
                collapseShow: "hidden",
                navItems: [
                    { label: "appointments", icon: "appointments", href: route("appointment.index"), isActive: false },
                    { label: "requisitions", icon: "requisition", href: route("admin.requisitions.index"), isActive: false },
                    { label: "tests", icon: "test", href: route("admin.tests.index"), isActive: false },
                    { label: "review", icon: "result", href: route("admin.results.index"), isActive: false },
                    { label: "billing", icon: "report", href: route("admin.reports.index"), isActive: false },
                    { label: "patients", icon: "patient", href: route("admin.patient.index"), isActive: false },
                    { label: "clients", icon: "client", href: route("admin.clients.index"), isActive: false },
                    { label: "users", icon: "user-1", href: route("admin.users.index"), isActive: false },
                ],
            }
        },
        mounted() {
            this.navItems.forEach(item => item.label === this.activeLink ? item.isActive = true : item.isActive = false)
        },
        methods: {
            toggleCollapseShow: function(classes) {
                this.collapseShow = classes
            },
        },
    }
</script>


<style lang="scss">
.sidebar li:last-child svg {
    height: 19px !important;
    width: 19px !important;
}
</style>

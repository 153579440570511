<template>
    <h4 class="font-bold text-2xl text-grey-800 pb-6" v-text="title" style="font-size: 18px;"/>
</template>

<script>
    export default {
        name: "H1",
        props: {
            title: { type: String, required: true },
        },
    }
</script>

import * as tests from '../constants/tests'
import * as samples from '../constants/samples'
import { get as _get, set as _set } from 'lodash'

export const getFromObject = ($obj, $key, $default = null) => {
    return _get($obj, $key, $default)
}

export const setObject = ($obj, $path, $value  = null) => {
    return _set($obj, $path, $value)
}

export const isPCR = (test) => {
    return test === tests.SARS_COV_2_RT_PCR
}

export const isRapidTest = (test) => {
    return test === tests.COVID_ANTIBODY_RAPID_TEST
}

export const isCompleted = (status) => {
    return status === samples.COMPLETED
}

export const isApproved = (status) => {
    return status === samples.RESULT_APPROVED
}

export const isPending = (status) => {
    return status === samples.PENDING
}

export const loadOrder = (sorter) => {
    if (!sorter) {
        return ''
    }
    const order = getFromObject(sorter, 'order', 'ascend')
    const column = getFromObject(sorter, 'field', '')
    return order === 'ascend' ? `${column}` : `-${column}`
}

export  const preparePaginationFromData = (data) => {
    return {
        current: (getFromObject(data, "meta.current_page", 1)),
        total: (getFromObject(data, "meta.total", 0)),
        pageSize: (getFromObject(data, "meta.per_page", 20)),
        from: (getFromObject(data, "meta.from", 1)),
    }
}

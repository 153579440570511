<template>
    <div>
        <card-header title="Payment Method"/>
        <div class="grid gap-8">
            <div class="grid ">
                <label-component v-text="'Payment'"/>
                <radio-group-input
                    name="payment_type"
                    :options="paymentMethodOptions"
                    v-model="payment_type"/>
                <input-error
                    v-if="getFromObject(errors, 'payment.payment_type')"
                    :message="getFromObject(errors, 'payment.payment_type')"/>
                <input-error v-if="v$.payment_type.$error" :message="v$.payment_type.$errors[0].$message"/>
            </div>

            <div v-if="payment_type === 'self'">
                <h4 class="font-bold mb-8" v-text="'Self Payment'"/>
                <div class="md:grid md:grid-cols-3 gap-10 gap-y-6">
                    <div>
                        <label-component v-text="'Driver’s License / Identification Number'"/>
                        <input-component
                            type="text"
                            name="driver_license"
                            v-model="driver_license"/>
                        <input-error
                            v-if="getFromObject(errors, 'payment.driver_license')"
                            :message="getFromObject(errors, 'payment.driver_license')"/>
                        <input-error v-if="v$.driver_license.$error" :message="v$.driver_license.$errors[0].$message"/>
                    </div>
                    <div>
                        <label-component v-text="'Payment Status'"/>
                        <radio-group-input
                            name="payment_status"
                            :options="paymentStatusOptions"
                            v-model="payment_status"/>
                        <input-error
                            v-if="getFromObject(errors, 'payment.payment_status')"
                            :message="getFromObject(errors, 'payment.payment_status')"/>
                        <input-error v-if="v$.payment_status.$error" :message="v$.payment_status.$errors[0].$message"/>
                    </div>
                </div>
            </div>
            <div v-if="payment_type === 'insurance'">
                <h4 class="font-bold mb-8" v-text="'Insurance'"/>
                <div class="md:grid md:grid-cols-3 gap-10 gap-y-6">
                    <div>
                        <label-component v-text="'Insurance Name'"/>
                        <input-component type="text" name="insurance_name" v-model="insurance_name"/>
                        <input-error
                            v-if="getFromObject(errors, 'payment.insurance_name')"
                            :message="getFromObject(errors, 'payment.insurance_name')"/>
                        <input-error v-if="v$.insurance_name.$error" :message="v$.insurance_name.$errors[0].$message"/>
                    </div>
                    <div>
                        <label-component v-text="'Primary Insurance Holder’s Name'"/>
                        <input-component type="text" name="insurance_holder_name" v-model="insurance_holder_name"/>
                        <input-error
                            v-if="getFromObject(errors, 'payment.insurance_holder_name')"
                            :message="getFromObject(errors, 'payment.insurance_holder_name')"/>
                        <input-error v-if="v$.insurance_holder_name.$error" :message="v$.insurance_holder_name.$errors[0].$message"/>
                    </div>
                    <div>
                        <label-component v-text="'Insurance ID'"/>
                        <input-component type="text" name="insurance_id" v-model="insurance_id"/>
                        <input-error
                            v-if="getFromObject(errors, 'payment.insurance_id')"
                            :message="getFromObject(errors, 'payment.insurance_id')"/>
                        <input-error v-if="v$.insurance_id.$error" :message="v$.insurance_id.$errors[0].$message"/>
                    </div>
                    <div>
                        <label-component v-text="'Insurance Group'"/>
                        <input-component type="text" name="insurance_group" v-model="insurance_group"/>
                        <input-error
                            v-if="getFromObject(errors, 'payment.insurance_group')"
                            :message="getFromObject(errors, 'payment.insurance_group')"/>
                        <input-error v-if="v$.insurance_group.$error" :message="v$.insurance_group.$errors[0].$message"/>
                    </div>
                    <div>
                        <label-component v-text="'Upload Insurance Document'"/>
                        <div class="file__input-wrap file__input--secondary rounded">
                            <input type="file" class="file__input" @input="inputFile">
                            <span v-if="hasUploadedFile" v-text="uploadedFileName"/>
                            <span v-if="!hasUploadedFile" class="pl-2 text-sm">No file Choosen</span>
                            <span class="file__input-trigger" v-text="'Choose File'"/>
                        </div>
                        <label-component class="pt-2 !pb-0 !text-xs"
                                         :value="'Max 5MB of PDF or image (jpeg, jpg, png) file can be uploaded '"></label-component>
                        <input-error v-if="v$.insurance_document.$error" :message="v$.insurance_document.$errors[0].$message"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { useVuelidate }         from "@vuelidate/core"
    import {
        helpers,
        required,
    }                               from "@vuelidate/validators"
    import { get as getFromObject } from "lodash"
    import {
        computed,
        inject,
        toRefs,
        watch,
        reactive,
    }                               from "vue"
    import DropDownInput            from "../../../../components/Admin/Forms/DropDownInput"
    import InputComponent           from "../../../../components/Admin/Forms/Input"
    import InputError               from "../../../../components/Admin/Forms/InputError"
    import LabelComponent           from "../../../../components/Admin/Forms/Label"
    import RadioGroupInput          from "../../../../components/Admin/Forms/RadioGroupInput"
    import H3                       from "../../../../components/Admin/typography/H3"
    import H2                       from "../../../../components/Admin/typography/H2"
    import CardHeader               from "../../../../components/Admin/General/CardHeader"

    export default {
        name: "PaymentMethodNew",
        components: { InputError, InputComponent, LabelComponent, DropDownInput, RadioGroupInput, H2, H3, CardHeader },
        inheritAttrs: false,
        emits: ["submit", "back", "update:modelValue"],
        props: {
            modelValue: { type: Object, required: false, default: () => ({}) },
            errors: { type: Object, required: false, default: () => ({}) },
            showButtons: { type: Boolean, required: false, default: false },
        },
        setup(props, { emit }) {
            const paymentMethodOptions = inject("paymentMethodOptions")
            const paymentStatusOptions = inject("paymentStatusOptions")

            const appState = reactive({
                payment_type: null,
                driver_license: null,
                payment_status: null,
                insurance_name: null,
                insurance_holder_name: null,
                insurance_id: null,
                insurance_group: null,
                insurance_document: null,
            })

            const resultValue = computed(() => {
                return {
                    payment_type: appState.payment_type,
                    driver_license: appState.driver_license,
                    payment_status: appState.payment_status,
                    insurance_name: appState.insurance_name,
                    insurance_holder_name: appState.insurance_holder_name,
                    insurance_id: appState.insurance_id,
                    insurance_group: appState.insurance_group,
                    insurance_document: appState.insurance_document,
                }
            })

            watch(resultValue, () => {
                emit("update:modelValue", resultValue.value)
            })

            let { modelValue } = toRefs(props)

            watch(() => modelValue.value, (newValue, oldValue) => {
                appState.payment_type = newValue.payment_type
                appState.driver_license = newValue.driver_license
                appState.payment_status = newValue.payment_status
                appState.insurance_name = newValue.insurance_name
                appState.insurance_holder_name = newValue.insurance_holder_name
                appState.insurance_id = newValue.insurance_id
                appState.insurance_group = newValue.insurance_group
                appState.insurance_document = newValue.insurance_document
            }, { deep: true, immediate: true })

            const hasPaymentTypeSelf = (value) => {
                if (!appState.payment_type) {
                    return true
                }

                if (!(appState.payment_type === "self")) {
                    return true
                }

                return !!value
            }

            const hasPaymentTypeInsurance = (value) => {
                if (!appState.payment_type) {
                    return true
                }

                if (!(appState.payment_type === "insurance")) {
                    return true
                }

                return !!value
            }

            const rules = computed(() => ({
                payment_type: {
                    required,
                },
                driver_license: {
                    requiredIfPaymentTypeSelf: helpers.withMessage("Required", hasPaymentTypeSelf),
                },
                payment_status: {
                    requiredIfPaymentTypeSelf: helpers.withMessage("Required", hasPaymentTypeSelf),
                },
                insurance_name: {
                    requiredIfPaymentTypeSelf: helpers.withMessage("Required", hasPaymentTypeInsurance),
                },
                insurance_holder_name: {
                    requiredIfPaymentTypeSelf: helpers.withMessage("Required", hasPaymentTypeInsurance),
                },
                insurance_id: {
                    requiredIfPaymentTypeSelf: helpers.withMessage("Required", hasPaymentTypeInsurance),
                },
                insurance_group: {
                    requiredIfPaymentTypeSelf: helpers.withMessage("Required", hasPaymentTypeInsurance),
                },
                insurance_document: {
                    requiredIfPaymentTypeSelf: helpers.withMessage("Required", hasPaymentTypeInsurance),
                },
            }))

            const v$ = useVuelidate(rules, appState)

            return { v$, ...toRefs(appState), getFromObject, paymentMethodOptions, paymentStatusOptions }
        },
        data() {
            return {
                paymentMethod: "self",
                hasUploadedFile: false,
                uploadedFileName: undefined,
            }
        },
        methods: {
            nextPage() {
                this.v$.$touch()
                if (this.v$.$error) return

                this.$emit("next")
            },
            goBack() {
                this.$emit("back")
            },
            inputFile(e) {
                if (e.target.files.length > 0) {
                    this.insurance_document = e.target.files[0]
                    this.hasUploadedFile = true
                    this.uploadedFileName = this.insurance_document.name
                }
            },
        },
    }
</script>

<style scoped>

</style>

<template>
    <web-layout>
        <div>
            <div class="container !py-10 flex container--large !px-6">
                <div class="m-auto md:flex items-center gap-8">
                    <p>Patient Home</p>
                    <secondary-button href="/logout">Logout</secondary-button>
                </div>
            </div>
        </div>
    </web-layout>
</template>

<script>
    import SecondaryButton from "../../../components/Front/Buttons/SecondaryButton"
    import WebLayout from "../../../components/Front/Layout/WebLayout"

    export default {
        name: "Dashboard",
        components: { SecondaryButton, WebLayout },
        props: {
            user: { type: Object, required: false, default: () => ({}) },
        },
    }
</script>

<style scoped>

</style>

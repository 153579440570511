<template>
    <div class="w-full pr-6" v-click-outside="closeSuggestion">
        <div>
            <input
                type="text"
                :placeholder="placeholder"
                class="form-control"
                @input="search"
                v-model="searchText"
                @keydown.enter.prevent="selectHighlighted"
                @keydown.up="highlightPrev"
                @keydown.down="highlightNext"
            >
            <button @click="selectHighlighted"
                    class="absolute top-0 right-0 h-full pl-4 pr-5 py-3 text-tertiary-100" type="button">
                <Icon name="search" class="!w-5 !h-5"/>
            </button>
        </div>
        <div v-if="showSearchResults"
             class="search-result  absolute w-full top-18 left-0  bg-white dark:bg-gray-800 !rounded shadow card--large !p-0  !pb-4"
             :class=" options.length > 6 ? '!pr-4' : ''"
        >
            <h5 class="font-bold pb-3 text-grey-900 p-4">Search Result (<span v-text="options.length"/>)
            </h5>
            <ul @mousemove="highlightOption" ref="scrollContainer" v-if="options.length"
                class="custom-scrollbar search-result-list" :class=" options.length > 6 ? 'pr-4' : ''">
                <li ref="options" v-for="(item, index) in options"
                    @click="select(item)"
                    :key="item.id"
                    class="autocomplete-result flex text-grey-700 text-sm flex-row select-none cursor-pointer flex flex-1 items-center py-3 px-4"
                    :class="{ 'is-active': index === highlightedIndex }">
                    {{ item.value }}<span class="text-xs ml-1 pointer-events-none">({{ item.dob }})</span>
                </li>
            </ul>
            <p v-else class="px-4">Not found</p>
        </div>
    </div>
</template>
<script>
import {
    ref,
    inject
} from "vue"
import { get as getFromObject } from "lodash"
import Icon from "../General/Icon";
import vClickOutside from "click-outside-vue3";

export default {
    name: 'SearchSelect',
    props: {
        placeholder: {Type: String, required: false, default: "Search"},
        options: {Type: Array, required: false},
    },
    components: {Icon,},

    data() {
        return {
            searchId: null,
            searchText: "",
            scrollContainer: null,
            showSearchResults: false,
            highlightedIndex: 0,
        }
    },

    setup() {
        const scrollContainer = ref(null);
        return {scrollContainer,}
    },

    methods: {
        search() {
            this.showSearchResults = true
            this.highlightedIndex = 0
            this.$emit('search', this.searchText)
        },

        select(item) {
            this.$emit('select', getFromObject(item, "key"))
            this.searchText = getFromObject(item, "value")
            this.searchId = getFromObject(item, "key")
            this.showSearchResults = false
        },

        scrollToHighlighted() {
            // this.scrollContainer.children[this.highlightedIndex].scrollIntoView({block: 'nearest'});
            this.scrollContainer.scrollTop = (this.scrollContainer.children[this.highlightedIndex].offsetTop - 50);
        },

        highlight() {
            if (this.highlightedIndex < 0) {
                this.highlightedIndex = this.options.length - 1;
            }

            if (this.highlightedIndex > this.options.length - 1
            ) {
                this.highlightedIndex = 0;
            }
            this.scrollToHighlighted();
        },

        highlightPrev() {
            if (!this.showSearchResults) return

            if (this.showSearchResults) {
                if (!(this.highlightedIndex > 0)) {
                    this.highlightedIndex = this.options.length - 1
                } else {
                    this.highlightedIndex -= 1
                }
                this.highlight();
            }
        },

        highlightNext() {
            if (!this.showSearchResults) return

            if (this.highlightedIndex === null) {
                this.highlightedIndex = 0
            } else {
                this.highlightedIndex += 1
            }

            if (this.highlightedIndex === this.options.length) {
                this.highlightedIndex = 0
            }
            this.highlight();
        },

        highlightOption(e) {
            // Find the index of the highlighted option
            let node = e.target
            let listArr = [...this.scrollContainer.children]
            let nodeIndex = Array.prototype.indexOf.call(listArr, node);
            this.highlightedIndex = nodeIndex;
        },

        selectHighlighted(e) {
            if (this.options[this.highlightedIndex]) {
                this.searchText = this.options[this.highlightedIndex].value
                this.$emit('select', getFromObject(this.options, `${this.highlightedIndex}.key`))
                this.closeSuggestion()
            }
        },

        closeSuggestion() {
            this.showSearchResults = false
        }
    },

    directives: {
        clickOutside: vClickOutside.directive
    },
}
</script>


